import React from 'react'
import { Box, Card } from '@mui/material'

import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(() => ({
  root: {
    whiteSpace: 'normal',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  mediaCampaigns: {
    marginLeft: 12,
    marginTop: 4,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
}))

function Header({ Title, TitleMeta, EndComponent, classes = {} }) {
  const { classes: defaultClasses } = useStyles()
  const titleStyles = classes.title ? classes.title : defaultClasses.title
  const containerStyles = classes.container
    ? classes.container
    : defaultClasses.container

  return (
    <Card>
      <Box className={containerStyles} p={2} mt={3}>
        <Box className={titleStyles}>
          {Title && <Title />}
          {TitleMeta && <TitleMeta />}
        </Box>
        <Box>{EndComponent && <EndComponent />}</Box>
      </Box>
    </Card>
  )
}

export default Header
