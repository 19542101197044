import React from 'react'
import { Paper, Typography } from '@mui/material'
import Page from 'src/components/Page'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import Hanifi from 'src/assets/images/home/hanifi.webp'
import Link from '@mui/material/Link'
import { useSelector } from 'react-redux'
import { userSelector } from 'src/selectors/accountSelectors'
import { startCase } from 'lodash'

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: '#fff',
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
//   width: 200,
//   height: 200,
// }))

const Container = styled(Paper)(() => ({
  height: '90%',
  width: '95%',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
}))

const TitleWrapper = styled(Box)(({ theme }) => ({
  height: '30%',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '200px',
}))

const Text = styled(Typography)(() => ({
  fontFamily: 'PT-Sans,Helvetica, sans-serif',
  marginBottom: '8px',
  textAlign: 'center',
}))

const Img = styled('img')(() => ({
  height: '250px',
  marginLeft: '10px',
}))

const Email = styled(Link)(() => ({
  display: 'flex',
  justifyContent: 'center',
}))

export default function Home() {
  const user = useSelector(userSelector)

  // const handleNoImage = (e) => {
  //   const copy = { ...e }
  //   copy.target.src =
  //     'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930'
  //   return copy
  // }

  const name = user.firstName ? `, ${startCase(user.firstName)}` : ''

  return (
    <Page title="Home" style={{ height: '100%' }}>
      <Box
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Container elevation={5}>
          <TitleWrapper>
            <Text variant="h1">Herzlich willkommen in MySmile{name}</Text>
            <Text variant="h3">
              Hier kannst du dein Smile-Setup anpassen oder ganz neu aufstellen.
            </Text>
            <Text variant="h3">
              Bei Fragen und Feedback wende dich bitte und gerne jederzeit an
              deinen Customer Success Manager:
            </Text>
            <Box display="flex" justifyContent="space-evenly" mt={3}>
              <Box>
                <Img src={Hanifi} alt="Hanifi CSM" />
                <Email href="mailto:hanifi.yapar@smile.bi">
                  <Typography
                    variant="body2"
                    display="inline"
                    // className={classes.colorBlack}
                  >
                    hanifi.yapar@smile.bi
                  </Typography>
                </Email>
              </Box>
            </Box>
          </TitleWrapper>
        </Container>
      </Box>
    </Page>
  )
}
