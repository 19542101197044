function generateActionType(type) {
  return `@katSoll/${type}`
}

// filters
export const UPDATE_FILTERS = generateActionType('UPDATE_FILTERS')

// sidebar
export const CLOSE_SIDEBAR = generateActionType('CLOSE_SIDEBAR')
export const OPEN_DRAWER = generateActionType('OPEN_DRAWER')
export const SELECT_ROW = generateActionType('SELECT_ROW')

// table
export const SET_RESULTS = generateActionType('SET_RESULTS')
export const FETCHING = generateActionType('FETCHING')
export const FETCHING_404 = generateActionType('FETCHING_404')
export const FETCHING_ERROR = generateActionType('FETCHING_ERROR')
export const PAGE_CHANGE = generateActionType('PAGE_CHANGE')
export const SET_SIDEBAR_ABDA = generateActionType('SET_SIDEBAR_ABDA')
export const REMOVE_KATSOLL = generateActionType('REMOVE_KATSOLL')
export const DELETE_MANY = generateActionType('DELETE_MANY')
export const DELETE_ALL = generateActionType('DELETE_ALL')
export const SET_ALL_KEYWORDS = generateActionType('SET_ALL_KEYWORDS')
export const IMPORT_SHOPS = generateActionType('IMPORT_SHOPS')
export const UPDATE_SIDEBAR_VALUE = generateActionType('UPDATE_SIDEBAR_VALUE')
export const IMPORT_CATEGORIES = generateActionType('IMPORT_CATEGORIES')
export const ADD_NEW_KATSOLL = generateActionType('ADD_NEW_KATSOLL')
export const RESET_SETTINGS = generateActionType('RESET_SETTINGS')
export const IMPORT_FILTERS = generateActionType('IMPORT_FILTERS')
export const IMPORT_MANUFACTURERS = generateActionType('IMPORT_MANUFACTURERS')
export const IMPORT_BRANDS = generateActionType('IMPORT_BRANDS')
export const ADD_CHECKED_PZN = generateActionType('ADD_CHECKED_PZN')
export const REMOVE_CHECKED_PZN = generateActionType('REMOVE_CHECKED_PZN')
export const UPDATE_ADD_TO_ALL = generateActionType('UPDATE_ADD_TO_ALL')
export const REMOVE_TRACKED = generateActionType('REMOVE_TRACKED')
export const TRACK_REJECTED_CATEGORIES = generateActionType(
  'TRACK_REJECTED_CATEGORIES',
)
