import { useRef, useCallback } from 'react'
import axios from 'src/utils/axios'

const useCancelToken = () => {
  const axRef = useRef(null)
  const { CancelToken } = axios

  const cancel = useCallback(() => {
    const source = CancelToken.source()
    axRef.current = source
    return axRef.current
  }, [CancelToken])

  return { source: cancel() }
}

export default useCancelToken
