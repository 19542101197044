import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Typography,
  Checkbox,
  Box,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'src/utils/axios'
import useSimpleNotification from 'src/hooks/useSimpleNotification'
import { getCurrentKatSoll, getThreadValue } from '../lib/selectors'
import BasicBlueButton from 'src/components/BasicBlueButton'
import { addNewKatsoll } from '../lib/actions'
import { Loader2 } from './LinearProgress'
import useVersionSwitcher from '../hooks/useVersionSwitcher'

const CategoryRecomendationModalWhenNoResults = ({
  selectedCategory,
  onClose,
}) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const thread = useSelector(getThreadValue(selectedCategory?.subBrandId)) || {}
  const domainId = thread?.shop?.id || null
  const [checked, setChecked] = useState(new Map())
  const noti = useSimpleNotification()
  const dispatch = useDispatch()
  const katSollDetails = useSelector(
    getCurrentKatSoll(selectedCategory?.subBrandId),
  )
  const { currentVersion } = useVersionSwitcher()

  const selectAll = () => {
    if (data.length === checked.size) {
      setChecked(new Map())
    } else {
      const newChecked = new Map()
      data.forEach((item) => {
        newChecked.set(item.url, item)
      })
      setChecked(newChecked)
    }
  }

  const selectItem = (item) => {
    if (checked.has(item.url)) {
      setChecked((prev) => {
        const copy = new Map(prev)
        copy.delete(item.url)
        return copy
      })
    } else {
      setChecked((prev) => new Map(prev.set(item.url, item)))
    }
  }

  const saveToDb = async (item) => {
    try {
      const alreadyExists = katSollDetails.find(
        (kat) => kat.categoryLink === item.url,
      )
      console.log('alreadyExists', alreadyExists)
      if (!selectedCategory?.subBrandId || !domainId || alreadyExists) {
        return
      }

      const { data: category } = await axios.get(
        `/categories/subbrands/category/url`,
        {
          params: {
            url: item.url,
          },
        },
      )

      const body = {
        subBrandId: selectedCategory?.subBrandId,
        domainId: domainId,
        category: category.category,
        categoryLink: category.category_link,
        categoryRd: category.category_rd,
        valid: true,
      }
      // console.log(body)
      await dispatch(addNewKatsoll(body))
    } catch (error) {
      console.log(error)
      noti('error')
    }
  }

  const onAddSelected = async () => {
    if (checked.size === 0) {
      return
    }
    checked.forEach(async (item) => {
      const kat = checked.get(item.url)
      await saveToDb(kat)
    })
    noti('success')
    onClose()
  }

  useEffect(() => {
    async function fetch() {
      const body = {
        domainId,
        name: selectedCategory?.name,
      }
      setLoading(true)
      setError(false)
      try {
        const { data } = await axios.post(
          `/categories/subbrands/katsoll/suggestions-by-name/${currentVersion}`,
          body,
        )
        // const data = {
        //   recommendations: [
        //     {
        //       rank: 1,
        //       url: 'https://www.delmed.de/category/zahngesundheit.33426.html',
        //       name: 'Delmed > Zahngesundheit ',
        //     },
        //     {
        //       rank: 2,
        //       url: 'https://www.delmed.de/category/zahnpasta.11950.html',
        //       name: 'Delmed > Zahnpasta',
        //     },
        //     {
        //       rank: 3,
        //       url: 'https://www.delmed.de/category/zahnseide.11951.html',
        //       name: 'Delmed > Zahnseide',
        //     },
        //     {
        //       rank: 4,
        //       url: 'https://www.delmed.de/category/zungenreiniger.33438.html',
        //       name: 'Delmed > Zungenreiniger',
        //     },
        //     {
        //       rank: 5,
        //       url: 'https://www.delmed.de/category/zungenreiniger.11952.html',
        //       name: 'Delmed > Zungenreiniger',
        //     },
        //   ],
        // }
        setData(data.recommendations)
      } catch (error) {
        console.error(error)
        noti('error')
        setError(true)
      } finally {
        setLoading(false)
      }
    }

    if (!!selectedCategory) {
      fetch()
    }

    return () => {
      setData([])
      setChecked(new Map())
    }
  }, [selectedCategory, domainId, noti])

  if (!!selectedCategory === false) {
    return null
  }

  return (
    <Dialog open={!!selectedCategory} onClose={onClose} maxWidth="lg">
      <DialogTitle>Category Recommendations</DialogTitle>
      <DialogContent sx={{ width: '40vw', height: '55vh' }}>
        {error && (
          <Typography variant="subtitle1">
            Error, contact our support please.
          </Typography>
        )}
        {loading && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            height="100%"
            gap={2}
          >
            {/* <Typography variant="h5">Loading...</Typography> */}
            <Loader2 />
          </Box>
        )}

        {!loading && (
          <List>
            <ListItem>
              <Box display="flex" alignItems="center" gap={1}>
                <Checkbox
                  onChange={selectAll}
                  checked={data.length === checked.size}
                />
                <ListItemText primary="Select all" />
              </Box>
            </ListItem>
            {data.map((item, index) => (
              // ...

              <ListItem key={index}>
                {/* <ListItemIcon>
                  <IconButton onClick={() => onAdd(item)}>
                    <SvgIcon>
                      <AddCircle />
                    </SvgIcon>
                  </IconButton>
                </ListItemIcon> */}
                <Checkbox
                  onChange={() => selectItem(item)}
                  checked={checked.has(item.url)}
                />
                <ListItemText
                  primary={item.name}
                  secondary={
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.url}
                    </a>
                  }
                />
              </ListItem>
            ))}
          </List>
        )}
        {!loading && (
          <Box display="flex" justifyContent="flex-end">
            <BasicBlueButton onClick={onAddSelected} text="Add selected" />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default CategoryRecomendationModalWhenNoResults
