function generateActionType(type) {
  return `@damTexts/${type}`
}

export const SET_TEXTFILES = generateActionType('SET_TEXTFILES')
export const UNSELECT_TEXTFILE = generateActionType('UNSELECT_TEXTFILE')
export const SELECT_TEXTFILE = generateActionType('SELECT_TEXTFILE')
export const SELECT_MANY_TEXTFILES = generateActionType('SELECT_MANY_TEXTFILES')
export const CLEAR_ALL_TEXTFILES = generateActionType('CLEAR_ALL_TEXTFILES')
export const CLEAR_ALL_TEXTFILES_FOR_PZN = generateActionType(
  'CLEAR_ALL_TEXTFILES_FOR_PZN',
)
export const OPEN_IMPLEMENTATION_DETAILS = generateActionType(
  'OPEN_IMPLEMENTATION_DETAILS',
)
