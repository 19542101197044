import axios from 'src/utils/axios'
import { errorHandler } from 'src/utils/errorHandler'
import {
  UPDATE_FILTERS,
  ADD_CHECKED_PZN,
  REMOVE_CHECKED_PZN,
  CLOSE_SIDEBAR,
  OPEN_DRAWER,
  FETCHING,
  SET_RESULTS,
  FETCHING_404,
  FETCHING_ERROR,
  PAGE_CHANGE,
  CLEAR_SELECTED_KW,
  DEACTIVATE_KEYWORDS,
  SET_IMPORTED_SERVICES,
  ADD_NEW_SERVICE,
} from './actionTypes'

export const getBookedServices =
  ({ page, filters, source }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: FETCHING,
      })

      const params = {
        page,
        companyName: filters?.search || null,
      }

      const { data } = await axios.get(
        '/smile-customers/customer-booked-services',
        {
          params,
          cancelToken: source.token,
        },
      )
      const { companiesCount, ...rest } = data
      return dispatch({
        type: SET_RESULTS,
        payload: {
          data: rest,
          count: companiesCount,
        },
      })
    } catch (error) {
      if (axios.isCancel(error)) {
        return null
      }
      const is404 = error?.response?.status === 404
      if (is404) {
        return dispatch({
          type: FETCHING_404,
        })
      }
      dispatch({
        type: FETCHING_ERROR,
      })
      return errorHandler(error, {})
    }
  }

export const addNewService = (kw) => async () => {
  try {
    return await axios.post('/keywords', { name: kw })
  } catch (error) {
    return errorHandler(error, {})
  }
}

// eslint-disable-next-line no-unused-vars
export const deleteKeywordsAction = (keywords) => async (dispatch) => {
  try {
    const ids = keywords.map((kw) => kw.id)
    await axios.patch('/keywords/deactivate', { ids })
    dispatch({
      type: DEACTIVATE_KEYWORDS,
      payload: ids,
    })
    return dispatch(clearCheckedKw())
  } catch (error) {
    return errorHandler(error, {})
  }
}

export const importServices = () => async (dispatch) => {
  try {
    const { data } = await axios.get('/smile-customers/services')
    return dispatch({
      type: SET_IMPORTED_SERVICES,
      payload: data,
    })
  } catch (error) {
    return errorHandler(error, {})
  }
}

export const addNewSubService = (value, service) => async (dispatch) => {
  try {
    const body = {
      serviceId: service?.id || null,
      name: value,
    }
    const { data } = await axios.post(
      '/smile-customers/add-new-sub-service',
      body,
    )

    return dispatch({
      type: ADD_NEW_SERVICE,
      payload: data,
    })
  } catch (error) {
    return errorHandler(error, {})
  }
}

export const pageChangeAction = (page) => (dispatch) =>
  dispatch({
    type: PAGE_CHANGE,
    payload: page,
  })

export const updateFiltersAction = (target, value) => (dispatch) =>
  dispatch({
    type: UPDATE_FILTERS,
    payload: {
      target,
      value,
    },
  })

export const addCheckedPzn = (pzn) => (dispatch) => {
  dispatch({
    type: ADD_CHECKED_PZN,
    payload: pzn,
  })
}
export const removeCheckedPzn = (pzn) => (dispatch) => {
  dispatch({
    type: REMOVE_CHECKED_PZN,
    payload: pzn,
  })
}

export const clearCheckedKw = () => (dispatch) =>
  dispatch({ type: CLEAR_SELECTED_KW })

export const closeDrawerAction = () => (dispatch) =>
  dispatch({
    type: CLOSE_SIDEBAR,
  })
export const openDrawer = () => (dispatch) => dispatch({ type: OPEN_DRAWER })
