import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  AppBar,
  Box,
  Button,
  Divider,
  Toolbar,
  Hidden,
  Typography,
  Link,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { APP_VERSION } from 'src/config'
import Logo from 'src/components/Logo'

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  toolbar: {
    height: 64,
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}))

function TopBar({ className, ...rest }) {
  const { classes, cx } = useStyles()

  return (
    <AppBar className={cx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/app/home">
          <Logo className={classes.logo} />
        </RouterLink>
        <Hidden lgDown>
          <Typography variant="caption" color="textSecondary">
            Version {APP_VERSION}
          </Typography>
        </Hidden>
        <Box flexGrow={1} />
      </Toolbar>
    </AppBar>
  )
}

TopBar.propTypes = {
  className: PropTypes.string,
}

export default TopBar
