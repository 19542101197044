import { combineReducers } from 'redux'
import {
  DAM_WIZARD_REDUCER_NAME,
  SENDING_HISTORY_REDUCER_NAME,
  DAM_WIZARD_REDUCER_NAME2,
  DAM_PACKSHOTS_REDUCER,
  DAM_TEXTFILES_REDUCER,
  DAM_WIZARD_SHOPS,
  DAM_SUBJECT_REDUCER,
  PACKSHOTS_RATING_REDUCER_NAME,
  CUSTOMER_PANEL,
  NEWSLETTER_CONFIG,
  DESCRIPTION_RATING_REDUCER,
  WKZ_DATA_INPUT_CONFIG,
  WKZ_DATA_INPUT,
  KEYWORDS,
  GSHOPPING,
  KEYWORDS_MGMT,
} from 'src/constants/reducerNames'
import {
  BookedServicesReducer,
  BOOKED_SERVICES_REDUCER_NAME,
} from 'src/views/management/BookedServices'
import {
  ABDA_KW_REDUCER,
  AbdaKeywordsReducer,
} from 'src/views/management/AbdaKeywords'
import {
  ABDA_KATSOLL_REDUCER,
  AbdaKatSollReducer,
} from 'src/views/management/AbdaKatSoll'
import { KATSOLL_REDUCER, KatSollReducer } from 'src/views/management/KatSoll'
import accountReducer from './accountReducer'
import AmazonProductsReducer from './amazonProductsReducer'
import HelpReducer from './helpReducer'
import TopBarCTAReducer from './topBarCTAReducer'
import WkzReducer from './wkzReducer'
import WkzMediaListReducer from './wkzMediaListReducer'
import DamWizardReducer from './damWizardReducer'
import SendingHistoryReducer from './sendingHistoryReducer'
import DamWizReducer from './damWizardRed'
import DamPackshotsReducer from './damPackshotsReducer'
import DamTextFilesReducer from './damTextFilesReducer'
import DamWizardShopReducer from './damWizardShopReducer'
import DamSubjectReducer from './damSubjectReducer'
import PackshotsRatingReducer from './packshotsRatingReducer'
import CustomerPanelReducer from './customerPanelReducer'
import NewsletterReducer from './newsletterReducer'
import DescriptionRatingReducer from './descriptionRatingReducer'
import WkzDataInputConfigReducer from './wkzDataInputConfigReducer'
import WkzDataInputReducer from './wkzDataInputReducer'
import GoogleReducer from './googleShoppingReducer'
import KeywordsManagementReducer from './keywordMgmtReducer'

import UsersPanelReducer, {
  REDUCER_NAME as UsersPanelReducerName,
} from './usersPanelReducer'
import DamPznReducer, {
  REDUCER_NAME as DamPznReducerName,
} from './damPznReducer'
import BundlesReducer, {
  REDUCER_NAME as BundlesReducerName,
} from './bundlesReducer'
import ShopContactsReducer, {
  REDUCER_NAME as ShopContactsReducerName,
} from './shopContactsReducer'
import KeywordsReducer from './KeywordsReducer'

const rootReducer = combineReducers({
  account: accountReducer,
  amazon_products: AmazonProductsReducer,
  help: HelpReducer,
  topBarCTA: TopBarCTAReducer,
  wkz: WkzReducer,
  wkzList: WkzMediaListReducer,
  [UsersPanelReducerName]: UsersPanelReducer,
  [DamPznReducerName]: DamPznReducer,
  [BundlesReducerName]: BundlesReducer,
  [ShopContactsReducerName]: ShopContactsReducer,
  [DAM_WIZARD_REDUCER_NAME]: DamWizardReducer,
  [SENDING_HISTORY_REDUCER_NAME]: SendingHistoryReducer,
  [DAM_WIZARD_REDUCER_NAME2]: DamWizReducer,
  [DAM_PACKSHOTS_REDUCER]: DamPackshotsReducer,
  [DAM_TEXTFILES_REDUCER]: DamTextFilesReducer,
  [DAM_WIZARD_SHOPS]: DamWizardShopReducer,
  [DAM_SUBJECT_REDUCER]: DamSubjectReducer,
  [PACKSHOTS_RATING_REDUCER_NAME]: PackshotsRatingReducer,
  [CUSTOMER_PANEL]: CustomerPanelReducer,
  [NEWSLETTER_CONFIG]: NewsletterReducer,
  [DESCRIPTION_RATING_REDUCER]: DescriptionRatingReducer,
  [WKZ_DATA_INPUT_CONFIG]: WkzDataInputConfigReducer,
  [WKZ_DATA_INPUT]: WkzDataInputReducer,
  [KEYWORDS]: KeywordsReducer,
  [GSHOPPING]: GoogleReducer,
  [KEYWORDS_MGMT]: KeywordsManagementReducer,
  [BOOKED_SERVICES_REDUCER_NAME]: BookedServicesReducer,
  [ABDA_KW_REDUCER]: AbdaKeywordsReducer,
  [ABDA_KATSOLL_REDUCER]: AbdaKatSollReducer,
  [KATSOLL_REDUCER]: KatSollReducer,
})

export default rootReducer
