import { create } from 'zustand'
import axios from 'src/utils/axios'
import { useEffect } from 'react'
import useSimpleNotification from 'src/hooks/useSimpleNotification'
import socketService from 'src/services/socketService'

export type Statuses = 'running' | 'done' | 'failed' | 'skipped'

type Job = {
  jobId: string
  shop: string
  product: string
  status: Statuses
}

interface StoreState {
  jobs: Job[]
  connection: boolean
  openDetails: boolean
  startRun: (recomendation: any) => void
  addJob: (job: Job) => void
  clearAllJobs: () => void
  updateJobStatus: (jobId: string, status: Statuses) => void
  getAllJobs: () => void
  setConnection: (connection: boolean) => void
  setOpenDetails: (open: boolean) => void
}

const useBackgroundRecomendationStore = create<StoreState>((set, get) => ({
  jobs: [],
  connection: false,
  openDetails: false,
  addJob: (job: Job) => {
    set((state) => {
      if (state.jobs.find((j) => j.jobId === job.jobId)) {
        return {
          ...state,
          jobs: state.jobs.map((j) => {
            if (j.jobId === job.jobId) {
              return job
            }
            return j
          }),
        }
      }

      return {
        ...state,
        jobs: [...state.jobs, job],
      }
    })
  },
  clearAllJobs: () => set({ jobs: [] }),
  updateJobStatus: (jobId, status) =>
    set((state) => ({
      jobs: state.jobs.map((job) =>
        job.jobId === jobId ? { ...job, status } : job,
      ),
    })),

  startRun: async (selectedProducts: Map<string, any>) => {
    try {
      await axios.post('/katsoll-recomendation/start-task', {
        products: Array.from(selectedProducts.values()),
      })
    } catch (error) {
      console.log(error)
    }
  },
  getAllJobs: async () => {
    try {
      const response = await axios.get('/katsoll-recomendation/jobs')

      const active = response.data.active.map((job: any) => ({
        jobId: job.id,
        shop: job.data.shop.name,
        product: job.data.product.name,
        status: 'running',
      }))

      const completed = response.data.completed.map((job: any) => ({
        jobId: job.id,
        shop: job.data.shop.name,
        product: job.data.product.name,
        status: 'done',
      }))

      const failed = response.data.failed.map((job: any) => ({
        jobId: job.id,
        shop: job.data.shop.name,
        product: job.data.product.name,
        status: 'failed',
      }))

      const waiting = response.data.waiting.map((job: any) => ({
        jobId: job.id,
        shop: job.data.shop.name,
        product: job.data.product.name,
        status: 'running',
      }))

      set(() => ({
        jobs: [...active, ...completed, ...failed, ...waiting],
      }))

      // set((state) => ({
      //   jobs: response.data,
      // }))
    } catch (error) {
      console.log(error)
    }
  },
  setConnection: (connection) => set({ connection }),
  setOpenDetails: (openDetails) => set({ openDetails }),
}))
const socket = socketService.getCustomSocket()

export default function useBackgroundKatsoll() {
  const {
    startRun,
    addJob,
    updateJobStatus,
    jobs,
    getAllJobs,
    connection,
    setOpenDetails,
    openDetails,
    setConnection,
  } = useBackgroundRecomendationStore()
  const noti = useSimpleNotification()
  // console.log('daa', socket)

  useEffect(() => {
    if (socket.connected) {
      setConnection(true)
    }

    socket.on('connect', () => {
      // console.log('Connected to server') // Debugging log
      setConnection(true)
    })

    socket.on('disconnect', () => {
      // console.log('Disconnected from server') // Debugging log
      setConnection(false)
    })

    socket.on('info:task-started', (data: any) => {
      noti('success', 'Task started')
    })

    socket.on('info:task-done', (data: any) => {
      noti('success', 'Task done')
    })

    socket.on('err:task-fail', (data: any) => {
      console.log(data, 'error')
      noti('error', 'Task failed')
    })

    socket.on('info:job-added', (data: any) => {
      // console.log('object', data)
      addJob({
        jobId: data.job.id,
        shop: data.shop.name,
        product: data.product.name,
        status: 'running',
      })
    })

    socket.on('info:job-completed', (data: any) => {
      updateJobStatus(data.jobId, 'done')
    })

    socket.on('info:job-skipped', (data: any) => {
      updateJobStatus(data.job.id, 'skipped')
    })

    socket.on('err:job-failed', (data: any) => {
      updateJobStatus(data.jobId, 'failed')
    })

    socket.on('err:prepare-data-empty', (data: any) => {
      noti('error', 'Task failed')
    })

    return () => {
      socket.off('connect')
      socket.off('info:task-started')
      socket.off('info:task-done')
      socket.off('info:job-completed')
      socket.off('info:job-added')
      socket.off('info:job-skipped')
      socket.off('err:prepare-data-empty')
      socket.off('err:job-failed')
      socket.off('err:task-fail')
      socket.off('disconnect')
    }
  }, [])

  return {
    connection,
    startRun,
    jobs,
    openDetails,
    setOpenDetails,
    getAllJobs,
  }
}
