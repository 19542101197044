import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import SplashScreen from 'src/components/SplashScreen'
import { setUserData, logoutIdle, logout } from 'src/actions/accountActions'
import authService from 'src/services/authService'
import { addInternalUserDataLayer } from 'src/utils/setupDataLayer'

function Auth({ children }) {
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(true)
  useEffect(() => {
    const initAuth = async () => {
      authService.setAxiosInterceptors({
        onLogout: () => dispatch(logout()),
      })

      authService.handleAuthentication()
      if (authService.isAuthenticated()) {
        try {
          const user = await authService.loginInWithToken()
          addInternalUserDataLayer(user)
          await dispatch(setUserData(user))
        } catch (e) {
          // if login with token fails logoutUser
          // backend is responding with 401 when there is another concurent session and if in some case token auth fails
          dispatch(logoutIdle())
        }
      }

      setLoading(false)
    }
    initAuth()
  }, [dispatch])

  if (isLoading) {
    return <SplashScreen />
  }

  return children
}

Auth.propTypes = {
  children: PropTypes.any,
}

export default Auth
