import React from 'react'
import { Box, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import AbsoluteDivider from 'src/components/AbsoluteDivider'
import { useDispatch } from 'react-redux'
import Chip from '@mui/material/Chip'
import { removeKeywordFromAbda } from '../lib/actions'
import KeywordsInput from './KeywordsInput'

const useStyles = makeStyles()(() => ({
  fieldTitle: {
    letterSpacing: '0.4px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '12px',
    marginBottom: 5,
    fontFamily: 'PT-Sans',
  },
  detail: {
    fontSize: '14px',
  },
  chipWrapper: {
    paddingBottom: '16px',
    '& *': {
      marginLeft: '2px',
      marginBottom: '2px',
    },
  },
  chip: {
    background: '#3F51B5',
    color: '#FFFFFF',
  },
}))

export default function PznSection({ abdaPodGrp, abdaKeywords, onAdd }) {
  const { classes } = useStyles()
  const dispatch = useDispatch()

  const onDelete = (kw) => {
    dispatch(removeKeywordFromAbda(kw.id))
  }

  return (
    <Box>
      <Box pt={2}>
        <Box>
          <Typography className={classes.fieldTitle}>ABDA</Typography>
          <Typography className={classes.detail}>{abdaPodGrp}</Typography>
        </Box>
      </Box>
      <Box mt={2} mb={2}>
        <KeywordsInput buttonText="Add" onAdd={onAdd} />
      </Box>
      <Box className={classes.chipWrapper}>
        {abdaKeywords?.length > 0 &&
          abdaKeywords.map((kw) => (
            <Chip
              classes={{
                root: classes.chip,
              }}
              color="primary"
              key={kw.id}
              label={kw.keyword}
              onDelete={() => onDelete(kw)}
            />
          ))}
      </Box>
      <AbsoluteDivider />
    </Box>
  )
}
