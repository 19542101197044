import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TableWrapper } from 'src/components/TableList'
import { useSnackbar } from 'notistack'
import useRetry from 'src/hooks/useRetry'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  CircularProgress,
  SvgIcon,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { Check } from 'react-feather'
import ErrorPageByType from 'src/views/pages/ErrorPageByType'
import { SERVER_ERROR, NO_RESULTS } from 'src/constants/errorPageConstants'
import useCancelToken from 'src/hooks/useCancelToken'
import {
  getBookedServicesSelector,
  getFetchingStatus,
  getParams,
} from '../selectors'
import { getBookedServices } from '../actions'
const useStyles = makeStyles()(() => ({
  selected: {
    '& td': {
      color: '#FFFFFF',
    },
    backgroundColor: '#4472C4 !important',
    '&:hover': {
      backgroundColor: '#4472C4',
    },
  },
  row: {
    height: '75px',
    '& .MuiCollapse-wrapper': {
      width: '50px',
    },
  },
  limitText: {
    textOverflow: 'ellipsis',
    maxWidth: '450px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  headCells: {
    borderRight: '1.5px solid rgba(224, 224, 224, 1)',
    borderBottom: '1.5px solid rgba(224, 224, 224, 1)',
    '&:last-child': {
      borderRight: 'none',
    },
  },
  bodyCells: {
    height: '70px',
    borderRight: '1.5px solid rgba(224, 224, 224, 1)',
    '&:last-child': {
      borderRight: 'none',
    },
  },
  icon: {
    color: '#4caf50',
    fontSize: '2rem',
  },
}))

export default function Results({ Pagination }) {
  const dispatch = useDispatch()
  const { source } = useCancelToken()
  const { classes } = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [retry, setRetry, retryLimitReached] = useRetry()
  const booked = useSelector(getBookedServicesSelector)
  const { fetching, error } = useSelector(getFetchingStatus)
  const { page, filters } = useSelector(getParams)
  const { companies, services } = booked
  useEffect(() => {
    dispatch(getBookedServices({ page, filters, source }))
    return () => {
      source.cancel('Component got unmounted')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filters, retry])

  useEffect(() => {
    if (retryLimitReached) {
      enqueueSnackbar('Retry limit reached. Please try later.', {
        variant: 'error',
      })
    }
  }, [retryLimitReached, enqueueSnackbar])

  const onRetry = () => {
    setRetry((prevState) => !prevState)
  }

  if (fetching) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignContent="center"
        marginBottom={4}
        marginTop={4}
        data-testid="loading-screen"
      >
        <CircularProgress color="secondary" disableShrink />
      </Box>
    )
  }

  if (!companies?.length && !fetching) {
    return <ErrorPageByType type={NO_RESULTS} />
  }
  if (error) {
    return <ErrorPageByType type={SERVER_ERROR} onRetry={onRetry} />
  }
  const arrOfServiceLength = Array.from(Array(services.length).keys())

  const getValueOfService = (idx, companyServices) => {
    const currentService = services[idx]
    return companyServices.indexOf(currentService.id) > -1
  }

  return (
    <TableWrapper Pagination={Pagination}>
      <Table data-testid="sending-history-results">
        <TableHead>
          <TableRow>
            <TableCell className={classes.headCells}>Company Name</TableCell>
            {services?.length > 0 &&
              services.map((service) => (
                <TableCell
                  key={service.id}
                  align="center"
                  className={classes.headCells}
                >
                  {service.name}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {companies?.length > 0 &&
            companies.map((company) => (
              <TableRow key={company.companyId}>
                <TableCell className={classes.bodyCells}>
                  {company.companyName}
                </TableCell>
                {arrOfServiceLength.map((serv) => (
                  <TableCell
                    key={serv}
                    align="center"
                    className={classes.bodyCells}
                  >
                    {getValueOfService(serv, company.bookedServices) ? (
                      <SvgIcon className={classes.icon}>
                        <Check />
                      </SvgIcon>
                    ) : (
                      ''
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableWrapper>
  )
}
