import produce from 'immer'
import { TOGGLE_HELP_DRAWER, SET_ACTIVE_HELPER } from 'src/actions/helpActions'

const initialState = {
  openDrawer: false,
  activeHelper: null,
}

function HelpReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_HELP_DRAWER:
      return produce(state, (draft) => {
        draft.openDrawer = !draft.openDrawer
      })
    case SET_ACTIVE_HELPER:
      return produce(state, (draft) => {
        draft.activeHelper = action.payload
        // if drawer is open then no need to toggle on of just leave it open
        if (!draft.openDrawer) {
          draft.openDrawer = !draft.openDrawer
        }
      })
    default:
      return state
  }
}

export default HelpReducer
