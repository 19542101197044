export const FETCHING_PRODUCTS = '@products/FETCHING_PRODUCTS'
export const FETCHING_PRODUCTS_ERROR = '@products/FETCHING_PRODUCTS_ERROR'
export const UPDATE_PRODUCTS = '@products/UPDATE_PRODUCTS'
export const UPDATE_COUNT = '@products/UPDATE_COUNT'
export const UPDATE_PAGE = '@products/UPDATE_PAGE'
export const UPDATE_FILTERS = '@products/UPDATE_FILTERS'
export const RESET_FILTERS = '@products/RESET_FILTERS'
export const UPDATE_PRODUCT = '@products/UPDATE_PRODUCT'
export const UPDATE_PRIMARY_ASIN = '@products/UPDATE_PRIMARY_ASIN'
export const OPEN_MODAL = '@products/OPEN_MODAL'
export const PREV_NEXT_AMAZON_PRODUCT = '@products/PREV_NEXT_AMAZON_PRODUCT'
export const UPDATE_ACTIVE_AMAZON_PRODUCT =
  '@products/UPDATE_ACTIVE_AMAZON_PRODUCT'
export const CONFIRM_AMAZON_PZN = '@products/CONFIRM_AMAZON_PZN'
export const UNCONFIRM_AMAZON_PZN = '@products/UNCONFIRM_AMAZON_PZN'
export const DEACTIVATE_PRODUCT = '@products/DEACTIVATE_PRODUCT'
export const REFRESH_MODAL = '@products/REFRESH_MODAL'
