import React from 'react'
import {
  Box,
  Typography,
  Link,
  Checkbox,
  IconButton,
  SvgIcon,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useDispatch, useSelector } from 'react-redux'
import useSimpleNotification from 'src/hooks/useSimpleNotification'
import BasicGreyButton from 'src/components/BasicGreyButton'
import { removeKatSollDetail } from '../lib/actions'
import { AutoAwesome } from '@mui/icons-material'
import clsx from 'clsx'
import { isAdminSelector } from 'src/selectors/accountSelectors'

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #B0BEC5',
    padding: '10px 10px',
  },
  details: {
    width: '100%',
  },
  title: {
    ...theme.text.fieldTitle,
  },
  notValid: {
    backgroundColor: '#faf2f4',
  },
}))

export default function KatSollDetails({
  categoryLink,
  category,
  categoryId,
  checked,
  onChange,
  subBrandId,
  onMagicClick,
  valid,
}) {
  const notification = useSimpleNotification()
  const { classes } = useStyles()
  const dispatch = useDispatch()
  const isAdmin = useSelector(isAdminSelector)

  const onDelete = async () => {
    try {
      await dispatch(removeKatSollDetail(subBrandId, categoryId))
      notification('success')
    } catch (error) {
      notification('error')
    }
  }
  return (
    <>
      <Box className={clsx(classes.wrapper, !valid && classes.notValid)}>
        <Box>
          <Checkbox onChange={() => onChange(categoryId)} checked={checked} />
        </Box>
        <Box className={classes.details}>
          <Typography className={classes.title}>Category</Typography>
          <Typography>{category}</Typography>
          <Box mb={1}></Box>
          <Typography className={classes.title}>Category Link</Typography>
          <Link
            variant="subtitle2"
            color="textPrimary"
            underline="always"
            target="_blank"
            rel="noopener noreferrer"
            href={categoryLink}
            onClick={(e) => e.stopPropagation()}
          >
            {categoryLink}
          </Link>
        </Box>
        <Box flexGrow={1} ml={2} display="flex" gap={2}>
          {!valid && isAdmin && (
            <IconButton onClick={onMagicClick}>
              <SvgIcon sx={{ color: '#00fff0' }}>
                <AutoAwesome />
              </SvgIcon>
            </IconButton>
          )}
          <BasicGreyButton onClick={onDelete} buttonText="delete" />
        </Box>
      </Box>
    </>
  )
}
