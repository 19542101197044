import React from 'react'
import PropTypes from 'prop-types'
import SquarePagination from '@mui/material/Pagination'
import { makeStyles } from 'tss-react/mui'
import { Box, useMediaQuery } from '@mui/material'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    // marginTop: 10,
    flexGrow: 1,
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center',
    },
  },
  ul: {
    '& li': {
      color: '#263238',
    },
    '& .Mui-selected': {
      border: '1px solid #B0BEC5',
      background: '#FFFFFF',
    },
  },
  paginationWrapper: {
    background: '#FFFFFF',
    padding: 21,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      paddingBottom: 21,
    },
  },
}))

const Pagination = ({
  hideOnFetch = false,
  fetching = false,
  page,
  count,
  onChange,
  paginationWrapper = false,
  customStyle,
  hidePrevButton = true,
  hideNextButton = true,
}) => {
  const { classes, cx } = useStyles()
  const matches = useMediaQuery('(max-width:600px)')
  const SIZE = matches ? 'medium' : 'large'
  const PER_PAGE = 50

  if (fetching && hideOnFetch) {
    return null
  }

  const numberOfPages = Math.ceil(count / PER_PAGE)

  return (
    <Box
      className={cx(
        classes.root,
        paginationWrapper && classes.paginationWrapper,
        customStyle && customStyle,
      )}
    >
      <SquarePagination
        count={numberOfPages}
        onChange={onChange}
        page={page}
        shape="rounded"
        defaultPage={1}
        hidePrevButton={hidePrevButton}
        hideNextButton={hideNextButton}
        classes={{ ul: classes.ul }}
        size={SIZE}
      />
    </Box>
  )
}

Pagination.propTypes = {
  hideOnFetch: PropTypes.bool,
  fetching: PropTypes.bool,
  page: PropTypes.number,
  count: PropTypes.number,
  onChange: PropTypes.func,
}

export default Pagination
