const checkForScope = (requiredScope, usersScope) =>
  usersScope.indexOf(requiredScope) > -1

export const hasAdminRouteAccess = (config, user) => {
  if (config.adminOnly) {
    const allAllowed = config.items.some((item) => item.isAvailable(user))
    return allAllowed
  }

  return true
}

export const hasSubheaderRouteAccess = (config, user) => {
  if (!config.items) {
    console.error('No empty items.')
    return false
  }

  if (config.adminOnly) {
    return true
  }

  const requiredScopes = config.items.map((item) => item.scopeTitle)
  const userScopes = [...user.scopesTitles]
  return requiredScopes.some((required) => userScopes.indexOf(required) > -1)
}

export const hasItemAccess = (config, user) => {
  if (config.isAvailable) {
    return config.isAvailable(user)
  }

  const userScopes = [...user.scopesTitles]
  return checkForScope(config.scopeTitle, userScopes)
}
