import { useSelector } from 'react-redux'
import { userSelector, isAdminSelector } from 'src/selectors/accountSelectors'
import { FileText } from 'react-feather'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Box, IconButton, SvgIcon } from '@mui/material'
import { useState } from 'react'
import { format } from 'date-fns'

const whitelistUsers = [
  7, // srdjan
  13, // nikola
  9, // obrad
]

export default function LogIcon() {
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const user = useSelector(userSelector)
  const isAdmin = useSelector(isAdminSelector)
  const canSeeIcon = whitelistUsers.includes(user.id) && isAdmin
  if (!canSeeIcon) {
    return null
  }

  const onClick = (event: any) => {
    if (open) {
      setOpen(false)
    } else {
      setOpen(true)
    }
    if (anchorEl === null) {
      setAnchorEl(event.currentTarget)
    } else {
      setAnchorEl(null)
    }
  }

  const onChange = (newValue: Date | null) => {
    if (!newValue) {
      return null
    }
    window.open(
      'http://116.202.204.56:3111/logger/log/' +
        format(newValue, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
    )
    setOpen(false)
    setAnchorEl(null)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box>
        <DatePicker
          open={open}
          label="Basic example"
          value={null}
          onChange={onChange}
          PopperProps={{ anchorEl }}
          renderInput={(props: any) => <Box></Box>}
        />
        <IconButton onClick={onClick}>
          <SvgIcon titleAccess="select date">
            <FileText color="white" />
          </SvgIcon>
        </IconButton>
      </Box>
    </LocalizationProvider>
  )
}
