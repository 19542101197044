import { useCallback } from 'react'
import { Box, IconButton, SvgIcon, Badge } from '@mui/material'
import TableRowsIcon from '@mui/icons-material/TableRows'
import { CrawlerContainer } from 'src/hooks/useCrawlerOnDemand'
import { makeStyles } from 'tss-react/mui';
import { useSelector } from 'react-redux'
import { getScope } from 'src/selectors/accountSelectors'

const useStyles = makeStyles()((theme) => ({
  green: {
    transform: 'scale(0.5) translate(20%, 20%)',
    backgroundColor: '#4caf50',
  },
  orange: {
    backgroundColor: '#ed6c02',
  },
}));

const getColor = (connection: boolean, hasItemsInQueue: boolean) => {
  if (!connection) {
    return undefined
  }

  if (connection && !hasItemsInQueue) {
    return 'primary'
  }

  return 'secondary'
}

export default function CrawlerQueueIcon() {
  const { classes } = useStyles()
  const { drawerOpen, openDrawer, closeDrawer, queue, connection } =
    CrawlerContainer.useContainer()
  const number = queue.filter((e) => e.status === 'in progress')
  const hasScope = useSelector(getScope('ondemandcrawling'))
  const toggleQueueSidebar = useCallback(() => {
    if (drawerOpen) {
      closeDrawer()
    } else {
      openDrawer()
    }
  }, [drawerOpen, closeDrawer, openDrawer])

  if (!hasScope) {
    return null
  }

  const color = getColor(connection.connected, !!number.length)
  return (
    <Box ml={1} style={{ transform: 'rotate(90deg)' }}>
      <IconButton onClick={toggleQueueSidebar} size="large">
        <Badge
          style={{
            transform: 'rotate(270deg)',
          }}
          classes={{
            colorPrimary: classes.green,
            colorSecondary: classes.orange,
          }}
          color={color}
          badgeContent={number.length || ' '}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <SvgIcon htmlColor="#FFFFFF">
            <TableRowsIcon />
          </SvgIcon>
        </Badge>
      </IconButton>
    </Box>
  )
}
