export function getNewActiveTabAfterTabRemoval(tabs, currentTab) {
  if (!tabs.length) {
    return null
  }
  const indexOfCurrent = tabs.indexOf(currentTab)
  const prev = tabs[indexOfCurrent - 1]
  if (prev) {
    return prev
  }

  const next = tabs[indexOfCurrent + 1]
  if (next) {
    return next
  }

  return null
}
