import React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import { TextField } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useSelector } from 'react-redux'
import { getAllServices } from '../../selectors'

const useStyles = makeStyles()((theme) => ({
  input: {
    width: 300,
    marginBottom: 5,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: 5,
      marginTop: 5,
    },
  },
}))

const ServiceDropdown = ({ onChange, selectedService }) => {
  const { classes } = useStyles()
  const services = useSelector(getAllServices)

  return (
    <Autocomplete
      className={classes.input}
      id="servicesDropdown"
      options={services}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option === value}
      value={selectedService || null}
      onChange={onChange}
      renderInput={(params) => (
        <TextField {...params} label="Services" variant="outlined" />
      )}
    />
  )
}

export default ServiceDropdown
