import React, { Component } from 'react'
import ErrorPageByType from 'src/views/pages/ErrorPageByType'
import { reportSentry } from 'src/utils/reportSentry'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error) {
    // call sentry here
    // console.error('ErrorBoundary caught as error', error, info)
    reportSentry(error)
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPageByType type={this.props.type} {...this.props} />
    }

    return this.props.children
  }
}

export default ErrorBoundary
