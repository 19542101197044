import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Box,
  Button,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { X as CloseIcon } from 'react-feather'
import PerfectScrollbar from 'react-perfect-scrollbar'

const useStyles = makeStyles()(() => ({
  dialogStyle: {
    maxWidth: '781px',
    display: 'flex',
    maxHeight: 'calc(100% - 64px)',
    flexDirection: 'column',
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    direction: 'rtl',
  },
  title: {
    fontFamily: 'PT-Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '31px',
    marginBottom: '40px',
  },
  text: {
    fontFamily: 'PT-Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '21px',
    marginBottom: '10px',
  },
  okButton: {
    width: '65px',
    height: '42px',
    background: '#4472C4',
    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.14)',
    borderRadius: '4px',
    color: '#FFFFFF',
    marginTop: '64px',
    '&:hover': {
      background: '#4472C4',
      opacity: 0.9,
    },
  },
  paper: {
    minWidth: '781px',
    paddingBottom: 27,
    paddingRight: 24,
    paddingLeft: 24,
  },
}))

function WhatsNewModal({ open, setOpen, newFeatures }) {
  const { classes } = useStyles()
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullWidth
      classes={{ paper: classes.paper }}
    >
      <PerfectScrollbar
        options={{ suppressScrollX: true }}
        style={{ top: '15px' }}
      >
        <DialogTitle className={classes.dialogTitle}>
          <IconButton onClick={() => setOpen(false)} size="large">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography className={classes.title}>What&apos;s new</Typography>
          {newFeatures?.length &&
            newFeatures.map((feature) => (
              <Typography className={classes.text} key={feature.id}>
                {feature.content}
              </Typography>
            ))}
          <Box className={classes.dialogTitle}>
            <Button className={classes.okButton} onClick={() => setOpen(false)}>
              Ok
            </Button>
          </Box>
        </DialogContent>
      </PerfectScrollbar>
    </Dialog>
  )
}

export default WhatsNewModal
