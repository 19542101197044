import { colors } from '@mui/material'

import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  mobileDrawer: {
    width: theme.navBar.openWidth,
    top: 64,
    boxShadow:
      '0px 1px 2px rgba(63, 63, 68, 0.15), 0px 0px 0px rgba(63, 63, 68, 0.05)',
  },
  drawer: {
    width: theme.navBar.openWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: theme.navBar.openWidth,
    top: 64,
    height: 'calc(100% - 64px)',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: '80px',
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
  orgLogo: {
    width: '100%',
    height: 'auto',
    maxWidth: 61,
    maxHeight: 85,
    paddingTop: 10,
    cursor: 'pointer',
  },
  initials: {
    textTransform: 'uppercase',
  },
  smAvatar: {
    width: 40,
    height: 40,
    margin: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
  smLogo: {
    width: '100%',
    height: 'auto',
    padding: theme.spacing(1),
    paddingTop: theme.spacing(3),
  },
  smLogoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: theme.spacing(14),
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.actionBlue.main,
    },
  },
  bottomInfoContainer: {
    position: 'fixed',
    bottom: 0,
    paddingBottom: 26,
    backgroundColor: '#ffffff',
    width: theme.navBar.openWidth - 32,
  },
  currentRoute: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      width: 20,
      height: 20,
      marginRight: 8,
      marginLeft: 8,
    },
  },
  drawerButtonRow: {
    display: 'flex',
    alignItems: 'center',
  },
  drawerButtonClose: {
    cursor: 'pointer',
    marginLeft: 3,
    color: colors.blueGrey[200],
    '&:hover': {
      color: theme.palette.actionBlue.main,
    },
  },
}))

export default useStyles
