import React from 'react'
import { Box, Typography, Link } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useDispatch } from 'react-redux'
import useSimpleNotification from 'src/hooks/useSimpleNotification'
import BasicGreyButton from 'src/components/BasicGreyButton'
import { removeKatSollDetail } from '../lib/actions'

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #B0BEC5',
    padding: '10px 10px',
  },
  details: {
    width: '100%',
  },
  title: {
    ...theme.text.fieldTitle,
  },
}))

export default function KatSollDetails({ categoryLink, category, pivotId }) {
  const notification = useSimpleNotification()
  const { classes } = useStyles()
  const dispatch = useDispatch()

  const onDelete = async () => {
    try {
      await dispatch(removeKatSollDetail(pivotId))
      notification('success')
    } catch (error) {
      notification('error')
    }
  }

  return (
    <>
      <Box className={classes.wrapper}>
        <Box className={classes.details}>
          <Typography className={classes.title}>Category</Typography>
          <Typography>{category}</Typography>
          <Box mb={1}></Box>
          <Typography className={classes.title}>Category Link</Typography>
          <Link
            variant="subtitle2"
            color="textPrimary"
            underline="always"
            target="_blank"
            rel="noopener noreferrer"
            href={categoryLink}
            onClick={(e) => e.stopPropagation()}
          >
            {categoryLink}
          </Link>
        </Box>
        <Box flexGrow={1} ml={2}>
          <BasicGreyButton onClick={onDelete} buttonText="delete" />
        </Box>
      </Box>
    </>
  )
}
