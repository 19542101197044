import React, { useState } from 'react'
import { create } from 'jss'
import rtl from 'jss-rtl'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { SettingsProvider } from 'src/context/SettingsContext'
import { Provider } from 'react-redux'

import { configureStore } from 'src/store'
import { createTheme } from 'src/theme'
import useSettings from 'src/hooks/useSettings'
import { ThemeProvider, StyledEngineProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { SnackbarProvider } from 'notistack'
import { HelmetProvider } from 'react-helmet-async'
import { restoreSettings } from './utils/settings'
import { CrawlerContainer } from 'src/hooks/useCrawlerOnDemand'

const jss = create({ plugins: [rtl()] })
const store = configureStore()
const restoredSettings = restoreSettings()
const appState = {
  consentOpen: true,
  navBarOpen: true,
}

export const AppStateContext = React.createContext([appState, () => null])

const AppStateProvider = ({ children }) => {
  const [state, setState] = useState(appState)
  const val = [state, setState]
  return (
    <AppStateContext.Provider value={val}>{children}</AppStateContext.Provider>
  )
}

const SubProviders = ({ children }) => {
  const { settings } = useSettings()
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createTheme(settings)}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <SnackbarProvider maxSnack={1}>
            <HelmetProvider>
              <AppStateProvider>
                <CrawlerContainer.Provider>
                  {children}
                </CrawlerContainer.Provider>
              </AppStateProvider>
            </HelmetProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export const TestAppProviders = ({ children, mockStore }) => (
  <Provider store={mockStore}>
    <SettingsProvider settings={restoredSettings}>
      <SubProviders>{children}</SubProviders>
    </SettingsProvider>
  </Provider>
)

export const AppProviders = ({ children }) => (
  <Provider store={store}>
    <SettingsProvider settings={restoredSettings}>
      <SubProviders>{children}</SubProviders>
    </SettingsProvider>
  </Provider>
)
