/**
 * Helper fn to check if error is unauthorized or something else
 * also can be used as middleware for reporting errors to sentry ie
 * @param {object} e - Error object from axios
 * @param {*} returnValue - Value we want to return when request is unauthorized, default is Object
 */
export function errorHandler(e, returnValue = {}) {
  if (e) {
    const isUnauthorized = e?.response?.status === 401

    /**
     * if it's unauthorized return value or empty object
     * this is because axios catches 401 and redirects to login page
     * but there is race condition and it passes error to the error handler
     * of the function calling ajax, there it's catched and notistac is showed
     * which is uneccesary since 401 will show Session expired error on login page
     *
     */
    if (isUnauthorized) {
      return returnValue
    }
    throw e
  } else {
    throw new Error(
      'Unexpected behavior, please provide Error as first argument',
    )
  }
}
