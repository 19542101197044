import React, { useEffect } from 'react'
import { Box, Hidden } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import FiltersWrapper from 'src/components/TableList/Filters'

import FiltersBigScreens from './FiltersBigScreens'
import FiltersSmallScreens from './SmallScreenFilters'
import { FiltersConfig } from './types'

const useStyles = makeStyles()((theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      display: 'block',
    },
  },
  wrapper: {
    display: 'flex',
    '& > *:not(:first-of-type)': {
      marginRight: '10px',
    },
  },
}))

const sortFilters = (filtersConfig: FiltersConfig[]) =>
  filtersConfig.reduce(
    (acc, next) => {
      if (next.visible) {
        acc.visibleFilters.push(next)
      } else {
        acc.hiddenFilters.push(next)
      }
      return acc
    },
    {
      visibleFilters: [] as any[],
      hiddenFilters: [] as any[],
    },
  )

type FiltersProps = {
  filtersConfig: FiltersConfig[]
  onEffect?: () => Promise<void>
  onCleanup?: () => Promise<void>
  openDrawer?: boolean
  dataTestId?: string
}

const Filters = ({
  filtersConfig = [],
  onEffect,
  onCleanup,
  openDrawer,
  dataTestId = 'filters-testid',
}: FiltersProps) => {
  const { classes } = useStyles()
  useEffect(() => {
    if (onEffect && typeof onEffect === 'function') {
      onEffect()
      return () => {
        if (onCleanup && typeof onCleanup === 'function') {
          onCleanup()
        }
      }
    }
  }, [onCleanup, onEffect])

  const { visibleFilters, hiddenFilters } = sortFilters(filtersConfig)

  return (
    <FiltersWrapper>
      <Box data-testid={dataTestId} className={classes.container}>
        <Hidden mdDown>
          <FiltersBigScreens
            openDrawer={openDrawer}
            filtersConfig={filtersConfig}
          />
        </Hidden>
        <Hidden mdUp>
          <FiltersSmallScreens
            openDrawer={openDrawer}
            visibleFilters={visibleFilters}
            hiddenFilters={hiddenFilters}
          />
        </Hidden>
      </Box>
    </FiltersWrapper>
  )
}

export default Filters
