import React, { useEffect } from 'react'
import { Drawer, Box, Typography, IconButton, SvgIcon } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { X as CloseIcon } from 'react-feather'
import { useDefaultDrawerStyles } from 'src/theme/drawer'
import { useSelector, useDispatch } from 'react-redux'
import { openDrawerSelector } from '../../selectors'
import { closeDrawerAction } from '../../actions'
import AddContainer from './AddContainer'

const useStyles = makeStyles()((theme) => ({
  formsWrapper: {
    '& > *': {
      marginBottom: '15px',
    },
  },
  addButton: {
    background: '#4472C4',
    color: '#FFFFFF',
    marginLeft: 12,
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '26px',
    '&:hover': {
      background: '#4472C4',
    },
  },
  noItems: {
    padding: theme.spacing(2),
  },
  noItemsTitle: {
    fontSize: '1.5rem',
    fontWeight: 500,
    opacity: 0.7,
    fontFamily: 'PT-Sans',
  },
}))

export default function Sidebar({
  drawerDesktopWidth = '50%',
  openDrawerCallback,
}) {
  const { classes } = useStyles()
  const dispatch = useDispatch()
  const openDrawer = useSelector(openDrawerSelector)
  const { classes: defaultClasses } = useDefaultDrawerStyles({
    destopWidth: drawerDesktopWidth,
  })()
  // const notification = useSimpleNotification()

  useEffect(() => {
    if (openDrawerCallback) openDrawerCallback(openDrawer)
  }, [openDrawer, openDrawerCallback])

  const onClose = () => {
    dispatch(closeDrawerAction())
  }

  const content = (
    <Box>
      <Box p={2}>
        <Box className={defaultClasses.header}>
          <Box className={defaultClasses.titleWrapper}>
            <Typography className={defaultClasses.title}>Setup</Typography>
          </Box>
          <Box>
            <IconButton onClick={() => onClose()} size="large">
              <SvgIcon>
                <CloseIcon />
              </SvgIcon>
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box className={classes.noItems}>
        <AddContainer />
      </Box>
    </Box>
  )
  return (
    <Drawer
      anchor="right"
      classes={{ paper: defaultClasses.drawer }}
      open={openDrawer}
      onClose={() => onClose()}
      variant="persistent"
    >
      {content}
    </Drawer>
  )
}
