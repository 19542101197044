import React from 'react'
import { Box } from '@mui/material'

import RestrictedFooter from 'src/layouts/shared/RestrictedFooter'
import useStyles from './styles'
import TopBar from './TopBar'

function LegalLayout({ children }) {
  const { classes } = useStyles()

  return (
    <Box className={classes.wrapper}>
      <TopBar />
      <Box mt={5}>{children}</Box>
      <RestrictedFooter />
    </Box>
  )
}

export default LegalLayout
