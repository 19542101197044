import axios from 'src/utils/axios'
import { errorHandler } from 'src/utils/errorHandler'
import debounce from 'lodash/debounce'
import {
  UPDATE_FILTERS,
  CLOSE_SIDEBAR,
  OPEN_DRAWER,
  FETCHING,
  SET_RESULTS,
  FETCHING_404,
  FETCHING_ERROR,
  PAGE_CHANGE,
  SET_SIDEBAR_ABDA,
  REMOVE_KATSOLL,
  IMPORT_SHOPS,
  UPDATE_SIDEBAR_VALUE,
  IMPORT_CATEGORIES,
  ADD_NEW_KATSOLL,
  RESET_SETTINGS,
  IMPORT_MANUFACTURERS,
  IMPORT_BRANDS,
  ADD_CHECKED_PZN,
  REMOVE_CHECKED_PZN,
  UPDATE_ADD_TO_ALL,
  DELETE_MANY,
  DELETE_ALL,
  TRACK_REJECTED_CATEGORIES,
  REMOVE_TRACKED,
} from './actionTypes'

export const getAbdaKeywords =
  ({ page, filters, source }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: FETCHING,
      })
      const params = {
        page,
        subbrand: filters?.search || null,
        manufacturerId: filters?.manufacturer?.id || null,
        brandId: filters?.brand?.id || null,
      }

      const { data } = await axios.get('/categories/subbrands', {
        params,
        cancelToken: source.token,
      })
      const { count, res } = data
      return dispatch({
        type: SET_RESULTS,
        payload: {
          data: res,
          count,
        },
      })
    } catch (error) {
      if (axios.isCancel(error)) {
        return null
      }
      const is404 = error?.response?.status === 404
      if (is404) {
        return dispatch({
          type: FETCHING_404,
        })
      }
      dispatch({
        type: FETCHING_ERROR,
      })
      return errorHandler(error, {})
    }
  }

export const getShops = () => async (dispatch) => {
  try {
    const { data } = await axios.get('/categories/shops')
    return dispatch({
      type: IMPORT_SHOPS,
      payload: data,
    })
  } catch (error) {
    return errorHandler(error, {})
  }
}

export const getKatsollDetails =
  ({ id, domainId } = {}) =>
  async (dispatch) => {
    try {
      const { data } = await axios.get(
        `/categories/subbrands/${id}/${domainId}`,
      )
      return dispatch({
        type: SET_SIDEBAR_ABDA,
        payload: {
          subBrandId: id,
          shopId: domainId,
          data,
        },
      })
    } catch (error) {
      const is404 = error?.response?.status === 404
      if (is404) {
        return dispatch({
          type: SET_SIDEBAR_ABDA,
          payload: [],
        })
      }
      return errorHandler(error, {})
    }
  }

export const getCategories = async (domainId, category = '', dispatch) => {
  try {
    if (!domainId) {
      return null
    }
    const params = {
      domainId: domainId || null,
      category: category || null,
    }

    if (params.category?.length <= 2) {
      return
    }

    const { data } = await axios.get(`/categories`, { params })
    dispatch({
      type: IMPORT_CATEGORIES,
      payload: data,
    })
    return data
  } catch (error) {
    return errorHandler(error, {})
  }
}

export const getCategoriesDebounced = debounce(getCategories, 350, {
  leading: true,
})

export const removeKatSollDetail =
  (subBrandId, categoryId) => async (dispatch) => {
    try {
      await axios.delete(`/categories/subbrands/katsoll/${categoryId}`)
      return dispatch({
        type: REMOVE_KATSOLL,
        payload: { categoryId, subBrandId },
      })
    } catch (error) {
      return errorHandler(error, {})
    }
  }

export const addNewKatsoll = (body) => async (dispatch) => {
  try {
    const { data } = await axios.post('/categories/subbrands/katsoll', body)
    return dispatch({
      type: ADD_NEW_KATSOLL,
      payload: { subBrandId: body.subBrandId, data },
    })
  } catch (error) {
    return errorHandler(error, {})
  }
}

export const importFilters = () => async (dispatch) => {
  dispatch(fetchManufacturers())
  dispatch(fetchBrands())
}

export const fetchManufacturers =
  (brand = {}) =>
  async (dispatch) => {
    try {
      const params = {
        brandId: brand?.id || null,
      }
      const { data } = await axios.get(`/categories/manufacturers`, { params })
      return dispatch({
        type: IMPORT_MANUFACTURERS,
        payload: data.manufacturers,
      })
    } catch (error) {
      return errorHandler(error, {})
    }
  }

export const fetchBrands =
  (manufacturer = {}) =>
  async (dispatch) => {
    try {
      const params = {
        manufacturerId: manufacturer?.id || null,
      }
      const { data } = await axios.get(`/categories/brands`, { params })
      return dispatch({
        type: IMPORT_BRANDS,
        payload: data.brands,
      })
    } catch (error) {
      return errorHandler(error, {})
    }
  }

export const pageChangeAction = (page) => (dispatch) =>
  dispatch({
    type: PAGE_CHANGE,
    payload: page,
  })

export const updateFiltersAction = (target, value) => (dispatch) =>
  dispatch({
    type: UPDATE_FILTERS,
    payload: {
      target,
      value,
    },
  })

export const closeDrawerAction = () => (dispatch) =>
  dispatch({
    type: CLOSE_SIDEBAR,
  })
export const openDrawer = (data) => (dispatch) =>
  dispatch({ type: OPEN_DRAWER, payload: data })

export const updateSidebarValues = (target, type, value) => (dispatch) =>
  dispatch({
    type: UPDATE_SIDEBAR_VALUE,
    payload: {
      target,
      type,
      value,
    },
  })
export const updateAddToAllValues = (type, value) => (dispatch) =>
  dispatch({
    type: UPDATE_ADD_TO_ALL,
    payload: {
      type,
      value,
    },
  })

export const resetSettings = () => (dispatch) =>
  dispatch({
    type: RESET_SETTINGS,
  })

export const addCheckedPzn = (pzn) => (dispatch) => {
  dispatch({
    type: ADD_CHECKED_PZN,
    payload: pzn,
  })
}

export const removeCheckedPzn = (pzn) => (dispatch) => {
  dispatch({
    type: REMOVE_CHECKED_PZN,
    payload: pzn,
  })
}

export const deleteManyKatsoll =
  (subBrandId, forDeletion = []) =>
  async (dispatch) => {
    await axios.delete(
      `/categories/subbrands/katsoll/${forDeletion.toString()}`,
    )

    return dispatch({
      type: DELETE_MANY,
      payload: { subBrandId, forDeletion },
    })
  }

export const deleteAllKatsoll =
  (forDeletion = []) =>
  async (dispatch) => {
    await axios.delete(
      `/categories/subbrands/katsoll/${forDeletion.toString()}`,
    )

    return dispatch({
      type: DELETE_ALL,
    })
  }

export const trackRejectedCategoriesDuringBulkAdding =
  (rejected) => (dispatch) =>
    dispatch({
      type: TRACK_REJECTED_CATEGORIES,
      payload: rejected,
    })

export const removeTrackingOfRejected = () => (dispatch) =>
  dispatch({
    type: REMOVE_TRACKED,
  })
