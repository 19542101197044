import React, { useState } from 'react'
import { TextField, InputAdornment, IconButton } from '@mui/material'
import { Eye, EyeOff } from 'react-feather'

export default function InputWithEye({
  touched,
  errors,
  handleBlur,
  handleChange,
  values,
  showPasswordCb,
  label,
}) {
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev)
    // allow other components to be aware that click has happened
    showPasswordCb()
  }
  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  return (
    <TextField
      data-testid="input-with-eye"
      error={Boolean(touched.newPassword && errors.newPassword)}
      fullWidth
      helperText={touched.newPassword && errors.newPassword}
      label={label}
      margin="normal"
      name="newPassword"
      onBlur={handleBlur}
      onChange={handleChange}
      type={showPassword ? 'text' : 'password'}
      value={values.newPassword}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              data-testid="toggle-pwd-visibility"
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              size="large"
            >
              {showPassword ? <Eye /> : <EyeOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}
