import produce from 'immer'

import {
  REDUCER_NAME as RN,
  OPEN_ROW,
  UPDATE_FILTERS,
  FETCHING_IN_PROGRESS,
  FETCHING_SUCCESS,
  FETCHING_ERROR,
  SET_MAIN_EMAIL,
  GET_SHOPS_DROPDOWN,
  RESET_FILTERS,
  CLOSE_SIDEBAR,
  ADD_NEW_PROSPECT,
  EDIT_PROSPECT,
  DELETE_ASSIGNED_PROSPECT,
} from 'src/actions/shopContactsActions'

const initialState = {
  fetchingInProgress: false,
  fetchingError: false,
  openDrawer: false,
  activeRow: null,
  filters: {
    user_account: '',
    shop: null,
  },
  contacts: {},
  shops: [],
}

export const REDUCER_NAME = RN
export default function ShopContactsReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_ROW:
      return produce(state, (draft) => {
        draft.openDrawer = true
        draft.activeRow = action.payload
      })
    case CLOSE_SIDEBAR:
      return produce(state, (draft) => {
        draft.openDrawer = false
        draft.activeRow = initialState.activeRow
      })
    case UPDATE_FILTERS:
      return produce(state, (draft) => {
        const { target, value } = action.payload
        draft.filters[target] = value
        draft.pagination.page = 1
      })
    case FETCHING_IN_PROGRESS:
      return produce(state, (draft) => {
        draft.fetchingInProgress = true
        draft.fetchingError = false
      })
    case FETCHING_SUCCESS:
      return produce(state, (draft) => {
        draft.fetchingInProgress = false
        draft.fetchingError = false
        draft.contacts = action?.payload?.data || []
      })
    case FETCHING_ERROR:
      return produce(state, (draft) => {
        draft.fetchingError = true
        draft.fetchingInProgress = false
      })
    case SET_MAIN_EMAIL:
      return produce(state, (draft) => {
        const { shopName, id } = action.payload

        const updated = draft.contacts.grouped[shopName].map((contact) => {
          const copy = { ...contact }
          if (copy.id === id) {
            copy.isMainEmail = 1
          } else {
            copy.isMainEmail = 0
          }
          return copy
        })
        draft.contacts.grouped[shopName] = updated

        const main = updated.find((contact) => contact.isMainEmail === 1)
        if (main) {
          draft.contacts.mains[shopName] = main
        }
      })
    case GET_SHOPS_DROPDOWN:
      return produce(state, (draft) => {
        draft.shops = action.payload?.shops
      })
    case RESET_FILTERS:
      return produce(state, (draft) => {
        draft.filters = {
          user_account: '',
          shop: null,
        }
      })
    case ADD_NEW_PROSPECT:
      return produce(state, (draft) => {
        const { shopName } = action.payload
        draft.contacts.grouped[shopName].push(action.payload)
      })
    case EDIT_PROSPECT:
      return produce(state, (draft) => {
        const { shopName, id } = action.payload
        const target = draft.contacts.grouped[shopName].findIndex(
          (e) => e.id === id,
        )

        if (target > -1) {
          draft.contacts.grouped[shopName][target] = action.payload
        }
      })
    case DELETE_ASSIGNED_PROSPECT:
      return produce(state, (draft) => {
        const { shopName, prospectId } = action.payload
        const filtered = draft.contacts.grouped[shopName].filter(
          (prospect) => prospect.id !== prospectId,
        )
        draft.contacts.grouped[shopName] = filtered
      })
    default:
      return state
  }
}
