import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box, Typography, Button, Divider } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import {
  AMAZON_ACTIVE,
  BUNDLES_ACTIVE,
  WKZ_SLOT_CONFIG_ACTIVE,
  USERS_ACTIVE,
  WKZ_MEDIA_LIST,
  PRIVACY_ACTIVE,
  IMPRESSUM_ACTIVE,
  PRODUCT_RATING,
  DESCRIPTION_RATING,
  NEWSLETTER,
  CUSTOMERS,
  SHOP_CONTACTS_ACTIVE,
  DAMPZN_ACTIVE,
  SENDING_HISTORY,
  KEYWORDS,
  WKZ_CONFIG,
  WKZ_INPUT,
  GOOGLE_SHOPPING,
  BOOKED_SERVICES,
  KAT_SOLL,
  MY_MARKETS,
  BRANDS,
  PZN,
  ONDEMANDCRAWL,
  BOOKMARKS,
  AVBAANALYTICS,
  PZN_OVERVIEW,
  TEMPLATES,
  UPLOAD_DATA,
  PRESET_FILTERS,
  SMILE_GPT,
  CONTACTS,
  COMMUNICATION_COPILOT_SETTINGS,
  PROMPT_FACTORY,
} from 'src/constants/topBarConstants'
import { toggleWkzModalAction } from 'src/actions/wkzActions'
import { openUsersSidebar } from 'src/actions/usersPanelActions'

const useStyles = makeStyles()((theme) => ({
  box: {
    marginLeft: 105,
    '& hr': {
      marginRight: theme.spacing(2),
      marginLeft: 0,
      backgroundColor: 'rgba(255, 255, 255, 0.12)',
    },
  },
  button: {
    background: '#4472C4',
    color: '#FFFFFF',
    '&:hover': {
      background: '#4472C4',
    },
  },
  text: {
    marginRight: theme.spacing(2),
  },
}))

const AmazonCTA = ({ classes }) => (
  <Box className={classes.box}>
    <Typography className={classes.text}>Amazon</Typography>
  </Box>
)

const BundlesCTA = ({ classes }) => (
  <Box className={classes.box}>
    <Typography className={classes.text}>Bundles</Typography>
  </Box>
)

const WkzAdConfigCTA = ({ classes }) => {
  const dispatch = useDispatch()

  const onClick = () => {
    dispatch(toggleWkzModalAction())
  }

  return (
    <Box className={classes.box} display="flex" alignItems="center">
      <Typography className={classes.text}>WKZ Media Configuration</Typography>
      <Divider orientation="vertical" flexItem />
      <Button onClick={onClick} className={classes.button}>
        ADD
      </Button>
    </Box>
  )
}

const WkzAdListCTA = ({ classes }) => (
  <Box className={classes.box}>
    <Typography className={classes.text}>WKZ Media</Typography>
  </Box>
)

const UsersCTA = ({ classes }) => {
  const dispatch = useDispatch()

  const onClick = () => {
    dispatch(openUsersSidebar())
  }

  return (
    <Box className={classes.box} display="flex" alignItems="center">
      <Typography className={classes.text}>Users</Typography>
      <Divider orientation="vertical" flexItem />
      <Button onClick={onClick} className={classes.button}>
        ADD
      </Button>
    </Box>
  )
}

const ImpressumCTA = ({ classes }) => (
  <Box className={classes.box}>
    <Typography className={classes.text}>Impressum</Typography>
  </Box>
)

const PrivacyCTA = ({ classes }) => (
  <Box className={classes.box}>
    <Typography className={classes.text}>Datenschutzbestimmun</Typography>
  </Box>
)

const ProductRating = ({ classes }) => (
  <Box className={classes.box}>
    <Typography className={classes.text}>Packshot Rating</Typography>
  </Box>
)
const DescriptionRating = ({ classes }) => (
  <Box className={classes.box}>
    <Typography className={classes.text}>Description Rating</Typography>
  </Box>
)
const Newsletter = ({ classes }) => (
  <Box className={classes.box}>
    <Typography className={classes.text}>Newsletter Config</Typography>
  </Box>
)
const Customers = ({ classes }) => (
  <Box className={classes.box}>
    <Typography className={classes.text}>Customers</Typography>
  </Box>
)
const ShopContacts = ({ classes }) => (
  <Box className={classes.box}>
    <Typography className={classes.text}>Shop Contacts</Typography>
  </Box>
)
const DamPzn = ({ classes }) => (
  <Box className={classes.box}>
    <Typography className={classes.text}>Products</Typography>
  </Box>
)
const SendingHistory = ({ classes }) => (
  <Box className={classes.box}>
    <Typography className={classes.text}>Sending History</Typography>
  </Box>
)
const Keywords = ({ classes }) => (
  <Box className={classes.box}>
    <Typography className={classes.text}>Keywords</Typography>
  </Box>
)
const WkzInputConfig = ({ classes }) => (
  <Box className={classes.box}>
    <Typography className={classes.text}>WKZ Data Input Config</Typography>
  </Box>
)
const WkzInput = ({ classes }) => (
  <Box className={classes.box}>
    <Typography className={classes.text}>WKZ Data Input</Typography>
  </Box>
)
const GoogleShopping = ({ classes }) => (
  <Box className={classes.box}>
    <Typography className={classes.text}>Google Shopping</Typography>
  </Box>
)
const BookedServices = ({ classes }) => (
  <Box className={classes.box}>
    <Typography className={classes.text}>Booked Services</Typography>
  </Box>
)
const KatSoll = ({ classes }) => (
  <Box className={classes.box}>
    <Typography className={classes.text}>KatSoll</Typography>
  </Box>
)
const MyMarkets = ({ classes }) => (
  <Box className={classes.box}>
    <Typography className={classes.text}>Mein Märkte</Typography>
  </Box>
)
const Brands = ({ classes }) => (
  <Box className={classes.box}>
    <Typography className={classes.text}>Brand Management</Typography>
  </Box>
)
const PZNMgmt = ({ classes }) => (
  <Box className={classes.box}>
    <Typography className={classes.text}>PZN Management</Typography>
  </Box>
)
const OnDemandCrawl = ({ classes }) => (
  <Box className={classes.box}>
    <Typography className={classes.text}>Crawling On Demand</Typography>
  </Box>
)
const Bookmarks = ({ classes }) => (
  <Box className={classes.box}>
    <Typography className={classes.text}>Bookmarks</Typography>
  </Box>
)
const AvbAnalytics = ({ classes }) => (
  <Box className={classes.box}>
    <Typography className={classes.text}>Availability Analytics</Typography>
  </Box>
)
const PznOverview = ({ classes }) => (
  <Box className={classes.box}>
    <Typography className={classes.text}>PZN Overview</Typography>
  </Box>
)
const Templates = ({ classes }) => (
  <Box className={classes.box}>
    <Typography className={classes.text}>Templates</Typography>
  </Box>
)
const UploadExcelTemplate = ({ classes }) => (
  <Box className={classes.box}>
    <Typography className={classes.text}>Upload Excel Template</Typography>
  </Box>
)
const PresetFilters = ({ classes }) => (
  <Box className={classes.box}>
    <Typography className={classes.text}>Preset Filters</Typography>
  </Box>
)
const SmileGpt = ({ classes }) => (
  <Box className={classes.box}>
    <Typography className={classes.text}>
      SmileGPT: Dein Pharma Copilot
    </Typography>
  </Box>
)
const EmailContacts = ({ classes }) => (
  <Box className={classes.box}>
    <Typography className={classes.text}>Comm: Contacts</Typography>
  </Box>
)
const CommunicationCopilotSettings = ({ classes }) => (
  <Box className={classes.box}>
    <Typography className={classes.text}>
      Comm: Copilot Settings on Subbrand Level
    </Typography>
  </Box>
)
const PromptFactory = ({ classes }) => (
  <Box className={classes.box}>
    <Typography className={classes.text}>Create awesome prompts</Typography>
  </Box>
)

export default function TopBarCTA() {
  const { classes } = useStyles()
  const activeCTA = useSelector((state) => state.topBarCTA.activeCTA)
  switch (activeCTA) {
    case AMAZON_ACTIVE:
      return <AmazonCTA classes={classes} />
    case BUNDLES_ACTIVE:
      return <BundlesCTA classes={classes} />
    case WKZ_SLOT_CONFIG_ACTIVE:
      return <WkzAdConfigCTA classes={classes} />
    case WKZ_MEDIA_LIST:
      return <WkzAdListCTA classes={classes} />
    case USERS_ACTIVE:
      return <UsersCTA classes={classes} />
    case IMPRESSUM_ACTIVE:
      return <ImpressumCTA classes={classes} />
    case PRIVACY_ACTIVE:
      return <PrivacyCTA classes={classes} />
    case PRODUCT_RATING:
      return <ProductRating classes={classes} />
    case DESCRIPTION_RATING:
      return <DescriptionRating classes={classes} />
    case NEWSLETTER:
      return <Newsletter classes={classes} />
    case CUSTOMERS:
      return <Customers classes={classes} />
    case SHOP_CONTACTS_ACTIVE:
      return <ShopContacts classes={classes} />
    case DAMPZN_ACTIVE:
      return <DamPzn classes={classes} />
    case SENDING_HISTORY:
      return <SendingHistory classes={classes} />
    case KEYWORDS:
      return <Keywords classes={classes} />
    case WKZ_CONFIG:
      return <WkzInputConfig classes={classes} />
    case WKZ_INPUT:
      return <WkzInput classes={classes} />
    case GOOGLE_SHOPPING:
      return <GoogleShopping classes={classes} />
    case BOOKED_SERVICES:
      return <BookedServices classes={classes} />
    case KAT_SOLL:
      return <KatSoll classes={classes} />
    case MY_MARKETS:
      return <MyMarkets classes={classes} />
    case BRANDS:
      return <Brands classes={classes} />
    case PZN:
      return <PZNMgmt classes={classes} />
    case ONDEMANDCRAWL:
      return <OnDemandCrawl classes={classes} />
    case BOOKMARKS:
      return <Bookmarks classes={classes} />
    case AVBAANALYTICS:
      return <AvbAnalytics classes={classes} />
    case PZN_OVERVIEW:
      return <PznOverview classes={classes} />
    case TEMPLATES:
      return <Templates classes={classes} />
    case UPLOAD_DATA:
      return <UploadExcelTemplate classes={classes} />
    case PRESET_FILTERS:
      return <PresetFilters classes={classes} />
    case SMILE_GPT:
      return <SmileGpt classes={classes} />
    case CONTACTS:
      return <EmailContacts classes={classes} />
    case COMMUNICATION_COPILOT_SETTINGS:
      return <CommunicationCopilotSettings classes={classes} />
    case PROMPT_FACTORY:
      return <PromptFactory classes={classes} />
    default:
      return null
  }
}
