import produce from 'immer'
import uniqBy from 'lodash/uniqBy'
import {
  UPDATE_FILTERS,
  REMOVE_CHECKED_PZN,
  ADD_CHECKED_PZN,
  CLOSE_SIDEBAR,
  OPEN_DRAWER,
  SET_KEYWORDS,
  FETCHING_KEYWORDS,
  FETCHING_KEYWORDS_404,
  FETCHING_KEYWORDS_ERROR,
  PAGE_CHANGE,
  DEACTIVATE_DELETING_MODE,
  ACTIVATE_DELETING_MODE,
  CLEAR_SELECTED_KW,
  DEACTIVATE_KEYWORDS,
  SET_REACH_FACTOR,
} from 'src/actionTypes/keywordMgmtActionTypes'
const initialState = {
  kwds: [],
  fetchingKwd: false,
  fetchingKwdError: false,
  filters: {
    search: null,
    status: null,
    sort: null,
    reach: null,
  },
  deletingModeActive: false,
  pagination: {
    page: 1,
    count: 0,
  },
  activeRow: null,
  openDrawer: false,
  addingMode: false,
  checkedMap: new Map(),
  checked: [],
  keywords: [],
  currentKeywords: {},
}
export default function KeywordsManagementReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case UPDATE_FILTERS:
      return produce(state, (draft) => {
        draft.filters[payload.target] = payload.value
        draft.pagination.page = 1
      })
    case REMOVE_CHECKED_PZN:
      return produce(state, (draft) => {
        if (Array.isArray(payload)) {
          payload.forEach((p) => draft.checkedMap.delete(p.id))
          draft.checked = state.checked.filter((pzn) =>
            draft.checkedMap.has(pzn.id),
          )
        } else {
          draft.checked = draft.checked.filter((pzn) => pzn.id !== payload.id)
          draft.checkedMap.delete(payload.id)
        }
      })
    case ADD_CHECKED_PZN:
      return produce(state, (draft) => {
        if (Array.isArray(payload)) {
          const deduplicated = uniqBy(
            [...state.checked, ...payload],
            (element) => element.id,
          )
          draft.checked = deduplicated
          payload.forEach((p) => draft.checkedMap.set(p.id, true))
        } else {
          draft.checked.push(payload)
          draft.checkedMap.set(payload.id, true)
        }
      })
    case CLEAR_SELECTED_KW:
      return produce(state, (draft) => {
        draft.checked = []
        draft.checkedMap = new Map()
      })
    case CLOSE_SIDEBAR:
      return produce(state, (draft) => {
        draft.activeRow = null
        draft.openDrawer = false
      })
    case PAGE_CHANGE:
      return produce(state, (draft) => {
        draft.pagination.page = payload
      })
    case DEACTIVATE_KEYWORDS:
      return produce(state, (draft) => {
        const deactivated = draft.kwds.map((kw) => {
          const cp = { ...kw }
          if (draft.checkedMap.has(kw.id)) {
            cp.active = 0
          }
          return cp
        })
        draft.kwds = deactivated
      })
    case OPEN_DRAWER:
      return produce(state, (draft) => {
        draft.openDrawer = true
      })
    case SET_KEYWORDS:
      return produce(state, (draft) => {
        draft.kwds = payload.data
        draft.pagination.count = payload.count
        draft.fetchingKwd = false
        draft.fetchingKwdError = false
      })
    case FETCHING_KEYWORDS:
      return produce(state, (draft) => {
        draft.fetchingKwd = true
        draft.fetchingKwdError = true
      })
    case FETCHING_KEYWORDS_404:
      return produce(state, (draft) => {
        draft.fetchingKwd = false
        draft.fetchingKwdError = false
        draft.kwds = []
      })
    case FETCHING_KEYWORDS_ERROR:
      return produce(state, (draft) => {
        draft.fetchingPzns = false
        draft.fetchingKwdError = true
      })
    case ACTIVATE_DELETING_MODE:
      return produce(state, (draft) => {
        draft.deletingModeActive = true
      })
    case DEACTIVATE_DELETING_MODE:
      return produce(state, (draft) => {
        draft.deletingModeActive = false
      })
    case SET_REACH_FACTOR:
      return produce(state, (draft) => {
        draft.kwds = state.kwds.map((kw) => {
          const cp = { ...kw }
          if (cp.id === payload.id) {
            cp.reach = payload.reach
          }
          return cp
        })
      })
    default:
      return state
  }
}
