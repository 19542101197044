import { useEffect, useCallback, useState } from 'react'
import { createContainer } from 'unstated-next'
import { format } from 'date-fns'
import { useSelector } from 'react-redux'
import socket from '../services/socketService'
import { getUserId, userSelector } from 'src/selectors/accountSelectors'
import useSimpleNotification from './useSimpleNotification'
import getOnDemandSpecialUsers from 'src/utils/onDemandSpecialUsers'
import { getOndemandQueue } from 'src/services/ondemandService'
export interface IConnectionInitialState {
  connected: boolean
  error: boolean
  reconnecting: boolean
}
export enum CrawlingStatuses {
  InProgress = 'in progress',
  Done = 'done',
  Error = 'error',
}
export interface QueueElement {
  id: number
  domainId: number
  shop: string
  pzn: string
  status: CrawlingStatuses
  date: string
}

export interface RawElementForQueue {
  id: number
  domainId: number
  shop: string
  pzn: string
}

export interface IAddedToQueueSuccess {
  domainId: number
  pzn: string
  status: CrawlingStatuses
  userId: number
  id: number
  shop: string
}

export interface ISuccessResponse {
  id: number
  price: string
  availability: number
  listung: number
  domainId: number
  shop: string
  pzn: string
  status: CrawlingStatuses
  userIDs: number[]
  dateDone: string
  updatedAt: string
  rabatt: number
}

export interface IErrorCrawler {
  pzn: string
  domainId: number
  userIDs: number[]
  status: string
  shop: string
  id: number
  dateDone: Date
}

export interface ILoadedStillInProgress {
  id: number
  date: string
  domainId: number
  pzn: string
  shop: string
  status: CrawlingStatuses
  userId: number
  users: number[]
}
export interface IFirstLoadCrawlingInProgressResponse {
  userId: number
  pznsInProgress: ILoadedStillInProgress[]
}

const connectionInitialState: IConnectionInitialState = {
  connected: false,
  error: false,
  reconnecting: false,
}

function useCrawlerOnDemand() {
  const [io, setIo] = useState<any | null>(null)
  const sock = socket.getCustomSocket()
  const [connection, setConnection] = useState(connectionInitialState)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [queue, setQueue] = useState<QueueElement[]>([])
  const [bookmarked, setBookmarked] = useState<QueueElement[]>([])
  const [crawlingActive, setCrawlingActive] = useState(false)
  const noti = useSimpleNotification()
  const userId = useSelector(getUserId)
  // const onSuccess = useCallback(() => {
  //   noti('success', 'Sent to the crawler.')
  // }, [noti])
  const onError = useCallback(
    (msg?: string) => {
      if (msg) {
        return noti('error', msg)
      }
      noti('error')
    },
    [noti],
  )

  /**
   * this is the last updated element, added this so all subscribers to this hook that track this value
   * know which is the last one updated so they can update values in other components
   */
  const [updated, setUpdated] = useState<ISuccessResponse | null>(null)
  const user = useSelector(userSelector)

  useEffect(() => {
    if (!io) {
      setIo(sock)
      sock.emit('create', 'OnDemand')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sock])

  useEffect(() => {
    // if there is something in the queue then set crawling to active
    const inProgress = queue.filter((q) => q.status === 'in progress')
    if (queue.length && inProgress.length > 0) {
      setCrawlingActive(true)
    } else {
      setCrawlingActive(false)
    }
  }, [queue])

  const connectionListener = useCallback(() => {
    setConnection({
      connected: true,
      error: false,
      reconnecting: false,
    })
    // sock.emit('create', 'OnDemand')
  }, [])

  //  QUEUE HANDLERS
  const addToQueue = useCallback(
    (data: RawElementForQueue) => {
      const alreadyInQueue = queue.find(
        (e) => e.id === data.id && e.status === CrawlingStatuses.InProgress,
      )
      if (alreadyInQueue) {
        return
      }
      const inProgress = queue.filter(
        (e) => e.status === CrawlingStatuses.InProgress,
      )

      const isSpecialUser = getOnDemandSpecialUsers(user)
      const limit = isSpecialUser ? 1000 : 50
      if (inProgress.length === limit && onError) {
        return onError('Limit reached.')
      }

      io.emit('addPznToCrawlingQueue', {
        id: data.id,
        pzn: data.pzn,
        domainId: data.domainId,
        userId: user.id,
        shop: data.shop,
      })
    },
    [queue, user, io, onError],
  )

  const sendBookmarkToQueue = useCallback(
    (boomarkId: number) => {
      io.emit('addBookmarkToCrawlingQueue', { id: boomarkId })
    },
    [io],
  )

  const sendSelectedPznsToShop = useCallback(
    (data: RawElementForQueue[]) => {
      const isSpecialUser = getOnDemandSpecialUsers(user)
      const limit = isSpecialUser ? 1000 : 50
      const inProgress = queue.filter(
        (e) => e.status === CrawlingStatuses.InProgress,
      )
      if (inProgress.length === limit && onError) {
        return onError('Limit reached.')
      }

      const withUser = data.map((d) => ({
        ...d,
        userId: user.id,
      }))

      io.emit('addAllTabsToCrawlingQueue', withUser)
    },
    [io, user, queue],
  )

  const updateBookmarked = useCallback((newBookmarks: any) => {
    setBookmarked(newBookmarks)
  }, [])

  const addedSuccessfully = useCallback((res: IAddedToQueueSuccess) => {
    const element = {
      ...res,
      status: CrawlingStatuses.InProgress,
      date: format(new Date(), 'dd/MM HH:mm:ss'),
    }
    // if (onSuccess) {
    //   onSuccess()
    // }
    setQueue((prev) => {
      // if already in queue aloow element to be resent to the crawler
      const found = prev.find((p) => p.id === res.id)
      if (found) {
        const mapped = prev.map((p) => {
          const cp: QueueElement = { ...p }
          if (p.id === res.id) {
            cp.status = CrawlingStatuses.InProgress
          }
          return cp
        })
        return mapped
      }

      // if not in queue just add it there
      return [...prev, element]
    })
  }, [])
  // when it's done
  // set last updated
  const update = useCallback(
    (response: ISuccessResponse & { updatedAt: string }) => {
      setUpdated(response)
    },
    [],
  )

  const doneCrawling = async (res: ISuccessResponse) => {
    const { userIDs, ...rest } = res
    if (!userIDs.includes(userId)) {
      return
    }
    const status =
      rest.status.toLocaleLowerCase() ===
      CrawlingStatuses.Error.toLocaleLowerCase()
        ? CrawlingStatuses.Error
        : CrawlingStatuses.Done
    const elementForQueue: QueueElement = {
      ...rest,
      status: status,
      date: format(new Date(rest.dateDone), 'dd/MM HH:mm:ss'),
    }
    // const mapped = queue.map((r) => {
    //   if (r.id === res.id) {
    //     return elementForQueue
    //   }
    //   return r
    // })

    update({ ...res, updatedAt: res.dateDone })
    // no clue why this work so you have to set it twice othervise it wont work
    // problem is too many done requests come too fast and react batching do not update state correctly
    // my guess is this somehow slows it down or force it to batch one by one since it's different state values
    setQueue((prevQueue) => {
      return prevQueue.map((r) => {
        if (r.id === res.id) {
          return elementForQueue
        }
        return r
      })
    })
  }

  const addingError = useCallback(() => {
    if (onError) {
      onError('Adding to queue error. Try again.')
    }
  }, [onError])

  const crawlerError = useCallback(
    (res: IErrorCrawler) => {
      const d = res.dateDone ? res.dateDone : new Date()

      const mapped = queue.map((r) => {
        if (r.id === res.id) {
          return {
            ...r,
            status: CrawlingStatuses.Error,
            date: format(new Date(d), 'dd/MM HH:mm:ss'),
          }
        }
        return r
      })
      setQueue(mapped)
    },
    [queue],
  )

  const checkAndUpdateIfNeeded = (inProgress: number[]) => {
    setQueue((queue) => {
      // find elements that are not in the backend but are here in the queue
      const remaining = queue.filter((q) => inProgress.indexOf(q.id) === -1)

      // if we find some of these elements then update that remaining elements to be DONE
      const mapped = queue.map((r) => {
        const inRemaining = remaining.find((e) => r.id === e.id)
        if (inRemaining) {
          return {
            ...r,
            status: CrawlingStatuses.Done,
            date: format(new Date(), 'dd/MM HH:mm:ss'),
          }
        }
        return r
      })
      return mapped
    })
  }

  const removeFromQueue = useCallback(
    (e: RawElementForQueue) => {
      const filtered = queue.filter((element) => element.id !== e.id)
      setQueue(filtered)
    },
    [queue],
  )

  const clearQueue = useCallback(() => {
    setQueue([])
  }, [])

  const creditsListner = useCallback(() => {
    noti('error', 'Not enough credits to crawl all datapoints')
  }, [])

  // SOCKET IO HANDLERS
  const connectionErrorListener = useCallback((err: any) => {
    console.log(err)
    setConnection((prev) => {
      if (prev.reconnecting) {
        return {
          ...prev,
          connected: false,
          error: false,
        }
      }
      return {
        connected: false,
        error: true,
        reconnecting: false,
      }
    })
  }, [])

  const reconnectingInProgress = useCallback((number: any) => {
    console.log('Reconeccting attempt number:', number)
    setConnection({
      connected: false,
      error: false,
      reconnecting: true,
    })
  }, [])

  const reconnectionMaxFailed = useCallback((number: any) => {
    console.log('Reconeccting max attempt number:')
    setConnection({
      connected: false,
      error: true,
      reconnecting: false,
    })
  }, [])

  const connectionTimeoutListener = useCallback(() => {
    console.log('Connection timeout error')
    setConnection({
      connected: false,
      error: true,
      reconnecting: false,
    })
  }, [])

  const onDisconnect = useCallback(
    (reason: any) => {
      console.error(reason)
      socket.init()
      setConnection({
        connected: false,
        error: true,
        reconnecting: false,
      })
      setIo(sock)
      sock.emit('create', 'OnDemand')
    },
    [sock],
  )

  const openDrawer = useCallback(() => setDrawerOpen(true), [])
  const closeDrawer = useCallback(() => setDrawerOpen(false), [])

  useEffect(() => {
    if (connection.connected && user) {
      // ping backend to send us info about currently in progress
      io.emit(
        'getAllPznsInProgress',
        { userId: user.id },
        (res: IFirstLoadCrawlingInProgressResponse) => {
          if (res?.pznsInProgress?.length) {
            const pznsFromThisUser = res.pznsInProgress.filter(
              (p) => p.userId === user.id,
            )
            const mapped = pznsFromThisUser.map((p) => {
              const elementForQueue: QueueElement = {
                ...p,
                status: CrawlingStatuses.InProgress,
                date: format(new Date(p.date), 'dd/MM HH:mm:ss'),
              }
              return elementForQueue
            })
            setQueue(mapped)
          }
        },
      )
    }
  }, [user, connection.connected, io])

  // effect to spin up/down timers to ping backend and check if anything done
  // purpose of this is because of too many requests and older version of sockets atm @2.4.0
  // some of the results are not properly updated
  // so this is like 'sensor' or double check if we left something to update it correctly by polling results and comparing them with what we have
  // useEffect(() => {
  //   // initialize variables
  //   let interval: ReturnType<typeof setInterval> | null = null
  //   let timer: ReturnType<typeof setInterval> | null = null
  //   if (crawlingActive) {
  //     // if there is active crawling right now

  //     // spin up the timer
  //     timer = setTimeout(() => {
  //       // spin up interval
  //       interval = setInterval(async () => {
  //         const data = await getOndemandQueue()
  //         checkAndUpdateIfNeeded(data)
  //       }, 1000 * 60)
  //     }, 1000 * 4)
  //   }

  //   // cleanup
  //   return () => {
  //     if (timer) {
  //       clearTimeout(timer)
  //     }
  //     if (interval) {
  //       clearInterval(interval)
  //     }
  //   }
  // }, [crawlingActive])

  useEffect(() => {
    if (io) {
      io.on('connect', connectionListener)
      io.on('connect_error', connectionErrorListener)
      io.on('disconnect', onDisconnect)
      io.on('connect_timeout', connectionTimeoutListener)
      io.on('reconnecting', reconnectingInProgress)
      io.on('reconnect_failed', reconnectionMaxFailed)
      io.on('addedPznToQueueSuccessfully', addedSuccessfully)
      io.on('addingPznToQueueError', addingError)
      io.on('doneCrawlingPzn', doneCrawling)
      io.on('crawlerError', crawlerError)
      io.on('customerCredits', creditsListner)
    }

    // sendMsg()
    return () => {
      if (io) {
        io.off('connect', connectionListener)
        io.off('connect_error', connectionErrorListener)
        io.off('disconnect', onDisconnect)
        io.off('reconnecting', reconnectingInProgress)
        io.off('reconnect_failed', reconnectionMaxFailed)
        io.off('connect_timeout', connectionTimeoutListener)
        io.off('addedPznToQueueSuccessfully', addedSuccessfully)
        io.off('addingPznToQueueError', addingError)
        io.off('doneCrawlingPzn', doneCrawling)
        io.off('crawlerError', crawlerError)
        io.off('customerCredits', creditsListner)

        // io.disconnect()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [io, queue])

  return {
    connection,
    drawerOpen,
    openDrawer,
    closeDrawer,
    queue,
    addToQueue,
    clearQueue,
    removeFromQueue,
    updated,
    bookmarked,
    updateBookmarked,
    sendBookmarkToQueue,
    sendSelectedPznsToShop,
  }
}

export const CrawlerContainer = createContainer(useCrawlerOnDemand)
