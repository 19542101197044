function generateActionType(type) {
  return `@abdaKw/${type}`
}

// filters
export const UPDATE_FILTERS = generateActionType('UPDATE_FILTERS')

// sidebar
export const CLOSE_SIDEBAR = generateActionType('CLOSE_SIDEBAR')
export const OPEN_DRAWER = generateActionType('OPEN_DRAWER')
export const SELECT_ROW = generateActionType('SELECT_ROW')

// table
export const SET_RESULTS = generateActionType('SET_RESULTS')
export const FETCHING = generateActionType('FETCHING')
export const FETCHING_404 = generateActionType('FETCHING_404')
export const FETCHING_ERROR = generateActionType('FETCHING_ERROR')
export const PAGE_CHANGE = generateActionType('PAGE_CHANGE')
export const SET_SIDEBAR_ABDA = generateActionType('SET_SIDEBAR_ABDA')
export const ADD_KEYWORD = generateActionType('ADD_KEYWORD')
export const REMOVE_KEYWORD = generateActionType('REMOVE_KEYWORD')
export const SET_ALL_KEYWORDS = generateActionType('SET_ALL_KEYWORDS')
