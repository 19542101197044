import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(() => ({
  saveButton: {
    backgroundColor: '#F5F5F5',
    color: '#263238',
    width: '82px',
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '26px',
    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.14)',
  },
}))

export default function BasicGreyButton({
  onClick,
  buttonText = 'Save',
  classes = {},
  ...rest
}) {
  const { classes: defaultClasses, cx } = useStyles()
  return (
    <Button
      data-testid="basic-grey-button"
      onClick={onClick}
      className={cx(defaultClasses.saveButton, classes.root)}
      {...rest}
    >
      {buttonText}
    </Button>
  )
}
BasicGreyButton.propTypes = {
  onClick: PropTypes.func,
}
