import produce from 'immer'

import {
  TOGGLE_MODAL,
  CLOSE_MODAL,
  IMPORT_WKZ_DATA,
  IMPORT_WKZ_DATA_ERROR,
  IMPORT_WKZ_DATA_FETCHING,
  UPDATE_MODAL_VALUE,
  OPEN_SINGLE_ROW,
  ADD_SLOT,
  DELETE_SLOT,
  POPULATE_DROPDOWN_VALUES,
  UPDATE_FILTERS,
  PAGE_CHANGE,
  RESET_FILTERS,
} from 'src/actions/wkzActions'

let initialState = {
  slotsList: [],
  fetchingWkzDataError: false,
  fetchingWkzDataInProgress: false,
  modalIsOpen: false,
  wkzShopsList: [],
  pageTypesList: [],
  bannerTypeList: [],
  visibilityList: [],
  screenSizeList: [],
  draft: {},
  activeEditMode: false,
  indexOfEditElement: null,
  selectedShopFilter: null,
  pagination: {
    page: 1,
    count: 0,
  },
  modalValues: {
    shop: null,
    pageType: null,
    divIdTag: null,
    placement: null,
    bannerType: null,
    active: null,
    listPrice: null,
    actualCosts: null,
    unit: null,
    screenSize: null,
    connected: null,
    uniqueBannerLocationId: null,
    uniqueBannerImageId: null,
    imageCommand: null,
    bannerCommand: null,
    uniqueBannerUrlId: null,
    reachFactor: null,
  },
  filters: {
    shop: null,
    screensize: null,
    position: null,
  },
}

function addModalValuesKeysToTheInitialState(initState) {
  const copy = { ...initState }
  copy.modalValuesKeys = Object.keys(copy.modalValues)
  return copy
}

const cleanup = (initState) => ({
  modalValues: initState.modalValues,
  activeEditMode: false,
  indexOfEditElement: null,
  draft: {},
})

initialState = addModalValuesKeysToTheInitialState(initialState)

function WkzReducer(state = initialState, action) {
  switch (action.type) {
    case IMPORT_WKZ_DATA:
      return produce(state, (draft) => {
        const { data, total } = action.payload
        draft.slotsList = data
        draft.pagination = {
          ...draft.pagination,
          count: total,
        }
        draft.fetchingWkzDataError = false
        draft.fetchingWkzDataInProgress = false
      })
    case IMPORT_WKZ_DATA_ERROR:
      return produce(state, (draft) => {
        draft.fetchingWkzDataError = true
        draft.fetchingWkzDataInProgress = false
      })
    case IMPORT_WKZ_DATA_FETCHING:
      return produce(state, (draft) => {
        draft.fetchingWkzDataInProgress = true
        draft.fetchingWkzDataError = false
      })
    case TOGGLE_MODAL:
      return produce(state, (draft) => {
        draft.modalIsOpen = !draft.modalIsOpen
        if (draft.modalIsOpen === false) {
          // cleanup
          const clean = cleanup(initialState)
          return Object.assign(draft, clean)
        }
        return draft
      })
    case CLOSE_MODAL:
      return produce(state, (draft) => {
        // cleanup
        const clean = cleanup(initialState)
        return Object.assign(draft, clean)
      })
    case UPDATE_MODAL_VALUE:
      return produce(state, (draft) => {
        const { type, value } = action.payload

        if (draft.modalValuesKeys.indexOf(type) === -1) {
          throw new Error(`type ${type} doesn't exist in wkz state.`)
        }
        draft.modalValues[type] = value
        if (draft.activeEditMode && draft.indexOfEditElement > -1) {
          draft.draft[type] = value
        }
      })
    case OPEN_SINGLE_ROW:
      return produce(state, (draft) => {
        const target = { ...draft.slotsList[action.payload] }
        draft.activeEditMode = true
        draft.indexOfEditElement = action.payload
        draft.modalValues = target
        draft.draft = target
        draft.modalIsOpen = true
      })
    case ADD_SLOT:
      return produce(state, (draft) => {
        draft.modalIsOpen = false
        // editing mode
        if (draft.activeEditMode && draft.indexOfEditElement > -1) {
          draft.slotsList[draft.indexOfEditElement] = { ...draft.draft }
        } else {
          const newSlot = {
            ...draft.modalValues,
            id: action.payload,
          }

          draft.slotsList = [newSlot, ...draft.slotsList]
        }
        // cleanup
        const clean = cleanup(initialState)
        return Object.assign(draft, clean)
      })
    case DELETE_SLOT:
      return produce(state, (draft) => {
        const { activeEditMode, indexOfEditElement } = draft
        if (activeEditMode && indexOfEditElement > -1) {
          // remove from list
          const newSlots = [
            ...draft.slotsList.slice(0, indexOfEditElement),
            ...draft.slotsList.slice(
              indexOfEditElement + 1,
              draft.slotsList.length,
            ),
          ]
          draft.slotsList = newSlots
          draft.modalIsOpen = false
          // cleanup
          const clean = cleanup(initialState)
          return Object.assign(draft, clean)
        }
        return draft
      })
    case POPULATE_DROPDOWN_VALUES:
      return produce(state, (draft) => {
        const {
          wkzAllShopsList,
          pageTypesList,
          bannerTypeList,
          visibilityList,
          screenSizeList,
          unitsList,
          adPlacementsList,
        } = action.payload

        draft.wkzShopsList = wkzAllShopsList
        draft.pageTypesList = pageTypesList
        draft.bannerTypeList = bannerTypeList
        draft.visibilityList = visibilityList
        draft.screenSizeList = screenSizeList
        draft.unitsList = unitsList
        draft.adPlacementsList = adPlacementsList
      })
    // case UPDATE_SHOP_FILTER:
    //   return produce(state, (draft) => {
    //     draft.selectedShopFilter = action.payload;
    //     draft.modalValues.shop = action.payload;
    //     draft.pagination.page = 1;
    //   });
    case UPDATE_FILTERS:
      return produce(state, (draft) => {
        const { filter, value } = action.payload
        draft.filters[filter] = value
        draft.pagination.page = 1
      })
    case RESET_FILTERS:
      return produce(state, (draft) => {
        draft.filters = initialState.filters
      })
    case PAGE_CHANGE:
      return produce(state, (draft) => {
        draft.pagination.page = action.payload
      })

    default:
      return state
  }
}

export default WkzReducer
