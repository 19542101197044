import React, { useEffect, useState, Fragment, useContext } from 'react'
import { useLocation, matchPath } from 'react-router'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  Fade,
  Slide,
  Tooltip,
  IconButton,
  Collapse,
  Icon,
  SvgIcon,
  Typography,
} from '@mui/material'
import {
  ArrowLeft as ArrowLeftIcon,
  ArrowRight as ArrowRightIcon,
  Compass,
} from 'react-feather'
import { AppStateContext } from 'src/AppProviders'
import {
  hasAdminRouteAccess,
  hasSubheaderRouteAccess,
  hasItemAccess,
} from './userHasAccess'
import NavItem from './NavItem'
import FooterInfo from '../FooterInfo'
import UserInfo from './UserInfo'
import useStyles from './styles'
import getNav, { subheaders } from './config'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import uuid from 'src/utils/generateUuid'

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        [],
      )}
    </List>
  )
}

function reduceChildRoutes({ acc, pathname, item, depth = 0, user }) {
  const key = item.title + depth

  if (item.scopeTitle && !hasItemAccess(item, user)) {
    return acc
  }

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    })

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
        id={item.id}
        disabled={item.disabled}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>,
    )
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
        id={item.id}
        disabled={item.disabled}
      />,
    )
  }

  return acc
}

function NavBar({ openMobile, onMobileClose, openDrawer }) {
  const [open, setOpen] = useState(false)
  const [, setAppState] = useContext(AppStateContext)
  const { classes } = useStyles()
  const location = useLocation()
  const { user } = useSelector((state) => state.account)
  const isAdmin =
    user?.roles && user.roles.filter((role) => role.name === 'admin').length > 0
  const { scopes } = user
  const navConfig = getNav({ isAdmin, scopes })
  const [openList, setOpenList] = useState({
    [subheaders.adminPanel]: false,
    [subheaders.customerDataSetup]: false,
    [subheaders.dataSetup]: false,
    [subheaders.onDemand]: false,
    [subheaders.refineCrawlerData]: false,
    [subheaders.wkzConfig]: false,
  })

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
    // eslint-disable-next-line
  }, [location.pathname])

  useEffect(() => {
    setOpen(!open)
    // eslint-disable-next-line
  }, [openDrawer])

  useEffect(() => {
    setAppState({ navBarOpen: open })
  }, [setAppState, open])

  const onSubheaderClick = (subheader) => {
    setOpenList((prev) => ({
      ...prev,
      [subheader]: prev[subheader] ? false : true,
    }))
  }

  const miniVariant = (
    <Box height="100%" mt="64px" display="flex" flexDirection="column">
      <Slide direction="left" in={!open} mountOnEnter unmountOnExit>
        <Box>
          <UserInfo variant="sm" />
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box onClick={() => setOpen(!open)}>
              <Tooltip
                title="Expand &nbsp;&nbsp;&nbsp; ["
                placement="right"
                enterDelay={500}
              >
                <ArrowRightIcon className={classes.icon} />
              </Tooltip>
            </Box>
          </Box>
          <NavItem
            depth={0}
            href={'/app/home'}
            icon={Compass}
            key={'akshdf'}
            info={undefined}
            title={''}
            id={'dashboard'}
          />
          {navConfig.map((config) => {
            if (!hasAdminRouteAccess(config, user)) {
              return <Fragment key={`config.subheader_${uuid()}`}></Fragment>
            }

            if (!hasSubheaderRouteAccess(config, user)) {
              return <Fragment key={`config.subheader_${uuid()}`}></Fragment>
            }
            return (
              <List
                key={config.subheader}
                subheader={
                  <Box mb="2rem" mt="2rem">
                    <Divider />
                  </Box>
                }
              >
                {config.items.map((item) => {
                  if (item.scopeTitle && !hasItemAccess(item, user)) {
                    return null
                  }
                  return (
                    <NavItem
                      depth={0}
                      href={item.href}
                      icon={item.icon}
                      key={`${item.id}${item.title}`}
                      info={item.info}
                      id={item.id}
                      disabled={item.disabled}
                    />
                  )
                })}
              </List>
            )
          })}
        </Box>
      </Slide>
    </Box>
  )

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Fade in timeout={1000}>
        <Box>
          <UserInfo variant="lg" />
          <Box mx={2} className={classes.drawerButtonRow}>
            <Box flexGrow={1}>
              <Divider />
            </Box>
            <Hidden mdDown>
              <Tooltip
                title="Collapse &nbsp;&nbsp;&nbsp; ["
                placement="right"
                enterDelay={500}
              >
                <ArrowLeftIcon
                  onClick={() => setOpen(!open)}
                  size={20}
                  className={classes.drawerButtonClose}
                />
              </Tooltip>
            </Hidden>
          </Box>

          <Box p="0 1rem 2rem 1rem" m="34px 0px 0px 0px" mb="102px">
            <NavItem
              depth={0}
              href={'/app/home'}
              icon={Compass}
              key={'akshdf'}
              info={undefined}
              title={'Dashboard'}
              id={'dashboard'}
            />
            {navConfig.map((config) => {
              if (!hasAdminRouteAccess(config, user)) {
                return <Fragment key={`config.subheader_${uuid()}`}></Fragment>
              }

              if (!hasSubheaderRouteAccess(config, user)) {
                return <Fragment key={`config.subheader_${uuid()}`}></Fragment>
              }

              return (
                <List
                  key={config.subheader}
                  subheader={
                    <ListSubheader disableGutters disableSticky>
                      <Box
                        alignItems="center"
                        dislpay="flex"
                        justifyContent="space-between"
                      >
                        {config.subheader}
                        {openList[config.subheader] ? (
                          <IconButton
                            onClick={() => onSubheaderClick(config.subheader)}
                          >
                            <ExpandLess />
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={() => onSubheaderClick(config.subheader)}
                          >
                            <ExpandMore />
                          </IconButton>
                        )}
                      </Box>
                    </ListSubheader>
                  }
                >
                  <Collapse in={openList[config.subheader]}>
                    {renderNavItems({
                      items: config.items,
                      pathname: location.pathname,
                      user,
                    })}
                  </Collapse>
                </List>
              )
            })}
          </Box>
          <Box className={classes.bottomInfoContainer} ml={2}>
            <Box mb={2}>
              <Divider />
            </Box>
            <FooterInfo />
          </Box>
        </Box>
      </Fade>
    </Box>
  )

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          open
          variant="persistent"
        >
          {open ? content : miniVariant}
        </Drawer>
      </Hidden>
    </>
  )
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
}

export default NavBar
