import React, { Suspense } from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { makeStyles, GlobalStyles } from 'tss-react'

import Auth from 'src/components/Auth'
import CookiesNotification from 'src/components/CookiesNotification'
import ScrollReset from 'src/components/ScrollReset'

import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'nprogress/nprogress.css'
import 'src/assets/css/prism.css'
import 'src/services/socketService'

import Routes from 'src/Routes'

import { enableES5 } from 'immer'

const history = createBrowserHistory()

enableES5()

function App() {
  return (
    <Suspense fallback={<h1>Loading...</h1>}>
      <GlobalStyles
        styles={{
          '*': {
            boxSizing: 'border-box',
            margin: 0,
            padding: 0,
          },
          html: {
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
            height: '100%',
            width: '100%',
          },
          body: {
            height: '100%',
            width: '100%',
            color: '#263238',
          },
          '#root': {
            height: '100%',
            width: '100%',
          },
        }}
      />
      <Router history={history}>
        <Auth>
          <ScrollReset />
          <CookiesNotification />
          <Routes />
        </Auth>
      </Router>
    </Suspense>
  )
}

export default App
