import {
  Box,
  Card,
  Typography,
  IconButton,
  SvgIcon,
  Switch,
} from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from 'tss-react/mui'
import {
  openDrawerSelector,
  getCheckedMap,
  getRejectedCategories,
} from '../lib/selectors'
import { closeDrawerAction } from '../lib/actions'
import ShopsDropdown from './ShopsDropdown'
import CategoryInput from './CategoryInput'
import KatSollDetails from './KatsollDetailsContainer'
import AddButton from './AddButton'
import Sidebar from 'src/components/Sidebar/index'
import NoItemsInSidebar from 'src/components/Sidebar/NoItems'
import ShopsAddToAll from './ShopsAddToAllDropdown'
import CategoriesAddToAll from './CategoriesAddToAll'
import AddToAllButton from './AddToAllBtn'
import RemoveAllKatsoll from './RemoveAllButton'
import { AutoAwesome, AutoFixHigh } from '@mui/icons-material'
import { useState } from 'react'
import CategoryRecomendationModalWhenNoResults from './CategoryRecomendationModalWhenNoResults'
import { isAdminSelector } from 'src/selectors/accountSelectors'
import { isInternalSelector } from 'src/selectors/accountSelectors'
import useVersionSwitcher from '../hooks/useVersionSwitcher'
import useBackgroundRecomendationStore from '../hooks/useBackgroundRecomendation'

const useStyles = makeStyles()(() => ({
  wrap: {
    marginTop: 15,
  },
  bgWrap: {
    '& .MuiPaper-root:nth-of-type(even)': {
      background: '#CCC',
    },
    '& .MuiPaper-root:nth-of-type(odd)': {
      background: '#FFF',
    },
  },
  error: {
    border: '1px solid red',
  },
}))
//

export default function KatsollSidebar() {
  const { classes, cx } = useStyles()
  const dispatch = useDispatch()
  const checkedMap = useSelector(getCheckedMap)
  const openDrawer = useSelector(openDrawerSelector)
  const rejected = useSelector(getRejectedCategories)
  const onClose = () => {
    dispatch(closeDrawerAction())
  }
  const isAdmin = useSelector(isAdminSelector)
  const isInternal = useSelector(isInternalSelector)
  const allowed = isAdmin || isInternal
  const [open, setOpen] = useState(null)
  const { currentVersion, switchVersion } = useVersionSwitcher()
  const { startRun } = useBackgroundRecomendationStore()

  return (
    <Sidebar open={openDrawer} onClose={onClose} sidebarTitle="Config">
      {allowed && (
        <>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="h6">Experimental:</Typography>
            <Switch
              checked={currentVersion === '2'}
              onChange={() => switchVersion()}
            />
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="h6">Run Magic:</Typography>
            <IconButton onClick={() => startRun(checkedMap)}>
              <AutoFixHigh />
            </IconButton>
          </Box>
        </>
      )}
      {checkedMap.size ? (
        <Box>
          <Card>
            <Box mb={3} p={2}>
              <ShopsAddToAll />
              <Box mt={1} display="flex" alignItems="center">
                <CategoriesAddToAll />
                <Box ml={1} display="flex" alignItems="center">
                  <AddToAllButton />
                  <Box ml={1} />
                  <RemoveAllKatsoll />
                </Box>
              </Box>
            </Box>
          </Card>
          {Array.from(checkedMap.entries()).map(([_, kat]) => {
            const error = rejected.find((r) => kat.subBrandId === r.subBrandId)
            return (
              <Card
                key={kat.subBrandId}
                className={classes.bgWrap}
                classes={{ root: cx(classes.wrap, error && classes.error) }}
              >
                <Box p={2}>
                  <Typography>{kat.name}</Typography>
                </Box>
                <Box p={2}>
                  <ShopsDropdown kat={kat} />
                  <Box mt={1} display="flex" alignItems="center">
                    <CategoryInput kat={kat} />
                    {(isAdmin || isInternal) && (
                      <IconButton onClick={() => setOpen(kat)}>
                        <SvgIcon sx={{ color: '#00fff0' }}>
                          <AutoAwesome />
                        </SvgIcon>
                      </IconButton>
                    )}
                    <Box>
                      <AddButton kat={kat} />
                    </Box>
                  </Box>
                  <KatSollDetails kat={kat} />
                </Box>
              </Card>
            )
          })}
          <CategoryRecomendationModalWhenNoResults
            selectedCategory={open}
            onClose={() => setOpen(null)}
          />
        </Box>
      ) : (
        <NoItemsInSidebar text="Select some items first" />
      )}
    </Sidebar>
  )
}
