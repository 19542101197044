import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

function AdminAndInternalGuard({ children }) {
  const account = useSelector((state) => state.account)

  if (!account.user) {
    return <Redirect to="/login" />
  }

  const isAdminOrInternal =
    account.user?.roles &&
    account.user.roles.filter(
      (role) => role.name === 'admin' || role.name === 'internal',
    ).length > 0

  if (!isAdminOrInternal) {
    return <Redirect to="/" />
  }

  return children
}

AdminAndInternalGuard.propTypes = {
  children: PropTypes.any,
}

export default AdminAndInternalGuard
