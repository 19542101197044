const LOCAL_STORAGE_ID = 'sbi'

export const getLocalStorage = () => {
  let smileBiObj
  let storedData

  if (global.localStorage) {
    storedData = localStorage.getItem(LOCAL_STORAGE_ID)
    if (storedData) {
      smileBiObj = JSON.parse(storedData)
    }
  }

  return smileBiObj
}

export const setLocalStorage = (newObj) => {
  const obj = { ...getLocalStorage(), ...newObj }
  if (global.localStorage) {
    global.localStorage.setItem(LOCAL_STORAGE_ID, JSON.stringify(obj))
  }
}

export const setLocalStorageUserId = (uId) => {
  const userId = uId.toString()
  setLocalStorage({ userId })
}

export const setLocalStorageEnv = (env) => {
  setLocalStorage({ env })
}
