function generateActionType(type) {
  return `@account/${type}`
}

export const LOGIN_REQUEST = generateActionType('LOGIN_REQUEST')
export const LOGIN_SUCCESS = generateActionType('LOGIN_SUCCESS')
export const LOGIN_FAILURE = generateActionType('LOGIN_FAILURE')
export const SILENT_LOGIN = generateActionType('SILENT_LOGIN')
export const LOGOUT = generateActionType('LOGOUT')
export const LOGOUT_IDLE = generateActionType('LOGOUT_IDLE')
export const REGISTER = generateActionType('REGISTER')
export const UPDATE_PROFILE = generateActionType('UPDATE_PROFILE')
export const DEACTIVATE_USER_BY_ID = generateActionType('DEACTIVATE_USER_BY_ID')
export const SET_RECOVERY_CREDENTIALS = generateActionType(
  'SET_RECOVERY_CREDENTIALS',
)
