import { Box, SvgIcon, Typography } from '@mui/material'
import LoopIcon from '@mui/icons-material/Loop'
import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
  centerdiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '400px',
  },
  rotateIcon: {
    animation: 'spin 4s linear infinite',
  },
}))

export function Reconnecting() {
  const { classes } = useStyles()
  return (
    <Box>
      <Box className={classes.centerdiv}>
        <Box className={classes.rotateIcon}>
          <SvgIcon>
            <LoopIcon
              sx={{
                animation: 'spin 2s linear infinite',
                '@keyframes spin': {
                  '0%': {
                    transform: 'rotate(360deg)',
                  },
                  '100%': {
                    transform: 'rotate(0deg)',
                  },
                },
              }}
            />
          </SvgIcon>
          {/* <img src={<RefreshCw />} alt="connection error" /> */}
        </Box>
        <Box ml={2}>
          <Typography>Reconnecting...</Typography>
        </Box>
      </Box>
    </Box>
  )
}
