import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Typography,
  SvgIcon,
  IconButton,
  Box,
} from '@mui/material'
import axios from 'src/utils/axios'
import useSimpleNotification from 'src/hooks/useSimpleNotification'
import { ListItemIcon } from '@mui/material'
import { AddCircle } from '@mui/icons-material'
import { Loader2 } from './LinearProgress'

const CategoryRecomendationModal = ({
  open,
  onClose,
  domainId,
  link,
  onAdd,
}) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const noti = useSimpleNotification()
  useEffect(() => {
    async function fetch() {
      const body = {
        invalidCategoryUrl: link,
        domainId,
      }
      setLoading(true)
      setError(false)
      try {
        const { data } = await axios.post(
          '/categories/subbrands/katsoll/suggestions',
          body,
        )
        // const data = {
        //   recommendations: [
        //     {
        //       rank: 1,
        //       url: 'https://www.delmed.de/category/zahngesundheit.33426.html',
        //       name: 'Delmed > Zahngesundheit ',
        //     },
        //     {
        //       rank: 2,
        //       url: 'https://www.delmed.de/category/zahnpasta.11950.html',
        //       name: 'Delmed > Zahnpasta',
        //     },
        //     {
        //       rank: 3,
        //       url: 'https://www.delmed.de/category/zahnseide.11951.html',
        //       name: 'Delmed > Zahnseide',
        //     },
        //     {
        //       rank: 4,
        //       url: 'https://www.delmed.de/category/zungenreiniger.33438.html',
        //       name: 'Delmed > Zungenreiniger',
        //     },
        //     {
        //       rank: 5,
        //       url: 'https://www.delmed.de/category/zungenreiniger.11952.html',
        //       name: 'Delmed > Zungenreiniger',
        //     },
        //   ],
        // }
        setData(data.recommendations)
      } catch (error) {
        console.error(error)
        noti('error')
        setError(true)
      } finally {
        setLoading(false)
      }
    }

    if (open) {
      fetch()
    }

    return () => {
      setData([])
    }
  }, [open, domainId, link, noti])

  if (!open) {
    return null
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle>Category Recommendations</DialogTitle>
      <DialogContent sx={{ width: '40vw', height: '45vh' }}>
        {error && (
          <Typography variant="subtitle1">
            Error, contact our support please.
          </Typography>
        )}
        {loading && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            height="100%"
            gap={2}
          >
            <Loader2 />
          </Box>
        )}
        {
          <List>
            {data.map((item, index) => (
              // ...

              <ListItem key={index}>
                <ListItemIcon>
                  <IconButton onClick={() => onAdd(item)}>
                    <SvgIcon>
                      <AddCircle />
                    </SvgIcon>
                  </IconButton>
                </ListItemIcon>

                <ListItemText
                  primary={item.name}
                  secondary={
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.url}
                    </a>
                  }
                />
              </ListItem>
            ))}
          </List>
        }
      </DialogContent>
    </Dialog>
  )
}

export default CategoryRecomendationModal
