import React from 'react'
import {
  BottomNavigation,
  Box,
  Divider,
  Hidden,
  Link,
  Typography,
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import SmileLogo from 'src/assets/images/smilelogoGreen.svg'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  stickToBottom: {
    borderTop: '1px solid #DADADA',
    width: '100%',
    position: 'fixed',
    bottom: 0,
    alignItems: 'center',
    color: '#546E7A',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: theme.spacing(7),
    height: 'auto',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  column: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(0.25),
      paddingBottom: theme.spacing(0.25),
    },
  },
  logo: {
    width: theme.spacing(10),
    height: 'auto',
    marginRight: theme.spacing(2),
  },
  link: {
    color: 'inherit',
    cursor: 'pointer',
  },
  hr: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    height: theme.spacing(2),
  },
}))

const getYear = () => new Date().getFullYear()

const RestrictedFooter = () => {
  const { classes, cx } = useStyles()

  return (
    <BottomNavigation className={cx(classes.stickToBottom, classes.wrapper)}>
      <Box className={classes.column}>
        <img src={SmileLogo} className={classes.logo} alt="logo" />
        <Typography variant="body2">© Smile Bi GmbH {getYear()}</Typography>
      </Box>
      <Box className={classes.column}>
        <Hidden mdDown>
          <Divider orientation="vertical" className={classes.hr} />
        </Hidden>
        <Link
          component={RouterLink}
          to="/sites/impressum"
          underline="none"
          className={classes.link}
        >
          <Typography variant="body2">Imprint</Typography>
        </Link>
        <Divider orientation="vertical" className={classes.hr} />
        <Link
          component={RouterLink}
          to="/sites/privacy"
          underline="none"
          className={classes.link}
        >
          <Typography variant="body2">Privacy Notice</Typography>
        </Link>
      </Box>
    </BottomNavigation>
  )
}

export default RestrictedFooter
