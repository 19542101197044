import produce from 'immer'
import {
  SET_ACTIVE_SHOP_FILTER,
  SELECT_MANY_SHOPS,
  UNSELECT_ALL_SHOPS,
  SELECT_ONE_SHOP,
  UNSELECT_ONE_SHOP,
  FETCHING_SHOPS_SUCCESS,
  FETCHING_SHOPS,
  FETCHING_SHOPS_ERROR,
  LOAD_STRATEGY,
  SELECT_TEST_EMAIL,
  UNSELECT_TEST_EMAIL,
  DISABLE_TEST_EMAIL_SELECTION,
  ENABLE_TEST_EMAIL_SELECTION,
  FILTER_SHOPS,
} from 'src/actionTypes/damWizardShopActionTypes'
const initialState = {
  strategy: null,
  activeFilter: null,
  shops: [],
  selectedShops: [],
  fetchingShops: false,
  fetchingShopsError: false,
  selectedTestEmail: false,
  disabledTestEmail: false,
  filterValue: '',
}
export default function DamWizardShopReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_STRATEGY:
      return produce(state, (draft) => {
        draft.strategy = action.payload
      })
    case FETCHING_SHOPS_SUCCESS:
      return produce(state, (draft) => {
        draft.shops = action.payload
        draft.fetchingShops = false
        draft.fetchingShopsError = false
      })
    case FETCHING_SHOPS:
      return produce(state, (draft) => {
        draft.fetchingShops = true
        draft.fetchingShopsError = false
      })
    case FETCHING_SHOPS_ERROR:
      return produce(state, (draft) => {
        draft.fetchingShops = false
        draft.fetchingShopsError = true
      })
    case SET_ACTIVE_SHOP_FILTER:
      return produce(state, (draft) => {
        draft.activeFilter = action.payload
      })
    case UNSELECT_ALL_SHOPS:
      return produce(state, (draft) => {
        draft.selectedShops = initialState.selectedShops
      })
    case SELECT_MANY_SHOPS:
      return produce(state, (draft) => {
        draft.selectedShops = action.payload
      })
    case SELECT_ONE_SHOP:
      return produce(state, (draft) => {
        draft.selectedShops = [...state.selectedShops, action.payload]
      })
    case UNSELECT_ONE_SHOP:
      return produce(state, (draft) => {
        draft.selectedShops = state.selectedShops.filter(
          (selectedId) => selectedId !== action.payload,
        )
      })
    case SELECT_TEST_EMAIL:
      return produce(state, (draft) => {
        draft.selectedTestEmail = true
      })
    case UNSELECT_TEST_EMAIL:
      return produce(state, (draft) => {
        draft.selectedTestEmail = false
      })
    case DISABLE_TEST_EMAIL_SELECTION:
      return produce(state, (draft) => {
        draft.disabledTestEmail = true
      })
    case ENABLE_TEST_EMAIL_SELECTION:
      return produce(state, (draft) => {
        draft.disabledTestEmail = false
      })
    case FILTER_SHOPS:
      return produce(state, (draft) => {
        const { value } = action.payload
        draft.filterValue = value
      })
    default:
      return state
  }
}
