import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import debounce from 'lodash/debounce'
import { InputAdornment, SvgIcon, TextField } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { Search as SearchIcon } from 'react-feather'
import { updateFiltersAction } from '../../actions'
import { getSearchValue } from '../../selectors'

const useStyles = makeStyles()((theme) => ({
  queryField: {
    [theme.breakpoints.up('sm')]: {
      width: 279,
      minWidth: 130,
    },
    [theme.breakpoints.down('md')]: {
      flexGrow: 1,
    },
  },
}))

const SearchCustomerInput = ({ updateFilters }) => {
  const { classes } = useStyles()
  const [search, setSearchValue] = useState('')
  const searchValue = useSelector(getSearchValue)

  useEffect(() => {
    setSearchValue(searchValue)
  }, [searchValue])

  const handleChange = (event) => {
    const { value } = event.target
    setSearchValue(value)
    updateFilters('search', value)
  }

  return (
    <TextField
      className={classes.queryField}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SvgIcon fontSize="small" color="action">
              <SearchIcon />
            </SvgIcon>
          </InputAdornment>
        ),
      }}
      onChange={handleChange}
      value={search || ''}
      placeholder="Search"
      variant="outlined"
    />
  )
}

const SearchCustomerContainer = (props) => {
  const dispatch = useDispatch()
  const updateFiltersDebounced = debounce(
    (target, value) => dispatch(updateFiltersAction(target, value)),
    300,
  )

  return (
    <SearchCustomerInput updateFilters={updateFiltersDebounced} {...props} />
  )
}

export default SearchCustomerContainer
