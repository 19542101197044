import axios from 'src/utils/axios'
import { errorHandler } from 'src/utils/errorHandler'

export const REDUCER_NAME = 'damWizard'
export const OPEN_WIZARD = '@damWizard/OPEN_WIZARD'
export const CLOSE_WIZARD = '@damWizard/CLOSE_WIZARD'
export const OPEN_WIZARD_FROM_SIDEBAR = '@damWizard/OPEN_WIZARD_FROM_SIDEBAR'
export const RESET_PZN_FROM_SIDEBAR = '@damWizars/RESET_PZN_FROM_SIDEBAR'

export const WIZARD_REMOVE_SINGLE_PACKSHOTS_TAB =
  '@damWizard/WIZARD_REMOVE_SINGLE_PACKSHOTS_TAB'
export const WIZARD_REMOVE_ALL_PACKSHOT_TABS =
  '@damWizard/WIZARD_REMOVE_ALL_PACKSHOT_TABS'
export const FETCHING_SINGLE_PZN_DATA = '@damWizard/FETCHING_SINGLE_PZN_DATA'
export const FETCHING_SINGLE_PZN_DATA_SUCCESS =
  '@damWizard/FETCHING_SINGLE_PZN_DATA_SUCCESS'
export const FETCHING_SINGLE_PZN_DATA_ERROR =
  '@damWizard/FETCHING_SINGLE_PZN_DATA_ERROR'
export const WIZARD_SET_ACTIVE_TAB = '@damWizard/WIZARD_SET_ACTIVE_TAB'
export const UPDATE_SELECTED_PACKSHOTS_FOR_SENDING =
  '@damWizard/UPDATE_SELECTED_PACKSHOTS_FOR_SENDING'
export const INITIALIZE_SELECTED_PACKSHOTS_FOR_SENDING =
  '@damWizard/INITIALIZE_SELECTED_PACKSHOTS_FOR_SENDING'
export const SELECT_ALL_PACKSHOTS_AND_FILES_BY_PZN =
  '@damWizard/SELECT_ALL_PACKSHOTS_AND_FILES_BY_PZN'
export const UPDATE_SELECTED_SHOPS = '@damWizard/UPDATE_SELECTED_SHOPS'

export const FETCHING_SHOPS = '@damWizard/FETCHING_SHOPS'
export const FETCHING_SHOPS_SUCCESS = '@damWizard/FETCHING_SHOPS_SUCCESS'
export const FETCHING_SHOPS_ERROR = '@damWizard/FETCHING_SHOPS_ERROR'

export const UPDATE_SUBJECT = '@damWizard/UPDATE_SUBJECT'
export const SEND_DATA_SUCCESS = '@damWizard/SEND_DATA'
export const SEND_TEST_EMAIL_REQUEST = '@damWizard/SEND_TEST_EMAIL_REQUEST'
export const SEND_TEST_EMAIL_SUCCESS = '@damWizard/SEND_TEST_EMAIL_SUCCESS'
export const SEND_TEST_EMAIL_ERROR = '@damWizard/SEND_TEST_EMAIL_ERROR'
export const CHECK_TEST_EMAIL = '@damWizard/CHECK_TEST_EMAIL'
export const UPDATE_SELECTED_FILES_FOR_SENDING =
  '@damWizard/UPDATE_SELECTED_FILES_FOR_SENDING'

export const CREATE_STATE_FROM_DATABASE =
  '@damWizard/CREATE_STATE_FROM_DATABASE'
export const REMOVE_PZN_FROM_PRELOAD = '@damWizard/REMOVE_PZN_FROM_PRELOAD'
export const FETCHING_DUPLICATED_DATA_SUCCESS =
  '@damWizard/FETCHING_DUPLICATED_DATA_SUCCESS'
export const REMOVE_PRELOAD_SHOPS = '@damWizard/REMOVE_PRELOAD_SHOPS'
export const REMOVE_PRELOAD_SUBJECT = '@damWizard/REMOVE_PRELOAD_SUBJECT'

export const openWizardAction = () => (dispatch) =>
  dispatch({ type: OPEN_WIZARD })

export const openWizardFromSidebar = (pzn) => (dispatch) =>
  dispatch({ type: OPEN_WIZARD_FROM_SIDEBAR, payload: pzn })

export const resetPznFromSidebar = () => (dispatch) =>
  dispatch({ type: RESET_PZN_FROM_SIDEBAR })

export const closeWizardAction = () => (dispatch) =>
  dispatch({ type: CLOSE_WIZARD })

export const initializeSelectedPackshotsForSending = () => (dispatch) =>
  dispatch({ type: INITIALIZE_SELECTED_PACKSHOTS_FOR_SENDING })

export const updateSelectedPackshotsForSending = (packshot) => (dispatch) =>
  dispatch({ type: UPDATE_SELECTED_PACKSHOTS_FOR_SENDING, payload: packshot })

export const updateSelectedFilesForSending = (file) => (dispatch) =>
  dispatch({ type: UPDATE_SELECTED_FILES_FOR_SENDING, payload: { file } })

export const resetSelectedPackshotsForSending = () => (dispatch) =>
  dispatch({ type: WIZARD_REMOVE_ALL_PACKSHOT_TABS })

export const updateSelectedShops = (shops) => (dispatch) =>
  dispatch({
    type: UPDATE_SELECTED_SHOPS,
    payload: shops,
  })

export const updateSelectedSubjectAction = (selected) => (dispatch) =>
  dispatch({ type: UPDATE_SUBJECT, payload: selected })

export const setActivePackshotsTab = (tab) => (dispatch) =>
  dispatch({ type: WIZARD_SET_ACTIVE_TAB, payload: tab })

export const removeTabAction = (pzn) => (dispatch) =>
  dispatch({ type: WIZARD_REMOVE_SINGLE_PACKSHOTS_TAB, payload: pzn })

export const selectAllDataByPznAction = (pzn) => (dispatch) =>
  dispatch({ type: SELECT_ALL_PACKSHOTS_AND_FILES_BY_PZN, payload: pzn })

export const sendData =
  ({ pznAssets, shops, subject }) =>
  async (dispatch) => {
    const shopIds = Object.entries(shops).map((shop) => shop[1].id)

    const params = {
      subject,
      shops: shopIds || [],
      pznAssets,
    }

    try {
      const { data } = await axios.post('/dam/send-email', { ...params })
      dispatch({ type: SEND_DATA_SUCCESS })

      const sentCount = data?.pardotEmail?.length
      const msg = sentCount
        ? `Successfully sent email to ${sentCount} shop!`
        : 'Success.'
      return msg
    } catch (error) {
      return errorHandler(error, {})
    }
  }

export const getShops = () => async (dispatch) => {
  try {
    dispatch({
      type: FETCHING_SHOPS,
    })
    const { data: shops } = await axios.get('/dam/shops')
    const sorted = shops.sort((a, b) => a.sort - b.sort)
    return dispatch({
      type: FETCHING_SHOPS_SUCCESS,
      payload: sorted,
    })
  } catch (error) {
    dispatch({
      type: FETCHING_SHOPS_ERROR,
    })
    return errorHandler(error, {})
  }
}

export const getSinglePznData = (pzn) => async (dispatch) => {
  dispatch({
    type: FETCHING_SINGLE_PZN_DATA,
  })

  try {
    const { data } = await axios.get(`/dam/pzn/assets/${pzn}`)

    return dispatch({
      type: FETCHING_SINGLE_PZN_DATA_SUCCESS,
      payload: {
        data,
        pzn,
      },
    })
  } catch (error) {
    dispatch({
      type: FETCHING_SINGLE_PZN_DATA_ERROR,
    })
    return errorHandler(error, {})
  }
}

export const fetchDuplicatedData = (pzn) => async (dispatch) => {
  try {
    const { data } = await axios.get(`/dam/pzn/assets/${pzn}`)
    return dispatch({
      type: FETCHING_DUPLICATED_DATA_SUCCESS,
      payload: { data, pzn },
    })
  } catch (error) {
    return errorHandler(error, {})
  }
}

export const duplicateEmailAction = (state) => (dispatch) => {
  dispatch({
    type: CREATE_STATE_FROM_DATABASE,
    payload: state,
  })
}

export const checkTestEmail = () => (dispatch) => {
  dispatch({ type: CHECK_TEST_EMAIL })
}

export const sendTestEmail =
  ({ pznAssets }) =>
  async (dispatch) => {
    dispatch({
      type: SEND_TEST_EMAIL_REQUEST,
    })
    try {
      const params = {
        pznAssets,
      }

      const { data } = await axios.post('/dam/send-email/test', params)
      dispatch({ type: SEND_TEST_EMAIL_SUCCESS })

      return data
    } catch (error) {
      dispatch({ type: SEND_TEST_EMAIL_ERROR })

      return errorHandler(error, {})
    }
  }

export const removePznFromPreloadPznsAction = (pzn) => (dispatch) =>
  dispatch({
    type: REMOVE_PZN_FROM_PRELOAD,
    payload: pzn,
  })

export const removeShopsFromPreloadShopsAction = () => (dispatch) =>
  dispatch({ type: REMOVE_PRELOAD_SHOPS })
