import { useEffect } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import { TextField } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { getShops, getAddToAllValues } from '../lib/selectors'
import {
  updateAddToAllValues,
  getShops as getShopsAction,
} from '../lib/actions'

const ShopsAddToAll = ({ className }) => {
  const shops = useSelector(getShops)
  const addtoAllValues = useSelector(getAddToAllValues) || {}
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getShopsAction())
  }, [dispatch])

  const onChange = (val) => {
    dispatch(updateAddToAllValues('shop', val))
    dispatch(updateAddToAllValues('category', null))
  }

  return (
    <Autocomplete
      options={shops}
      className={className}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, val) => option.name === val.name}
      renderInput={(params) => (
        <TextField {...params} label="Shops" variant="outlined" />
      )}
      value={addtoAllValues.shop || null}
      onChange={(e, val) => onChange(val)}
    />
  )
}

export default ShopsAddToAll
