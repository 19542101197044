import { createSelector } from 'reselect'
import { REDUCER_NAME as REDUCER } from './constants'

export const getResults = (state) => state[REDUCER].results
export const getFetchingStatus = (state) => ({
  fetching: state[REDUCER].fetching,
  error: state[REDUCER].fetchingError,
})
export const getActiveRow = (state) => state[REDUCER].activeRow
export const getPagination = (state) => state[REDUCER].pagination
export const getFilters = (state) => state[REDUCER].filters
export const getParams = createSelector(
  getPagination,
  getFilters,
  (pagination, filters) => ({
    ...pagination,
    filters,
  }),
)
export const getSelectedBrand = (state) => state[REDUCER].filters.brand
export const getSearchValue = (state) => state[REDUCER].filters.search
export const getSelectedManufacturer = (state) =>
  state[REDUCER].filters.manufacturer
export const openDrawerSelector = (state) => state[REDUCER].openDrawer
export const getSidebarValue = (key) => (state) => {
  if (key && state[REDUCER]?.activeRow) {
    return state[REDUCER].activeRow[key]
  }
  return ''
}
export const getManufacturers = (state) => state[REDUCER].manufacturers
export const getBrands = (state) => state[REDUCER].brands
export const getCheckedElements = (state) => state[REDUCER].checked
export const getCheckedMap = (state) => state[REDUCER].checkedMap
export const getKeywordsSelector = (state) => state[REDUCER].keywords
export const selectKeywordsByPznId = (id) => (state) =>
  state[REDUCER].currentKeywords[id] || null
export const getCurrentKatSoll = (id) => (state) =>
  state[REDUCER].currentKatSollDetails[id]
export const getAllCurrentKatsollDetails = (state) =>
  state[REDUCER].currentKatSollDetails
export const getShops = (state) => state[REDUCER].shops
export const getCategoriesSelector = (state) => state[REDUCER].categories
export const getThreadValue = (id) => (state) =>
  state[REDUCER].sidebarThreads[id]
export const getAddToAllValues = (state) => state[REDUCER].addToAll
export const getAllThreads = (state) => state[REDUCER].sidebarThreads
export const getRejectedCategories = (state) => state[REDUCER].rejected
