import produce from 'immer'
import {
  SET_CUSTOMERS,
  SELECT_ROW,
  FETCHING_CUSTOMERS,
  FETCHING_CUSTOMERS_ERROR,
  CLOSE_SIDEBAR,
  IMPORT_SHOPS,
  UPDATE_FILTERS,
  CHECK_SHOPS,
  IMPORT_BRANDS,
  CHECK_BRAND,
  UNCHECK_BRAND,
  UNCHECK_OPTIMISTIC,
  LOADING_BRANDS,
  LOADING_BRANDS_ERROR,
  SET_ONLY_BRANDS,
  SET_STATUS_QUO_RESTRICTIONS,
  SET_TRENDS_RESTRICTIONS,
  FETCHING_RESTRICTIONS,
  SET_WKZ_APP_RESTRICTIONS,
  FETCHING_RESTRICTIONS_ERROR,
  UPDATE_STATUS_QUO,
  UPDATE_TRENDS,
  SET_PRICE_RESTRICTIONS,
  UPDATE_PRICE,
  SET_BOOKED_SERVICES_RESTRICTIONS,
  IMPORT_BOOKED_SERVICES,
  LOADING_BOOKED_SERVICES,
  LOADING_BOOKED_SERVICES_ERROR,
  SET_BOOKED_SERVICE_ISBOOKED_VAL,
  OPEN_ALL_ACCOUNTS_SIDEBAR,
  CLOSE_ALL_ACCOUNTS_SIDEBAR,
  CLEANUP,
  UPDATE_WKZ_APP,
} from 'src/actionTypes/customerPanelActionTypes'
const initialState = {
  activeRow: null,
  pagination: {
    page: 1,
    count: 0,
  },
  customers: [],
  fetchingInProgress: false,
  fetchingError: false,
  shops: [],
  brands: [],
  importedBrands: [],
  filters: {
    name: null,
  },
  loadingBrands: false,
  loadingBrandsError: false,
  selectedBrands: [],
  statusQuoRestrictions: [],
  trendeRestrictions: [],
  priceRestrictions: [],
  wkzAppRestrictions: [],
  fetchingRestrictions: false,
  fetchingRestrictionsError: false,
  bookedServices: [],
  bookedServicesLoading: false,
  bookedServicesError: false,
  allUsersDrawerOpen: false,
  allAccounts: [],
}

export default function customerPanelReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CUSTOMERS:
      return produce(state, (draft) => {
        draft.customers = action.payload.companies
        draft.pagination.total = action.payload.count
        draft.pagination.page = 1
        draft.fetchingInProgress = false
        draft.fetchingError = false
      })
    case SELECT_ROW:
      return produce(state, (draft) => {
        draft.activeRow = action.payload
        if (state.allUsersDrawerOpen) {
          draft.allUsersDrawerOpen = false
        }
      })
    case FETCHING_CUSTOMERS:
      return produce(state, (draft) => {
        draft.fetchingInProgress = true
        draft.fetchingError = false
      })
    case FETCHING_CUSTOMERS_ERROR:
      return produce(state, (draft) => {
        draft.fetchingError = true
        draft.fetchingInProgress = false
      })
    case CLOSE_SIDEBAR:
      return produce(state, (draft) => {
        draft.activeRow = null
        draft.allUsersDrawerOpen = false
      })
    case IMPORT_SHOPS:
      return produce(state, (draft) => {
        draft.shops = action.payload
      })
    case UPDATE_FILTERS:
      return produce(state, (draft) => {
        const { filter, value } = action.payload
        draft.filters[filter] = value
        draft.pagination.page = 1
      })
    case CHECK_SHOPS:
      return produce(state, (draft) => {
        draft.shops = action.payload
      })
    case IMPORT_BRANDS:
      return produce(state, (draft) => {
        draft.brands = action.payload
        draft.importedBrands = action.payload
        const ids = draft.brands.reduce((acc, next) => {
          let cp = [...acc]
          if (next.hasBrand) {
            cp = [...cp, next.brandId]
          }
          return cp
        }, [])

        draft.selectedBrands = ids
        draft.loadingBrands = false
        draft.loadingBrandsError = false
      })
    case SET_ONLY_BRANDS:
      return produce(state, (draft) => {
        draft.brands = action.payload
      })
    case LOADING_BRANDS:
      return produce(state, (draft) => {
        draft.loadingBrands = true
        draft.loadingBrandsError = false
      })
    case LOADING_BRANDS_ERROR:
      return produce(state, (draft) => {
        draft.loadingBrands = false
        draft.loadingBrandsError = true
      })
    case CHECK_BRAND:
      return produce(state, (draft) => {
        const { brandId, idOfSelected } = action.payload
        const indexOfId = draft.brands.findIndex(
          (brand) => brand.brandId === brandId,
        )
        draft.selectedBrands = [...draft.selectedBrands, brandId]
        draft.brands[indexOfId] = {
          ...draft.brands[indexOfId],
          hasBrand: 1,
          idOfSelected,
        }
        const indexOfIdInImportedBrands = draft.importedBrands.findIndex(
          (brand) => brand.brandId === brandId,
        )
        draft.importedBrands[indexOfIdInImportedBrands] = {
          ...draft.importedBrands[indexOfIdInImportedBrands],
          hasBrand: 1,
          idOfSelected,
        }
      })
    case UNCHECK_BRAND:
      return produce(state, (draft) => {
        const { brandId, id } = action.payload
        const indexOfId = draft.brands.findIndex(
          (brand) => brand.idOfSelected === id,
        )
        draft.brands[indexOfId].hasBrand = 0
        draft.selectedBrands = draft.selectedBrands.filter(
          (bId) => bId !== brandId,
        )
        const indexOfIdInImportedBrands = draft.importedBrands.findIndex(
          (brand) => brand.brandId === brandId,
        )
        draft.importedBrands[indexOfIdInImportedBrands].hasBrand = 0
      })
    case UNCHECK_OPTIMISTIC:
      return produce(state, (draft) => {
        const indexOfId = draft.brands.findIndex(
          (brand) => brand.brandId === action.payload,
        )
        draft.brands[indexOfId].hasBrand = 0
      })
    case FETCHING_RESTRICTIONS:
      return produce(state, (draft) => {
        draft.fetchingRestrictions = true
        draft.fetchingRestrictionsError = false
      })
    case FETCHING_RESTRICTIONS_ERROR:
      return produce(state, (draft) => {
        draft.fetchingRestrictions = false
        draft.fetchingRestrictionsError = true
      })
    case SET_STATUS_QUO_RESTRICTIONS:
      return produce(state, (draft) => {
        draft.statusQuoRestrictions = action.payload
      })
    case SET_TRENDS_RESTRICTIONS:
      return produce(state, (draft) => {
        draft.trendeRestrictions = action.payload
      })
    case SET_PRICE_RESTRICTIONS:
      return produce(state, (draft) => {
        draft.priceRestrictions = action.payload
      })
    case SET_BOOKED_SERVICES_RESTRICTIONS:
      return produce(state, (draft) => {
        draft.bookedServiceRestrictions = action.payload
      })
    case SET_WKZ_APP_RESTRICTIONS:
      return produce(state, (draft) => {
        draft.wkzAppRestrictions = action.payload
      })
    case UPDATE_TRENDS:
      return produce(state, (draft) => {
        draft.trendeRestrictions = action.payload
      })

    case UPDATE_STATUS_QUO:
      return produce(state, (draft) => {
        draft.statusQuoRestrictions = action.payload
      })
    case UPDATE_PRICE:
      return produce(state, (draft) => {
        draft.priceRestrictions = action.payload
      })
    case UPDATE_WKZ_APP:
      return produce(state, (draft) => {
        draft.wkzAppRestrictions = action.payload
      })
    case IMPORT_BOOKED_SERVICES:
      return produce(state, (draft) => {
        draft.bookedServices = action.payload
        draft.bookedServicesLoading = false
        draft.bookedServicesError = false
      })
    case LOADING_BOOKED_SERVICES_ERROR:
      return produce(state, (draft) => {
        draft.bookedServices = []
        draft.bookedServicesLoading = false
        draft.bookedServicesError = true
      })
    case LOADING_BOOKED_SERVICES:
      return produce(state, (draft) => {
        draft.bookedServicesLoading = true
        draft.bookedServicesError = false
      })
    case SET_BOOKED_SERVICE_ISBOOKED_VAL:
      return produce(state, (draft) => {
        draft.bookedServices = action.payload
      })
    case OPEN_ALL_ACCOUNTS_SIDEBAR:
      return produce(state, (draft) => {
        draft.allUsersDrawerOpen = true
      })
    case CLOSE_ALL_ACCOUNTS_SIDEBAR:
      return produce(state, (draft) => {
        draft.allUsersDrawerOpen = false
      })
    case CLEANUP:
      return produce(state, (draft) => {
        draft.activeRow = initialState.activeRow
        draft.pagination.page = initialState.pagination.page
        draft.allUsersDrawerOpen = initialState.allUsersDrawerOpen
      })
    default:
      return state
  }
}
