import React, { useEffect } from 'react'
import { changeTopNavCallToAction } from 'src/actions/topNavActions'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { Link, Box, Divider } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useDispatch } from 'react-redux'
import * as NavConstants from 'src/constants/topBarConstants'
import packageInfo from '../../../package.json'

const useStyles = makeStyles()((theme) => ({
  text: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '0.3px',
    color: '#546E7A',
    marginLeft: '4px',
  },
  mb: {
    marginBottom: 6,
  },
  diplayFlex: {
    display: 'flex',
  },
  separator: {
    margin: '0px 5px',
  },
  hr: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    height: theme.spacing(2),
  },
}))

const FooterNaviItem = ({
  id,
  component = RouterLink,
  to,
  underline,
  className,
  children = null,
}) => {
  const dispatch = useDispatch()
  const location = useLocation()

  useEffect(() => {
    if (location.pathname === to) {
      dispatch(changeTopNavCallToAction(id))
    }
  }, [location.pathname, dispatch, id, to])

  return (
    <Link
      component={component}
      to={to}
      underline={underline}
      className={className}
    >
      {!!children && children}
    </Link>
  )
}

function FooterInfo() {
  const { classes, cx } = useStyles()

  return (
    <>
      <FooterNaviItem
        component={RouterLink}
        to="/sites/release-notes"
        underline="none"
        className={cx(classes.text, classes.mb)}
      >
        Smile BI {packageInfo.version}
      </FooterNaviItem>
      <Box display="flex" mt={1}>
        <FooterNaviItem
          component={RouterLink}
          to="/sites/impressum"
          underline="none"
          className={cx(classes.text, classes.mb)}
          id={NavConstants.IMPRESSUM_ACTIVE}
        >
          Imprint
        </FooterNaviItem>
        <Divider orientation="vertical" className={classes.hr} />
        <FooterNaviItem
          component={RouterLink}
          to="/sites/privacy"
          underline="none"
          className={classes.text}
          id={NavConstants.PRIVACY_ACTIVE}
        >
          Privacy Notice
        </FooterNaviItem>
      </Box>
    </>
  )
}

export default FooterInfo
