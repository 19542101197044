function generateActionType(type) {
  return `@sendingHistory/${type}`
}

export const FETCHING_IN_PROGRESS = generateActionType('FETCHING_IN_PROGRESS')
export const FETCHING_ERROR = generateActionType('FETCHING_ERROR')
export const FETCHING_SUCCESS = generateActionType('FETCHING_SUCCESS')
export const ADD_CURRENTLY_SENDING_EMAIL_DATA = generateActionType(
  'ADD_CURRENTLY_SENDING_EMAIL_DATA',
)
export const REMOVE_CURRENLY_SENDING_EMAIL_DATA = generateActionType(
  'REMOVE_CURRENLY_SENDING_EMAIL_DATA',
)

export const PAGE_CHANGE = generateActionType('PAGE_CHANGE')
