import React from 'react'
import PropTypes from 'prop-types'
import { Box, Grid, Typography, Card } from '@mui/material'

import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(() => ({
  root: {
    whiteSpace: 'normal',
  },
  mediaCampaigns: {
    marginLeft: 12,
    marginTop: 4,
  },
}))

function Header({
  className,
  percentage,
  fetching,
  count,
  title,
  onlyTitle,
  mediaCampaigns,
}) {
  const { classes, cx } = useStyles()

  return (
    <Card>
      <Box p={2} mt={3}>
        <Grid
          container
          spacing={3}
          justifyContent="space-between"
          className={cx(classes.root, className)}
        >
          <Grid
            item
            style={{
              display: 'flex',
              // alignItems: 'center',
              // justifyContent: 'center',
            }}
          >
            {onlyTitle ? (
              <Typography variant="h3" color="textPrimary">
                {title}
              </Typography>
            ) : (
              <Typography variant="h3" color="textPrimary">
                {title}{' '}
                {!fetching ? `(${count}, ${percentage}% confirmed)` : ''}
              </Typography>
            )}

            <Box mt={2}></Box>
            {mediaCampaigns ? (
              <Typography
                color="textPrimary"
                className={classes.mediaCampaigns}
              >
                ({mediaCampaigns})
              </Typography>
            ) : null}
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  percentage: PropTypes.number,
  fetching: PropTypes.bool,
  count: PropTypes.number,
  title: PropTypes.string,
}

export default Header
