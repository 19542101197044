import { ACCOUNT_REDUCER_NAME as REDUCER } from 'src/constants/reducerNames'

export const isAdminSelector = (state) => state[REDUCER].user.isAdmin
export const isInternalSelector = (state) =>
  state[REDUCER].user.roles.filter((role) => role.name === 'internal').length >
  0
export const isCustomerSelector = (state) =>
  state[REDUCER].user.roles.find((r) => r.name === 'customer')

export const changePasswordOpenSelector = (state) =>
  state[REDUCER].changePasswordOpen

export const personalInformationSelector = (state) => {
  const { user } = state[REDUCER]

  return {
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
  }
}

export const getRecoveryCodeCredentials = (state) =>
  state[REDUCER].recoveryCredentials
export const rolesSelector = (state) => state[REDUCER].user.roles

export const userSelector = (state) => state[REDUCER].user

export const getScope =
  (targetScope = '') =>
  (state) => {
    const user = userSelector(state)
    try {
      const scope = user.scopes.find(
        (s) => s.scope.toLowerCase() === targetScope.toLowerCase(),
      )
      return scope
    } catch (error) {
      return null
    }
  }

export const getUserId = (state) => {
  if (state[REDUCER].user) {
    return state[REDUCER].user.id
  }
  return null
}

export const getCopilotPermissions = (state) => {
  const user = userSelector(state)
  const permissions = user?.copilotPermission || []
  return permissions
}
