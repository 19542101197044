export const DAM_WIZARD_REDUCER_NAME = 'damWizard'
export const DAM_PZN_REDUCER_NAME = 'damPzn'
export const SENDING_HISTORY_REDUCER_NAME = 'sendingHistory'
export const DAM_WIZARD_REDUCER_NAME2 = 'damWiz'
export const DAM_PACKSHOTS_REDUCER = 'damPackShots'
export const DAM_TEXTFILES_REDUCER = 'damTextFiles'
export const DAM_WIZARD_SHOPS = 'damWizShops'
export const DAM_SUBJECT_REDUCER = 'damSubj'
export const ACCOUNT_REDUCER_NAME = 'account'
export const PACKSHOTS_RATING_REDUCER_NAME = 'packshotRating'
export const CUSTOMER_PANEL = 'customerPanel'
export const NEWSLETTER_CONFIG = 'newsletterConfig'
export const DESCRIPTION_RATING_REDUCER = 'descRating'
export const WKZ_DATA_INPUT_CONFIG = 'wkzDIConf'
export const WKZ_DATA_INPUT = 'wkzDI'
export const KEYWORDS = 'keywords'
export const KEYWORDS_MGMT = 'keywordsMgmt'
export const GSHOPPING = 'googleShopping'
// for booked services reducer I used module aproach so constants will be located in module folder
