import React, { useEffect } from 'react'
import TableListPage from 'src/components/TableList'
import TwoColDrawer from 'src/components/TwoColDrawer/TwoColDrawer'
import { useDispatch } from 'react-redux'
import Results from './Results'
import Filters from './Filters'
import Pagination from './Pagination'
import Sidebar from './Sidebar'
import Header from './Header'
import { resetSettings } from './lib/actions'

export default function KatSoll() {
  const dispatch = useDispatch()
  const FilterPagination = <Pagination />

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    // cleanup
    return () => dispatch(resetSettings())
  }, [])

  return (
    <>
      <TwoColDrawer>
        <TableListPage pageTitle="Smile BI | KatSoll">
          <Header />
          <Filters />
          <Results Pagination={FilterPagination} />
          <Pagination paginationWrapper hideOnFetch />
        </TableListPage>
        <Sidebar />
      </TwoColDrawer>
    </>
  )
}
