import { useState } from 'react'
import { Box, Collapse } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { FilterButton } from 'src/components/TableList/Filters'
import { FiltersConfig } from './types'

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    // justifyContent: 'space-between',
    width: '100%',
    '& > *:not(:first-of-type)': {
      margin: '0px 10px',
    },
    // '& :nth-child(1)': {
    //   marginRight: '5px',
    // },
  },
  wrapper: {
    display: 'flex',
    '& > *:not(:first-of-type)': {
      margin: '0px 10px',
    },
    '& > :first-of-type': {
      marginRight: '10px',
    },
  },
  openDrawerWrapper: {
    marginLeft: 6,
    '& > *': {
      marginTop: 5,
    },
  },
}))

type BigScreenFilterProps = {
  filtersConfig: FiltersConfig[]
  openDrawer?: boolean
}

export default function BigScreenFilters({
  filtersConfig,
  openDrawer,
}: BigScreenFilterProps) {
  const { classes } = useStyles()
  const [openFilters, setOpenFilters] = useState(false)

  const onClick = () => setOpenFilters((prev) => !prev)

  if (openDrawer) {
    return (
      <Box>
        <FilterButton showFilters={onClick} />
        <Collapse in={openFilters}>
          <Box className={classes.openDrawerWrapper}>
            {filtersConfig.map(({ Component }, i) => (
              <Component openDrawer={openDrawer} key={`${i}_filter`} />
            ))}
          </Box>
        </Collapse>
      </Box>
    )
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.wrapper}>
        {filtersConfig.map(({ Component }, i) => (
          <Component openDrawer={openDrawer} key={`${i}_filter`} />
        ))}
      </Box>
    </Box>
  )
}
