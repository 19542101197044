import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

const checkForScope = (requiredScope, usersScope) =>
  usersScope.indexOf(requiredScope) > -1

function ScopeGuard({ children, scope }) {
  const { user } = useSelector((state) => state.account)
  if (user) {
    if (!checkForScope(scope, user.scopesTitles)) {
      return <Redirect to="/404" />
    }
  }

  return children
}

ScopeGuard.propTypes = {
  children: PropTypes.any,
  scope: PropTypes.string,
}

export default memo(ScopeGuard)
