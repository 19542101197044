import { makeStyles } from 'tss-react/mui'

export const useDefaultDrawerStyles = (props) => {
  const { destopWidth = '50%' } = props || {}
  return makeStyles()((theme) => ({
    drawer: {
      width: '100%',
      top: 64,
      height: 'calc(100% - 64px)',
      borderRight: '15px solid #f4f6f8',
      boxShadow: '1px 10px 8px 3px rgba(0, 0, 0, 0.12)',
      [theme.breakpoints.up('sm')]: {
        width: `calc(${destopWidth} - ${theme.spacing(1)})`,
      },
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 40,
      marginTop: 13,
    },
    titleWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    title: {
      fontFamily: 'PT-Sans',
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '31px',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    label: {
      marginLeft: 16,
    },
    fieldTitle: {
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '12px',
      letterSpacing: '0.4px',
      textAlign: 'left',
      marginBottom: 10,
      color: '#263238',
    },
    fieldText: {
      fontFamily: 'Roboto',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.1px',
      textAlign: 'left',
      color: '#263238',
    },
    divider: {
      backgroundColor: '#B0BEC5',
    },
  }))
}
