import produce from 'immer'

import {
  REDUCER_NAME as RN,
  FETCHING_ERROR,
  FETCHING_IN_PROGRESS,
  FETCHING_SUCCESS,
  OPEN_ROW,
  OPEN_USERS_SIDEBAR,
  CLOSE_USERS_SIDEBAR,
  PAGE_CHANGE,
  POPULATE_DROPDOWN_VALUES,
  UPDATE_FILTERS,
  UPDATE_USER_VALUES,
  UPDATE_USER,
  CREATE_USER,
  RESET_FILTERS,
  FIELDS_MISSING,
  DEACTIVATE_USER_BY_ID,
  ACTIVATE_USER_BY_ID,
} from 'src/actions/usersPanelActions'

const initialState = {
  fetchingInProgress: false,
  fetchingError: false,
  users: [],
  activeRow: null,
  userData: null,
  openDrawer: false,
  pagination: {
    page: 1,
    count: 0,
  },
  filters: {
    user_account: '', // string
    company: null, // object
    role: null, // object
  },
  companies: [],
  roles: [],
  positions: [],
  userSidebarValues: {
    first_name: null,
    last_name: null,
    companyName: null,
    role: null,
    scopes: null,
    job_position: {
      positionId: 2,
      position: 'E-Commerce Manager',
    },
    plans: null,
    gender: {
      gender: 'Diverse',
      genderId: 3,
    },
    manufacturers: [],
    password: null,
    birthday: '2022-12-05',
    plan: {
      planId: 1,
      plan: 'Basic',
    },
  },
  missingFields: [],
}

const cleanup = () => ({
  userData: null,
  userSidebarValues: initialState.userSidebarValues,
  openDrawer: initialState.openDrawer,
  missingFields: [],
})

export const REDUCER_NAME = RN

export default function UsersPanelReducer(state = initialState, action) {
  switch (action.type) {
    case FETCHING_IN_PROGRESS:
      return produce(state, (draft) => {
        draft.fetchingInProgress = true
        draft.fetchingError = false
      })
    case FETCHING_ERROR:
      return produce(state, (draft) => {
        draft.fetchingError = true
        draft.fetchingInProgress = false
      })
    case FETCHING_SUCCESS:
      return produce(state, (draft) => {
        draft.fetchingInProgress = false
        draft.fetchingError = false
        draft.users = action?.payload?.users || []
        draft.pagination = {
          ...draft.pagination,
          count: action.payload.total,
        }
      })
    case PAGE_CHANGE:
      return produce(state, (draft) => {
        draft.pagination = {
          ...draft.pagination,
          page: action.payload,
        }
      })
    case POPULATE_DROPDOWN_VALUES:
      return produce(state, (draft) => {
        const {
          companies,
          genders,
          roles,
          positions,
          scopes,
          plans,
          manufacturers,
        } = action.payload
        draft.genders = genders
        draft.companies = [...companies, { companyId: null, companyName: null }]
        draft.roles = roles
        draft.positions = [...positions, { position: null, positionId: null }]
        draft.scopes = scopes
        draft.plans = [...plans, { plan: null, planId: null }]
        draft.manufacturers = manufacturers
      })
    case UPDATE_FILTERS:
      return produce(state, (draft) => {
        const { target, value } = action.payload
        draft.filters[target] = value
        draft.pagination.page = 1
      })
    case RESET_FILTERS:
      return produce(state, (draft) => {
        draft.filters = initialState.filters
      })
    case UPDATE_USER_VALUES:
      return produce(state, (draft) => {
        const { type, value } = action.payload
        draft.userSidebarValues[type] = value
        // requirement from task https://drkaske.atlassian.net/browse/SBDT-1501
        // when user is admin should by default be set to Smile BI company
        if (
          type === 'role' &&
          (value.role === 'admin' || value.role === 'internal')
        ) {
          const smileBi = draft.companies.find(
            (company) => company.companyName === 'Smile BI',
          )
          draft.userSidebarValues.company = smileBi
        }
      })
    case OPEN_ROW:
      return produce(state, (draft) => {
        const target = { ...draft.users[action.payload] }
        draft.userData = target
        draft.userSidebarValues = {
          ...target,
        }
        draft.openDrawer = true
        draft.missingFields = []
        draft.activeRow = action.payload
      })
    case OPEN_USERS_SIDEBAR:
      return produce(state, (draft) => {
        const clean = cleanup(initialState)
        clean.openDrawer = true
        return Object.assign(draft, clean)
      })
    case CREATE_USER:
    case UPDATE_USER:
    case CLOSE_USERS_SIDEBAR:
      return produce(state, (draft) => {
        const clean = cleanup(initialState)
        return Object.assign(draft, clean)
      })
    case FIELDS_MISSING:
      return produce(state, (draft) => {
        draft.missingFields = action.payload
      })
    case DEACTIVATE_USER_BY_ID:
      return produce(state, (draft) => {
        const id = action.payload
        const indexOfUser = draft.users.findIndex((user) => user.id === id)
        if (draft.users[indexOfUser]) {
          draft.users[indexOfUser].account_active = 0
        }
        if (draft?.userData?.account_active === 1) {
          draft.userData.account_active = 0
          draft.userSidebarValues.account_active = 0
        }
      })
    case ACTIVATE_USER_BY_ID:
      return produce(state, (draft) => {
        const id = action.payload
        const indexOfUser = draft.users.findIndex((user) => user.id === id)
        if (draft.users[indexOfUser]) {
          draft.users[indexOfUser].account_active = 1
        }
        if (draft?.userData?.account_active === 0) {
          draft.userData.account_active = 1
          draft.userSidebarValues.account_active = 1
        }
      })
    default:
      return state
  }
}
