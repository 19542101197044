import useSimpleNotification from 'src/hooks/useSimpleNotification'
import { useSelector, useDispatch } from 'react-redux'
import BasicBlueButton from 'src/components/BasicBlueButton'
import {
  addNewKatsoll,
  trackRejectedCategoriesDuringBulkAdding,
  removeTrackingOfRejected,
} from '../lib/actions'
import { getAddToAllValues, getCheckedElements } from '../lib/selectors'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(() => ({
  btn: {
    width: '130px',
  },
}))

export default function AddToAllButton() {
  const { classes } = useStyles()
  const dispatch = useDispatch()
  const checked = useSelector(getCheckedElements)
  const addtoAllValues = useSelector(getAddToAllValues) || {}
  const noti = useSimpleNotification()

  const onClick = async () => {
    const { shop, category } = addtoAllValues
    if (!shop || !category) {
      return noti('error', 'You need to select shop and category')
    }

    const bodies = checked.map((c) => ({
      subBrandId: c.subBrandId,
      domainId: shop.id,
      category: category.category,
      categoryLink: category.categoryLink,
      categoryRd: category.categoryRd,
    }))

    const dispatches = bodies.map((b) => dispatch(addNewKatsoll(b)))
    const results = await Promise.allSettled(dispatches)
    const rejected = []
    results.forEach((r) => {
      if (r.status === 'rejected') {
        const element = JSON.parse(r.reason.config.data)
        rejected.push(element)
      }
    })
    if (rejected.length) {
      dispatch(trackRejectedCategoriesDuringBulkAdding(rejected))
      noti(
        'error',
        'Adding of some categories failed. They are marked with red border.',
      )
    } else {
      noti('success')
      dispatch(removeTrackingOfRejected())
    }
  }

  return (
    <BasicBlueButton
      classes={{ btn: classes.btn }}
      onClick={onClick}
      text="add to all"
    />
  )
}
