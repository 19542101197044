import React from 'react'
import { Box, Card, Typography, Button } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import noResultsImage from 'src/assets/images/errors/wkzEmpty.svg'

const useStyles = makeStyles()((theme) => ({
  card: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 500,
  },
  bigTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '28px',
    textAlign: 'center',
    marginTop: 16,
    color: '#263238',
  },
  text: {
    fontFamily: 'PT-Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '21px',
    alignItems: 'center',
    textAlign: 'center',
  },
  textWrapper: {
    width: 380,
    [theme.breakpoints.down('md')]: {
      width: 300,
    },
    [theme.breakpoints.down('sm')]: {
      width: 250,
      margin: 'auto',
    },
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    margin: '-45px',
  },
  link: {
    color: '#4472C4',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 16,
  },
  button: {
    background: '#4472C4',
    paddingLeft: 22,
    paddingRight: 22,
    color: '#FFFFFF',
    '&:hover': {
      background: '#4472C4',
    },
  },
}))

function NoResults({ onRetry }) {
  const { classes } = useStyles()
  return (
    <Card className={classes.card}>
      <Box p={1}>
        <Box className={classes.imageWrapper}>
          <img src={noResultsImage} alt="no results" />
        </Box>

        <Typography className={classes.bigTitle}>
          No records available.
        </Typography>
        <Box className={classes.textWrapper} mt={1}>
          <p className={classes.text}>
            Start the data collection. For tips, read our help or contact{' '}
            <span>
              <a className={classes.link} href="mailto:support@smile.bi">
                support@smile.bi
              </a>
            </span>{' '}
            .
          </p>
        </Box>
        <Box className={classes.buttonWrapper}>
          <Button className={classes.button} onClick={onRetry}>
            ADD and configure Ad-Slots
          </Button>
        </Box>
      </Box>
    </Card>
  )
}

export default NoResults
