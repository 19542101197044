import { Download } from 'react-feather'
import IconButtonWrapper from 'src/components/IconButton'

type ExportButtonProps = {
  onDownload: () => void
}

export default function ExportButton({ onDownload }: ExportButtonProps) {
  return (
    <IconButtonWrapper
      onClick={onDownload}
      titleAccess="Export"
      Icon={<Download />}
    />
  )
}
