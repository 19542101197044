import {
  Drawer,
  Box,
  IconButton,
  SvgIcon,
  Typography,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  tableCellClasses,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { X as CloseIcon } from 'react-feather'
import useBackgroundKatsoll from 'src/views/management/KatSoll/hooks/useBackgroundRecomendation'
import getLabel from './Labels'

export const useStyles = makeStyles()((theme) => ({
  desktopDrawer: {
    width: 556,
    top: 64,
    height: 'calc(100% - 64px)',
    zIndex: 1202,
    [theme.breakpoints.down('lg')]: {
      width: 356,
      top: 64,
      boxShadow:
        '0px 1px 2px rgba(63, 63, 68, 0.15), 0px 0px 0px rgba(63, 63, 68, 0.05)',
      zIndex: 1202,
    },
  },
  container: {
    marginBottom: 130,
  },
  closeIcon: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 2,
  },
  row: {
    borderBottom: 'none',
  },
  centerdiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '400px',
  },
  rotateIcon: {
    animation: 'spin 4s linear infinite',
  },
}))

export default function KatsolinaSidebarContainer() {
  const { classes } = useStyles()
  const {
    connection,
    openDetails: open,
    setOpenDetails,
    // getAllJobs,
    jobs,
  } = useBackgroundKatsoll()
  let content: React.ReactNode = null

  // useEffect(() => {
  //   if (connection) {
  //     // getAllJobs()
  //   }
  // }, [connection, getAllJobs])

  if (connection) {
    content = (
      <>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography>Auto Categorization Queue</Typography>
          </Box>
          <Box>
            <IconButton onClick={() => setOpenDetails(false)}>
              <SvgIcon>
                <CloseIcon />
              </SvgIcon>
            </IconButton>
          </Box>
        </Box>
        <Box>
          <Table
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none',
                // fontFamily: 'PT-Sans',
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>Product</TableCell>
                <TableCell>Shop</TableCell>
                {/* <TableCell>Started At</TableCell> */}
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  fontFamily: 'PT-Sans',
                },
              }}
            >
              {jobs.map((job) => (
                <TableRow key={job.jobId}>
                  <TableCell>{job.product}</TableCell>
                  <TableCell>{job.shop}</TableCell>
                  <TableCell>{getLabel(job.status)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </>
    )
  }

  return (
    <Drawer
      classes={{ paper: classes.desktopDrawer }}
      anchor="right"
      elevation={18}
      open={open}
      variant="persistent"
    >
      <Box p={2}>{content}</Box>
    </Drawer>
  )
}
