import React, { useState } from 'react'
import { Box, Collapse } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { FilterButton } from 'src/components/TableList/Filters'
import { FiltersConfig } from './types'

export const useStyles = makeStyles()(() => ({
  container: {},
  wrapper: {
    display: 'flex',
  },
}))

type SmallScreenFiltersProps = {
  visibleFilters: FiltersConfig[]
  hiddenFilters: FiltersConfig[]
  openDrawer?: boolean
}

function SmallScreenFilters({
  visibleFilters,
  hiddenFilters,
  openDrawer,
}: SmallScreenFiltersProps) {
  const { classes } = useStyles()
  const [openFilters, setOpenFilters] = useState(false)
  const showFilters = () => setOpenFilters((prev) => !prev)
  return (
    <Box className={classes.container}>
      <Box className={classes.wrapper}>
        {visibleFilters.map(({ Component }) => (
          <Component openDrawer={!!openDrawer} />
        ))}
        <FilterButton showFilters={showFilters} />
      </Box>
      <Collapse in={openFilters}>
        {hiddenFilters.map(({ Component }) => (
          <Component openDrawer={!!openDrawer} />
        ))}
      </Collapse>
    </Box>
  )
}

export default SmallScreenFilters
