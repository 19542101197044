import React from 'react'
import { Box, Button, Hidden } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import useStyles from './styles'
import Logo from '../../components/Logo'

export default function TopBar() {
  const history = useHistory()
  const { classes } = useStyles()
  const { user } = useSelector((state) => state.account)

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      mx={3}
      pt={1}
      alignItems="center"
    >
      <Box display="flex">
        <Logo />
        <Hidden mdDown>
          <Box component="span" ml={3}>
            <Button
              className={classes.button}
              onClick={() => history.push('/app/home')}
            >
              HOMEPAGE
            </Button>
          </Box>
        </Hidden>
      </Box>
      <Box>
        <Hidden mdDown>
          <Box component="span" mr={3}>
            <Button
              className={classes.button}
              href="mailto:support@smile.bi?subject=Demo Request My Smile BI"
            >
              REQUEST A DEMO
            </Button>
          </Box>
        </Hidden>
        <Button
          className={classes.button}
          onClick={() => history.push('/app/home')}
          variant="outlined"
        >
          {user ? 'GO BACK' : 'SIGN IN'}
        </Button>
      </Box>
    </Box>
  )
}
