import React from 'react'
import { Box, Card, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import noResultsImage from 'src/assets/images/errors/noResults.svg'

const useStyles = makeStyles()((theme) => ({
  card: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 500,
    whiteSpace: 'normal',
  },
  bigTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '28px',
    textAlign: 'center',
    marginTop: 16,
    color: '#263238',
  },
  text: {
    fontFamily: 'PT-Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '21px',
    alignItems: 'center',
    textAlign: 'center',
  },
  textWrapper: {
    width: 380,
    [theme.breakpoints.down('md')]: {
      width: 300,
    },
    [theme.breakpoints.down('sm')]: {
      width: 250,
      margin: 'auto',
    },
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  link: {
    color: '#4472C4',
  },
}))

function NoResults() {
  const { classes } = useStyles()
  return (
    <Card className={classes.card} data-testid="no-results">
      <Box p={1}>
        <Box className={classes.imageWrapper}>
          <img src={noResultsImage} alt="no results" />
        </Box>

        <Typography className={classes.bigTitle}>
          Oops, we haven&apos;t dug that yet.
        </Typography>
        <Box className={classes.textWrapper} mt={1}>
          <p className={classes.text}>
            And we&apos;ve been crawling so much already. Maybe you just made a
            typo? Feel free to contact our{' '}
            <span>
              <a className={classes.link} href="mailto:support@smile.bi">
                support@smile.bi
              </a>
            </span>{' '}
            if not.
          </p>
        </Box>
      </Box>
    </Card>
  )
}

export default NoResults
