import produce from 'immer'
import {
  REDUCER_NAME as RN,
  FETCHING_SUCCESS,
  FETCHING_ERROR,
  FETCHING_IN_PROGRESS,
  POPULATE_DROPDOWN_VALUES,
  RESET_FILTERS,
  UPDATE_FILTERS,
  OPEN_DRAWER,
  PAGE_CHANGE,
  ENTER_EDIT_MODE,
  EXIT_EDIT_MODE,
  CONFIRM_BUNDLE,
  UNCONFIRM_BUNDLE,
  UPDATE_UNDERLYING_AMOUNT,
  UPDATE_UNDERLYING_PZN,
  CONFIRM_UNDERLYING_PZN,
  ADD_UNDERLYING_PZN,
  DELETE_UNDERLYING_PZN,
  EDIT_BUNDLE_TYPE,
  MAKE_BUNDLE_IRRELEVANT,
  MAKE_BUNDLE_RELEVANT,
  TOGGLE_BUNDLE_SELECTION,
  ACTIVATE_PZN_ADDING_SIDEBAR,
} from 'src/actions/bundlesActions'

const initialState = {
  fetchingInProgress: true,
  fetchingError: false,
  bundles: {},
  percentage: null,
  pagination: {
    page: 1,
    count: 0,
  },
  filters: {
    title: '',
    type: '',
    status: '',
    manufacturer: '',
    shop: '',
  },
  manufacturers: [],
  shops: [],
  statuses: [
    {
      status: 'confirmed',
    },
    {
      status: 'unconfirmed',
    },
  ],
  bundleTypes: [{ type: '1' }, { type: '2' }, { type: '3' }],
  inEditMode: {
    byUserAction: false,
    byStatus: false,
  },
  drawerData: {},
  openDrawer: false, // bool
  activeRowIndex: null, // number | null
  selectedBundles: [],
  pznAddingSidebar: false,
}

export const REDUCER_NAME = RN
export default function BundlesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCHING_IN_PROGRESS:
      return produce(state, (draft) => {
        draft.fetchingInProgress = true
        draft.fetchingError = false
      })

    case FETCHING_ERROR:
      return produce(state, (draft) => {
        draft.fetchingError = true
        draft.fetchingInProgress = false
      })

    case FETCHING_SUCCESS:
      return produce(state, (draft) => {
        const { products, count, percentage } = action.payload
        draft.bundles = products
        draft.pagination.count = count
        draft.percentage = percentage
        draft.fetchingError = false
        draft.fetchingInProgress = false
      })

    case PAGE_CHANGE:
      return produce(state, (draft) => {
        draft.pagination = {
          ...draft.pagination,
          page: action.payload,
        }
      })

    case POPULATE_DROPDOWN_VALUES:
      return produce(state, (draft) => {
        const { manufacturers, shops } = action.payload
        draft.manufacturers = manufacturers || []
        draft.shops = shops || []
      })
    case UPDATE_FILTERS:
      return produce(state, (draft) => {
        const { target, value } = action.payload
        draft.filters[target] = value
        draft.pagination.page = 1
      })
    case RESET_FILTERS:
      return produce(state, (draft) => {
        draft.filters = initialState.filters
        draft.pagination.page = 1
      })
    case OPEN_DRAWER:
      return produce(state, (draft) => {
        const idx = action.payload
        if (draft.openDrawer) {
          // if clicked row is the same as previous close drawer
          // if idx is undefined that means that user clicked on X button to close modal
          // else drawer is still open but user clicked on other row so no need to close it
          if (draft.activeRowIndex === idx || idx === undefined) {
            draft.openDrawer = false
            draft.drawerData = {}
            draft.activeRowIndex = null
            draft.inEditMode = initialState.inEditMode
          } else {
            draft.drawerData = { ...draft.bundles[idx] }
            draft.activeRowIndex = idx
            if (draft.drawerData.status === 'unconfirmed') {
              draft.inEditMode.byStatus = true
            } else {
              draft.inEditMode.byStatus = false
            }
          }
        } else {
          draft.openDrawer = true
          draft.drawerData = { ...draft.bundles[idx] }
          if (draft.drawerData.status === 'unconfirmed') {
            draft.inEditMode.byStatus = true
          }
          draft.activeRowIndex = idx
        }
      })

    case ENTER_EDIT_MODE:
      return produce(state, (draft) => {
        draft.inEditMode.byUserAction = true
      })

    case EXIT_EDIT_MODE:
      return produce(state, (draft) => {
        draft.inEditMode.byUserAction = false
        draft.inEditMode.byStatus = false
      })

    case CONFIRM_BUNDLE:
      return produce(state, (draft) => {
        draft.drawerData = {
          ...draft.drawerData,
          ...action.payload,
        }
        draft.bundles[draft.activeRowIndex] = draft.drawerData
        draft.inEditMode.byStatus = false
      })

    case UNCONFIRM_BUNDLE:
      return produce(state, (draft) => {
        draft.drawerData.status = 'unconfirmed'
        draft.bundles[draft.activeRowIndex] = draft.drawerData
      })

    case UPDATE_UNDERLYING_PZN:
      return produce(state, (draft) => {
        const { payload } = action
        const idx = draft.drawerData.underlyingArr.findIndex(
          (element) => element.id === payload.id,
        )
        draft.drawerData.underlyingArr[idx] = {
          ...draft.drawerData.underlyingArr[idx],
          ...payload,
        }
        draft.bundles[draft.activeRowIndex] = draft.drawerData
      })

    case ADD_UNDERLYING_PZN:
      return produce(state, (draft) => {
        draft.bundles[draft.activeRowIndex].underlyingArr = [
          ...draft.bundles[draft.activeRowIndex].underlyingArr,
          { ...action.payload },
        ]
        draft.drawerData = { ...draft.bundles[draft.activeRowIndex] }
      })

    case DELETE_UNDERLYING_PZN:
      return produce(state, (draft) => {
        const filtered = draft.bundles[
          draft.activeRowIndex
        ].underlyingArr.filter((underlying) => underlying.id !== action.payload)
        draft.bundles[draft.activeRowIndex].underlyingArr = filtered
        draft.drawerData = { ...draft.bundles[draft.activeRowIndex] }
      })

    case UPDATE_UNDERLYING_AMOUNT:
      return produce(state, (draft) => {
        const { id, amount } = action.payload
        const idx = draft.drawerData.underlyingArr.findIndex(
          (element) => element.id === id,
        )
        draft.drawerData.underlyingArr[idx].quantity = amount
        draft.bundles[draft.activeRowIndex] = draft.drawerData
      })

    case EDIT_BUNDLE_TYPE:
      return produce(state, (draft) => {
        draft.drawerData.type = action.payload
        draft.bundles[draft.activeRowIndex] = draft.drawerData
      })

    case MAKE_BUNDLE_IRRELEVANT:
      return produce(state, (draft) => {
        draft.drawerData.relevant = 0
        draft.bundles[draft.activeRowIndex].relevant = 0
      })

    case MAKE_BUNDLE_RELEVANT:
      return produce(state, (draft) => {
        draft.drawerData.relevant = 1
        draft.bundles[draft.activeRowIndex].relevant = 1
      })

    case CONFIRM_UNDERLYING_PZN:
      return produce(state, (draft) => {
        const { id, date, ...rest } = action.payload
        const idx = draft.drawerData.underlyingArr.findIndex(
          (element) => element.id === id,
        )
        draft.drawerData.underlyingArr[idx] = {
          ...draft.drawerData.underlyingArr[idx],
          confirmedAt: date,
          ...rest,
        }
        draft.bundles[draft.activeRowIndex] = draft.drawerData
      })
    case TOGGLE_BUNDLE_SELECTION:
      return produce(state, (draft) => {
        const hasSelected = draft.selectedBundles.find(
          (b) => b.id === action.payload.id,
        )
        if (hasSelected) {
          draft.selectedBundles = state.selectedBundles.filter(
            (b) => b.id !== action.payload.id,
          )
        } else {
          draft.selectedBundles = [...state.selectedBundles, action.payload]
        }
      })
    case ACTIVATE_PZN_ADDING_SIDEBAR:
      return produce(state, (draft) => {
        if (state.pznAddingSidebar) {
          draft.pznAddingSidebar = false
        } else {
          draft.pznAddingSidebar = true
        }
      })
    default:
      return state
  }
}
