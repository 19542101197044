import React from 'react'
import { Box } from '@mui/material'
import BasicBlueButton from 'src/components/BasicBlueButton'
export default function AddButton({ onClick }) {
  return (
    <Box ml={1}>
      <BasicBlueButton text="Add" onClick={onClick} />
    </Box>
  )
}
