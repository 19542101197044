export const BASIC_TAB = 'BASIC_TAB'
export const PACKSHOT_TAB = 'PACKSHOT_TAB'
export const TEXT_TAB = 'TEXT_TAB'
export const COPILOT_AI = 'COPILOT_AI'
export const CUSTOMER_SHOPS_TAB = 'CUSTOMER_SHOPS_TAB'
export const CUSTOMER_BRANDS_TAB = 'CUSTOMER_BRANDS_TAB'
export const CUSTOMER_RESTRICTIONS_TAB = 'CUSTOMER_RESTRICTIONS_TAB'
export const BOOKED_SERVICES = 'BOOKED_SERVICES'
export const ACCOUNTS = 'ACCOUNTS'
export const CREDITS = 'CREDITS'
export const TEMPLATES = 'TEMPLATES'
export const COPILOTTILES = 'COPILOTTILES'
