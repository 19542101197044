import React from 'react'
import { Box, Card, IconButton } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import FilterIcon from 'src/assets/icons/filterIcon'

const useStyles = makeStyles()(() => ({
  showMoreFiltersButton: {
    height: 56,
    width: 53,
    opacity: 0.5,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    boxSizing: 'border-box',
    borderRadius: '4px',
    marginLeft: 6,
  },
}))

export const FilterButton = ({ showFilters }) => {
  const { classes } = useStyles()
  return (
    <Box
      className={classes.showMoreFiltersButton}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <IconButton
        onClick={showFilters}
        size="large"
        title="Filters"
        data-testid="open-filters-btn"
      >
        <FilterIcon />
      </IconButton>
    </Box>
  )
}

export default function BundleFiltersWrapper({ children }) {
  return (
    <Card>
      <Box p={2}>
        <Box display="flex" alignItems="center">
          {children}
        </Box>
      </Box>
    </Card>
  )
}
