import produce from 'immer'
import {
  FETCHING_PRODUCTS,
  FETCHING_PRODUCTS_ERROR,
  UPDATE_PRODUCTS,
  UPDATE_PRODUCT,
  UPDATE_PAGE,
  UPDATE_PRIMARY_ASIN,
  UPDATE_FILTERS,
  OPEN_MODAL,
  PREV_NEXT_AMAZON_PRODUCT,
  UPDATE_ACTIVE_AMAZON_PRODUCT,
  CONFIRM_AMAZON_PZN,
  UNCONFIRM_AMAZON_PZN,
  RESET_FILTERS,
  DEACTIVATE_PRODUCT,
  REFRESH_MODAL,
} from 'src/actionTypes/amazonActionTypes'

const initialState = {
  products: null,
  count: 0,
  fetching: false,
  fetching_error: false,
  page: 1,
  limit: 50,
  filters: {},
  modalOpen: false,
  activeAmazonProduct: null,
  percentage: 0,
}

const AmazonProductsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_PRODUCTS:
      return produce(state, (draft) => {
        draft.fetching = !draft.fetching
      })
    case FETCHING_PRODUCTS_ERROR:
      return produce(state, (draft) => {
        draft.fetching_error = true
        draft.fetching = false
      })
    case UPDATE_PRODUCTS:
      return produce(state, (draft) => {
        const { products, count, percentage } = action.payload
        draft.products = products
        draft.count = count
        draft.percentage = percentage
        draft.fetching = false
        draft.fetching_error = false
      })
    case UPDATE_PRODUCT:
      return produce(state, (draft) => {
        draft.products = action.payload.updatedProducts
      })
    case UPDATE_PAGE:
      return produce(state, (draft) => {
        const { page } = action.payload
        draft.page = page
      })
    case UPDATE_PRIMARY_ASIN:
      return produce(state, (draft) => {
        const { updatedProducts, pzn } = action.payload
        if (pzn) {
          draft.activeAmazonProduct = updatedProducts.n_products[pzn]
        }
        draft.products = updatedProducts
      })
    case UPDATE_FILTERS:
      return produce(state, (draft) => {
        const { filters } = action.payload
        draft.filters = filters
        draft.page = 1
      })
    case RESET_FILTERS:
      return produce(state, (draft) => {
        draft.filters = initialState.filters
      })
    case OPEN_MODAL:
      return produce(state, (draft) => {
        const { key } = action.payload
        if (key) {
          draft.activeAmazonProduct = state.products.n_products[key]
        } else {
          draft.activeAmazonProduct = null
        }
        draft.modalOpen = !state.modalOpen
      })
    case REFRESH_MODAL:
      return produce(state, (draft) => {
        const { key } = action.payload
        draft.activeAmazonProduct = state.products.n_products[key]
      })
    case PREV_NEXT_AMAZON_PRODUCT:
      return produce(state, (draft) => {
        const { key } = action.payload
        if (key) {
          draft.activeAmazonProduct = state.products.n_products[key]
        }
      })
    case UPDATE_ACTIVE_AMAZON_PRODUCT:
      return produce(state, (draft) => {
        const { id } = action.payload
        const activeOne = state.activeAmazonProduct
        const filteredProducts = activeOne.products.filter(
          (product) => product.id !== id,
        )
        const newActiveAmazonProducts = {
          ...state.activeAmazonProduct,
          products: filteredProducts,
        }
        draft.activeAmazonProduct = newActiveAmazonProducts
      })
    case CONFIRM_AMAZON_PZN:
      return produce(state, (draft) => {
        const { updatedProducts, pzn } = action.payload
        if (pzn) {
          draft.activeAmazonProduct = updatedProducts.n_products[pzn]
        }
        draft.products = updatedProducts
      })
    case UNCONFIRM_AMAZON_PZN:
      return produce(state, (draft) => {
        const { updatedProducts, pzn } = action.payload
        if (pzn) {
          draft.activeAmazonProduct = updatedProducts.n_products[pzn]
        }
        draft.products = updatedProducts
      })
    case DEACTIVATE_PRODUCT:
      return produce(state, (draft) => {
        const { pznId, updatedData } = action.payload

        const filteredProducts = state.activeAmazonProduct?.products.filter(
          (product) => product.id !== pznId,
        )

        draft.activeAmazonProduct.products = filteredProducts
        draft.products = updatedData
      })
    default:
      return state
  }
}

export default AmazonProductsReducer
