import { createSelector } from 'reselect'
import { REDUCER_NAME as REDUCER } from './constants'

export const getBookedServicesSelector = (state) => state[REDUCER].results
export const getFetchingStatus = (state) => ({
  fetching: state[REDUCER].fetching,
  error: state[REDUCER].fetchingError,
})
export const getActiveRow = (state) => state[REDUCER].activeRow
export const getPagination = (state) => state[REDUCER].pagination
export const getFilters = (state) => state[REDUCER].filters
export const getParams = createSelector(
  getPagination,
  getFilters,
  (pagination, filters) => ({
    ...pagination,
    filters,
  }),
)
export const getSearchValue = (state) => state[REDUCER].filters.search
export const openDrawerSelector = (state) => state[REDUCER].openDrawer
export const getAllServices = (state) => state[REDUCER].services
