function generateActionType(type) {
  return `@kwd/${type}`
}

export const SELECT_ROW = generateActionType('SELECT_ROW')
export const SET_FILTERS = generateActionType('SET_FILTERS')
export const UPDATE_FILTERS = generateActionType('UPDATE_FILTERS')
export const ADD_CHECKED_PZN = generateActionType('ADD_CHECKED_PZN')
export const REMOVE_CHECKED_PZN = generateActionType('REMOVE_CHECKED_PZN')
export const CLEAR_ALL_CHECKED = generateActionType('CLEAR_ALL_CHECKED')
export const CLOSE_SIDEBAR = generateActionType('CLOSE_SIDEBAR')
export const OPEN_DRAWER = generateActionType('OPEN_DRAWER')
export const SET_KEYWORDS = generateActionType('SET_KEYWORDS')
export const FETCHING_KEYWORDS = generateActionType('FETCHING_KEYWORDS')
export const FETCHING_KEYWORDS_404 = generateActionType('FETCHING_KEYWORDS_404')
export const SET_IMPORTED_KEYWORDS = generateActionType('SET_IMPORTED_KEYWORDS')
export const REMOVE_KEYWORD = generateActionType('REMOVE_KEYWORD')
export const SET_ALL_KEYWORDS = generateActionType('SET_ALL_KEYWORDS')
export const ASSIGN_KEYWORDS = generateActionType('ASSIGN_KEYWORDS')
export const PAGE_CHANGE = generateActionType('PAGE_CHANGE')
export const SET_CRAWLEDKW_VALUE = generateActionType('SET_CRAWLED_VALUE')
export const SET_SEARCHKW_VALUE = generateActionType('SET_SEARCHKW_VALUE')
export const CONFIRM_ABDA = generateActionType('CONFIRM_ABDA')
export const FETCHING_KEYWORDS_ERROR = generateActionType(
  'FETCHING_KEYWORDS_ERROR',
)
export const REMOVE_KEYWORD_FROM_MANY = generateActionType(
  'REMOVE_KEYWORD_FROM_MANY',
)
export const REMOVE_ALL_KEYWORDS_FOR_PZN_BY_PZN_ID = generateActionType(
  'REMOVE_ALL_KEYWORDS_FOR_PZN_BY_PZN_ID',
)
export const REMOVE_ALL_KEYWORDS_FOR_SINGLE_PZN_BY_PZN_ID = generateActionType(
  'REMOVE_ALL_KEYWORDS_FOR_SINGLE_PZN_BY_PZN_ID',
)
