import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useSelector, useDispatch } from 'react-redux'
import SearchAsYouType from 'src/components/SearchAsYouType'
import { getCategoriesDebounced, updateAddToAllValues } from '../lib/actions'
import { getAddToAllValues, getCategoriesSelector } from '../lib/selectors'

const useStyles = makeStyles()(() => ({
  input: {
    width: '100%',
  },
}))

export default function CategoriesAddToAll() {
  const { classes } = useStyles()
  const addtoAllValues = useSelector(getAddToAllValues) || {}
  const selectedCategories = useSelector(getCategoriesSelector)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [searchVal, setSearchVal] = useState('')
  const shopId = addtoAllValues?.shop?.id || null
  const category = addtoAllValues?.category || null
  const dispatch = useDispatch()
  useEffect(() => {
    query(shopId, searchVal)
  }, [searchVal, shopId])

  useEffect(() => {
    setSearchVal('')
  }, [shopId])

  useEffect(() => {
    if (selectedCategories) {
      setOptions(selectedCategories)
    }
  }, [selectedCategories])

  const query = async (domainId, searchFor) => {
    if (!domainId) {
      return null
    }
    setLoading(true)
    try {
      const res =
        (await getCategoriesDebounced(domainId, searchFor, dispatch)) || []
      setOptions(res)
    } catch (error) {
      setLoading(false)
    } finally {
      setLoading(false)
    }
    return null
  }

  const onSearch = async (title = '') => {
    setSearchVal(title)
  }

  const onSelect = (_, value) => {
    dispatch(updateAddToAllValues('category', value))
  }
  const isOptionEqualToValue = (option, value) =>
    option.category === value.category
  const getOptionLabel = (option) =>
    option.category || option.categoryLink || ''
  const renderOption = (props, option) => (
    <li {...props} key={option.id}>
      {option.category || option.categoryLink || ''}
    </li>
  )
  return (
    <SearchAsYouType
      onSelect={onSelect}
      onSearch={onSearch}
      loading={loading}
      options={options}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      label="Category"
      renderOption={renderOption}
      classes={{ autocomplete: classes.input }}
      value={category}
    />
  )
}
