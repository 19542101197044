import jwtDecode from 'jwt-decode'
import axios from 'src/utils/axios'
import { setLocalStorageUserId } from 'src/utils/localStorage'
import { reportSentry } from 'src/utils/reportSentry'

const TOKEN_NAME = process.env.REACT_APP_TOKEN_NAME

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null)

          if (onLogout) {
            onLogout()
          }
        }

        return Promise.reject(error)
      },
    )
  }

  handleAuthentication() {
    const accessToken = this.getAccessToken()
    if (!accessToken) {
      return
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken)
    } else {
      this.setSession(null)
    }
  }

  loginWithEmailAndPassword = (email, password) =>
    new Promise((resolve, reject) => {
      axios
        .post('/auth/signin', { username: email, password })
        .then((response) => {
          if (response.data.user) {
            const { id } = response.data.user
            setLocalStorageUserId(id)
            this.setSession(response.data.accessToken)
            resolve(response.data.user)
          } else {
            reject(response.data.error)
          }
        })
        .catch((error) => {
          if (error?.response?.status >= 500) {
            reportSentry(error)
          }
          reject(error)
        })
    })

  loginInWithToken = () =>
    new Promise((resolve, reject) => {
      axios
        .post('/auth/validate-token')
        .then((response) => {
          if (response.data.user) {
            resolve(response.data.user)
          } else {
            reject(response.data.error)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })

  logout = () => {
    this.setSession(null)
  }

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem(TOKEN_NAME, accessToken)
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
      localStorage.removeItem(TOKEN_NAME)
      delete axios.defaults.headers.common.Authorization
    }
  }

  getAccessToken = () => localStorage.getItem(TOKEN_NAME)

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false
    }

    const decoded = jwtDecode(accessToken)
    const currentTime = Date.now() / 1000

    return decoded.exp > currentTime
  }

  isAuthenticated = () => !!this.getAccessToken()

  async generateRecoveryCode(email) {
    const { data } = await axios.post('/auth/user/generate-recovery-code', {
      email,
    })
    return data
  }

  async checkRecoveryCode(code) {
    const { data } = await axios.get(
      `/auth/user/generate-recovery-code/${code}`,
    )
    return data
  }

  async setPassword(id, password) {
    const { data } = await axios.post('/auth/user/create-new-password', {
      id,
      password,
    })
    return data
  }

  async resendActivation(id) {
    const { data } = await axios.post(`/auth/user/resend-activation/${id}`)

    return data
  }

  async checkIsLinkExpired(id) {
    const { data } = await axios.get(`/auth/user/account/status/${id}`)
    return data
  }
}

const authService = new AuthService()

export default authService
