import { Box, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  noItems: {
    display: 'flex',
    alignItems: 'center',
    height: 200,
    justifyContent: 'center',
  },
  noItemsTitle: {
    fontSize: '1.5rem',
    fontWeight: 500,
    opacity: 0.7,
    fontFamily: 'PT-Sans',
  },
}));

type NoItemsInSidebarProps = {
  text: string
}

export default function NoItemsInSidebar({ text }: NoItemsInSidebarProps) {
  const { classes } = useStyles()
  return (
    <Box className={classes.noItems}>
      <Typography className={classes.noItemsTitle}>{text}</Typography>
    </Box>
  )
}
