const getParams = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  })
  return params
}

const componentLoader = (lazyComponent, attemptsLeft = 3, interval = 1000) =>
  new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (attemptsLeft === 1) {
            const params = getParams()
            let reloadCount = Number(params.reloadCount) || 0

            if (Number.isNaN(reloadCount)) {
              reloadCount = 1
            }

            if (Number(reloadCount) === 3) {
              reject(error)
            } else {
              // hard refresh
              // taken from https://stackoverflow.com/a/70901317
              const url = new URL(window.location.href)
              url.searchParams.set('reloadTime', Date.now().toString())
              url.searchParams.set('reloadCount', (reloadCount += 1))
              window.location.href = url.toString()
            }
          }
          componentLoader(lazyComponent, attemptsLeft - 1, interval).then(
            resolve,
            reject,
          )
        }, interval)
      })
  })

export default componentLoader
