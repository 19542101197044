import {
  Layers as LayersIcon,
  Box as BoxIcon,
  Users as UsersIcon,
  Grid as GridIcon,
  ShoppingBag as ShoppingBagIcon,
  // Mail as MailIcon,
  User as CustomerIcon,
  Columns,
  PlusSquare,
  BookOpen,
  Crosshair,
  PieChart,
  Clock,
  Bookmark,
  BarChart2,
  List,
  UploadCloud,
  Filter,
  MessageSquare,
  Target,
  Book,
} from 'react-feather'
import AttachEmailIcon from '@mui/icons-material/VerticalSplit'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import FactoryIcon from '@mui/icons-material/Factory'
import MusicNoteIcon from '@mui/icons-material/MusicNote'
import AbcIcon from '@mui/icons-material/Abc'
import MoodSharpIcon from '@mui/icons-material/MoodSharp'
import FormatPaintSharpIcon from '@mui/icons-material/FormatPaintSharp'
import SportsScoreSharpIcon from '@mui/icons-material/SportsScoreSharp'
// import GoogleIcon from '@mui/icons-material/Google'
import * as NavConstants from 'src/constants/topBarConstants'
import { BackupTable } from '@mui/icons-material'
import LanguageIcon from '@mui/icons-material/Language'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'

/**
 * since in case when scope doesn't exist module won't show in sidebar in case every module is not available title
 * subheader title should be displayed either
 * @param {*} scopes
 * @param {*} items
 * @param {*} subheaderTitle
 * @returns
 */
const getHeaderName = (scopes, items, subheaderTitle) => {
  const allScopes = items
    .map((item) => {
      if (item.scopeTitle) {
        return item.scopeTitle.toLowerCase()
      }
      return item.scopeTitle
    })
    .filter(Boolean)
  const exists = scopes.some(
    ({ scope }) => allScopes.indexOf(scope.toLowerCase()) > -1,
  )
  return exists ? subheaderTitle : ''
}

const CUSTOMER_SETUP = [
  {
    title: 'Customers',
    scopeTitle: 'smilecustomers',
    id: NavConstants.CUSTOMERS,
    icon: CustomerIcon,
    href: '/app/management/customers',
  },
  {
    title: 'Booked Services',
    scopeTitle: 'bookedservices',
    id: NavConstants.BOOKED_SERVICES,
    icon: BookOpen,
    href: '/app/management/booked-services',
  },
  {
    title: 'PZN Overview',
    scopeTitle: 'pznoverview',
    id: NavConstants.PZN_OVERVIEW,
    icon: List,
    href: '/app/management/pzn-overview',
  },
]

const DATA_SETUP = [
  {
    title: 'PZN',
    scopeTitle: 'pzn',
    id: NavConstants.PZN,
    icon: AbcIcon,
    href: '/app/management/pzn',
  },
  {
    title: 'PAD',
    scopeTitle: 'pzn',
    id: NavConstants.PZN,
    icon: AbcIcon,
    href: '/app/management/pad',
  },
  {
    title: 'Comm: Subbrand',
    scopeTitle: 'subbrandsettings',
    id: NavConstants.COMMUNICATION_COPILOT_SETTINGS,
    icon: Book,
    href: '/app/management/communication-subbrands',
  },
  {
    title: 'Manage Keywords',
    scopeTitle: 'mankeywords',
    id: NavConstants.KEYWORDS,
    icon: AbcIcon,
    href: '/app/management/keywords-management',
  },
  {
    title: 'Keywords',
    scopeTitle: 'keyword',
    id: NavConstants.KEYWORDS,
    icon: AbcIcon,
    href: '/app/management/keywords',
  },

  {
    title: 'KatSoll',
    scopeTitle: 'category',
    id: NavConstants.KAT_SOLL,
    icon: AbcIcon,
    href: '/app/management/katsoll',
  },
  {
    title: 'Brands',
    scopeTitle: 'brand',
    id: NavConstants.BRANDS,
    icon: AbcIcon,
    href: '/app/management/brands-management',
  },
  {
    title: 'Article Names',
    scopeTitle: 'articlenames',
    id: NavConstants.BRANDS,
    icon: AbcIcon,
    href: '/app/management/article-names',
  },
  {
    title: 'Mein Märkte',
    scopeTitle: 'mymarkets',
    id: NavConstants.MY_MARKETS,
    icon: AbcIcon,
    href: '/app/management/my-markets',
  },
  {
    title: 'Comm: Send',
    scopeTitle: 'contacts',
    id: NavConstants.CONTACTS,
    icon: Book,
    href: '/app/management/contacts',
  },
  {
    title: 'Templates',
    scopeTitle: 'emailtemplates',
    id: NavConstants.CONTACTS,
    icon: BackupTable,
    href: '/app/management/email-templates',
  },
  {
    title: 'Upload Data',
    scopeTitle: 'mymarkets',
    id: NavConstants.UPLOAD_DATA,
    icon: UploadCloud,
    href: '/app/management/upload-excel',
  },
  {
    title: 'Filters',
    scopeTitle: 'qlikfilterpresets',
    id: NavConstants.PRESET_FILTERS,
    icon: Filter,
    href: '/app/management/preset-filters',
  },
]

const PROMPT_SETUP = [
  {
    title: 'Target Groups',
    scopeTitle: 'targetgroups',
    id: NavConstants.COMMUNICATION_COPILOT_SETTINGS,
    icon: Target,
    href: '/app/management/target-groups',
  },
  {
    title: 'Tonalities',
    scopeTitle: 'tonality',
    id: NavConstants.COMMUNICATION_COPILOT_SETTINGS,
    icon: MusicNoteIcon,
    href: '/app/management/tonalities',
  },
  {
    title: 'Moods',
    scopeTitle: 'moods',
    id: NavConstants.COMMUNICATION_COPILOT_SETTINGS,
    icon: MoodSharpIcon,
    href: '/app/management/moods',
  },
  {
    title: 'Product Topics',
    scopeTitle: 'rxproducttopics',
    id: NavConstants.COMMUNICATION_COPILOT_SETTINGS,
    icon: LocalOfferIcon,
    href: '/app/management/product-topics',
  },
  {
    title: 'Styles',
    scopeTitle: 'styles',
    id: NavConstants.COMMUNICATION_COPILOT_SETTINGS,
    icon: FormatPaintSharpIcon,
    href: '/app/management/styles',
  },
  {
    title: 'Objectives',
    scopeTitle: 'objectives',
    id: NavConstants.COMMUNICATION_COPILOT_SETTINGS,
    icon: SportsScoreSharpIcon,
    href: '/app/management/objectives',
  },
  {
    title: 'Internationalization',
    scopeTitle: 'internationalization',
    id: NavConstants.COMMUNICATION_COPILOT_SETTINGS,
    icon: LanguageIcon,
    href: '/app/management/internationalization',
  },
  {
    title: 'Tiles Factory',
    scopeTitle: 'tilesfactory',
    id: NavConstants.COMMUNICATION_COPILOT_SETTINGS,
    icon: FactoryIcon,
    href: '/app/management/tiles-factory',
  },
  {
    title: 'Prompts Factory',
    scopeTitle: 'promptfactory',
    id: NavConstants.PROMPT_FACTORY,
    icon: FactoryIcon,
    href: '/app/management/prompt-factory',
  },
]

const ON_DEMAND = [
  {
    title: 'On Demand Crawling',
    scopeTitle: 'ondemandcrawling',
    id: NavConstants.ONDEMANDCRAWL,
    icon: Crosshair,
    href: '/app/management/on-demand-crawling',
  },
  {
    title: 'On Demand Schedule',
    scopeTitle: 'ondemandschedule',
    id: NavConstants.ONDEMANDCRAWL,
    icon: Clock,
    href: '/app/users/on-demand-scheduler',
  },
  {
    title: 'On Demand Usage',
    scopeTitle: 'ondemandusage',
    id: NavConstants.USERS_ACTIVE,
    icon: PieChart,
    href: '/app/users/on-demand-usage',
  },
  {
    title: 'Analytics',
    id: NavConstants.AVBAANALYTICS,
    scopeTitle: 'avbanalytics',
    icon: BarChart2,
    href: '/app/management/availability-analytics',
  },
]

const WKZ_CONFIG = [
  {
    title: 'WKZ Slots Config',
    scopeTitle: 'wkz',
    adminOnly: true,
    id: NavConstants.WKZ_SLOT_CONFIG_ACTIVE,
    icon: GridIcon,
    href: '/app/management/wkz-slot-config',
  },
  {
    title: 'WKZ Media',
    scopeTitle: 'wkz',
    id: NavConstants.WKZ_MEDIA_LIST,
    icon: GridIcon,
    href: '/app/management/wkz-media',
  },
  {
    title: 'WKZ Data Input Config',
    scopeTitle: 'wkz',
    id: NavConstants.WKZ_CONFIG,
    icon: PlusSquare,
    href: '/app/management/wkz-data-entry-config',
  },
  {
    title: 'WKZ Data Input',
    scopeTitle: 'wkz',
    id: NavConstants.WKZ_INPUT,
    icon: PlusSquare,
    href: '/app/management/wkz-data-entry',
  },
  {
    title: 'Newsletter Config',
    scopeTitle: 'newsletter',
    id: NavConstants.NEWSLETTER,
    icon: AttachEmailIcon,
    href: '/app/management/newsletter-config',
  },
]

const REFINE_CRAWLER_DATA_ITEMS = [
  {
    title: 'Amazon',
    scopeTitle: 'amazon',
    id: NavConstants.AMAZON_ACTIVE,
    icon: BoxIcon,
    href: '/app/management/amazon',
  },
  {
    title: 'Bundles',
    scopeTitle: 'bundles',
    id: NavConstants.BUNDLES_ACTIVE,
    icon: LayersIcon,
    href: '/app/management/bundles',
  },
  {
    title: 'Packshot Rating',
    id: NavConstants.PRODUCT_RATING,
    icon: Columns,
    scopeTitle: 'pacrating',
    href: '/app/management/packshots-rating',
  },
  {
    title: 'Description Rating',
    id: NavConstants.DESCRIPTION_RATING,
    icon: Columns,
    scopeTitle: 'descrating',
    href: '/app/management/description-rating',
  },
]

export const subheaders = {
  customerDataSetup: 'CUSTOMER DATA SETUP',
  dataSetup: 'DATA SETUP',
  promptSetup: 'PROMPT SETUP',
  onDemand: 'ON DEMAND',
  wkzConfig: 'WKZ CONFIG',
  refineCrawlerData: 'REFINE CRAWLER DATA',
  adminPanel: 'ADMIN PANEL',
}

// NOTE - always type scope title in lowercase
const getNav = ({ scopes } = {}) => [
  {
    subheader: 'Dashboard',
    items: [],
  },
  {
    subheader: getHeaderName(
      scopes,
      CUSTOMER_SETUP,
      subheaders.customerDataSetup,
    ),
    items: CUSTOMER_SETUP,
  },
  {
    subheader: getHeaderName(scopes, DATA_SETUP, subheaders.dataSetup),
    items: DATA_SETUP,
  },
  {
    subheader: getHeaderName(scopes, PROMPT_SETUP, subheaders.promptSetup),
    items: PROMPT_SETUP,
  },
  {
    subheader: getHeaderName(scopes, ON_DEMAND, subheaders.onDemand),
    items: ON_DEMAND,
  },
  {
    subheader: getHeaderName(scopes, WKZ_CONFIG, subheaders.wkzConfig),
    items: WKZ_CONFIG,
  },
  {
    subheader: getHeaderName(
      scopes,
      REFINE_CRAWLER_DATA_ITEMS,
      subheaders.refineCrawlerData,
    ),
    items: REFINE_CRAWLER_DATA_ITEMS,
  },
  {
    subheader: subheaders.adminPanel,
    adminOnly: true,
    items: [
      {
        title: 'Shop Contacts',
        id: NavConstants.SHOP_CONTACTS_ACTIVE,
        icon: ShoppingBagIcon,
        href: '/app/management/shop-contacts',
        isAvailable: (user) => user.isAdmin,
        scopeTitle: 'admin',
      },
      {
        title: 'Users',
        id: NavConstants.USERS_ACTIVE,
        icon: UsersIcon,
        href: '/app/management/users',
        isAvailable: (user) => user.isAdmin,
        scopeTitle: 'admin',
      },
      {
        title: 'Bookmarks',
        id: NavConstants.BOOKMARKS,
        icon: Bookmark,
        href: '/app/admin/management/bookmarks',
        isAvailable: (user) => user.isAdmin,
        scopeTitle: 'admin',
      },
      {
        title: 'Templates',
        id: NavConstants.TEMPLATES,
        icon: BorderColorIcon,
        href: '/app/admin/management/templates',
        isAvailable: (user) => user.isAdmin,
        scopeTitle: 'admin',
      },
    ],
  },
]

export default getNav
