import produce from 'immer'

import {
  OPEN_WIZARD,
  CLOSE_WIZARD,
  WIZARD_REMOVE_SINGLE_PACKSHOTS_TAB,
  WIZARD_REMOVE_ALL_PACKSHOT_TABS,
  FETCHING_SINGLE_PZN_DATA,
  FETCHING_SINGLE_PZN_DATA_SUCCESS,
  FETCHING_SINGLE_PZN_DATA_ERROR,
  WIZARD_SET_ACTIVE_TAB,
  UPDATE_SELECTED_PACKSHOTS_FOR_SENDING,
  UPDATE_SELECTED_SHOPS,
  FETCHING_SHOPS,
  FETCHING_SHOPS_SUCCESS,
  FETCHING_SHOPS_ERROR,
  UPDATE_SUBJECT,
  SEND_DATA_SUCCESS,
  OPEN_WIZARD_FROM_SIDEBAR,
  RESET_PZN_FROM_SIDEBAR,
  SELECT_ALL_PACKSHOTS_AND_FILES_BY_PZN,
  SEND_TEST_EMAIL_REQUEST,
  SEND_TEST_EMAIL_SUCCESS,
  SEND_TEST_EMAIL_ERROR,
  CHECK_TEST_EMAIL,
  CREATE_STATE_FROM_DATABASE,
  UPDATE_SELECTED_FILES_FOR_SENDING,
  REMOVE_PZN_FROM_PRELOAD,
  FETCHING_DUPLICATED_DATA_SUCCESS,
  REMOVE_PRELOAD_SHOPS,
} from 'src/actions/damWizardActions'

const initialState = {
  open: false,
  wizardPackshotsTabs: [],
  wizardPackshotsTabsData: {},
  activeWizardPackshotsTab: null,
  pznFromSidebar: null,
  sendingTestEmails: false,
  sendingTestEmailsError: false,
  testEmailChecked: false,
  fetching_single_pzn_data: false,
  fetching_single_pzn_data_error: false,
  selectedShopsForSending: [],
  fetchingShops: false,
  fetcingShopsError: false,
  shops: [],
  subject: '',
  textFiles: {},
  preloadPzns: [], // pzns loaded from backend (saved in state and we are loading it)
  preloadShops: [], // shops from backend (saved in state and we are loading it)
  preloadSubject: '', // subject loaded from backend (saved in state and we are loading it)
}

function getNewActiveTabAfterTabRemoval(tabs, currentTab) {
  if (!tabs.length) {
    return null
  }
  const indexOfCurrent = tabs.indexOf(currentTab)
  const prev = tabs[indexOfCurrent - 1]
  if (prev) {
    return prev
  }

  const next = tabs[indexOfCurrent + 1]
  if (next) {
    return next
  }

  return null
}

export default function DamWizardReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_WIZARD:
      return produce(state, (draft) => {
        draft.open = true
      })
    case CLOSE_WIZARD:
      return produce(state, (draft) => {
        draft.open = false
      })
    case OPEN_WIZARD_FROM_SIDEBAR:
      return produce(state, (draft) => {
        const pzn = action.payload
        draft.open = true
        draft.pznFromSidebar = pzn
      })
    case RESET_PZN_FROM_SIDEBAR:
      return produce(state, (draft) => {
        draft.pznFromSidebar = null
      })
    case SELECT_ALL_PACKSHOTS_AND_FILES_BY_PZN:
      return produce(state, (draft) => {
        const pzn = action.payload
        if (draft.wizardPackshotsTabsData[pzn]) {
          const packshotIds = draft.wizardPackshotsTabsData[
            pzn
          ].data.packshots.map(({ id }) => id)
          const fileIds = draft.wizardPackshotsTabsData[pzn].data.textFiles.map(
            ({ id }) => id,
          )
          draft.wizardPackshotsTabsData[pzn].selected.packshots = packshotIds
          draft.wizardPackshotsTabsData[pzn].selected.texts = fileIds
        }
      })
    case WIZARD_SET_ACTIVE_TAB:
      return produce(state, (draft) => {
        draft.activeWizardPackshotsTab = action.payload
      })
    case WIZARD_REMOVE_SINGLE_PACKSHOTS_TAB:
      return produce(state, (draft) => {
        const pzn = action.payload
        const newActiveTab = getNewActiveTabAfterTabRemoval(
          draft.wizardPackshotsTabs,
          draft.activeWizardPackshotsTab,
        )
        draft.wizardPackshotsTabs = draft.wizardPackshotsTabs.filter(
          (p) => p !== pzn,
        )
        delete draft.wizardPackshotsTabsData[pzn]
        draft.activeWizardPackshotsTab = newActiveTab
      })

    case WIZARD_REMOVE_ALL_PACKSHOT_TABS:
      return produce(state, (draft) => {
        draft.wizardPackshotsTabs = []
        draft.wizardPackshotsTabsData = {}
        draft.activeWizardPackshotsTab = null
        draft.selectedShopsForSending = []
        draft.preloadPzns = []
        draft.preloadShops = []
        draft.preloadSubject = ''
      })

    case FETCHING_SINGLE_PZN_DATA:
      return produce(state, (draft) => {
        draft.fetching_single_pzn_data = true
        draft.fetching_single_pzn_data_error = false
      })

    case FETCHING_SINGLE_PZN_DATA_SUCCESS:
      return produce(state, (draft) => {
        const { pzn, data } = action.payload
        draft.wizardPackshotsTabsData[pzn] = {
          data,
          selected: { packshots: [], texts: [] },
        }
        draft.wizardPackshotsTabs = [...draft.wizardPackshotsTabs, pzn]
        draft.fetching_single_pzn_data = false
        draft.fetching_single_pzn_data_error = false
        draft.activeWizardPackshotsTab = pzn
      })

    case FETCHING_SINGLE_PZN_DATA_ERROR:
      return produce(state, (draft) => {
        draft.fetching_single_pzn_data = false
        draft.fetching_single_pzn_data_error = true
      })
    case UPDATE_SELECTED_PACKSHOTS_FOR_SENDING:
      return produce(state, (draft) => {
        const packshot = action.payload
        const activeTab = draft.activeWizardPackshotsTab

        const selected = [
          ...draft.wizardPackshotsTabsData[activeTab].selected.packshots,
        ]
        const { id: packshotId } = packshot
        const exists = selected.includes(packshotId)

        if (exists) {
          const filtered = selected.filter((id) => id !== packshotId)
          draft.wizardPackshotsTabsData[activeTab].selected.packshots = filtered
        } else {
          draft.wizardPackshotsTabsData[activeTab].selected.packshots.push(
            packshotId,
          )
        }
      })
    case UPDATE_SELECTED_FILES_FOR_SENDING:
      return produce(state, (draft) => {
        const { file } = action.payload
        const activeTab = draft.activeWizardPackshotsTab

        const selected = [
          ...draft.wizardPackshotsTabsData[activeTab].selected.texts,
        ]
        const { id: fileId } = file
        const exists = selected.includes(fileId)

        if (exists) {
          const filtered = selected.filter((id) => id !== fileId)
          draft.wizardPackshotsTabsData[activeTab].selected.texts = filtered
        } else {
          draft.wizardPackshotsTabsData[activeTab].selected.texts.push(fileId)
        }
      })
    case UPDATE_SELECTED_SHOPS:
      return produce(state, (draft) => {
        draft.selectedShopsForSending = action.payload
      })

    case FETCHING_SHOPS:
      return produce(state, (draft) => {
        draft.fetchingShops = true
        draft.fetcingShopsError = false
      })
    case FETCHING_SHOPS_SUCCESS:
      return produce(state, (draft) => {
        draft.fetchingShops = false
        draft.fetcingShopsError = false
        draft.shops = action.payload
      })
    case FETCHING_SHOPS_ERROR:
      return produce(state, (draft) => {
        draft.fetchingShops = false
        draft.fetcingShopsError = true
      })
    case UPDATE_SUBJECT:
      return produce(state, (draft) => {
        draft.subject = action.payload
      })
    case SEND_DATA_SUCCESS:
      return state
    case CHECK_TEST_EMAIL:
      return produce(state, (draft) => {
        draft.testEmailChecked = !draft.testEmailChecked
      })
    case SEND_TEST_EMAIL_REQUEST:
      return produce(state, (draft) => {
        draft.sendingTestEmails = true
        draft.sendingTestEmailsError = false
      })
    case SEND_TEST_EMAIL_SUCCESS:
      return produce(state, (draft) => {
        draft.sendingTestEmails = false
        draft.sendingTestEmailsError = false
        draft.testEmailChecked = false
      })
    case SEND_TEST_EMAIL_ERROR:
      return produce(state, (draft) => {
        draft.sendingTestEmails = true
        draft.sendingTestEmailsError = true
        draft.testEmailChecked = false
      })
    case CREATE_STATE_FROM_DATABASE:
      return produce(state, (draft) => {
        const { pznAssets, subject, shops, isTestEmail } = action.payload
        if (isTestEmail) {
          draft.testEmailChecked = true
        }
        const tabs = []
        pznAssets.forEach((asset) => {
          const [[pzn, data]] = Object.entries(asset)
          if (pzn && data) {
            tabs.push(pzn)
            const { packshots, texts } = data || {}
            draft.wizardPackshotsTabsData[pzn] = {
              selected: {
                packshots: packshots || [],
                texts: texts || [],
              },
            }
          }
        })
        draft.activeWizardPackshotsTab = tabs[0] || null
        draft.wizardPackshotsTabs = tabs
        draft.preloadSubject = subject
        draft.preloadPzns = tabs
        draft.preloadShops = shops
        draft.open = true
      })
    case REMOVE_PZN_FROM_PRELOAD:
      return produce(state, (draft) => {
        const preloadPznsWithRemovedPzn = draft.preloadPzns.filter(
          (pzn) => pzn !== action.payload,
        )
        draft.preloadPzns = preloadPznsWithRemovedPzn
      })
    case REMOVE_PRELOAD_SHOPS:
      return produce(state, (draft) => {
        draft.preloadShops = []
      })
    case FETCHING_DUPLICATED_DATA_SUCCESS:
      return produce(state, (draft) => {
        const { pzn, data } = action.payload
        draft.wizardPackshotsTabsData[pzn].data = { ...data }
        draft.fetching_single_pzn_data = false
        draft.fetching_single_pzn_data_error = false
      })
    default:
      return state
  }
}
