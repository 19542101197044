import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useIdleTimer } from 'react-idle-timer'
import { makeStyles } from 'tss-react/mui'
import { useDispatch } from 'react-redux'
import { logoutIdle } from 'src/actions/accountActions'
import NavBar from './NavBar'
import TopBar from './TopBar'
import WhatsNewModal from './WhatsNewModal'
import Help from './Help'
import CrawlerSidebar from './CrawlerSidebar'
import KatsolinaSidebarContainer from './Katsolina/KatsolinaSidebarContainer'

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    position: 'relative',
  },
}))

function DashboardLayout({ children }) {
  const { classes } = useStyles()
  const [isMobileNavOpen, setMobileNavOpen] = useState(false)
  const isMounted = useRef(false)
  const [open, setOpen] = useState(false)
  const [isOpenDrawer, setOpenDrawer] = useState(false)
  const [newFeatures, setNewFeatures] = useState([])
  const isUnmounted = useRef(false)
  const dispatch = useDispatch()

  const onIdle = () => {
    dispatch(logoutIdle())
  }

  // logout user after 24h of idle time
  useIdleTimer({
    timeout: 1000 * 60 * 60 * 24,
    onIdle,
    debounce: 1000,
  })

  const mainRef = useRef(null)
  useEffect(() => {
    mainRef.current.focus()
  }, [mainRef])

  const toggleDrawer = (e) => {
    // if (e.key === '[') {
    //   setOpenDrawer(!isOpenDrawer)
    // }
  }

  return (
    <div
      className={classes.root}
      ref={mainRef}
      tabIndex="-1"
      onKeyDown={toggleDrawer}
      role="button"
    >
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        openDrawer={isOpenDrawer}
      />
      <CrawlerSidebar />
      <KatsolinaSidebarContainer />
      <Help />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
      <WhatsNewModal open={open} setOpen={setOpen} newFeatures={newFeatures} />
    </div>
  )
}

DashboardLayout.propTypes = {
  children: PropTypes.any,
}

export default DashboardLayout
