function generateActionType(type) {
  return `@damPkshot/${type}`
}
// strategy
export const LOAD_STRATEGY = generateActionType('LOAD_STRATEGY')

// selection
export const SET_PACKSHOTS = generateActionType('SET_PACKSHOTS')
export const UNSELECT_PACKSHOT = generateActionType('UNSELECT_PACKSHOT')
export const SELECT_PACKSHOT = generateActionType('SELECT_PACKSHOT')
export const SELECT_MANY_PACKSHOTS = generateActionType('SELECT_MANY_PACKSHOTS')
export const CLEAR_ALL_PACKSHOTS = generateActionType('CLEAR_ALL_PACKSHOTS')
export const CLEAR_ALL_PACKSHOTS_BY_PZN = generateActionType(
  'CLEAR_ALL_PACKSHOTS_BY_PZN',
)
export const DELETE_ALL_PACKSHOTS = generateActionType('DELETE_ALL_PACKSHOTS')
