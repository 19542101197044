import React, { useRef, useState } from 'react'
// import { Link as RouterLink } from "react-router-dom";
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import {
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { logout } from 'src/actions/accountActions'

const useStyles = makeStyles()(() => ({
  popover: {
    width: 200,
  },
}))

function Account({ openSidebar }) {
  const { classes } = useStyles()
  const history = useHistory()
  const ref = useRef(null)
  const dispatch = useDispatch()
  const account = useSelector((state) => state.account)
  const { enqueueSnackbar } = useSnackbar()
  const [isOpen, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleLogout = async () => {
    try {
      handleClose()
      await dispatch(logout())
      history.push('/login')
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error',
      })
    }
  }

  const handleChangePassword = () => {
    handleClose()
    // dispatch(openChangePassword())
    openSidebar()
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
        data-testid="account"
      >
        <Hidden mdDown>
          <Typography variant="h6" color="inherit">
            {`${account.user?.username}`}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        anchorEl={ref.current}
        open={isOpen}
      >
        {/* <MenuItem
        component={RouterLink}
        to="/app/social/profile"
      >
        Profile
      </MenuItem>
      <MenuItem
        component={RouterLink}
        to="/app/account"
      >
        Account
      </MenuItem> */}
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
        <MenuItem
          data-testid="account-change-password"
          onClick={handleChangePassword}
        >
          Change Password
        </MenuItem>
      </Menu>
    </>
  )
}

export default Account
