import produce from 'immer'
import {
  FETCHING_IN_PROGRESS,
  FETCHING_SUCCESS,
  FETCHING_ERROR,
  ADD_CURRENTLY_SENDING_EMAIL_DATA,
  REMOVE_CURRENLY_SENDING_EMAIL_DATA,
  PAGE_CHANGE,
} from 'src/actionTypes/sendingHistoryActionTypes'

const initialState = {
  fetchingInProgress: false,
  fetchingError: false,
  sendingHistories: null,
  currentlySending: null,
  pagination: {
    page: 1,
    count: 0,
  },
}

export default function SendingHistoryReducer(state = initialState, action) {
  switch (action.type) {
    case FETCHING_IN_PROGRESS:
      return produce(state, (draft) => {
        draft.fetchingInProgress = true
        draft.fetchingError = false
        draft.sendingHistories = null
      })
    case FETCHING_SUCCESS:
      return produce(state, (draft) => {
        const { data, count } = action.payload
        draft.fetchingError = false
        draft.sendingHistories = data
        draft.fetchingInProgress = false
        draft.pagination = {
          ...draft.pagination,
          count,
        }
      })
    case FETCHING_ERROR:
      return produce(state, (draft) => {
        draft.fetchingError = true
        draft.fetchingInProgress = false
        draft.sendingHistories = null
      })
    case ADD_CURRENTLY_SENDING_EMAIL_DATA:
      return produce(state, (draft) => {
        draft.currentlySending = action.payload
      })
    case REMOVE_CURRENLY_SENDING_EMAIL_DATA:
      return produce(state, (draft) => {
        draft.currentlySending = null
      })
    case PAGE_CHANGE:
      return produce(state, (draft) => {
        const { page } = action.payload

        draft.pagination = {
          ...draft.pagination,
          page,
        }
      })
    default:
      return state
  }
}
