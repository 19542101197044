import React, { useState, useEffect } from 'react'
import { Box, Typography, Collapse } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import useSimpleNotification from 'src/hooks/useSimpleNotification'
import { useDispatch } from 'react-redux'
import { importServices, addNewSubService } from '../../actions'
import ServiceDropdown from './ServiceInputDropdown'
import AddInput from './AddInput'
import AddButton from './AddButton'

const useStyles = makeStyles()(() => ({
  title: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '31px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#000000',
  },
}))

export default function AddContainer() {
  const { classes } = useStyles()
  const dispatch = useDispatch()
  const notification = useSimpleNotification()
  const [newServiceName, newServiceNameSet] = useState('')
  const [selectedService, selectedServiceSet] = useState('')
  useEffect(() => {
    dispatch(importServices())
  }, [dispatch])

  const onChange = (e) => {
    newServiceNameSet(e.target.value)
  }
  const onServiceChange = (_, newVal) => {
    if (!newVal) {
      newServiceNameSet('')
    }
    selectedServiceSet(newVal)
  }
  const onClick = async () => {
    try {
      await dispatch(addNewSubService(newServiceName, selectedService))
      notification('success')
    } catch (error) {
      notification('error')
    }
  }
  return (
    <Box>
      <Box mb={1}>
        <Typography className={classes.title}>Add New Service</Typography>
      </Box>
      <ServiceDropdown
        onChange={onServiceChange}
        selectedService={selectedService}
      />
      <Collapse in={!!selectedService}>
        <Box display="flex" alignItems="center">
          <AddInput value={newServiceName} onChange={onChange} />
          <AddButton onClick={onClick} />
        </Box>
      </Collapse>
    </Box>
  )
}
