import React from 'react'
import { Box, IconButton, SvgIcon } from '@mui/material'
import { HelpCircle as HelpIcon } from 'react-feather'
import { useDispatch } from 'react-redux'
import { toggleHelpDrawerAction } from 'src/actions/helpActions'

function Help() {
  const dispatch = useDispatch()
  const toggle = () => {
    dispatch(toggleHelpDrawerAction())
  }

  return (
    <Box ml={1}>
      <IconButton onClick={toggle} size="large">
        <SvgIcon htmlColor="#FFFFFF">
          <HelpIcon />
        </SvgIcon>
      </IconButton>
    </Box>
  )
}

export default Help
