import { Box } from '@mui/material'
import React from 'react'
import logo from 'src/assets/images/smile-bi-logo-rsz.png'
import logoSplash from 'src/assets/images/smile-bi-logo-splash.png'
function Logo(props) {
  return (
    <>
      <Box
        sx={{ background: '#150c41', padding: '2px 15px', marginTop: '4px' }}
      >
        <img alt="Logo" src={logo} {...props} />
      </Box>
    </>
  )
}

export function SplashScreenLogo(props) {
  return (
    <Box sx={{ background: '#150c41', padding: '2px 15px' }}>
      <img alt="Logo" src={logoSplash} {...props} />
    </Box>
  )
}

export function SmileLogoIcon() {
  return <img alt="Logo" style={{ marginRight: '5px' }} src={logo} />
}

export default Logo
