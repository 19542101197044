import { Box } from '@mui/material'
import Filters from 'src/components/BasicFilters'
import Search from './SearchInput'

const config = [{ Component: Search, visible: true }]

const BookedServicesFilters = () => {
  return (
    <Box>
      <Filters filtersConfig={config} />
    </Box>
  )
}

export default BookedServicesFilters
