import React from 'react'
import { TextField } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import debounce from 'lodash/debounce'
import Autocomplete from '@mui/material/Autocomplete'
import { useSelector, useDispatch } from 'react-redux'
import { updateFiltersAction, fetchBrands } from '../lib/actions'
import { getManufacturers, getSelectedManufacturer } from '../lib/selectors'

const useStyles = makeStyles()((theme) => ({
  input: {
    [theme.breakpoints.up('lg')]: {
      width: 210,
    },
    [theme.breakpoints.down('lg')]: {
      flexGrow: 1,
      marginBottom: 5,
      marginTop: 5,
    },
  },
  openDrawer: {
    width: '100%',
  },
}))

function ManufacturersInput({ handleChange, openDrawer }) {
  const dispatch = useDispatch()
  const { classes, cx } = useStyles()
  const manufacturers = useSelector(getManufacturers)
  const selectedManufacturer = useSelector(getSelectedManufacturer)

  const handleShopChange = async (e, newValue) => {
    // setShop(newValue)
    handleChange(newValue)
    dispatch(fetchBrands(newValue))
  }

  const optionsSelected = (option, value) => option.id === value.id

  return (
    <Autocomplete
      className={cx(classes.input, openDrawer && classes.openDrawer)}
      id="rating-manufacturers"
      options={manufacturers}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={optionsSelected}
      value={selectedManufacturer || null}
      onChange={handleShopChange}
      renderInput={(params) => (
        <TextField {...params} label="Manufacturers" variant="outlined" />
      )}
    />
  )
}

export default function ManufacturersInputContainer(props) {
  const dispatch = useDispatch()

  const handleChange = (value) => {
    dispatch(updateFiltersAction('manufacturer', value))
  }
  const handleChangeDebounced = debounce(handleChange, 200)
  return <ManufacturersInput {...props} handleChange={handleChangeDebounced} />
}
