import React, { useState, useEffect } from 'react'
import { NavLink as RouterLink, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Button, Collapse, ListItem } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { changeTopNavCallToAction } from 'src/actions/topNavActions'

const useStyles = makeStyles()((theme, _params, classes) => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
  },
  buttonLeaf: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      [`& .${classes.title}`]: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },
  iconOnly: {
    justifyContent: 'center',
    padding: '10px ',
    minWidth: '100%',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    marginLeft: theme.spacing(1),
    marginRight: 'auto',
  },
  active: {
    color: theme.palette.actionBlue.main,
    [`& .${classes.title}`]: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    [`& .${classes.icon}`]: {
      color: theme.palette.actionBlue.main,
    },
  },
}))

function NavItem({
  title,
  href,
  depth,
  children,
  icon: Icon,
  className,
  open: openProp,
  info: Info,
  id,
  disabled,
  ...rest
}) {
  const { classes, cx } = useStyles()
  const [open, setOpen] = useState(openProp)
  const dispatch = useDispatch()
  const location = useLocation()

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  useEffect(() => {
    if (location.pathname === href) {
      dispatch(changeTopNavCallToAction(id))
    }
  }, [location.pathname, href, dispatch, id])

  let paddingLeft = 8

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth
  }

  const style = { paddingLeft }
  if (children) {
    return (
      <ListItem
        className={cx(classes.item, className)}
        disableGutters
        key={title || id}
        {...rest}
      >
        <Button className={classes.button} onClick={handleToggle} style={style}>
          {Icon && <Icon className={classes.icon} size="20" />}
          <span className={classes.title}>{title}</span>
          {open ? (
            <ExpandLessIcon size="small" color="inherit" />
          ) : (
            <ExpandMoreIcon size="small" color="inherit" />
          )}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    )
  }

  if (disabled) {
    return null
  }
  return (
    <ListItem
      className={cx(classes.itemLeaf, className)}
      disableGutters
      key={title || id}
      {...rest}
    >
      <Button
        activeClassName={classes.active}
        className={cx(classes.buttonLeaf, `depth-${depth}`, {
          [classes.iconOnly]: !title,
        })}
        component={RouterLink}
        exact
        style={style}
        to={href}
        disabled={disabled}
      >
        {Icon && <Icon className={classes.icon} size="20" />}
        {title && <span className={classes.title}>{title}</span>}
        {Info && <Info className={classes.info} />}
      </Button>
    </ListItem>
  )
}

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  info: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string,
}

NavItem.defaultProps = {
  open: false,
}

export default NavItem
