import { Box } from '@mui/material'
import Filters from 'src/components/BasicFilters'
import { useDispatch, useSelector } from 'react-redux'
import { importFilters } from '../lib/actions'
import Search from './SearchInput'
import BrandsFilter from './BrandsFilter'
import ManufacturersFilter from './ManufacturersFilter'
import { openDrawerSelector } from '../lib/selectors'

const config = [
  { Component: Search, visible: true },
  { Component: ManufacturersFilter },
  { Component: BrandsFilter },
]

const KatSollFilters = () => {
  const dispatch = useDispatch()
  const openDrawer = useSelector(openDrawerSelector)

  const onEffect = () => dispatch(importFilters())

  return (
    <Box>
      <Filters
        openDrawer={openDrawer}
        filtersConfig={config}
        onEffect={onEffect}
      />
    </Box>
  )
}

export default KatSollFilters
