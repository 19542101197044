import { useState } from 'react'
import { Box, TextField } from '@mui/material'
import BasicBlueButton from 'src/components/BasicBlueButton'
import ModalWrapper from 'src/components/ModalWrapper'
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  queryField: {
    [theme.breakpoints.up('sm')]: {
      width: 279,
      minWidth: 130,
    },
    [theme.breakpoints.down('md')]: {
      flexGrow: 1,
    },
  },
}));

type BookmarkModalProps = {
  open: boolean
  closeModal: () => void
  saveBookmark: (name: string) => Promise<void>
}

export default function BookmarkModal({
  open,
  closeModal,
  saveBookmark,
}: BookmarkModalProps) {
  const { classes } = useStyles()
  const [name, setName] = useState('')

  const handleChange = (e: any) => {
    setName(e.target.value)
  }

  return (
    <ModalWrapper
      open={open}
      handleClose={closeModal}
      title="Save bookmark"
      Content={{
        body: (
          <Box>
            <TextField
              className={classes.queryField}
              onChange={handleChange}
              value={name || ''}
              placeholder="Bookmark Name"
              variant="outlined"
            />
            <Box display="flex" mt={1} justifyContent="flex-end">
              <BasicBlueButton onClick={() => saveBookmark(name)} text="Save" />
            </Box>
          </Box>
        ),
      }}
    />
  )
}
