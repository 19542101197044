import produce from 'immer'
import { REDUCER_NAME as RN } from 'src/actions/DAMPznActions'
import {
  DELETE_ALL_PACKSHOTS,
  FETCHING_IN_PROGRESS,
  FETCHING_SUCCESS,
  FETCHING_ERROR,
  CLOSE_SIDEBAR,
  PAGE_CHANGE,
  POPULATE_DROPDOWN_VALUES,
  FETCHING_PACKSHOTS,
  FETCHING_PACKSHOTS_ERROR,
  FETCHING_PACKSHOTS_SUCCESS,
  FETCHING_TEXTS,
  FETCHING_TEXTS_ERROR,
  FETCHING_TEXTS_SUCCESS,
  SET_UPLOADING_PACKSHOTS,
  UPDATE_ACTIVE_TAB,
  UPDATE_FILTERS,
  UPDATE_PACKSHOT_NAME,
  UPDATE_TEXTFILE_NAME,
  UPLOADING_PACKSHOTS_ERROR,
  UPLOADING_TEXTFILES,
  UPLOADING_TEXTFILES_ERROR,
  UPLOADING_TEXTFILES_SUCCESS,
  UPLOAD_PACKSHOTS_SUCCESS,
  DELETE_PACKSHOT,
  DELETE_TEXTFILES,
  OPEN_ROW,
  RESET_FILTERS,
  FETCH_FOUND_PACKSHOTS_REQUEST,
  FETCH_FOUND_PACKSHOTS_SUCCESS,
  FETCH_FOUND_PACKSHOTS_ERROR,
  VALIDATE_PACKSHOT_CONFIRM,
  VALIDATE_PACKSHOT_INVALID,
} from 'src/actionTypes/damPznActionTypes'
import { BASIC_TAB } from 'src/constants/tabNames'
import { getTextsKey, getPackshotsKey } from 'src/utils/getFileCacheKey'

const initialState = {
  fetchingInProgress: true,
  fetchingError: false,
  pzns: [],
  pagination: {
    page: 1,
    count: 0,
  },
  filters: {
    pznOrTitle: '', // string
    manufacturer: null, // object
    brand: null, // object
    packshots: null,
    text: null,
    companies: null,
  },
  manufacturers: [],
  brands: [],
  companies: [],
  openDrawer: false, // bool
  activeTab: BASIC_TAB,
  activeRow: null, // object
  activeRowIndex: null, // number | null
  packshots: [],
  uploadingPackshots: [],
  uploadingPackshotsFilesError: false,
  selectedPackshotsForSending: [],
  selectedTextsForSending: [],
  uploadingTextFiles: [],
  uploadingTextFilesError: false,
  packshotFiles: {},
  fetchingPackshots: false,
  fetchingPackshotsError: false,
  textFiles: {},
  fetchingTextFiles: false,
  fetchingTextFilesError: false,
  fetchingFoundPackshots: false,
  foundPackshots: null,
}
export const REDUCER_NAME = RN
export default function DamPznReducer(state = initialState, action) {
  switch (action.type) {
    case FETCHING_IN_PROGRESS:
      return produce(state, (draft) => {
        draft.fetchingInProgress = true
        draft.fetchingError = false
      })
    case FETCHING_ERROR:
      return produce(state, (draft) => {
        draft.fetchingError = true
        draft.fetchingInProgress = false
      })
    case FETCHING_SUCCESS:
      return produce(state, (draft) => {
        draft.fetchingError = false
        draft.fetchingInProgress = false
        draft.pzns = action?.payload?.res || []
        draft.pagination = {
          ...draft.pagination,
          count: action.payload.count,
        }
      })
    case PAGE_CHANGE:
      return produce(state, (draft) => {
        draft.pagination = {
          ...draft.pagination,
          page: action.payload,
        }
      })
    case UPDATE_FILTERS:
      return produce(state, (draft) => {
        const { target, value } = action.payload
        draft.filters[target] = value
        draft.pagination.page = 1
      })
    case RESET_FILTERS:
      return produce(state, (draft) => {
        draft.filters = initialState.filters
      })
    case POPULATE_DROPDOWN_VALUES:
      return produce(state, (draft) => {
        const { manufacturers, brands, companies } = action.payload
        draft.manufacturers = manufacturers || []
        draft.brands = brands || []
        draft.companies = companies || []
      })
    case OPEN_ROW:
      return produce(state, (draft) => {
        const { index } = action.payload
        draft.openDrawer = true
        draft.activeRow = draft.pzns[index]
        draft.activeRowIndex = index
      })
    case CLOSE_SIDEBAR:
      return produce(state, (draft) => {
        draft.openDrawer = false
        draft.activeRow = null
        draft.activeRowIndex = null
      })
    case UPDATE_ACTIVE_TAB:
      return produce(state, (draft) => {
        draft.activeTab = action.payload
      })
    case FETCHING_PACKSHOTS:
      return produce(state, (draft) => {
        draft.fetchingPackshots = true
        draft.fetchingPackshotsError = false
      })
    case FETCHING_PACKSHOTS_ERROR:
      return produce(state, (draft) => {
        draft.fetchingPackshots = false
        draft.fetchingPackshotsError = true
      })
    case FETCHING_PACKSHOTS_SUCCESS:
      return produce(state, (draft) => {
        const { id } = draft.activeRow
        draft.packshotFiles[getPackshotsKey(id)] = action.payload
        draft.fetchingPackshots = false
        draft.fetchingPackshotsError = false
      })
    case UPLOAD_PACKSHOTS_SUCCESS:
      return produce(state, (draft) => {
        const { files, id } = action.payload
        const key = getPackshotsKey(id)
        if (!state.packshotFiles[key]) {
          draft.packshotFiles[key] = []
        }
        draft.packshotFiles[key].push(...files)
        draft.uploadingPackshotsFilesError = false
        draft.uploadingPackshots = []
      })
    case SET_UPLOADING_PACKSHOTS:
      return produce(state, (draft) => {
        const { uploadingPackshots } = action.payload
        draft.uploadingPackshots = uploadingPackshots
      })
    case UPLOADING_PACKSHOTS_ERROR:
      return produce(state, (draft) => {
        draft.uploadingPackshots = []
      })

    case DELETE_PACKSHOT:
      return produce(state, (draft) => {
        const targetId = action.payload
        const rowId = draft.activeRow.id
        const packshots = draft.packshotFiles[getPackshotsKey(rowId)] // just take reference
        const remaining = packshots.filter(
          (packshot) => packshot.id !== targetId,
        )
        draft.packshotFiles[getPackshotsKey(rowId)] = remaining
      })
    case DELETE_TEXTFILES:
      return produce(state, (draft) => {
        const elements = action.payload
        const rowId = draft.activeRow.id
        const files = draft.textFiles[getTextsKey(rowId)] // just take reference
        let remaining = [...files]
        if (elements.length > 1) {
          remaining = files.filter((file) => !elements.includes(file.textId))
        } else {
          remaining = files.filter((file) => file.textId !== elements[0])
        }
        draft.textFiles[getTextsKey(rowId)] = remaining
      })

    case UPDATE_PACKSHOT_NAME:
      return produce(state, (draft) => {
        const { id, name } = action.payload
        const rowId = draft.activeRow.id
        const packshots = draft.packshotFiles[getPackshotsKey(rowId)] // just take reference
        const indexOfFile = packshots.findIndex((file) => file.id === id)

        if (indexOfFile > -1) {
          packshots[indexOfFile].originalName = name
        }
      })
    case UPDATE_TEXTFILE_NAME:
      return produce(state, (draft) => {
        const { id, name } = action.payload
        const rowId = draft.activeRow.id
        const indexOfFile = draft.textFiles[getTextsKey(rowId)].findIndex(
          (file) => file.textId === id,
        )
        if (indexOfFile > -1) {
          draft.textFiles[getTextsKey(rowId)][indexOfFile].originalName = name
        }
      })
    case UPLOADING_TEXTFILES:
      return produce(state, (draft) => {
        draft.uploadingTextFiles = action.payload
        draft.uploadingTextFilesError = false
      })
    case UPLOADING_TEXTFILES_ERROR:
      return produce(state, (draft) => {
        draft.uploadingTextFiles = []
        draft.uploadingTextFilesError = true
      })
    case UPLOADING_TEXTFILES_SUCCESS:
      return produce(state, (draft) => {
        const { files, id } = action.payload
        const key = getTextsKey(id)
        if (!state.textFiles[key]) {
          draft.textFiles[key] = []
        }
        draft.textFiles[key].push(...files)
        draft.uploadingTextFilesError = false
        draft.uploadingTextFiles = []
      })
    case FETCHING_TEXTS:
      return produce(state, (draft) => {
        draft.fetchingTextFiles = true
        draft.fetchingTextFilesError = false
      })
    case FETCHING_TEXTS_SUCCESS:
      return produce(state, (draft) => {
        const { data, id } = action.payload

        draft.textFiles[getTextsKey(id)] = data
        draft.fetchingTextFiles = false
        draft.fetchingTextFilesError = false
      })
    case FETCHING_TEXTS_ERROR:
      return produce(state, (draft) => {
        draft.fetchingTextFiles = false
        draft.fetchingTextFilesError = true
      })
    case DELETE_ALL_PACKSHOTS:
      return produce(state, (draft) => {
        const { rowId } = action.payload

        draft.packshotFiles[getPackshotsKey(rowId)] = []
      })
    case FETCH_FOUND_PACKSHOTS_REQUEST:
      return produce(state, (draft) => {
        draft.fetchingFoundPackshots = true
      })
    case FETCH_FOUND_PACKSHOTS_SUCCESS:
      return produce(state, (draft) => {
        const { foundPackshots } = action.payload

        draft.fetchingFoundPackshots = false
        draft.foundPackshots = foundPackshots
      })
    case FETCH_FOUND_PACKSHOTS_ERROR:
      return produce(state, (draft) => {
        draft.fetchingFoundPackshots = false
        draft.foundPackshots = null
      })
    case VALIDATE_PACKSHOT_INVALID:
      return produce(state, (draft) => {
        const { scraperPackshotId } = action.payload

        const filteredFoundPackshots = draft.foundPackshots.mainPackshot.filter(
          (packshot) => packshot.id !== scraperPackshotId,
        )

        draft.foundPackshots.mainPackshot = filteredFoundPackshots
      })
    case VALIDATE_PACKSHOT_CONFIRM:
      return produce(state, (draft) => {
        const { scraperPackshotId, packshotId } = action.payload

        const filteredFoundPackshots = draft.foundPackshots.mainPackshot.filter(
          (packshot) => packshot.id !== scraperPackshotId,
        )
        const filteredSendPackshot = draft.foundPackshots.sentPackshots.filter(
          (packshot) => packshot.id !== packshotId,
        )

        draft.foundPackshots.mainPackshot = filteredFoundPackshots
        draft.foundPackshots.sentPackshots = filteredSendPackshot
      })

    default:
      return state
  }
}
