import { useState } from 'react'
import {
  Drawer,
  Box,
  IconButton,
  SvgIcon,
  Typography,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  tableCellClasses,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import {
  CrawlerContainer,
  CrawlingStatuses,
} from 'src/hooks/useCrawlerOnDemand'
import { X as CloseIcon, Bookmark } from 'react-feather'

import getLabel from './Labels'
import { Reconnecting } from './Reconnecting'
import { NoConnection } from './NoConnection'
import BookmarkModal from './SaveBookmarkModal'
import { useSelector } from 'react-redux'
import { userSelector } from 'src/selectors/accountSelectors'
import useSimpleNotification from 'src/hooks/useSimpleNotification'
import { saveBookmark } from 'src/services/bookmarkService'
import ExportButton from './ExportExcel'
import { fileDownloaderV2, fileDownloaderV3 } from 'src/utils/downloader'
import getOnDemandSpecialUsers from 'src/utils/onDemandSpecialUsers'

export const useStyles = makeStyles()((theme) => ({
  desktopDrawer: {
    width: 556,
    top: 64,
    height: 'calc(100% - 64px)',
    zIndex: 1202,
    [theme.breakpoints.down('lg')]: {
      width: 356,
      top: 64,
      boxShadow:
        '0px 1px 2px rgba(63, 63, 68, 0.15), 0px 0px 0px rgba(63, 63, 68, 0.05)',
      zIndex: 1202,
    },
  },
  container: {
    marginBottom: 130,
  },
  closeIcon: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 2,
  },
  row: {
    borderBottom: 'none',
  },
  centerdiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '400px',
  },
  rotateIcon: {
    animation: 'spin 4s linear infinite',
  },
}))

export default function CrawlerSidebarContainer() {
  const { classes } = useStyles()
  const { drawerOpen, closeDrawer, connection, queue, updateBookmarked } =
    CrawlerContainer.useContainer()
  const [open, setOpenModal] = useState(false)
  let content: React.ReactNode = null
  const inProgressCount = queue.filter(
    (q) => q.status === CrawlingStatuses.InProgress,
  )
  const user = useSelector(userSelector)
  const noti = useSimpleNotification()

  const openModal = () => {
    if (queue.length) {
      setOpenModal(true)
    }
  }

  const closeModal = () => {
    setOpenModal(false)
  }

  const onSave = async (name: string) => {
    const elements = queue.map((data) => ({
      id: data.id,
      pzn: data.pzn,
      domainId: data.domainId,
      userId: user.id,
      shop: data.shop,
    }))
    try {
      await saveBookmark(name, elements)
      updateBookmarked(elements)
      setOpenModal(false)
      noti('success', 'Bookmark added.')
    } catch (error) {
      noti('error')
    }
  }

  const onDownload = async () => {
    try {
      if (!queue.length) {
        return
      }
      const elements = queue.map((q) => q.id)
      const url = '/on-demand-crawling/export'
      const today = new Date().getTime()
      const name = `pzns_${today}.xls`
      await fileDownloaderV3(url, {
        originalName: name,
        type: 'xls',
        body: elements,
      })
    } catch (error) {
      noti('error')
    }
  }

  const isSpecialUser = getOnDemandSpecialUsers(user)
  const limit = isSpecialUser ? 1000 : 50
  const max = queue.length > limit ? queue.length : limit

  if (connection.connected) {
    content = (
      <>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography>
              On Demand Crawling Queue {inProgressCount.length}/{max}
            </Typography>
          </Box>
          <Box>
            <ExportButton onDownload={onDownload} />
            <IconButton onClick={openModal}>
              <SvgIcon titleAccess="Bookmark">
                <Bookmark />
              </SvgIcon>
            </IconButton>
            <IconButton onClick={closeDrawer}>
              <SvgIcon>
                <CloseIcon />
              </SvgIcon>
            </IconButton>
          </Box>
        </Box>
        <Box>
          <Table
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none',
                // fontFamily: 'PT-Sans',
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>PZN</TableCell>
                <TableCell>Shop</TableCell>
                <TableCell>Started At</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  fontFamily: 'PT-Sans',
                },
              }}
            >
              {queue.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.pzn}</TableCell>
                  <TableCell>{row.shop}</TableCell>
                  <TableCell>{row.date}</TableCell>
                  <TableCell>{getLabel(row.status)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </>
    )
  }

  if (connection.error) {
    content = <NoConnection />
  }

  if (connection.reconnecting) {
    content = <Reconnecting />
  }

  return (
    <Drawer
      classes={{ paper: classes.desktopDrawer }}
      anchor="right"
      elevation={18}
      open={drawerOpen}
      variant="persistent"
    >
      <Box p={2}>{content}</Box>
      <BookmarkModal
        open={open}
        closeModal={closeModal}
        saveBookmark={onSave}
      />
    </Drawer>
  )
}
