import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(() => ({
  wrapper: {
    backgroundImage: `url("data:image/svg+xml;charset=utf8,%3Csvg width='1390' height='1103' viewBox='0 0 1390 1103' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Crect width='1390' height='1103' fill='white'/%3E%3Cg filter='url(%23filter0_d)'%3E%3Cpath d='M0 -4H1387.14L1391 78.1259C1391 78.1259 1262.13 95.3397 1062.31 92.7484C862.497 90.1571 606.692 52.702 384.19 78.8663C161.688 105.031 0 184 0 184V-4Z' fill='white' fill-opacity='0.01'/%3E%3C/g%3E%3Cpath d='M0 -7H1390V53.1484C1390 53.1484 1271.79 86.0052 1095.69 86.0052C919.59 86.0052 692.346 49.7495 396.591 53.3373C100.837 56.9251 0 103 0 103V-7Z' fill='%2320358C'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d' x='-3' y='-6' width='1397' height='194' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/%3E%3CfeOffset dy='1'/%3E%3CfeGaussianBlur stdDeviation='1.5'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape'/%3E%3C/filter%3E%3CclipPath id='clip0'%3E%3Crect width='1390' height='1103' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E")`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    maxHeight: '100vh',
  },
  button: {
    borderColor: '#ffffff',
    color: '#ffffff',
  },
}))

export default useStyles
