import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TableWrapper } from 'src/components/TableList'
import { useSnackbar } from 'notistack'
import useRetry from 'src/hooks/useRetry'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import ErrorPageByType from 'src/views/pages/ErrorPageByType'
import { SERVER_ERROR, NO_RESULTS } from 'src/constants/errorPageConstants'
import useCancelToken from 'src/hooks/useCancelToken'
import {
  getResults,
  getFetchingStatus,
  getParams,
  getActiveRow,
  getCheckedMap,
} from './lib/selectors'
import {
  getAbdaKeywords,
  openDrawer,
  addCheckedPzn,
  removeCheckedPzn,
} from './lib/actions'
const useStyles = makeStyles()(() => ({
  selected: {
    '& td': {
      color: '#FFFFFF',
    },
    backgroundColor: '#4472C4 !important',
    '&:hover': {
      backgroundColor: '#4472C4',
    },
  },
  limitText: {
    textOverflow: 'ellipsis',
    maxWidth: '450px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}))

export default function Results({ Pagination }) {
  const dispatch = useDispatch()
  const { classes } = useStyles()
  const { source } = useCancelToken()
  const [loading, setLoading] = React.useState(true)
  const { enqueueSnackbar } = useSnackbar()
  const [retry, setRetry, retryLimitReached] = useRetry()
  const results = useSelector(getResults)
  const { fetching, error } = useSelector(getFetchingStatus)
  const activeRow = useSelector(getActiveRow)
  const { page, filters } = useSelector(getParams)
  const [checkedSelectAll, setCheckedSelectAll] = useState(false)
  const checkedMap = useSelector(getCheckedMap)

  useEffect(() => {
    dispatch(getAbdaKeywords({ page, filters, source })).finally(() =>
      setLoading(false),
    )
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [page, filters, retry])

  useEffect(() => {
    if (retryLimitReached) {
      enqueueSnackbar('Retry limit reached. Please try later.', {
        variant: 'error',
      })
    }
  }, [retryLimitReached, enqueueSnackbar])

  useEffect(() => {
    const every = results.every((pzn) => checkedMap.has(pzn.subBrandId))
    if (results.length && every) {
      setCheckedSelectAll(true)
    } else {
      setCheckedSelectAll(false)
    }
  }, [checkedMap, results])

  const onRetry = () => {
    setRetry((prevState) => !prevState)
  }

  const handleRowClick = (row) => {
    dispatch(openDrawer(row))
  }

  const onSelectAll = () => {
    if (checkedSelectAll) {
      // setCheckedSelectAll(false)
      dispatch(removeCheckedPzn(results))
    } else {
      // setCheckedSelectAll(true)
      dispatch(addCheckedPzn(results))
    }
  }

  const onClick = (e) => e.stopPropagation()

  const onChange = (pzn) => {
    if (checkedMap.has(pzn.subBrandId)) {
      dispatch(removeCheckedPzn(pzn))
    } else {
      dispatch(addCheckedPzn(pzn))
    }
  }

  if (fetching) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignContent="center"
        marginBottom={4}
        marginTop={4}
        data-testid="loading-screen"
      >
        <CircularProgress color="secondary" disableShrink />
      </Box>
    )
  }

  if (!results.length && !fetching && !loading) {
    return <ErrorPageByType type={NO_RESULTS} />
  }
  if (error) {
    return <ErrorPageByType type={SERVER_ERROR} onRetry={onRetry} />
  }
  return (
    <TableWrapper Pagination={Pagination}>
      <Table data-testid="sending-history-results">
        <TableHead>
          <TableRow>
            <TableCell>
              <FormControlLabel
                label="Select All"
                control={
                  <Checkbox
                    sx={{
                      marginLeft: '11px',
                    }}
                    checked={checkedSelectAll}
                    onChange={onSelectAll}
                  />
                }
              />
            </TableCell>
            <TableCell>Article Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.length > 0 &&
            results.map((row) => (
              <TableRow
                hover
                key={row.subBrandId}
                selected={activeRow?.subBrandId === row.subBrandId}
                onClick={() => handleRowClick(row)}
                classes={{
                  selected: classes.selected,
                }}
              >
                <TableCell>
                  <Checkbox
                    // sx={
                    //   activeRow?.subBrandId === row.subBrandId && {
                    //     color: 'white',
                    //   }
                    // }
                    onClick={onClick}
                    onChange={() => onChange(row)}
                    checked={checkedMap.has(row.subBrandId)}
                  />
                </TableCell>
                <TableCell>{row.name}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableWrapper>
  )
}
