/* eslint-disable no-console */
import { merge } from 'lodash'
import {
  colors,
  responsiveFontSizes,
  createTheme as createMuiTheme,
} from '@mui/material'
import typography from './typography'
import { softShadows, strongShadows } from './shadows'
import { THEMES } from '../constants'

const baseConfig = {
  direction: 'ltr',
  navBar: {
    openWidth: 235,
    closeWidth: 57,
  },
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)',
      },
    },
    MuiButton: {
      sizeLarge: {
        padding: '15px 22px',
      },
    },
  },
}

const themeConfigs = [
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600],
          },
        },
      },
    },
    palette: {
      mode: 'light',
      action: {
        active: colors.blueGrey[600],
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white,
      },
      primary: {
        main: '#150c41',
      },
      secondary: {
        main: '#20358C',
      },
      gpt: {
        main: '#464454',
        secondary: '#1B2845',
      },
      actionBlue: {
        main: '#4472C4',
        secondary: '#3F51B5',
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
        black: '#263238',
      },
      grey: {
        main: '#F5F5F5',
        text: '#263238',
      },
      errors: {
        lightRed: '#feebee',
        default: '#f44336',
      },
      good: {
        main: '#e8f5e9',
        secondary: '#4caf50',
      },
      warning: {
        main: '#ed6c02',
      },
    },
    shadows: softShadows,
    fonts: {
      roboto: 'Roboto',
      ptSans: 'PT-Sans',
    },
    buttons: {
      activeButton: {
        backgroundColor: '#4472C4',
        color: '#FFFFFF',
      },
      red: {
        backgroundColor: '#feebee',
        color: '#f44336',
        width: '112px',
        height: '42px',
        fontWeight: 500,
        fontSize: '15px',
        lineHeight: '26px',
        boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.14)',
        marginTop: '9px',
        marginBottom: '9px',
        '&:hover': {
          backgroundColor: '#feebee',
          color: '#f44336',
        },
      },
      green: {
        backgroundColor: '#e8f5e9',
        color: '#4caf50',
        width: '112px',
        height: '42px',
        fontWeight: 500,
        fontSize: '15px',
        lineHeight: '26px',
        boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.14)',
        marginTop: '9px',
        marginBottom: '9px',
        '&:hover': {
          backgroundColor: '#e8f5e9',
          color: '#4caf50',
        },
      },
    },
    text: {
      fieldTitle: {
        letterSpacing: '0.4px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '12px',
        marginBottom: 5,
        fontFamily: 'PT-Sans',
      },
    },
    fonts: {
      ptSans: 'PT-Sans, Helvetica, sans-serif',
      roboto: 'Roboto, Helvetica, sans-serif',
    },
  },
  {
    name: THEMES.ONE_DARK,
    palette: {
      mode: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)',
      },
      background: {
        default: '#282C34',
        dark: '#1c2025',
        paper: '#282C34',
      },
      primary: {
        main: '#8a85ff',
      },
      secondary: {
        main: '#8a85ff',
      },
      text: {
        primary: '#e6e5e8',
        secondary: '#adb0bb',
      },
    },
    shadows: strongShadows,
  },
  {
    name: THEMES.UNICORN,
    palette: {
      mode: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)',
      },
      background: {
        default: '#2a2d3d',
        dark: '#222431',
        paper: '#2a2d3d',
      },
      primary: {
        main: '#a67dff',
      },
      secondary: {
        main: '#a67dff',
      },
      text: {
        primary: '#f6f5f8',
        secondary: '#9699a4',
      },
    },
    shadows: strongShadows,
  },
]

const componentsConfig = {
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
    },
  },
}

export function createTheme(settings = {}) {
  let themeConfig = themeConfigs.find((theme) => theme.name === settings.theme)

  if (!themeConfig) {
    console.warn(new Error(`The theme ${settings.theme} is not valid`))
    ;[themeConfig] = themeConfigs
  }

  let theme = createMuiTheme(
    merge(
      {},
      baseConfig,
      themeConfig,
      { direction: settings.direction },
      componentsConfig,
    ),
  )

  if (settings.responsiveFontSizes) {
    theme = responsiveFontSizes(theme)
  }

  return theme
}
