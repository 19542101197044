import produce from 'immer'
import * as NavConstants from 'src/constants/topBarConstants'

const initialState = {
  activeCTA: null,
}

function TopBarCTAReducer(state = initialState, action) {
  switch (action.id) {
    case NavConstants.AMAZON_ACTIVE:
      return produce(state, (draft) => {
        draft.activeCTA = NavConstants.AMAZON_ACTIVE
      })
    case NavConstants.BUNDLES_ACTIVE:
      return produce(state, (draft) => {
        draft.activeCTA = NavConstants.BUNDLES_ACTIVE
      })
    case NavConstants.WKZ_SLOT_CONFIG_ACTIVE:
      return produce(state, (draft) => {
        draft.activeCTA = NavConstants.WKZ_SLOT_CONFIG_ACTIVE
      })
    case NavConstants.WKZ_MEDIA_LIST:
      return produce(state, (draft) => {
        draft.activeCTA = NavConstants.WKZ_MEDIA_LIST
      })
    case NavConstants.USERS_ACTIVE:
      return produce(state, (draft) => {
        draft.activeCTA = NavConstants.USERS_ACTIVE
      })
    case NavConstants.IMPRESSUM_ACTIVE:
      return produce(state, (draft) => {
        draft.activeCTA = NavConstants.IMPRESSUM_ACTIVE
      })
    case NavConstants.PRIVACY_ACTIVE:
      return produce(state, (draft) => {
        draft.activeCTA = NavConstants.PRIVACY_ACTIVE
      })
    case NavConstants.DAMPZN_ACTIVE:
      return produce(state, (draft) => {
        draft.activeCTA = NavConstants.DAMPZN_ACTIVE
      })
    case NavConstants.PRODUCT_RATING:
      return produce(state, (draft) => {
        draft.activeCTA = NavConstants.PRODUCT_RATING
      })
    case NavConstants.DESCRIPTION_RATING:
      return produce(state, (draft) => {
        draft.activeCTA = NavConstants.DESCRIPTION_RATING
      })
    case NavConstants.NEWSLETTER:
      return produce(state, (draft) => {
        draft.activeCTA = NavConstants.NEWSLETTER
      })

    case NavConstants.CUSTOMERS:
      return produce(state, (draft) => {
        draft.activeCTA = NavConstants.CUSTOMERS
      })
    case NavConstants.SHOP_CONTACTS_ACTIVE:
      return produce(state, (draft) => {
        draft.activeCTA = NavConstants.SHOP_CONTACTS_ACTIVE
      })
    case NavConstants.SENDING_HISTORY:
      return produce(state, (draft) => {
        draft.activeCTA = NavConstants.SENDING_HISTORY
      })
    case NavConstants.KEYWORDS:
      return produce(state, (draft) => {
        draft.activeCTA = NavConstants.KEYWORDS
      })
    case NavConstants.WKZ_CONFIG:
      return produce(state, (draft) => {
        draft.activeCTA = NavConstants.WKZ_CONFIG
      })
    case NavConstants.WKZ_INPUT:
      return produce(state, (draft) => {
        draft.activeCTA = NavConstants.WKZ_INPUT
      })
    case NavConstants.GOOGLE_SHOPPING:
      return produce(state, (draft) => {
        draft.activeCTA = NavConstants.GOOGLE_SHOPPING
      })
    case NavConstants.BOOKED_SERVICES:
      return produce(state, (draft) => {
        draft.activeCTA = NavConstants.BOOKED_SERVICES
      })
    case NavConstants.KAT_SOLL:
      return produce(state, (draft) => {
        draft.activeCTA = NavConstants.KAT_SOLL
      })
    case NavConstants.MY_MARKETS:
      return produce(state, (draft) => {
        draft.activeCTA = NavConstants.MY_MARKETS
      })
    case NavConstants.BRANDS:
      return produce(state, (draft) => {
        draft.activeCTA = NavConstants.BRANDS
      })
    case NavConstants.PZN:
      return produce(state, (draft) => {
        draft.activeCTA = NavConstants.PZN
      })
    case NavConstants.ONDEMANDCRAWL:
      return produce(state, (draft) => {
        draft.activeCTA = NavConstants.ONDEMANDCRAWL
      })
    case NavConstants.BOOKMARKS:
      return produce(state, (draft) => {
        draft.activeCTA = NavConstants.BOOKMARKS
      })
    case NavConstants.AVBAANALYTICS:
      return produce(state, (draft) => {
        draft.activeCTA = NavConstants.AVBAANALYTICS
      })
    case NavConstants.PZN_OVERVIEW:
      return produce(state, (draft) => {
        draft.activeCTA = NavConstants.PZN_OVERVIEW
      })
    case NavConstants.TEMPLATES:
      return produce(state, (draft) => {
        draft.activeCTA = NavConstants.TEMPLATES
      })
    case NavConstants.UPLOAD_DATA:
      return produce(state, (draft) => {
        draft.activeCTA = NavConstants.UPLOAD_DATA
      })
    case NavConstants.PRESET_FILTERS:
      return produce(state, (draft) => {
        draft.activeCTA = NavConstants.PRESET_FILTERS
      })
    case NavConstants.SMILE_GPT:
      return produce(state, (draft) => {
        draft.activeCTA = NavConstants.SMILE_GPT
      })
    case NavConstants.CONTACTS:
      return produce(state, (draft) => {
        draft.activeCTA = NavConstants.CONTACTS
      })
    case NavConstants.COMMUNICATION_COPILOT_SETTINGS:
      return produce(state, (draft) => {
        draft.activeCTA = NavConstants.COMMUNICATION_COPILOT_SETTINGS
      })
    case NavConstants.PROMPT_FACTORY:
      return produce(state, (draft) => {
        draft.activeCTA = NavConstants.PROMPT_FACTORY
      })
    default:
      return state
  }
}

export default TopBarCTAReducer
