import React from 'react'
import { Box, Container } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import Page from 'src/components/Page'
import ErrorBoundary from 'src/components/ErrorBoundary'
import { SERVER_ERROR } from 'src/constants/errorPageConstants'

import TableWrapper from './TableWrapper'
import Pagination from './Pagination'
import Header from './Header'
import Filters from './Filters'

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    // paddingTop: theme.spacing(3),
    paddingBottom: 100,
    whiteSpace: 'nowrap',
  },
  paginationWrapper: {
    background: '#FFFFFF',
    padding: 21,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      paddingBottom: 21,
    },
  },
}))
export { TableWrapper, Pagination, Header, Filters }
export default function TableListPage({
  children,
  pageTitle,
  className = undefined,
}) {
  const { classes, cx } = useStyles()

  return (
    <Page
      className={cx([classes.root, className])}
      title={pageTitle}
      data-testid={pageTitle}
    >
      <Container maxWidth={false}>
        <ErrorBoundary type={SERVER_ERROR}>
          <Box mt={3}>{children}</Box>
        </ErrorBoundary>
      </Container>
    </Page>
  )
}
