function generateActionType(type) {
  return `@bookedS/${type}`
}

export const SELECT_ROW = generateActionType('SELECT_ROW')
export const UPDATE_FILTERS = generateActionType('UPDATE_FILTERS')
export const ADD_CHECKED_PZN = generateActionType('ADD_CHECKED_PZN')
export const REMOVE_CHECKED_PZN = generateActionType('REMOVE_CHECKED_PZN')
export const CLOSE_SIDEBAR = generateActionType('CLOSE_SIDEBAR')
export const OPEN_DRAWER = generateActionType('OPEN_DRAWER')
export const SET_RESULTS = generateActionType('SET_RESULTS')
export const FETCHING = generateActionType('FETCHING')
export const FETCHING_404 = generateActionType('FETCHING_404')
export const PAGE_CHANGE = generateActionType('PAGE_CHANGE')
export const CLEAR_SELECTED_KW = generateActionType('CLEAR_SELECTED_KW')
export const DEACTIVATE_KEYWORDS = generateActionType('DEACTIVATE_KEYWORDS')
export const FETCHING_ERROR = generateActionType('FETCHING_ERROR')
export const SET_IMPORTED_SERVICES = generateActionType('SET_IMPORTED_SERVICES')
export const ADD_NEW_SERVICE = generateActionType('ADD_NEW_SERVICE')
