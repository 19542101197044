export const AMAZON_ACTIVE = '@topnavCTA/AMAZON_ACTIVE'
export const BUNDLES_ACTIVE = '@topnavCTA/BUNDLES_ACTIVE'
export const WKZ_SLOT_CONFIG_ACTIVE = '@topnavCTA/WKZ_SLOT_CONFIG_ACTIVE'
export const WKZ_MEDIA_LIST = '@topnavCTA/WKZ_MEDIA_LIST'
export const USERS_ACTIVE = '@topnavCTA/USERS_ACTIVE'
export const IMPRESSUM_ACTIVE = '@topnavCTA/IMPRESSUM_ACTIVE'
export const PRIVACY_ACTIVE = '@topnavCTA/PRIVACY_ACTIVE'
export const DAMPZN_ACTIVE = '@topnavCTA/DAMPZN_ACTIVE'
export const SENDING_HISTORY = '@topnavCTA/SENDING_HISTORY'
export const SHOP_CONTACTS_ACTIVE = '@topnavCTA/SHOP_CONTACTS_ACTIVE'
export const DAM_RATING = '@topnavCTA/DAM_RATING_ACTIVE'
export const CUSTOMERS_PANEL = '@topnavCTA/CUSTOMERS_PANEL'
export const PRODUCT_RATING = '@topnavCTA/PRODUCT_RATING'
export const DESCRIPTION_RATING = '@topnavCTA/DESCRIPTION_RATING'
export const NEWSLETTER = '@topnavCTA/NEWSLETTER'
export const CUSTOMERS = '@topnavCTA/CUSTOMERS'
export const KEYWORDS = '@topnavCTA/KEYWORDS'
export const WKZ_CONFIG = '@topnavCTA/WKZ_CONFIG'
export const WKZ_INPUT = '@topnavCTA/WKZ_INPUT'
export const GOOGLE_SHOPPING = '@topnavCTA/GOOGLE_SHOPPING'
export const BOOKED_SERVICES = '@topnavCTA/BOOKED_SERVICES'
export const KAT_SOLL = '@topnavCTA/KAT_SOLL'
export const MY_MARKETS = '@topnavCTA/MY_MARKETS'
export const BRANDS = '@topnavCTA/BRANDS'
export const PZN = '@topnavCTA/PZN'
export const ONDEMANDCRAWL = '@topnavCTA/ONDEMANDCRAWL'
export const BOOKMARKS = '@topnavCTA/BOOKMARKS'
export const AVBAANALYTICS = '@topnavCTA/AVBAANALYTICS'
export const PZN_OVERVIEW = '@topnavCTA/PZN_OVERVIEW'
export const TEMPLATES = '@topnavCTA/TEMPLATES'
export const UPLOAD_DATA = '@topnavCTA/UPLOAD_DATA'
export const PRESET_FILTERS = '@topnavCTA/PRESET_FILTERS'
export const SMILE_GPT = '@topnavCTA/SMILE_GPT'
export const CONTACTS = '@topnavCTA/CONTACTS'
export const COMMUNICATION_COPILOT_SETTINGS =
  '@topnavCTA/COMMUNICATION_COPILOT_SETTINGS'
export const PROMPT_FACTORY = '@topnavCTA/PROMPT_FACTORY'
