function generateActionType(type) {
  return `@pckRtg/${type}`
}

export const PAGE_CHANGE = generateActionType('PAGE_CHANGE')
export const FETCHING_IN_PROGRESS = generateActionType('FETCHING_IN_PROGRESS')
export const FETCHING_SUCCESS = generateActionType('FETCHING_SUCCESS')
export const FETCHING_ERROR = generateActionType('FETCHING_ERROR')
export const UPDATE_FILTERS = generateActionType('UPDATE_FILTERS')
export const UPDATE_SINGLE_FILTER = generateActionType('UPDATE_SINGLE_FILTER')
export const IMPORT_FILTERS = generateActionType('IMPORT_FILTERS')
export const CLOSE_SIDEBAR = generateActionType('CLOSE_SIDEBAR')
export const OPEN_SIDEBAR = generateActionType('OPEN_SIDEBAR')
export const IMPORT_SHOPS = generateActionType('IMPORT_SHOPS')
export const SELECT_SHOP = generateActionType('SELECT_SHOP')
export const SELECT_STATUS = generateActionType('SELECT_STATUS')
export const SET_PACKSHOTS = generateActionType('SET_PACKSHOTS')
export const SET_STATUS = generateActionType('SET_STATUS')
export const FETCHING_PACKSHOTS_FOR_RATING = generateActionType(
  'FETCHING_PACKSHOTS_FOR_RATING',
)
export const FETCHING_PACKSHOTS_FOR_RATING_ERROR = generateActionType(
  'FETCHING_PACKSHOTS_FOR_RATING_ERROR',
)

export const CLEAR_FILTERS_FOR_RATING = generateActionType(
  'CLEAR_FILTERS_FOR_RATING',
)
export const CLEAR_ALL_SELECTED_BRANDS = generateActionType(
  'CLEAR_ALL_SELECTED_BRANDS',
)
export const CLEAR_ALL_SELECTED_MANUFACTURERS = generateActionType(
  'CLEAR_ALL_SELECTED_MANUFACTURERS',
)
export const CLEAR_ALL_SELECTED_ARTICLE_NAMES = generateActionType(
  'CLEAR_ALL_SELECTED_ARTICLE_NAMES',
)
