import Label from 'src/components/LabelTS'
import { CrawlingStatuses } from 'src/hooks/useCrawlerOnDemand'

export default function getLabel(status: CrawlingStatuses) {
  if (status === CrawlingStatuses.Error) {
    return <Label color="error">error</Label>
  }

  if (status === CrawlingStatuses.InProgress) {
    return <Label color="warning">in progress</Label>
  }

  if (status === CrawlingStatuses.Done) {
    return <Label color="success">done</Label>
  }

  return null
}
