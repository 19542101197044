import React, { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { TextField, Box, Button } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import InputWithEye from 'src/components/InputWithEye'
// import { useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'
import { changePassword } from 'src/actions/accountActions'

const useStyles = makeStyles()((theme) => ({
  changeButton: {
    background: theme.palette.actionBlue.secondary,
    color: theme.palette.background.default,
    '&:hover': {
      background: theme.palette.actionBlue.secondary,
    },
  },
  pwdContainer: {
    maxWidth: '427px',
  },
}))

export default function ResetPwd() {
  const { classes } = useStyles()
  const [showPassword, setShowPassword] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const showPasswordCb = () => {
    setShowPassword((prev) => !prev)
  }

  const onClick = async (values) => {
    try {
      await changePassword(values)
      enqueueSnackbar('Success', {
        variant: 'success',
      })
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      })
    }
  }

  return (
    <>
      <Formik
        initialValues={{
          oldPassword: '',
          newPassword: '',
        }}
        validationSchema={Yup.object().shape({
          oldPassword: Yup.string()
            .label('Old Password')
            .min(8)
            .max(32)
            .required('Password is required')
            .matches(
              /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/,
              'Password must contain at least 8 characters, one uppercase, one number and one special case character',
            ),

          newPassword: Yup.string()
            .label('New Password')
            .min(8)
            .max(32)
            .required('New password is required')
            .matches(
              /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/,
              'Password must contain at least 8 characters, one uppercase, one number and one special case character',
            ),
        })}
      >
        {({ errors, touched, handleBlur, handleChange, values }) => (
          <form>
            <Box display="flex" flexGrow={1}>
              <Box mr={5} flexGrow={1} className={classes.pwdContainer}>
                <TextField
                  data-testid="old-password"
                  error={Boolean(touched.oldPassword && errors.oldPassword)}
                  fullWidth
                  helperText={touched.oldPassword && errors.oldPassword}
                  label="Old Password"
                  margin="normal"
                  name="oldPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type={showPassword ? 'text' : 'password'}
                  value={values.oldPassword}
                  variant="outlined"
                />
              </Box>
              <Box flexGrow={1} className={classes.pwdContainer}>
                <InputWithEye
                  showPasswordCb={showPasswordCb}
                  touched={touched}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  values={values}
                  label="New Password"
                />
              </Box>
            </Box>
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button
                onClick={() => onClick(values)}
                className={classes.changeButton}
              >
                Change Password
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  )
}
