import { Box, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import NoInternet from 'src/assets/images/errors/somethingWentWrong.svg'

export const useStyles = makeStyles()(() => ({
  centerdiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '400px',
  },
}))
export function NoConnection() {
  const { classes } = useStyles()
  return (
    <Box>
      <Box className={classes.centerdiv}>
        <Box>
          <img src={NoInternet} alt="connection error" />
        </Box>
        <Box ml={2}>
          <Typography>Service currently not available.</Typography>
        </Box>
      </Box>
    </Box>
  )
}
