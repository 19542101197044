import React, { useRef, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useSelector } from 'react-redux'

const useStyles = makeStyles()(() => ({
  title: {
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '22px',
    color: '#263238',
  },
  text: {
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#263238',
  },
  active: {
    backgroundColor: '#FBFDB0',
  },
}))

function HelpItem({ active = false, title = '', text = '', scrollRef }) {
  const { classes, cx } = useStyles()
  const ref = useRef(null)
  const openDrawer = useSelector((state) => state?.help?.openDrawer) || false

  useEffect(() => {
    // scroll all help items to the active one so it's visible
    if (active && openDrawer && scrollRef) {
      // eslint-disable-next-line
      scrollRef.scrollTop = ref.current.offsetTop
    }
  }, [openDrawer, active, scrollRef])

  return (
    <Box padding={1} mb={1} className={cx(active && classes.active)} ref={ref}>
      <Box mb={1} className={classes.title}>
        <Typography variant="h5">{title}</Typography>
      </Box>
      <Box mb={1}>
        <Typography className={classes.text}>{text}</Typography>
      </Box>
    </Box>
  )
}

export default HelpItem
