import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Box, IconButton } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { X as CloseIcon } from 'react-feather'

const useStyles = makeStyles()(() => ({
  dialogTitleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // paddingLeft: 24,
  },
  dialogTitle: {
    fontFamily: 'PT-Sans',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '31px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#000000',
  },
}))

export default function ModalWrapper({ open, handleClose, title, Content }) {
  const { classes } = useStyles()
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        data-testid="confirmation-modal"
      >
        <DialogTitle id="alert-dialog-title">
          <Box className={classes.dialogTitleWrapper}>
            <Typography className={classes.dialogTitle}>{title}</Typography>
            <IconButton onClick={handleClose} size="large">
              <CloseIcon color="#263238" />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>{Content.body}</DialogContent>
      </Dialog>
    </div>
  )
}

ModalWrapper.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  Content: PropTypes.shape({ body: PropTypes.node.isRequired }).isRequired,
}
