import produce from 'immer'
import {
  UPDATE_FILTERS,
  CLOSE_SIDEBAR,
  OPEN_DRAWER,
  SET_RESULTS,
  FETCHING_ERROR,
  FETCHING_404,
  FETCHING,
  PAGE_CHANGE,
  SET_SIDEBAR_ABDA,
  ADD_KEYWORD,
  REMOVE_KEYWORD,
  SET_ALL_KEYWORDS,
} from './actionTypes'
const initialState = {
  results: [],
  fetching: false,
  fetchingError: false,
  filters: {
    search: null,
  },
  pagination: {
    page: 1,
    count: 0,
  },
  activeRow: null,
  currentAbda: null,
  keywords: [],
}
export default function BookedServicesReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case UPDATE_FILTERS:
      return produce(state, (draft) => {
        draft.filters[payload.target] = payload.value
        draft.pagination.page = 1
      })
    case CLOSE_SIDEBAR:
      return produce(state, (draft) => {
        draft.activeRow = null
        draft.openDrawer = false
      })
    case PAGE_CHANGE:
      return produce(state, (draft) => {
        draft.pagination.page = payload
      })

    case OPEN_DRAWER:
      return produce(state, (draft) => {
        draft.openDrawer = true
        draft.activeRow = payload
      })
    case SET_RESULTS:
      return produce(state, (draft) => {
        draft.results = payload.data
        draft.pagination.count = payload.count
        draft.fetching = false
        draft.fetchingError = false
      })
    case FETCHING:
      return produce(state, (draft) => {
        draft.fetching = true
        draft.fetchingError = true
      })
    case FETCHING_404:
      return produce(state, (draft) => {
        draft.fetching = false
        draft.fetchingError = false
        draft.results = []
      })
    case FETCHING_ERROR:
      return produce(state, (draft) => {
        draft.fetchingError = true
        draft.fetching = false
      })
    case SET_SIDEBAR_ABDA:
      return produce(state, (draft) => {
        draft.currentAbda = payload
      })
    case ADD_KEYWORD:
      return produce(state, (draft) => {
        if (draft.currentAbda && Array.isArray(draft.currentAbda)) {
          draft.currentAbda.push(payload)
        } else {
          draft.currentAbda = [payload]
        }
      })
    case REMOVE_KEYWORD:
      return produce(state, (draft) => {
        draft.currentAbda = state.currentAbda.filter((kw) => kw.id !== payload)
      })
    case SET_ALL_KEYWORDS:
      return produce(state, (draft) => {
        draft.keywords = payload
      })
    default:
      return state
  }
}
