function generateActionType(type) {
  return `@damWizShops/${type}`
}

// general
export const LOAD_STRATEGY = generateActionType('LOAD_STRATEGY')

// network
export const FETCHING_SHOPS_SUCCESS = generateActionType(
  'FETCHING_SHOPS_SUCCESS',
)
export const FETCHING_SHOPS = generateActionType('FETCHING_SHOPS')
export const FETCHING_SHOPS_ERROR = generateActionType('FETCHING_SHOPS_ERROR')

// filters
export const SET_ACTIVE_SHOP_FILTER = generateActionType(
  'SET_ACTIVE_SHOP_FILTER',
)
export const FILTER_SHOPS = generateActionType('FILTER_SHOPS')

export const SELECT_MANY_SHOPS = generateActionType('SELECT_MANY_SHOPS')
export const UNSELECT_ALL_SHOPS = generateActionType('UNSELECT_ALL_SHOPS')
export const SELECT_ONE_SHOP = generateActionType('SELECT_ONE_SHOP')
export const UNSELECT_ONE_SHOP = generateActionType('UNSELECT_ONE_SHOP')
export const SELECT_TEST_EMAIL = generateActionType('SELECT_TEST_EMAIL')
export const UNSELECT_TEST_EMAIL = generateActionType('UNSELECT_TEST_EMAIL')
export const DISABLE_TEST_EMAIL_SELECTION = generateActionType(
  'DISABLE_TEST_EMAIL_SELECTION',
)
export const ENABLE_TEST_EMAIL_SELECTION = generateActionType(
  'ENABLE_TEST_EMAIL_SELECTION',
)
