import React from 'react'
// import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client'
import { Integrations } from '@sentry/tracing'
import * as Sentry from '@sentry/react'
import { SERVER_ERROR } from 'src/constants/errorPageConstants'
import ErrorBoundary from 'src/components/ErrorBoundary'
import App from './App'
import CacheBuster from 'react-cache-buster'
import packageInfo from '../package.json'
// import { Provider } from 'react-redux'
import { AppProviders } from './AppProviders'
import 'src/assets/fonts/PT_Sans/PTSans-Regular.ttf'
import './index.css'

// TODO move dsn to env
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    // leaving dsn in repo because we need to figure a way to keep env variables available in jenkins and saved somewhere on server like for backend
    // that will require deployment changes which is not in scope of this task SBDT-1079
    dsn: 'https://07a2839f87cd4e43b761ca4b4c2c5210@o462001.ingest.sentry.io/5464674',
    integrations: [new Integrations.BrowserTracing()],

    tracesSampleRate: 1.0,
  })
}

const Providers = () => (
  <AppProviders>
    <ErrorBoundary type={SERVER_ERROR}>
      <CacheBuster
        currentVersion={packageInfo.version}
        isEnabled={process.env.NODE_ENV === 'production'} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
      >
        <App />
      </CacheBuster>
    </ErrorBoundary>
  </AppProviders>
)

const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(<Providers />)

// ReactDOM.render(<Providers />, document.getElementById('root'))
