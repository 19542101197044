function generateActionType(type) {
  return `@damPzn/${type}`
}

export const DELETE_ALL_PACKSHOTS = generateActionType('DELETE_ALL_PACKSHOTS')
export const FETCHING_IN_PROGRESS = generateActionType('FETHING_IN_PROGRESS')
export const FETCHING_ERROR = generateActionType('FETCHING_ERROR')
export const FETCHING_SUCCESS = generateActionType('FETCHING_SUCCESS')
export const UPDATE_FILTERS = generateActionType('UPDATE_FILTERS')
export const RESET_FILTERS = generateActionType('RESET_FILTERS')
export const PAGE_CHANGE = generateActionType('PAGE_CHANGE')
export const POPULATE_DROPDOWN_VALUES = generateActionType(
  'POPULATE_DROPDOWN_VALUES',
)
export const UPLOAD_PACKSHOTS_SUCCESS = generateActionType(
  'UPLOAD_PACKSHOTS_SUCCESS',
)
export const SET_UPLOADING_PACKSHOTS = generateActionType(
  'SET_UPLOADING_PACKSHOTS',
)
export const UPLOADING_PACKSHOTS_ERROR = generateActionType(
  'UPLOADING_PACKSHOTS_ERROR',
)
export const DELETE_PACKSHOT = generateActionType('DELETE_PACKSHOT')
export const DELETE_TEXTFILES = generateActionType('DELETE_TEXTFILES')
export const FETCHING_PACKSHOTS = generateActionType('FETCHING_PACKSHOTS')
export const FETCHING_PACKSHOTS_SUCCESS = generateActionType(
  'FETCHING_PACKSHOTS_SUCCESS',
)
export const FETCHING_PACKSHOTS_ERROR = generateActionType(
  'FETCHING_PACKSHOTS_ERROR',
)
export const UPDATE_PACKSHOT_NAME = generateActionType('UPDATE_PACKSHOT_NAME')
export const FETCHING_TEXTS = generateActionType('FETCHING_TEXTS')
export const FETCHING_TEXTS_ERROR = generateActionType('FETCHING_TEXTS_ERROR')
export const FETCHING_TEXTS_SUCCESS = generateActionType(
  'FETCHING_TEXTS_SUCCESS',
)
export const OPEN_ROW = generateActionType('OPEN_ROW')
export const CLOSE_SIDEBAR = generateActionType('CLOSE_SIDEBAR')
export const UPDATE_TEXTFILE_NAME = generateActionType('UPDATE_TEXTFILE_NAME')
export const UPLOADING_TEXTFILES = generateActionType('UPLOADING_TEXTFILES')
export const UPLOADING_TEXTFILES_SUCCESS = generateActionType(
  'UPLOADING_TEXTFILES_SUCCESS',
)
export const UPLOADING_TEXTFILES_ERROR = generateActionType(
  'UPLOADING_TEXTFILES_ERROR',
)
export const OPEN_DRAWER = generateActionType('OPEN_DRAWER')
export const UPDATE_ACTIVE_TAB = generateActionType('UPDATE_ACTIVE_TAB')
export const FETCH_FOUND_PACKSHOTS_REQUEST = generateActionType(
  'FETCH_FOUND_PACKSHOTS_REQUEST',
)
export const FETCH_FOUND_PACKSHOTS_SUCCESS = generateActionType(
  'FETCH_FOUND_PACKSHOTS_SUCCESS',
)
export const FETCH_FOUND_PACKSHOTS_ERROR = generateActionType(
  'FETCH_FOUND_PACKSHOTS_ERROR',
)
export const VALIDATE_PACKSHOT_CONFIRM = generateActionType(
  'VALIDATE_PACKSHOT_CONFIRM',
)
export const VALIDATE_PACKSHOT_INVALID = generateActionType(
  'VALIDATE_PACKSHOT_INVALID',
)
