/* eslint-disable no-param-reassign */
import produce from 'immer'
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  LOGOUT_IDLE,
  SILENT_LOGIN,
  UPDATE_PROFILE,
  SET_RECOVERY_CREDENTIALS,
} from 'src/actionTypes/accountActionTypes'

const getScopesTitles = (user = {}) =>
  user?.scopes.map(({ scope }) => scope.toLowerCase()) || []

const initialState = {
  user: null,
  logoutIdle: false,
  recoveryCredentials: null,
}

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.user = null
        draft.logoutIdle = false
      })
    }

    case LOGIN_SUCCESS: {
      return produce(state, (draft) => {
        const { user } = action.payload
        const scopes = getScopesTitles(user)
        const isAdmin =
          user?.roles?.filter((role) => role.name === 'admin').length > 0 ||
          false
        draft.user = { ...user, scopesTitles: scopes, isAdmin }
        draft.logoutIdle = false
      })
    }

    case LOGIN_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      })
    }

    case LOGOUT: {
      return produce(state, (draft) => {
        draft.user = null
        draft.logoutIdle = false
      })
    }

    case LOGOUT_IDLE: {
      return produce(state, (draft) => {
        draft.user = null
        draft.logoutIdle = true
      })
    }

    case SILENT_LOGIN: {
      return produce(state, (draft) => {
        const { user } = action.payload
        const scopes = getScopesTitles(user)
        const isAdmin =
          user?.roles?.filter((role) => role.name === 'admin').length > 0 ||
          false
        draft.user = { ...user, scopesTitles: scopes, isAdmin }
        draft.logoutIdle = false
      })
    }

    case UPDATE_PROFILE: {
      return produce(state, (draft) => {
        const { user } = action.payload
        draft.user = user
      })
    }

    case SET_RECOVERY_CREDENTIALS: {
      return produce(state, (draft) => {
        const recoveryCreds = action.payload
        draft.recoveryCredentials = recoveryCreds
      })
    }
    default: {
      return state
    }
  }
}

export default accountReducer
