import { Box } from '@mui/material'
import BasicGreyButton from 'src/components/BasicGreyButton'
import { makeStyles } from 'tss-react/mui'
import { useDispatch, useSelector } from 'react-redux'
import { getAllCurrentKatsollDetails } from '../lib/selectors'
import { deleteAllKatsoll } from '../lib/actions'

const useStyles = makeStyles()(() => ({
  btn: {
    width: '130px',
    height: '38px',
  },
}))

export default function RemoveAllKatsoll() {
  const { classes } = useStyles()
  const dispatch = useDispatch()
  const currentKatsoll = useSelector(getAllCurrentKatsollDetails)
  const onClick = () => {
    const ids = Object.values(currentKatsoll)
      .flat()
      .map((k) => k.categoryId)
    dispatch(deleteAllKatsoll(ids))
  }

  return (
    <Box>
      <BasicGreyButton
        classes={{ root: classes.btn }}
        onClick={onClick}
        buttonText="remove all"
      />
    </Box>
  )
}
