import produce from 'immer'
import {
  SET_LETTERS,
  FETCHING_LETTER,
  FETCHING_LETTER_ERROR,
  UPDATE_FILTERS,
  PAGE_CHANGE,
  IMPORT_SHOPS,
  CLOSE_SIDEBAR,
  SELECT_ROW,
  UPDATE_SIDEBAR_VALUE,
  OPEN_DRAWER,
  ACTIVATE_ADDING_MODE,
  ADD_NEW_NEWSLETTER,
  UPDATE_NEWSLETTER,
  DELETE_LETTER,
  FETCHING_LETTER_404,
} from 'src/actionTypes/newsletterConfigActionTypes'
const initialState = {
  letters: [],
  fetchingLetters: false,
  fetchingLettersError: false,
  filters: {
    shop: null,
  },
  shops: [],
  pagination: {
    page: 1,
    count: 0,
  },
  activeRow: null,
  openDrawer: false,
  addingMode: false,
}
export default function newsletterReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case FETCHING_LETTER:
      return produce(state, (draft) => {
        draft.fetchingLettersError = false
        draft.fetchingLetters = true
      })
    case FETCHING_LETTER_ERROR:
      return produce(state, (draft) => {
        draft.fetchingLettersError = true
        draft.fetchingLetters = false
      })
    case FETCHING_LETTER_404:
      return produce(state, (draft) => {
        draft.fetchingLetters = false
        draft.fetchingLettersError = false
        draft.letters = []
      })
    case SET_LETTERS:
      return produce(state, (draft) => {
        const { data, count } = payload
        draft.letters = data
        draft.pagination.count = count
        draft.fetchingLetters = false
        draft.fetchingLettersError = false
      })
    case PAGE_CHANGE:
      return produce(state, (draft) => {
        draft.pagination.page = payload
      })
    case IMPORT_SHOPS:
      return produce(state, (draft) => {
        draft.shops = payload
      })
    case UPDATE_FILTERS:
      return produce(state, (draft) => {
        draft.filters[payload.type] = payload.value
        draft.pagination.page = 1
      })
    case SELECT_ROW:
      return produce(state, (draft) => {
        draft.activeRow = payload
        draft.openDrawer = true
        if (payload.shop) {
          draft.activeRow = {
            ...draft.activeRow,
            shop: { name: payload.shop, id: payload.domainId },
          }
        }
      })
    case CLOSE_SIDEBAR:
      return produce(state, (draft) => {
        draft.activeRow = null
        draft.openDrawer = false
        draft.addingMode = false
      })
    case UPDATE_SIDEBAR_VALUE:
      return produce(state, (draft) => {
        if (!draft.activeRow) {
          draft.activeRow = {}
        }
        draft.activeRow[payload.type] = payload.value
      })
    case UPDATE_NEWSLETTER:
      return produce(state, (draft) => {
        const indexOfTarget = draft.letters.findIndex(
          (letter) => letter.id === payload.id,
        )
        if (indexOfTarget > -1) {
          draft.letters[indexOfTarget] = payload
        }
      })
    case OPEN_DRAWER:
      return produce(state, (draft) => {
        draft.openDrawer = true
      })
    case ACTIVATE_ADDING_MODE:
      return produce(state, (draft) => {
        draft.addingMode = true
      })
    case ADD_NEW_NEWSLETTER:
      return produce(state, (draft) => {
        if (!draft.letters) {
          draft.letters = []
        }
        draft.letters = [...draft.letters, payload]

        if (draft?.activeRow?.newsletterUrl) {
          draft.activeRow.newsletterUrl = ''
        }
      })
    case DELETE_LETTER:
      return produce(state, (draft) => {
        draft.letters = draft.letters.filter((letter) => letter.id !== payload)
        draft.activeRow = null
        draft.openDrawer = false
        draft.addingMode = false
      })
    default:
      return state
  }
}
