function generateActionType(type) {
  return `@DIConf/${type}`
}

export const SELECT_ROW = generateActionType('SELECT_ROW')
export const SET_DATA = generateActionType('SET_DATA')
export const FETCHING_DATA = generateActionType('FETCHING_DATA')
export const FETCHING_DATA_ERROR = generateActionType('FETCHING_DATA_ERROR')
export const FETCHING_DATA_404 = generateActionType('FETCHING_DATA_404')
export const UPDATE_FILTERS = generateActionType('UPDATE_FILTERS')
export const IMPORT_SHOPS = generateActionType('IMPORT_SHOPS')
export const PAGE_CHANGE = generateActionType('PAGE_CHANGE')
export const CLOSE_SIDEBAR = generateActionType('CLOSE_SIDEBAR')
export const UPDATE_SIDEBAR_VALUE = generateActionType('UPDATE_SIDEBAR_VALUE')
export const OPEN_DRAWER = generateActionType('OPEN_DRAWER')
export const ACTIVATE_ADDING_MODE = generateActionType('ACTIVATE_ADDING_MODE')
export const ADD_NEW_INPUT = generateActionType('ADD_NEW_INPUT')
export const UPDATE_INPUT = generateActionType('UPDATE_INPUT')
export const DELETE_LETTER = generateActionType('DELETE_LETTER')
