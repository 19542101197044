import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  btn: {
    display: 'flex',
    backgroundColor: theme.palette.actionBlue.main,
    color: theme.palette.background.default,
    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.14)',
    borderRadius: '4px',
    boxSizing: 'border-box',
    padding: '6px 16px',
    '&:hover': {
      backgroundColor: theme.palette.actionBlue.main,
      opacity: 0.9,
    },
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '26px',
    cursor: 'pointer',
    fontFamily: theme.fonts.roboto,
  },
}))

export default function BasicBlueButton({ classes = {}, onClick, text }) {
  const { classes: defaultClasses, cx } = useStyles()

  return (
    <Box>
      <Button
        onClick={onClick}
        className={cx(defaultClasses.btn, classes.btn)}
        data-testid="blue-button"
      >
        {text}
      </Button>
    </Box>
  )
}

BasicBlueButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
}
