import React from 'react'
import { alpha } from '@mui/material'

import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: any) => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    alignItems: 'center',
    borderRadius: 2,
    display: 'inline-flex',
    flexGrow: 0,
    whiteSpace: 'nowrap',
    cursor: 'default',
    flexShrink: 0,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium,
    height: 20,
    justifyContent: 'center',
    letterSpacing: 0.5,
    minWidth: 20,
    padding: theme.spacing(0.5, 1),
    textTransform: 'uppercase',
  },
  primary: {
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
  },
  secondary: {
    color: theme.palette.secondary.main,
    backgroundColor: alpha(theme.palette.secondary.main, 0.08),
  },
  error: {
    color: theme.palette.error.main,
    backgroundColor: alpha(theme.palette.error.main, 0.08),
  },
  success: {
    color: theme.palette.success.main,
    backgroundColor: alpha(theme.palette.success.main, 0.08),
  },
  warning: {
    color: theme.palette.warning.main,
    backgroundColor: alpha(theme.palette.warning.main, 0.08),
  },
  blank: {
    // color: theme.palette.warning.main,
    backgroundColor: 'transparent',
    color: 'transparent',
  },
  gray: {
    color: '#263238',
    backgroundColor: '#ECEFF1',
  },
  white: {
    color: '#263238',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '12px',
    background: '#FFFFFF',
  },
}))

type Colors =
  | 'primary'
  | 'gray'
  | 'white'
  | 'secondary'
  | 'error'
  | 'warning'
  | 'success'
  | 'blank'

type LabelProps = {
  className?: string
  color?: Colors
  children?: React.ReactNode
  backgroundColor?: string
}

function Label({
  className,
  color = 'secondary',
  children,
  backgroundColor,
}: LabelProps) {
  const { classes, cx } = useStyles()

  return (
    <span
      className={cx(
        classes.root,
        //@ts-ignore
        {
          [classes[color]]: color,
        },
        className,
      )}
      style={{
        backgroundColor,
      }}
    >
      {children}
    </span>
  )
}

export default Label
