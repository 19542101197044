import React from 'react'
import TableListPage from 'src/components/TableList'
import TwoColDrawer from 'src/components/TwoColDrawer/TwoColDrawer'

import Results from './Results'
import Filters from './Filters'
import Pagination from './Pagination'
import Sidebar from './Sidebar'
import Header from './Header'

export default function AbdaKeywords() {
  const FilterPagination = <Pagination />

  return (
    <>
      <TwoColDrawer>
        <TableListPage pageTitle="Smile BI | ABDA Keywords">
          <Header />
          <Filters />
          <Results Pagination={FilterPagination} />
          <Pagination paginationWrapper hideOnFetch />
        </TableListPage>
        <Sidebar />
      </TwoColDrawer>
    </>
  )
}
