import produce from 'immer'
import {
  UPDATE_FILTERS,
  REMOVE_CHECKED_PZN,
  ADD_CHECKED_PZN,
  CLOSE_SIDEBAR,
  OPEN_DRAWER,
  SET_RESULTS,
  FETCHING_ERROR,
  FETCHING_404,
  FETCHING,
  PAGE_CHANGE,
  CLEAR_SELECTED_KW,
  DEACTIVATE_KEYWORDS,
  SET_IMPORTED_SERVICES,
  ADD_NEW_SERVICE,
} from './actionTypes'
const initialState = {
  results: [],
  fetching: false,
  fetchingError: false,
  filters: {
    search: null,
  },
  deletingModeActive: false,
  pagination: {
    page: 1,
    count: 0,
  },
  activeRow: null,
  services: [],
}
export default function BookedServicesReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case UPDATE_FILTERS:
      return produce(state, (draft) => {
        draft.filters[payload.target] = payload.value
        draft.pagination.page = 1
      })
    case REMOVE_CHECKED_PZN:
      return produce(state, (draft) => {
        draft.checked = draft.checked.filter((pzn) => pzn.id !== payload.id)
        delete draft.checkedMap[payload.id]
      })
    case ADD_CHECKED_PZN:
      return produce(state, (draft) => {
        draft.checked.push(payload)
        draft.checkedMap[payload.id] = true
      })
    case CLEAR_SELECTED_KW:
      return produce(state, (draft) => {
        draft.checked = []
        draft.checkedMap = {}
      })
    case CLOSE_SIDEBAR:
      return produce(state, (draft) => {
        draft.activeRow = null
        draft.openDrawer = false
      })
    case PAGE_CHANGE:
      return produce(state, (draft) => {
        draft.pagination.page = payload
      })
    case DEACTIVATE_KEYWORDS:
      return produce(state, (draft) => {
        const deactivated = draft.kwds.map((kw) => {
          const cp = { ...kw }
          if (draft.checkedMap[kw.id]) {
            cp.active = 0
          }
          return cp
        })
        draft.kwds = deactivated
      })
    case OPEN_DRAWER:
      return produce(state, (draft) => {
        draft.openDrawer = true
      })
    case SET_RESULTS:
      return produce(state, (draft) => {
        draft.results = payload.data
        draft.pagination.count = payload.count
        draft.fetching = false
        draft.fetcingError = false
      })
    case FETCHING:
      return produce(state, (draft) => {
        draft.fetching = true
        draft.fetcingError = true
      })
    case FETCHING_404:
      return produce(state, (draft) => {
        draft.fetching = false
        draft.fetcingError = false
        draft.results = []
      })
    case FETCHING_ERROR:
      return produce(state, (draft) => {
        draft.fetcingError = true
        draft.fetching = false
      })
    case SET_IMPORTED_SERVICES:
      return produce(state, (draft) => {
        draft.services = payload
      })
    case ADD_NEW_SERVICE:
      return produce(state, (draft) => {
        draft.results.services.push(payload)
        draft.activeRow = null
        draft.openDrawer = false
      })
    default:
      return state
  }
}
