import React, { useEffect } from 'react'
import { TextField } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import debounce from 'lodash/debounce'
import Autocomplete from '@mui/material/Autocomplete'
import { useSelector } from 'react-redux'

const useStyles = makeStyles()((theme) => ({
  input: {
    [theme.breakpoints.up('lg')]: {
      width: 210,
    },
    [theme.breakpoints.down('lg')]: {
      flexGrow: 1,
      marginBottom: 5,
      marginTop: 5,
    },
  },
  openDrawer: {
    width: '100%',
  },
}))

function InputComponent({
  handleChange,
  openDrawer,
  selected,
  label = 'no label',
  selectedItemSelector,
  allOptionsSelector,
  getLabel,
  id,
  onEffect,
}) {
  const { classes, cx } = useStyles()
  const options = useSelector(allOptionsSelector)
  const selectedItem = useSelector(selectedItemSelector)

  useEffect(() => {
    if (onEffect) {
      onEffect()
    }
  }, [onEffect])

  const onChange = (e, newValue) => {
    handleChange(newValue)
  }

  return (
    <Autocomplete
      className={cx(classes.input, openDrawer && classes.openDrawer)}
      id={id || 'shops'}
      options={options}
      getOptionLabel={getLabel}
      isOptionEqualToValue={selected}
      value={selectedItem || null}
      onChange={onChange}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="outlined" />
      )}
    />
  )
}

export default function InputContainer(props) {
  const { onChange } = props

  const handleChange = (value) => {
    onChange(value)
  }
  const handleChangeDebounced = debounce(handleChange, 200)
  return <InputComponent {...props} handleChange={handleChangeDebounced} />
}
