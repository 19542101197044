import React, { cloneElement, useContext, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { AppStateContext } from 'src/AppProviders'

const useStyles = (appState) =>
  makeStyles()((theme) => ({
    leftColOpenDrawer: {
      [theme.breakpoints.up('sm')]: {
        maxWidth: `calc(50% - ${
          appState.navBarOpen
            ? theme.navBar.openWidth / 2 - theme.spacing(5)
            : theme.spacing(2) * -1
        }px)`,
      },
    },
  }))

const TwoColDrawer = ({ children, drawerDesktopWidth = '50%' }) => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [appState] = useContext(AppStateContext)
  const { classes } = useStyles(appState, {
    props: appState,
  })()

  if (children.length !== 2) return { children }

  const openDrawerCallback = (open) => {
    setDrawerOpen(open)
  }

  const [leftCol, rightCol] = children
  const rightColProps = { drawerDesktopWidth, openDrawerCallback }
  const leftColProps = {
    className: drawerOpen ? classes.leftColOpenDrawer : undefined,
  }

  return (
    <>
      {cloneElement(leftCol, leftColProps)}
      {cloneElement(rightCol, rightColProps)}
    </>
  )
}

export default TwoColDrawer
