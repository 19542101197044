import React from 'react'
import { Box, Card, Typography } from '@mui/material'

import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(() => ({
  card: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 500,
    whiteSpace: 'normal',
  },
  bigTitle: {
    fontFamily: 'PT-Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '21px',
    alignItems: 'center',
    textAlign: 'center',
  },
}))

function InvalidSearch() {
  const { classes } = useStyles()
  return (
    <Card className={classes.card} data-testid="error-screen">
      <Box p={1}>
        <Typography className={classes.bigTitle}>
          Please search with 3 or more characteristics.
        </Typography>
      </Box>
    </Card>
  )
}

export default InvalidSearch
