import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

// const routes = {
//   amazon: '/app/management/amazon',
//   bundles: '/app/management/bundles',
//   wkz: '/app/management/wkz-media',
//   dam: '/app/management/dam/pzn',
//   pacrating: '/app/management/packshots-rating',
//   descrating: '/app/management/description-rating',
//   smilecustomers: '/app/management/customers',
//   newsletter: '/app/management/newsletter-config',
//   keyword: '/app/management/keywords',
//   mankeywords: '/app/management/keywords-management',
//   abdakeywords: '/app/management/abda-keywords',
//   category: '/app/management/katsoll',
//   googleshopping: '/app/management/gshopping',
//   mymarkets: '/app/management/my-markets',
//   brand: '/app/management/brands-management',
//   ondemandcrawling: '/app/management/on-demand-crawling',
//   ondemandschedule: '/app/users/on-demand-scheduler',
//   avbanalytics: '/app/management/availability-analytics',
// }
/**
 * Component that checks for users scope and then redirects user to it's first scope topic
 * before we had it hardcoded and since we don't have dashboard page this is substitution for now
 * FEEL FREE TO DELETE THIS WHEN WE MAKE DASHBOARD PAGE
 */
export default function Redirecter() {
  const { user } = useSelector((state) => state.account)
  const history = useHistory()

  useEffect(() => {
    if (!user) {
      // will force user trough AuthGuard, and trigger funtionality
      return history.push('/app/management/amazon')
    }

    // let redirectTo = '/404'
    // for (let i = 0; i < user.scopesTitles.length; i += 1) {
    //   const scope = user.scopesTitles[i].toLowerCase()
    //   if (routes[scope]) {
    //     redirectTo = routes[scope]
    //     break
    //   }
    // }
    history.push('/app/home')
    return () => {}
  }, [history, user])

  return <></>
}
