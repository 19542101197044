import produce from 'immer'
import uniqBy from 'lodash/uniqBy'
import { threadTemplate } from './constants'
import {
  UPDATE_FILTERS,
  CLOSE_SIDEBAR,
  OPEN_DRAWER,
  SET_RESULTS,
  FETCHING_ERROR,
  FETCHING_404,
  FETCHING,
  PAGE_CHANGE,
  SET_SIDEBAR_ABDA,
  REMOVE_KATSOLL,
  IMPORT_SHOPS,
  UPDATE_SIDEBAR_VALUE,
  IMPORT_CATEGORIES,
  ADD_NEW_KATSOLL,
  RESET_SETTINGS,
  IMPORT_MANUFACTURERS,
  IMPORT_BRANDS,
  REMOVE_CHECKED_PZN,
  ADD_CHECKED_PZN,
  UPDATE_ADD_TO_ALL,
  DELETE_MANY,
  DELETE_ALL,
  TRACK_REJECTED_CATEGORIES,
  REMOVE_TRACKED,
} from './actionTypes'
const initialState = {
  results: [],
  fetching: false,
  fetchingError: false,
  filters: {
    search: null,
  },
  pagination: {
    page: 1,
    count: 0,
  },
  activeRow: null,
  currentKatSollDetails: {},
  shops: [],
  brands: [],
  manufacturers: [],
  categories: [],
  checkedMap: new Map(),
  checked: [],
  sidebarThreads: {},
  addToAll: {},
  rejected: [],
}
export default function BookedServicesReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case UPDATE_FILTERS:
      return produce(state, (draft) => {
        draft.filters[payload.target] = payload.value
        draft.pagination.page = 1
      })

    case IMPORT_MANUFACTURERS:
      return produce(state, (draft) => {
        draft.manufacturers = payload
      })
    case IMPORT_BRANDS:
      return produce(state, (draft) => {
        draft.brands = payload
      })
    case CLOSE_SIDEBAR:
      return produce(state, (draft) => {
        draft.activeRow = null
        draft.openDrawer = false
      })
    case PAGE_CHANGE:
      return produce(state, (draft) => {
        draft.pagination.page = payload
      })

    case OPEN_DRAWER:
      return produce(state, (draft) => {
        draft.openDrawer = true
        // draft.activeRow = payload
        draft.currentKatSoll = null
        draft.categories = []
      })
    case SET_RESULTS:
      return produce(state, (draft) => {
        draft.results = payload.data
        draft.pagination.count = payload.count
        draft.fetching = false
        draft.fetchingError = false
      })
    case FETCHING:
      return produce(state, (draft) => {
        draft.fetching = true
        draft.fetchingError = true
      })
    case FETCHING_404:
      return produce(state, (draft) => {
        draft.fetching = false
        draft.fetchingError = false
        draft.results = []
      })
    case FETCHING_ERROR:
      return produce(state, (draft) => {
        draft.fetchingError = true
        draft.fetching = false
      })
    case SET_SIDEBAR_ABDA:
      return produce(state, (draft) => {
        const { subBrandId, data } = payload
        draft.currentKatSollDetails[subBrandId] = data
      })
    case REMOVE_KATSOLL:
      return produce(state, (draft) => {
        const { subBrandId, categoryId } = payload
        draft.currentKatSollDetails[subBrandId] = state.currentKatSollDetails[
          subBrandId
        ].filter((kw) => kw.categoryId !== categoryId)
      })
    case DELETE_MANY:
      return produce(state, (draft) => {
        const { subBrandId, forDeletion } = payload
        draft.currentKatSollDetails[subBrandId] = state.currentKatSollDetails[
          subBrandId
        ].filter((kw) => !forDeletion.includes(kw.categoryId))
      })
    case DELETE_ALL:
      return produce(state, (draft) => {
        draft.currentKatSollDetails = initialState.currentKatSollDetails
      })
    case IMPORT_SHOPS:
      return produce(state, (draft) => {
        draft.shops = payload
      })
    case IMPORT_CATEGORIES:
      return produce(state, (draft) => {
        draft.categories = payload
      })
    case UPDATE_SIDEBAR_VALUE:
      return produce(state, (draft) => {
        if (!draft.activeRow) {
          draft.activeRow = {}
        }
        draft.sidebarThreads[payload.target][payload.type] = payload.value
      })
    case UPDATE_ADD_TO_ALL:
      return produce(state, (draft) => {
        draft.addToAll[payload.type] = payload.value
      })
    case ADD_NEW_KATSOLL:
      return produce(state, (draft) => {
        const { data, subBrandId } = payload
        if (
          draft.currentKatSollDetails[subBrandId] &&
          Array.isArray(draft.currentKatSollDetails[subBrandId])
        ) {
          draft.currentKatSollDetails[subBrandId].push(data)
        } else {
          draft.currentKatSollDetails[subBrandId] = [data]
        }
      })
    case RESET_SETTINGS:
      // eslint-disable-next-line arrow-body-style
      return produce(state, (draft) => {
        draft.activeRow = initialState.activeRow
        draft.pagination = initialState.pagination
        draft.results = initialState.results
        draft.openDrawer = initialState.openDrawer
      })
    case REMOVE_CHECKED_PZN:
      return produce(state, (draft) => {
        if (Array.isArray(payload)) {
          payload.forEach((p) => draft.checkedMap.delete(p.subBrandId))
          draft.checked = state.checked.filter((pzn) =>
            draft.checkedMap.has(pzn.subBrandId),
          )
          state.checked.forEach(
            (pzn) => delete draft.sidebarThreads[pzn.subBrandId],
          )
        } else {
          draft.checked = draft.checked.filter(
            (pzn) => pzn.subBrandId !== payload.subBrandId,
          )
          draft.checkedMap.delete(payload.subBrandId)
          delete draft.sidebarThreads[payload.subBrandId]
        }
      })
    case ADD_CHECKED_PZN:
      return produce(state, (draft) => {
        if (Array.isArray(payload)) {
          const deduplicated = uniqBy(
            [...state.checked, ...payload],
            (element) => element.subBrandId,
          )
          draft.checked = deduplicated
          payload.forEach((p) => draft.checkedMap.set(p.subBrandId, p))
          payload.forEach(
            (p) => (draft.sidebarThreads[p.subBrandId] = threadTemplate),
          )
        } else {
          draft.checked.push(payload)
          draft.checkedMap.set(payload.subBrandId, payload)
          draft.sidebarThreads[payload.subBrandId] = threadTemplate
        }
      })
    case TRACK_REJECTED_CATEGORIES:
      return produce(state, (draft) => {
        draft.rejected = payload
      })
    case REMOVE_TRACKED:
      return produce(state, (draft) => {
        draft.rejected = []
      })
    default:
      return state
  }
}
