import { useDispatch } from 'react-redux'
import { Box } from '@mui/material'
import Filters from 'src/components/BasicFilters'

import { importAllKeywords } from '../lib/actions'
import Search from './SearchInput'

const config = [{ Component: Search, visible: true }]

const AbdaKeywordsFilters = () => {
  const dispatch = useDispatch()
  const onEffect = () => dispatch(importAllKeywords())
  return (
    <Box>
      <Filters filtersConfig={config} onEffect={onEffect} />
    </Box>
  )
}

export default AbdaKeywordsFilters
