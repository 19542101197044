import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TableWrapper } from 'src/components/TableList'
import { useSnackbar } from 'notistack'
import useRetry from 'src/hooks/useRetry'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  CircularProgress,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import ErrorPageByType from 'src/views/pages/ErrorPageByType'
import { SERVER_ERROR, NO_RESULTS } from 'src/constants/errorPageConstants'
import useCancelToken from 'src/hooks/useCancelToken'
import {
  getResults,
  getFetchingStatus,
  getParams,
  getActiveRow,
} from './lib/selectors'
import { getAbdaKeywords, openDrawer } from './lib/actions'
const useStyles = makeStyles()(() => ({
  selected: {
    '& td': {
      color: '#FFFFFF',
    },
    backgroundColor: '#4472C4 !important',
    '&:hover': {
      backgroundColor: '#4472C4',
    },
  },
  limitText: {
    textOverflow: 'ellipsis',
    maxWidth: '450px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}))

export default function Results({ Pagination }) {
  const dispatch = useDispatch()
  const { classes } = useStyles()
  const { source } = useCancelToken()
  const { enqueueSnackbar } = useSnackbar()
  const [retry, setRetry, retryLimitReached] = useRetry()
  const results = useSelector(getResults)
  const { fetching, error } = useSelector(getFetchingStatus)
  const activeRow = useSelector(getActiveRow)
  const { page, filters } = useSelector(getParams)
  useEffect(() => {
    dispatch(getAbdaKeywords({ page, filters, source }))

    return () => {
      source.cancel('Component got unmounted')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filters, retry])

  useEffect(() => {
    if (retryLimitReached) {
      enqueueSnackbar('Retry limit reached. Please try later.', {
        variant: 'error',
      })
    }
  }, [retryLimitReached, enqueueSnackbar])

  const onRetry = () => {
    setRetry((prevState) => !prevState)
  }

  const handleRowClick = (row) => {
    dispatch(openDrawer(row))
  }

  if (fetching) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignContent="center"
        marginBottom={4}
        marginTop={4}
        data-testid="loading-screen"
      >
        <CircularProgress color="secondary" disableShrink />
      </Box>
    )
  }

  if (!results.length && !fetching) {
    return <ErrorPageByType type={NO_RESULTS} />
  }
  if (error) {
    return <ErrorPageByType type={SERVER_ERROR} onRetry={onRetry} />
  }

  return (
    <TableWrapper Pagination={Pagination}>
      <Table data-testid="sending-history-results">
        <TableHead>
          <TableRow>
            <TableCell>ABDA</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.length > 0 &&
            results.map((row) => (
              <TableRow
                hover
                key={row.abdaPodGrp}
                selected={activeRow?.abdaPodGrp === row.abdaPodGrp}
                onClick={() => handleRowClick(row)}
                classes={{
                  selected: classes.selected,
                }}
              >
                <TableCell>{row.abdaPodGrp}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableWrapper>
  )
}
