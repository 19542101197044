import axios from 'src/utils/axios'
import authService from 'src/services/authService'
import { addInternalUserDataLayer } from 'src/utils/setupDataLayer'

import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  SILENT_LOGIN,
  LOGOUT,
  LOGOUT_IDLE,
  UPDATE_PROFILE,
  SET_RECOVERY_CREDENTIALS,
} from 'src/actionTypes/accountActionTypes'
import { errorHandler } from 'src/utils/errorHandler'

export function login(email, password) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGIN_REQUEST })
      const user = await authService.loginWithEmailAndPassword(email, password)

      addInternalUserDataLayer(user)
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user,
        },
      })
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE })
      throw error
    }
  }
}

export function setUserData(user) {
  return (dispatch) =>
    dispatch({
      type: SILENT_LOGIN,
      payload: {
        user,
      },
    })
}

export function logout() {
  return async (dispatch) => {
    authService.logout()

    dispatch({
      type: LOGOUT,
    })
  }
}

export function logoutIdle() {
  return async (dispatch) => {
    dispatch({
      type: LOGOUT_IDLE,
    })
    authService.logout()
  }
}

export function register() {
  return true
}

export function updateProfile(update) {
  const request = axios.post('/api/account/profile', { update })

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: UPDATE_PROFILE,
        payload: response.data,
      }),
    )
  }
}

export async function changePassword({ oldPassword, newPassword }) {
  try {
    const { data } = await axios.post('/auth/reset-password', {
      oldPassword,
      newPassword,
    })
    authService.setSession(data.accessToken)
  } catch (e) {
    if (e?.response?.status === 409) {
      throw new Error('Invalid old password')
    }
    errorHandler(e, {})
  }
}

export async function getDataFromHash(hash) {
  try {
    const { data } = await axios.get(`auth/user/account/${hash}`)
    return data
  } catch (error) {
    return errorHandler(error, {})
  }
}

export async function setNewPassword(password, id) {
  try {
    const params = {
      password,
      id,
    }
    await axios.post('/auth/user/create-new-password', params)
    return null
  } catch (error) {
    return errorHandler(error, {})
  }
}

export const setRecoveryCredentials = (creds) => (dispatch) =>
  dispatch({ type: SET_RECOVERY_CREDENTIALS, payload: creds })
