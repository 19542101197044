function generateActionType(type) {
  return `@customerPanel/${type}`
}

export const SELECT_ROW = generateActionType('SELECT_ROW')
export const SET_CUSTOMERS = generateActionType('SET_CUSTOMERS')
export const FETCHING_CUSTOMERS = generateActionType('FETCHING_CUSTOMERS')
export const FETCHING_CUSTOMERS_ERROR = generateActionType(
  'FETCHING_CUSTOMERS_ERROR',
)
export const CLOSE_SIDEBAR = generateActionType('CLOSE_SIDEBAR')
export const CLOSE_ALL_ACCOUNTS_SIDEBAR = generateActionType(
  'CLOSE_ALL_ACCOUNTS_SIDEBAR',
)
export const OPEN_ALL_ACCOUNTS_SIDEBAR = generateActionType(
  'OPEN_ALL_ACCOUNTS_SIDEBAR',
)

export const IMPORT_SHOPS = generateActionType('IMPORT_SHOPS')
export const UPDATE_FILTERS = generateActionType('UPDATE_FILTERS')
export const CHECK_SHOPS = generateActionType('CHECK_SHOPS')
export const IMPORT_BRANDS = generateActionType('IMPORT_BRANDS')
export const CHECK_BRAND = generateActionType('CHECK_BRAND')
export const UNCHECK_BRAND = generateActionType('UNCHECK_BRAND')
export const UNCHECK_OPTIMISTIC = generateActionType('UNCHECK_OPTIMISTIC')
export const LOADING_BRANDS = generateActionType('LOADING_BRANDS')
export const LOADING_BRANDS_ERROR = generateActionType('LOADING_BRANDS_ERROR')
export const SET_ONLY_BRANDS = generateActionType('SET_ONLY_BRANDS')
export const SET_STATUS_QUO_RESTRICTIONS = generateActionType(
  'SET_STATUS_QUO_RESTRICTIONS',
)
export const SET_TRENDS_RESTRICTIONS = generateActionType(
  'SET_TRENDS_RESTRICTIONS',
)
export const SET_PRICE_RESTRICTIONS = generateActionType(
  'SET_PRICE_RESTRICTIONS',
)
export const SET_WKZ_APP_RESTRICTIONS = generateActionType(
  'SET_WKZ_APP_RESTRICTIONS',
)
export const FETCHING_RESTRICTIONS = generateActionType('FETCHING_RESTRICTIONS')
export const FETCHING_RESTRICTIONS_ERROR = generateActionType(
  'FETCHING_RESTRICTIONS_ERROR',
)
export const UPDATE_STATUS_QUO = generateActionType('UPDATE_STATUS_QUO')
export const UPDATE_TRENDS = generateActionType('UPDATE_TRENDS')
export const UPDATE_PRICE = generateActionType('UPDATE_PRICE')
export const UPDATE_WKZ_APP = generateActionType('UPDATE_WKZ_APP')
export const LOADING_BOOKED_SERVICES = generateActionType(
  'LOADING_BOOKED_SERVICES',
)
export const LOADING_BOOKED_SERVICES_ERROR = generateActionType(
  'LOADING_BOOKED_SERVICES_ERROR',
)
export const IMPORT_BOOKED_SERVICES = generateActionType(
  'IMPORT_BOOKED_SERVICES',
)
export const SET_BOOKED_SERVICES_RESTRICTIONS = generateActionType(
  'SET_BOOKED_SERVICES_RESTRICTIONS',
)
export const SET_BOOKED_SERVICE_ISBOOKED_VAL = generateActionType(
  'SET_BOOKED_SERVICE_ISBOOKED_VAL',
)

export const CLEANUP = generateActionType('CLEANUP')
