import React from 'react'
import { Pagination } from 'src/components/TableList'
import { Box } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { pageChangeAction } from '../actions'
import { getPagination, getFetchingStatus } from '../selectors'

const KeywordMgmtPagination = (props) => {
  const dispatch = useDispatch()
  const { page, count } = useSelector(getPagination)
  const { fetching } = useSelector(getFetchingStatus)

  const pageChange = (event, newPage) => {
    dispatch(pageChangeAction(newPage))
  }

  return (
    <Box data-testid="newsletter-pagination">
      <Pagination
        fetching={fetching}
        count={Number(count)}
        onChange={pageChange}
        page={page}
        limit={50}
        filterPagination
        {...props}
      />
    </Box>
  )
}

export default KeywordMgmtPagination
