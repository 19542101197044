function generateActionType(type) {
  return `@kwMgmt/${type}`
}

export const SELECT_ROW = generateActionType('SELECT_ROW')
export const UPDATE_FILTERS = generateActionType('UPDATE_FILTERS')
export const ADD_CHECKED_PZN = generateActionType('ADD_CHECKED_PZN')
export const REMOVE_CHECKED_PZN = generateActionType('REMOVE_CHECKED_PZN')
export const CLOSE_SIDEBAR = generateActionType('CLOSE_SIDEBAR')
export const OPEN_DRAWER = generateActionType('OPEN_DRAWER')
export const SET_KEYWORDS = generateActionType('SET_KEYWORDS')
export const FETCHING_KEYWORDS = generateActionType('FETCHING_KEYWORDS')
export const FETCHING_KEYWORDS_404 = generateActionType('FETCHING_KEYWORDS_404')
export const PAGE_CHANGE = generateActionType('PAGE_CHANGE')
export const CLEAR_SELECTED_KW = generateActionType('CLEAR_SELECTED_KW')
export const DEACTIVATE_KEYWORDS = generateActionType('DEACTIVATE_KEYWORDS')
export const SET_REACH_FACTOR = generateActionType('SET_REACH_FACTOR')
export const ACTIVATE_DELETING_MODE = generateActionType(
  'ACTIVATE_DELETING_MODE',
)
export const DEACTIVATE_DELETING_MODE = generateActionType(
  'DEACTIVATE_DELETING_MODE',
)
export const FETCHING_KEYWORDS_ERROR = generateActionType(
  'FETCHING_KEYWORDS_ERROR',
)
