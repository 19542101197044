export const get = (obj, properties, defaultValue) => {
  if (!obj) return null
  let value = { ...obj }
  const props = properties.split('.')
  for (let i = 0; i < props.length; i += 1) {
    const prop = props[i]
    value = value[prop] || defaultValue
    if (value === defaultValue) break
  }
  return value
}

/**
 * Helper that will find target element in array of objects, each object
 * is dropdown array of object for example for companies.
 * @param {Array.<Object>} arr - values to filter for target
 * @param {string} target - target we are looking for
 */
export function getDropdownObject(arr, key, target) {
  if (typeof target !== 'string') {
    return null
  }

  const result = arr.filter((el) => el[key] === target)
  if (result?.length) {
    return result[0]
  }
  return null
}
