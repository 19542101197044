function generateActionType(type) {
  return `@damWiz/${type}`
}

export const LOAD_STRATEGY = generateActionType('LOAD_STRATEGY')
export const OPEN_WIZARD = generateActionType('OPEN_WIZARD')
export const CLOSE_WIZARD = generateActionType('CLOSE_WIZARD')
export const SET_CURRENT_VIEW = generateActionType('SET_CURRENT_VIEW')

// duplicate data strategy
export const SET_DUPLICATE_EMAIL_DATA = generateActionType(
  'SET_DUPLICATE_EMAIL_DATA',
)
export const CLEAR_DUPLICATE_EMAIL_DATA = generateActionType(
  'CLEAR_DUPLICATE_EMAIL_DATA',
)

// reminder email strategy
export const SET_REMINDER_EMAIL_DATA = generateActionType(
  'SET_REMINDER_EMAIL_DATA',
)
export const CLEAR_REMINDER_EMAIL_DATA = generateActionType(
  'CLEAR_REMINDER_EMAIL_DATA',
)
export const PREVENT_NAVIGATION_BACK = generateActionType(
  'PREVENT_NAVIGATION_BACK',
)

// network
// assets
export const FETCHING_ASSETS = generateActionType('FETCHING_ASSETS')
export const FETCHING_ASSETS_ERROR = generateActionType('FETCHING_ASSETS_ERROR')
export const FETCHING_ASSETS_SUCCESS = generateActionType(
  'FETCHING_ASSETS_SUCCESS',
)
// emails
export const SEND_TEST_EMAIL_REQUEST = generateActionType(
  'SEND_TEST_EMAIL_REQUEST',
)
export const SEND_TEST_EMAIL_SUCCESS = generateActionType(
  'SEND_TEST_EMAIL_SUCCESS',
)
export const SEND_TEST_EMAIL_ERROR = generateActionType('SEND_TEST_EMAIL_ERROR')
export const SEND_REGULAR_EMAIL_SUCCESS = generateActionType(
  'SEND_REGULAR_EMAIL_SUCCESS',
)

// packshots
export const SET_ACTIVE_TAB_ACTION = generateActionType('SET_ACTIVE_TAB_ACTION')
export const ADD_TAB = generateActionType('ADD_TAB')
export const DELETE_TAB = generateActionType('DELETE_TAB')
export const CLEAR_ALL_TABS = generateActionType('CLEAR_ALL_TABS')

// Metadata
export const SET_METADATA_ACTION = generateActionType('SET_METADATA_ACTION')

// sanbox
export const DEACTIVATE_SANDBOX_SENDING = generateActionType(
  'DEACTIVATE_SANDBOX_SENDING',
)
export const ACTIVATE_SANDBOX_SENDING = generateActionType(
  'ACTIVATE_SANDBOX_SENDING',
)
