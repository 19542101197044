import produce from 'immer'
import union from 'lodash/union'
import {
  SET_TEXTFILES,
  SELECT_TEXTFILE,
  SELECT_MANY_TEXTFILES,
  UNSELECT_TEXTFILE,
  CLEAR_ALL_TEXTFILES,
  CLEAR_ALL_TEXTFILES_FOR_PZN,
  OPEN_IMPLEMENTATION_DETAILS,
} from 'src/actionTypes/textFilesActionTypes'
const initialState = {
  texts: {},
  selectedTexts: {},
  implementationDetails: {},
}
export default function DamTextFilesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TEXTFILES:
      return produce(state, (draft) => {
        const { pzn, textFiles } = action.payload
        draft.texts[pzn] = textFiles
      })
    case SELECT_TEXTFILE:
      return produce(state, (draft) => {
        const { id, pzn } = action.payload
        if (!state.selectedTexts[pzn]) {
          draft.selectedTexts[pzn] = []
        }
        draft.selectedTexts[pzn] = [...draft.selectedTexts[pzn], id]
      })
    case SELECT_MANY_TEXTFILES:
      return produce(state, (draft) => {
        const { pzn, textFiles } = action.payload
        draft.selectedTexts[pzn] = union(state.selectedTexts[pzn], textFiles)
      })
    case UNSELECT_TEXTFILE:
      return produce(state, (draft) => {
        const { id, pzn } = action.payload
        draft.selectedTexts[pzn] = draft.selectedTexts[pzn].filter(
          (selectedId) => selectedId !== id,
        )
      })
    case CLEAR_ALL_TEXTFILES:
      return produce(state, (draft) => {
        draft.texts = initialState.texts
        draft.selectedTexts = initialState.selectedTexts
      })
    case CLEAR_ALL_TEXTFILES_FOR_PZN:
      return produce(state, (draft) => {
        const { [action.payload]: omitText, ...restTexts } = state.texts
        const { [action.payload]: omitSelected, ...restSelected } =
          state.selectedTexts
        draft.texts = restTexts
        draft.selectedTexts = restSelected
      })
    case OPEN_IMPLEMENTATION_DETAILS:
      return produce(state, (draft) => {
        draft.implementationDetails[action.payload] = true
      })
    default:
      return state
  }
}
