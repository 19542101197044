import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

function AdminGuard({ children }) {
  const account = useSelector((state) => state.account)

  if (!account.user) {
    return <Redirect to="/login" />
  }
  const isAdmin =
    account.user?.roles &&
    account.user.roles.filter((role) => role.name === 'admin').length > 0

  if (!isAdmin) {
    return <Redirect to="/" />
  }

  return children
}

AdminGuard.propTypes = {
  children: PropTypes.any,
}

export default AdminGuard
