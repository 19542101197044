import axios from 'src/utils/axios'
import { errorHandler } from 'src/utils/errorHandler'
import debounce from 'lodash/debounce'
import {
  UPDATE_FILTERS,
  CLOSE_SIDEBAR,
  OPEN_DRAWER,
  FETCHING,
  SET_RESULTS,
  FETCHING_404,
  FETCHING_ERROR,
  PAGE_CHANGE,
  SET_SIDEBAR_ABDA,
  ADD_KATSOLL,
  REMOVE_KATSOLL,
  IMPORT_SHOPS,
  UPDATE_SIDEBAR_VALUE,
  IMPORT_CATEGORIES,
  ADD_NEW_KATSOLL,
  RESET_SETTINGS,
} from './actionTypes'

export const getAbdaKeywords =
  ({ page, filters, source }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: FETCHING,
      })

      const params = {
        page,
        abda: filters?.search || null,
      }

      const { data } = await axios.get('/categories/abda-groups', {
        params,
        cancelToken: source.token,
      })
      const { count, res } = data
      return dispatch({
        type: SET_RESULTS,
        payload: {
          data: res,
          count,
        },
      })
    } catch (error) {
      if (axios.isCancel(error)) {
        return null
      }

      const is404 = error?.response?.status === 404
      if (is404) {
        return dispatch({
          type: FETCHING_404,
        })
      }
      dispatch({
        type: FETCHING_ERROR,
      })
      return errorHandler(error, {})
    }
  }

export const getShops = () => async (dispatch) => {
  try {
    const { data } = await axios.get('/categories/shops')
    return dispatch({
      type: IMPORT_SHOPS,
      payload: data,
    })
  } catch (error) {
    return errorHandler(error, {})
  }
}

export const addNewService = (kw) => async () => {
  try {
    return await axios.post('/keywords', { name: kw })
  } catch (error) {
    return errorHandler(error, {})
  }
}

export const getKatsollDetails =
  ({ abdaId, domainId } = {}) =>
  async (dispatch) => {
    try {
      const { data } = await axios.get(
        `/categories/abda-groups/abda-categories/${abdaId}/${domainId}`,
      )
      return dispatch({
        type: SET_SIDEBAR_ABDA,
        payload: data.res,
      })
    } catch (error) {
      const is404 = error?.response?.status === 404
      if (is404) {
        return dispatch({
          type: SET_SIDEBAR_ABDA,
          payload: [],
        })
      }
      return errorHandler(error, {})
    }
  }

export const addKeywordToAbda =
  ({ id, keyword, abda }) =>
  async (dispatch) => {
    try {
      const body = {
        abda,
        keywordId: id,
        keyword,
      }
      const { data } = await axios.post(
        '/keywords/abda-groups/abda-keywords',
        body,
      )
      return dispatch({
        type: ADD_KATSOLL,
        payload: data,
      })
    } catch (error) {
      return errorHandler(error, {})
    }
  }

export const getCategories = async (domainId, category = '', dispatch) => {
  try {
    if (!domainId) {
      return null
    }
    const params = {
      domainId: domainId || null,
      category: category || null,
    }
    const { data } = await axios.get(`/categories`, { params })
    dispatch({
      type: IMPORT_CATEGORIES,
      payload: data,
    })
    return data
  } catch (error) {
    return errorHandler(error, {})
  }
}

export const getCategoriesDebounced = debounce(getCategories, 350, {
  leading: true,
})

export const removeKatSollDetail = (pivotId) => async (dispatch) => {
  try {
    await axios.delete(`/categories/abda-groups/abda-categories/${pivotId}`)
    return dispatch({
      type: REMOVE_KATSOLL,
      payload: pivotId,
    })
  } catch (error) {
    return errorHandler(error, {})
  }
}

export const addNewKatsoll = (body) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      '/categories/abda-groups/abda-categories',
      body,
    )
    return dispatch({
      type: ADD_NEW_KATSOLL,
      payload: data,
    })
  } catch (error) {
    return errorHandler(error, {})
  }
}

export const pageChangeAction = (page) => (dispatch) =>
  dispatch({
    type: PAGE_CHANGE,
    payload: page,
  })

export const updateFiltersAction = (target, value) => (dispatch) =>
  dispatch({
    type: UPDATE_FILTERS,
    payload: {
      target,
      value,
    },
  })

export const closeDrawerAction = () => (dispatch) =>
  dispatch({
    type: CLOSE_SIDEBAR,
  })
export const openDrawer = (data) => (dispatch) =>
  dispatch({ type: OPEN_DRAWER, payload: data })

export const updateSidebarValues = (type, value) => (dispatch) =>
  dispatch({
    type: UPDATE_SIDEBAR_VALUE,
    payload: {
      type,
      value,
    },
  })

export const resetSettings = () => (dispatch) =>
  dispatch({
    type: RESET_SETTINGS,
  })
