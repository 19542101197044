import axios from 'src/utils/axios'
import { errorHandler } from 'src/utils/errorHandler'

export const REDUCER_NAME = 'shopContacts'
export const OPEN_ROW = '@shop_contacts/OPEN_ROW'
export const CLOSE_SIDEBAR = '@shop_contacts/CLOSE_SIDEBAR'
export const UPDATE_FILTERS = '@shop_contacts/UPDATE_FILTERS'
export const RESET_FILTERS = '@shop_contacts/RESET_FILTERS'
export const FETCHING_ERROR = '@shop_contacts/FETCHING_ERROR'
export const FETCHING_IN_PROGRESS = '@shop_contacts/FETHING_IN_PROGRESS'
export const FETCHING_SUCCESS = '@shop_contacts/FETCHING_SUCCESS'
export const SET_MAIN_EMAIL = '@shop_contacts/SET_MAIN_EMAIL'
export const GET_SHOPS_DROPDOWN = '@shop_contacts/GET_SHOPS_DROPDOWN'
export const ADD_NEW_PROSPECT = '@shop_contacts/ADD_NEW_PROSPECT'
export const CONFIRM_PROSPECT = '@shop_contacts/CONFIRM_PROSPECT'
export const EDIT_PROSPECT = '@shop_contacts/EDIT_PROSPECT'
export const DELETE_ASSIGNED_PROSPECT =
  '@shop_contacts/DELETE_ASSIGNED_PROSPECT'

export const openRowAction = (shop) => (dispatch) =>
  dispatch({
    type: OPEN_ROW,
    payload: shop,
  })

export const closeSidebar = () => (dispatch) =>
  dispatch({
    type: CLOSE_SIDEBAR,
  })

export const updateFiltersAction = (target, value) => (dispatch) =>
  dispatch({
    type: UPDATE_FILTERS,
    payload: {
      target,
      value,
    },
  })

export const fetchShopContacts = () => async (dispatch) => {
  // const shopIds = filters?.shop ? filters?.shop.map((item) => item.id) : null

  dispatch({ type: FETCHING_IN_PROGRESS })

  // const params = {
  //   user_account: filters?.user_account || null,
  //   shopIds: shopIds?.length ? JSON.stringify(shopIds) : null,
  // }
  try {
    // const res = await axios.get(`/dam/prospect-list`, { params })
    // const { data } = res
    const data = {}
    return dispatch({ type: FETCHING_SUCCESS, payload: { data } })
  } catch (error) {
    const is404 = error?.response?.status === 404
    if (is404) {
      return dispatch({
        type: FETCHING_SUCCESS,
        payload: {
          data: [],
        },
      })
    }

    dispatch({
      type: FETCHING_ERROR,
    })
    return errorHandler(error, {})
  }
}

export const getShops = () => async (dispatch) => {
  try {
    const { data } = await axios.get('/dam/shops')

    return dispatch({
      type: GET_SHOPS_DROPDOWN,
      payload: {
        shops: data,
      },
    })
  } catch (error) {
    return errorHandler(error, {})
  }
}

export const setMainEmail = (id, shopId, shopName) => async (dispatch) => {
  try {
    await axios.patch(
      `/dam/prospect-list/set-prospect-as-primary/${id}/${shopId}`,
    )

    return dispatch({
      type: SET_MAIN_EMAIL,
      payload: {
        id,
        shopId,
        shopName,
      },
    })
  } catch (error) {
    return errorHandler(error, {})
  }
}

export const resetFilters = () => (dispatch) =>
  dispatch({ type: RESET_FILTERS })

export const addProspectAction = (prospectData) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      '/dam/prospect-list/create-new-prospect',
      { ...prospectData },
    )
    return dispatch({
      type: ADD_NEW_PROSPECT,
      payload: data,
    })
  } catch (error) {
    return errorHandler(error, {})
  }
}

export const confirmProspect =
  (shopId, shopName, userForConfirmation) => async (dispatch) => {
    try {
      await axios.put('/dam/prospect-list/assign-prospect-to-shop', {
        shopId,
        prospectId: userForConfirmation.id,
      })

      return dispatch({
        type: ADD_NEW_PROSPECT,
        payload: { ...userForConfirmation, shopName, shopId },
      })
    } catch (error) {
      return errorHandler(error, {})
    }
  }

export const editProspectAction = (prospectData) => async (dispatch) => {
  try {
    const { data } = await axios.patch('/dam/prospect-list/edit-prospect', {
      ...prospectData,
    })
    return dispatch({
      type: EDIT_PROSPECT,
      payload: data,
    })
  } catch (error) {
    return errorHandler(error, {})
  }
}

export const deleteAssignedUser = (userInfo) => async (dispatch) => {
  const { prospectId, shopId } = userInfo
  try {
    await axios.delete(`/dam/prospect-list/${prospectId}/${shopId}`)
    return dispatch({
      type: DELETE_ASSIGNED_PROSPECT,
      payload: userInfo,
    })
  } catch (error) {
    return errorHandler(error, {})
  }
}
