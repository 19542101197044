import axios from 'src/utils/axios'

interface ISaveBookmark {
  id: number
  pzn: string
  domainId: number
  userId: number
  shop: string
}

export interface IBookmark {
  id: number
  pzn: string
  domainId: number
  userId: number
  shop: string
}
export interface IAllBookmarksResponse {
  bookmark_name: string
  id: number
  userId: number
  bookmarks: IBookmark[]
  created_at: string
}

export const saveBookmark = async (name: string, elements: ISaveBookmark[]) => {
  try {
    const body = {
      bookmarkName: name,
      elements,
    }
    await axios.post('/on-demand-crawling/bookmark', body)
  } catch (error) {
    throw error
  }
}

export const getBookmarks = async (): Promise<IAllBookmarksResponse[]> => {
  try {
    const { data } = await axios.get('/on-demand-crawling/bookmarks')
    return data
  } catch (error) {
    throw error
  }
}

export const deleteBookmark = async (id: number) => {
  try {
    await axios.delete(`/on-demand-crawling/bookmark/${id}`)
  } catch (error) {
    throw error
  }
}
