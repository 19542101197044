import axios from 'axios'
import { setLocalStorageEnv } from './localStorage'

const instance = axios.create()
instance.CancelToken = axios.CancelToken
instance.isCancel = axios.isCancel

setLocalStorageEnv(process.env.NODE_ENV)

instance.interceptors.request.use(
  (config) => {
    if (
      process.env.NODE_ENV === 'production' &&
      process.env.REACT_APP_API_BASE_URL
    ) {
      // eslint-disable-next-line
      config.baseURL = process.env.REACT_APP_API_BASE_URL
    } else if (process.env.REACT_APP_DEV_API_BASE_URL) {
      // eslint-disable-next-line
      config.baseURL = process.env.REACT_APP_DEV_API_BASE_URL
    }

    return config
  },
  (error) => Promise.reject(error),
)

export default instance
