import * as React from 'react'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
const l13 = keyframes`
  25%, 100% {
    transform: translateX(calc(var(--s, 1) * 100%));
  }
`

const LoaderStyle = styled('div')`
  font-weight: bold;
  font-family: monospace;
  font-size: 30px;
  display: inline-grid;
  overflow: hidden;
  &:before,
  &:after {
    content: 'Loading...';
    grid-area: 1/1;
    clip-path: inset(0 -200% 50%);
    text-shadow: -10ch 0 0;
    animation: ${l13} 2s infinite;
  }
  &:after {
    clip-path: inset(50% -200% 0%);
    text-shadow: 10ch 0 0;
    --s: -1;
    animation-delay: 1s;
  }
`

const l60 = keyframes`
  0%  {background-position: 0    100%,100% 100%,100% 0}
  33% {background-position: 100% 100%,100% 100%,100% 0}
  66% {background-position: 100% 0   ,100% 0   ,100% 0}
`

const l600 = keyframes`
  0%  {transform: scaleX(1)  rotate(0deg)}
  50% {transform: scaleX(-1) rotate(-90deg)}
`

const l61 = keyframes`
  16.5%{transform:perspective(150px) rotateX(-90deg)  rotateY(0deg)    rotateX(0deg);filter:grayscale(0.8)}
  33%  {transform:perspective(150px) rotateX(-180deg) rotateY(0deg)    rotateX(0deg)}
  66%  {transform:perspective(150px) rotateX(-180deg) rotateY(-180deg) rotateX(0deg)}
  100% {transform:perspective(150px) rotateX(-180deg) rotateY(-180deg) rotateX(-180deg);filter:grayscale(0.8)}
`

const LoaderStyle2 = styled('div')`
  width: 60px;
  aspect-ratio: 1;
  display: grid;
  grid: 50% / 50%;
  color: #25b09b;
  --_g: no-repeat linear-gradient(currentColor 0 0);
  background: var(--_g), var(--_g), var(--_g);
  background-size: 50.1% 50.1%;
  animation: ${l60} 1.5s infinite steps(1) alternate,
    ${l600} 3s infinite steps(1);

  &::before {
    content: '';
    background: currentColor;
    transform: perspective(150px) rotateY(0deg) rotateX(0deg);
    transform-origin: bottom right;
    animation: ${l61} 1.5s infinite linear alternate;
  }
`

export const Loader2 = () => {
  return <LoaderStyle2 />
}

export const Loader = () => {
  return <LoaderStyle />
}

export default function LinearDeterminate() {
  const [progress, setProgress] = React.useState(0)

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return oldProgress
        }
        const diff = Math.random() * 10
        return Math.min(oldProgress + diff, 100)
      })
    }, 600)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress
        color="secondary"
        variant="determinate"
        value={progress}
      />
    </Box>
  )
}
