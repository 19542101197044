import produce from 'immer'
import {
  SET_SUBJECT,
  SET_PZN_DATA,
  LOAD_STRATEGY,
  SET_CUSTOM_TEXT,
} from 'src/actionTypes/damSubjectActionTypes'

const initialState = {
  strategy: null,
  subject: '',
  pznData: {},
  customText: '',
}
export default function DamSubjectReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_STRATEGY:
      return produce(state, (draft) => {
        draft.strategy = action.payload
      })
    case SET_SUBJECT:
      return produce(state, (draft) => {
        draft.subject = action.payload
      })
    case SET_PZN_DATA:
      return produce(state, (draft) => {
        const { pzn, data } = action.payload
        draft.pznData[pzn] = data
      })
    case SET_CUSTOM_TEXT:
      return produce(state, (draft) => {
        draft.customText = action.payload
      })
    default:
      return state
  }
}
