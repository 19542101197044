import produce from 'immer'
import {
  UPDATE_FILTERS,
  CLOSE_SIDEBAR,
  OPEN_DRAWER,
  SET_RESULTS,
  FETCHING_ERROR,
  FETCHING_404,
  FETCHING,
  PAGE_CHANGE,
  SET_SIDEBAR_ABDA,
  ADD_KATSOLL,
  REMOVE_KATSOLL,
  IMPORT_SHOPS,
  UPDATE_SIDEBAR_VALUE,
  IMPORT_CATEGORIES,
  ADD_NEW_KATSOLL,
  RESET_SETTINGS,
} from './actionTypes'
const initialState = {
  results: [],
  fetching: false,
  fetchingError: false,
  filters: {
    search: null,
  },
  pagination: {
    page: 1,
    count: 0,
  },
  activeRow: null,
  currentKatSoll: null,
  shops: [],
  categories: [],
}
export default function BookedServicesReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case UPDATE_FILTERS:
      return produce(state, (draft) => {
        draft.filters[payload.target] = payload.value
        draft.pagination.page = 1
      })
    case CLOSE_SIDEBAR:
      return produce(state, (draft) => {
        draft.activeRow = null
        draft.openDrawer = false
      })
    case PAGE_CHANGE:
      return produce(state, (draft) => {
        draft.pagination.page = payload
      })

    case OPEN_DRAWER:
      return produce(state, (draft) => {
        draft.openDrawer = true
        draft.activeRow = payload
        draft.currentKatSoll = null
        draft.categories = []
      })
    case SET_RESULTS:
      return produce(state, (draft) => {
        draft.results = payload.data
        draft.pagination.count = payload.count
        draft.fetching = false
        draft.fetchingError = false
      })
    case FETCHING:
      return produce(state, (draft) => {
        draft.fetching = true
        draft.fetchingError = false
      })
    case FETCHING_404:
      return produce(state, (draft) => {
        draft.fetching = false
        draft.fetchingError = false
        draft.results = []
      })
    case FETCHING_ERROR:
      return produce(state, (draft) => {
        draft.fetchingError = true
        draft.fetching = false
      })
    case SET_SIDEBAR_ABDA:
      return produce(state, (draft) => {
        draft.currentKatSoll = payload
      })
    case ADD_KATSOLL:
      return produce(state, (draft) => {
        if (draft.currentAbda && Array.isArray(draft.currentAbda)) {
          draft.currentAbda.push(payload)
        } else {
          draft.currentAbda = [payload]
        }
      })
    case REMOVE_KATSOLL:
      return produce(state, (draft) => {
        draft.currentKatSoll = state.currentKatSoll.filter(
          (kw) => kw.pivotId !== payload,
        )
      })
    case IMPORT_SHOPS:
      return produce(state, (draft) => {
        draft.shops = payload
      })
    case IMPORT_CATEGORIES:
      return produce(state, (draft) => {
        draft.categories = payload
      })
    case UPDATE_SIDEBAR_VALUE:
      return produce(state, (draft) => {
        if (!draft.activeRow) {
          draft.activeRow = {}
        }
        draft.activeRow[payload.type] = payload.value
      })
    case ADD_NEW_KATSOLL:
      return produce(state, (draft) => {
        if (Array.isArray(state.currentKatSoll)) {
          draft.currentKatSoll.push(payload)
        } else {
          draft.currentKatSoll = [payload]
        }
      })
    case RESET_SETTINGS:
      // eslint-disable-next-line arrow-body-style
      return produce(state, (draft) => {
        draft.activeRow = initialState.activeRow
        draft.pagination = initialState.pagination
        draft.results = initialState.results
        draft.openDrawer = initialState.openDrawer
      })
    default:
      return state
  }
}
