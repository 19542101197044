import produce from 'immer'
import {
  OPEN_WIZARD,
  CLOSE_WIZARD,
  SET_CURRENT_VIEW,
  LOAD_STRATEGY,
  SET_ACTIVE_TAB_ACTION,
  ADD_TAB,
  DELETE_TAB,
  SET_DUPLICATE_EMAIL_DATA,
  CLEAR_DUPLICATE_EMAIL_DATA,
  CLEAR_ALL_TABS,
  FETCHING_ASSETS_SUCCESS,
  FETCHING_ASSETS,
  FETCHING_ASSETS_ERROR,
  SET_METADATA_ACTION,
  SET_REMINDER_EMAIL_DATA,
  CLEAR_REMINDER_EMAIL_DATA,
  PREVENT_NAVIGATION_BACK,
  ACTIVATE_SANDBOX_SENDING,
  DEACTIVATE_SANDBOX_SENDING,
} from 'src/actionTypes/damWizardActonTypes'
import { getNewActiveTabAfterTabRemoval } from 'src/utils/selectTabAfterDeletion'

const initialState = {
  strategy: null,
  open: false,
  currentView: 0,
  activeTab: null,
  tabs: [],
  duplicatedData: null,
  fetchingAssets: false,
  fetchingAssetsError: false,
  metadata: {},
  reminderEmailData: null,
  backNavPrevented: false,
  activeSandbox: false,
}

export default function DamWizReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_WIZARD:
      return produce(state, (draft) => {
        draft.open = true
      })
    case CLOSE_WIZARD:
      return produce(state, (draft) => {
        draft.open = false
      })
    case LOAD_STRATEGY:
      return produce(state, (draft) => {
        draft.strategy = action.payload
      })
    case FETCHING_ASSETS_SUCCESS:
      return produce(state, (draft) => {
        draft.fetchingAssets = false
        draft.fetchingAssetsError = false
      })
    case FETCHING_ASSETS:
      return produce(state, (draft) => {
        draft.fetchingAssetsError = false
        draft.fetchingAssets = true
      })
    case FETCHING_ASSETS_ERROR:
      return produce(state, (draft) => {
        draft.fetchingAssetsError = true
        draft.fetchingAssets = false
      })
    case SET_DUPLICATE_EMAIL_DATA:
      return produce(state, (draft) => {
        draft.duplicatedData = action.payload
      })
    case CLEAR_DUPLICATE_EMAIL_DATA:
      return produce(state, (draft) => {
        draft.duplicatedData = null
      })
    case SET_REMINDER_EMAIL_DATA:
      return produce(state, (draft) => {
        draft.reminderEmailData = action.payload
      })
    case CLEAR_REMINDER_EMAIL_DATA:
      return produce(state, (draft) => {
        draft.reminderEmailData = null
        draft.backNavPrevented = false
      })
    case SET_CURRENT_VIEW:
      return produce(state, (draft) => {
        draft.currentView = action.payload
      })
    case SET_ACTIVE_TAB_ACTION:
      return produce(state, (draft) => {
        draft.activeTab = action.payload
      })
    case ADD_TAB:
      return produce(state, (draft) => {
        draft.tabs = [...state.tabs, action.payload]
      })
    case DELETE_TAB:
      return produce(state, (draft) => {
        const newTab = getNewActiveTabAfterTabRemoval(
          state.tabs,
          state.activeTab,
        )
        draft.tabs = state.tabs.filter((tab) => tab !== action.payload)
        draft.activeTab = newTab
      })
    case CLEAR_ALL_TABS:
      return produce(state, (draft) => {
        draft.tabs = initialState.tabs
        draft.activeTab = initialState.activeTab
      })
    case SET_METADATA_ACTION:
      return produce(state, (draft) => {
        const { metadata, pzn } = action.payload
        draft.metadata[pzn] = metadata
      })
    case PREVENT_NAVIGATION_BACK:
      return produce(state, (draft) => {
        draft.backNavPrevented = true
      })
    case ACTIVATE_SANDBOX_SENDING:
      return produce(state, (draft) => {
        draft.activeSandbox = true
      })
    case DEACTIVATE_SANDBOX_SENDING:
      return produce(state, (draft) => {
        draft.activeSandbox = false
      })
    default:
      return state
  }
}
