import { IconButton, SvgIcon } from '@mui/material'

type IconButtonProps = {
  titleAccess: string
  Icon: React.ReactNode
  onClick: (event?: any) => void
  sx?: any
}

export default function IconButtonWrapper({
  titleAccess,
  Icon,
  onClick,
  sx,
}: IconButtonProps) {
  return (
    <IconButton onClick={onClick} sx={sx} data-testid="icon-button">
      <SvgIcon titleAccess={titleAccess}>{Icon}</SvgIcon>
    </IconButton>
  )
}
