import React, { useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import { VariableSizeList } from 'react-window'

import { TextField, useTheme, useMediaQuery, Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useSelector } from 'react-redux'
import GreyButton from 'src/components/BasicGreyButton'
import { getKeywordsSelector } from '../lib/selectors'

const LISTBOX_PADDING = 8 // px
const useStyles = makeStyles()((theme) => ({
  input: {
    width: '70%',
    marginBottom: 5,
    [theme.breakpoints.down('md')]: {
      marginTop: 5,
    },
  },
  btn: {
    width: '144px',
    marginLeft: 5,
  },
}))

function renderRow(props) {
  const { data, index, style } = props
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  })
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

function useResetCache(data) {
  const ref = React.useRef(null)
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref,
) {
  const { children, ...other } = props
  const itemData = React.Children.toArray(children)
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true })
  const itemCount = itemData.length
  const itemSize = smUp ? 36 : 48
  const getChildSize = (child) => {
    if (React.isValidElement(child)) {
      return 48
    }

    return itemSize
  }

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
  }

  const gridRef = useResetCache(itemCount)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

const ManufacturersDropdown = ({
  limit = 4,
  classes,
  buttonText = '',
  onAdd,
}) => {
  const { classes: dclasses, cx } = useStyles()
  const keywords = useSelector(getKeywordsSelector)
  const [selectedKw, setSelectedKw] = useState()

  const onChange = (e, value) => {
    if (value) {
      setSelectedKw(value)
    }
  }

  const onClick = () => {
    onAdd(selectedKw)
    setSelectedKw(null)
  }

  return (
    <Autocomplete
      limitTags={limit}
      className={cx(dclasses.input, classes && classes.root)}
      ListboxComponent={ListboxComponent}
      id="usersPanelSidebarManufacturersDropdown"
      options={keywords}
      getOptionLabel={(option) => option.keyword || ''}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disableCloseOnSelect
      value={selectedKw || null}
      onChange={onChange}
      renderInput={(params) => (
        <Box display="flex" alignItems="center">
          <TextField {...params} label="Keywords" variant="outlined" />
          <GreyButton
            classes={{ root: dclasses.btn }}
            buttonText={buttonText}
            onClick={onClick}
          />
        </Box>
      )}
    />
  )
}

export default ManufacturersDropdown
