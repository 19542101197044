import axios from 'src/utils/axios'
import * as fileSaver from 'file-saver'
import { PDF, XLS, DOC, DOCX, XLSX, ZIP } from 'src/constants/fileExtensions'

const contentTypes = {
  [PDF]: 'application/pdf',
  [XLS]: 'application/vnd.ms-excel',
  [XLSX]: 'application/vnd.ms-excel',
  [DOC]: 'application/msword',
  [DOCX]: 'application/msword',
  [ZIP]: 'application/zip, application/octet-stream',
}

export const fileDownloader = (url, { originalName, type }) =>
  new Promise((resolve, reject) => {
    const oReq = new XMLHttpRequest()
    oReq.open('GET', url, true)
    oReq.responseType = 'blob'
    oReq.onload = function onload() {
      const file = new Blob([oReq.response], {
        type: contentTypes[type],
      })
      fileSaver.saveAs(file, originalName)
      return resolve()
    }
    oReq.onreadystatechange = function stateChange(oEvent) {
      if (oReq.readyState === 4) {
        if (oReq.status !== 200) {
          return reject(oEvent.currentTarget.statusText)
        }
        return oEvent
      }
      return oEvent
    }
    oReq.send()
  })

/**
 * Download function that uses axios to download files
 * @param {string} url - url of endpoint
 * @param details Details of file
 * @param details.originalName name that will be assigned to the saved file
 * @param details.type extension type of the file
 */
export const fileDownloaderV2 = async (
  url,
  { originalName, type, params = {} },
) => {
  const { data } = await axios.get(url, { responseType: 'blob', params })
  const objectUrl = URL.createObjectURL(
    new Blob([data], {
      type: contentTypes[type],
    }),
  )
  const a = document.createElement('a')
  a.href = objectUrl
  a.download = originalName
  a.click()
}

export const fileDownloaderV3 = async (
  url,
  { originalName, type, body = {} },
) => {
  const { data } = await axios.post(
    url,
    {
      elements: body,
    },
    { responseType: 'blob' },
  )

  const objectUrl = URL.createObjectURL(
    new Blob([data], {
      type: contentTypes[type],
    }),
  )
  const a = document.createElement('a')
  a.href = objectUrl
  a.download = originalName
  a.click()
}
