import React, { useEffect } from 'react'
import { Drawer, Box, Typography, IconButton, SvgIcon } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { X as CloseIcon } from 'react-feather'
import { useDefaultDrawerStyles } from 'src/theme/drawer'
import { useSelector, useDispatch } from 'react-redux'
import useSimpleNotification from 'src/hooks/useSimpleNotification'
import {
  openDrawerSelector,
  getActiveRow,
  getCurrentAbda,
} from '../lib/selectors'
import {
  closeDrawerAction,
  getKeywordsForAbdaByAbda,
  addKeywordToAbda,
} from '../lib/actions'
import PznSection from './PznSection'

const useStyles = makeStyles()(() => ({
  formsWrapper: {
    '& > *': {
      marginBottom: '15px',
    },
  },
  addButton: {
    background: '#4472C4',
    color: '#FFFFFF',
    marginLeft: 12,
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '26px',
    '&:hover': {
      background: '#4472C4',
    },
  },
  noItems: {
    display: 'flex',
    alignItems: 'center',
    height: 200,
    justifyContent: 'center',
  },
  noItemsTitle: {
    fontSize: '1.5rem',
    fontWeight: 500,
    opacity: 0.7,
    fontFamily: 'PT-Sans',
  },
}))

export default function Sidebar({
  drawerDesktopWidth = '50%',
  openDrawerCallback,
}) {
  const { classes } = useStyles()
  const dispatch = useDispatch()
  const openDrawer = useSelector(openDrawerSelector)
  const activeRow = useSelector(getActiveRow)
  const selectedAbda = useSelector(getCurrentAbda)
  const { classes: defaultClasses } = useDefaultDrawerStyles({
    destopWidth: drawerDesktopWidth,
  })()
  const notification = useSimpleNotification()

  useEffect(() => {
    if (openDrawer) {
      dispatch(getKeywordsForAbdaByAbda(activeRow))
    }
  }, [activeRow, openDrawer, dispatch])

  useEffect(() => {
    if (openDrawerCallback) openDrawerCallback(openDrawer)
  }, [openDrawer, openDrawerCallback])

  const onClose = () => {
    dispatch(closeDrawerAction())
  }

  const onAdd = async (keyword) => {
    if (!keyword) {
      return null
    }
    try {
      await dispatch(
        addKeywordToAbda({ ...keyword, abda: activeRow.abdaPodGrp }),
      )
      notification('success')
      return null
    } catch (error) {
      notification('error')
      return null
    }
  }

  const content = (
    <Box>
      <Box p={2}>
        <Box className={defaultClasses.header}>
          <Box className={defaultClasses.titleWrapper}>
            <Typography className={defaultClasses.title}>Config</Typography>
          </Box>
          <Box>
            <IconButton onClick={() => onClose()} size="large">
              <SvgIcon>
                <CloseIcon />
              </SvgIcon>
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box p={2} className={classes.formsWrapper}>
        {!!selectedAbda && (
          <PznSection
            abdaKeywords={selectedAbda}
            {...activeRow}
            onAdd={onAdd}
          />
        )}
      </Box>
    </Box>
  )
  return (
    <Drawer
      anchor="right"
      classes={{ paper: defaultClasses.drawer }}
      open={openDrawer}
      onClose={() => onClose()}
      variant="persistent"
    >
      {content}
    </Drawer>
  )
}
