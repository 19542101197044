import React, { useState, useEffect, useContext } from 'react'
import Cookies from 'js-cookie'
import { Box, Button, Link, Portal, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { alpha } from '@mui/material/styles'
import { AppStateContext } from 'src/AppProviders'

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: alpha(theme.palette.common.black, 0.4),
    color: theme.palette.common.white,
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    outline: 'none',
    zIndex: 2000,
    display: 'flex',
    alignItems: 'flex-end',
  },
  overlay: {
    backgroundColor: alpha(theme.palette.common.black, 0.7),
    padding: theme.spacing(2),
    width: '100%',
  },
  btnContainer: {
    justifyContent: 'flex-end',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  btn: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  acceptBtn: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  rejectBtn: {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.common.white}`,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(1),
    transitionProperty: 'color, border-color',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.grey[500],
      borderColor: theme.palette.grey[500],
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0),
      marginTop: theme.spacing(1),
    },
  },
}))

function CookiesNotification() {
  const { classes, cx } = useStyles()
  const [open, setOpen] = useState(false)
  const [, setAppState] = useContext(AppStateContext)
  const cookieExpires = 60

  const handleClose = () => {
    Cookies.set('consent', 'true', { expires: cookieExpires })
    setOpen(false)
    setAppState({ consentOpen: false })
    if (window.dataLayer) {
      // followed this guide https://www.metric.ai/blog/gdpr-cookies/#step4_configure_GTM
      window.dataLayer.push({ event: 'consent' })
    }
  }

  useEffect(() => {
    const consent = Cookies.get('consent')

    if (!consent) {
      setOpen(true)
      setAppState({ consentOpen: true })
    }
  }, [setAppState])

  if (!open) {
    return null
  }

  return (
    <Portal>
      <div
        className={classes.root}
        onKeyDown={(e) => e.keyCode === 9 && e.preventDefault()}
      >
        <div className={classes.overlay}>
          <Typography variant="body1" color="inherit">
            Um ein optimales Smile-Nutzererlebnis zu garantieren, setzen wir
            Nutzer-basiertes Tracking ein. Um Smile nutzen zu können, stimme
            diesem bitte zu. Die Details zum eingesetzten Tracking findest Du in
            unserer{' '}
            <Link
              component="a"
              color="inherit"
              underline="always"
              href="/sites/privacy"
              target="_blank"
            >
              Datenschutzrichtlinie
            </Link>
            .
          </Typography>
          <Box mt={2} display="flex" className={classes.btnContainer}>
            <Button
              autoFocus
              onClick={handleClose}
              variant="contained"
              className={cx([classes.btn, classes.acceptBtn])}
              data-test="confirm-cookie-btn"
            >
              Ich stimme zu
            </Button>
            <Button
              className={cx([classes.btn, classes.rejectBtn])}
              href="/sites/privacy"
              target="_blank"
              variant="contained"
            >
              Mehr erfahren
            </Button>
          </Box>
        </div>
      </div>
    </Portal>
  )
}

export default CookiesNotification
