import React from 'react'
import { Box, Typography, Avatar } from '@mui/material'
import { useSelector } from 'react-redux'
import { get } from 'src/utils/get'
import useStyles from './styles'

export function NavUserInfoSmScreens({
  logo,
  company,
  displayName,
  avatar,
  initials,
}) {
  const { classes } = useStyles()

  return (
    <Box className={classes.smLogoContainer}>
      {logo ? (
        <img src={logo} className={classes.smLogo} alt={company} />
      ) : (
        <Avatar alt={displayName} className={classes.smAvatar} src={avatar} />
      )}
      {!!initials && (
        <Typography variant="h5" className={classes.initials}>
          {initials}
        </Typography>
      )}
    </Box>
  )
}

export function NavUserInfoLgScreens({
  logo,
  company,
  displayName,
  avatar,
  position,
}) {
  const { classes } = useStyles()
  return (
    <Box p={2}>
      <Box display="flex" justifyContent="center">
        {logo ? (
          <img src={logo} className={classes.orgLogo} alt={company} />
        ) : (
          <Avatar alt={displayName} className={classes.avatar} src={avatar} />
        )}
      </Box>
      <Box mt={2} textAlign="center">
        {!!displayName && <Typography variant="h5">{displayName}</Typography>}
        {!!position && (
          <Typography variant="body2" color="textSecondary">
            {position}
          </Typography>
        )}
      </Box>
    </Box>
  )
}

export default function UserInfo({ variant = 'lg' }) {
  const { user } = useSelector((state) => state.account)
  const {
    company,
    companyLogo,
    username,
    firstName,
    lastName,
    userJobPosition,
    avatar,
  } = user
  const displayName =
    !!firstName && !!lastName ? `${firstName} ${lastName}` : username
  const initials =
    !!firstName && !!lastName
      ? `${firstName.charAt(0)}${lastName.charAt(0)}`
      : null
  const position = get(userJobPosition, 'job_position')
  const logo = get(companyLogo, 'logo_url')

  const props = {
    company,
    companyLogo,
    username,
    firstName,
    lastName,
    userJobPosition,
    displayName,
    initials,
    position,
    logo,
    avatar,
  }

  return variant === 'lg' ? (
    <NavUserInfoLgScreens {...props} />
  ) : (
    <NavUserInfoSmScreens {...props} />
  )
}
