import React, { useEffect } from 'react'
import { Drawer, Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useDefaultDrawerStyles } from 'src/theme/drawer'
import SidebarHeader from './Header'

const useStyles = makeStyles()(() => ({
  bodyWrapper: {
    '& > *': {
      marginBottom: '15px',
    },
  },
}))

export default function Sidebar({
  drawerDesktopWidth = '50%',
  openDrawerCallback,
  open,
  onClose,
  children,
  sidebarTitle,
}) {
  const { classes } = useStyles()
  const { classes: defaultClasses } = useDefaultDrawerStyles({
    destopWidth: drawerDesktopWidth,
  })()

  useEffect(() => {
    if (openDrawerCallback) openDrawerCallback(open)
  }, [open, openDrawerCallback])

  return (
    <Drawer
      anchor="right"
      classes={{ paper: defaultClasses.drawer }}
      open={open}
      onClose={() => onClose()}
      variant="persistent"
    >
      <Box>
        <SidebarHeader
          drawerDesktopWidth={drawerDesktopWidth}
          onClose={onClose}
          title={sidebarTitle}
        />
        <Box p={2} className={classes.bodyWrapper}>
          {children}
        </Box>
      </Box>
    </Drawer>
  )
}
