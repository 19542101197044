import axios from 'src/utils/axios'
import { errorHandler } from 'src/utils/errorHandler'

export const TOGGLE_MODAL = '@wkz/TOGGLE_MODAL'
export const CLOSE_MODAL = '@wkz/CLOSE_MODAL'
export const ADD_SLOT = '@wkz/ADD_SLOT'
export const DELETE_SLOT = '@wkz/DELETE_SLOT'
export const POPULATE_DROPDOWN_VALUES = '@wkz/POPULATE_DROPDOWN_VALUES'
export const UPDATE_FILTERS = '@wkz/UPDATE_FILTERS'
export const RESET_FILTERS = '@wkz/RESET_FILTERS'
export const PAGE_CHANGE = '@wkz/PAGE_CHANGE'
export const IMPORT_WKZ_DATA = '@wkz/IMPORT_WKZ_DATA'
export const IMPORT_WKZ_DATA_ERROR = '@wkz/IMPORT_WKZ_DATA_ERROR'
export const IMPORT_WKZ_DATA_FETCHING = '@wkz/IMPORT_WKZ_DATA_FETCHING'
export const OPEN_SINGLE_ROW = '@wkz/OPEN_SINGLE_ROW'

// values
export const UPDATE_MODAL_VALUE = '@wkz/UPDATE_MODAL_VALUE'

// banner search
export const BANNER_SEARCH_STARTED = '@wkz/BANNER_SEARCH_STARTED'
export const BANNER_SEARCH_ERROR = '@wkz/BANNER_SEARCH_ERROR'
export const BANNER_SEARCH_SUCCESS = '@wkz/BANNER_SEARCH_SUCCESS'
export const BANNER_SEARCH_CANCELED = '@wkz/BANNER_SEARCH_CANCELED'

export const toggleWkzModalAction = () => (dispatch) =>
  dispatch({
    type: TOGGLE_MODAL,
  })

export const closeWkzModalAction = () => (dispatch) =>
  dispatch({
    type: CLOSE_MODAL,
  })

export const updateWkzValue = (actionType, key, value) => (dispatch) =>
  dispatch({
    type: actionType,
    payload: {
      type: key,
      value,
    },
  })

export const openRowAction = (index) => (dispatch) =>
  dispatch({
    type: OPEN_SINGLE_ROW,
    payload: index,
  })

export const addNewWkzSlotAction = (slotData) => async (dispatch) => {
  try {
    // if nothing has been added in to the modal
    let hasValues = false
    // eslint-disable-next-line
    for (let i in slotData) {
      // if value is empty
      if (slotData[i]) {
        hasValues = true
      }
    }

    if (!hasValues) {
      return null
    }

    const { data } = await axios.post('/wkz/slots', { ...slotData })
    return dispatch({
      type: ADD_SLOT,
      payload: data,
    })
  } catch (e) {
    return errorHandler(e, {})
  }
}

export const updateWkzSlotAction = (slotData) => async (dispatch) => {
  try {
    const values = {
      ...slotData,
      shop: slotData?.shop?.id || null,
      bannerType: slotData?.bannerType?.id || null,
      pageType: slotData?.pageType?.id || null,
      screenSize: slotData?.screenSize?.id || null,
      visibility: slotData?.visibility?.id || null,
      unit: slotData?.unit?.id || null,
      placement: slotData?.placement?.id || null,
      listPrice: slotData.listPrice ? parseFloat(slotData.listPrice) : null,
      actualCosts: slotData.actualCosts
        ? parseFloat(slotData.actualCosts)
        : null,
      active: slotData?.active?.value,
    }
    await axios.patch(`/wkz/slots`, values)
  } catch (e) {
    return errorHandler(e, {})
  }

  return dispatch({
    type: ADD_SLOT,
  })
}

export const deleteWkzSlotAction = (slotId) => async (dispatch) => {
  try {
    await axios.delete(`/wkz/slots/${slotId}`)
  } catch (e) {
    return errorHandler(e, {})
  }

  return dispatch({
    type: DELETE_SLOT,
  })
}

export const populateWkzDropdowns = () => async (dispatch) => {
  try {
    const { data } = await axios.get(`/wkz/media/values`)

    return dispatch({
      type: POPULATE_DROPDOWN_VALUES,
      payload: data,
    })
  } catch (e) {
    return errorHandler(e, {})
  }
}

// export const changeSelectedShopFilterAction = (shop) => (dispatch) => {
//   return dispatch({
//     type: UPDATE_SHOP_FILTER,
//     payload: shop,
//   });
// };

export const pageChangeAction = (page) => (dispatch) =>
  dispatch({
    type: PAGE_CHANGE,
    payload: page,
  })

export const fetchWkzData =
  ({ page, filters, source }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: IMPORT_WKZ_DATA_FETCHING,
      })

      const params = {
        page,
        shop: filters?.shop?.id || null,
        screensize: filters?.screensize?.id || null,
        position: filters?.position?.id || null,
      }

      const { data } = await axios.get('/wkz/slots', {
        params,
        cancelToken: source.token,
      })

      return dispatch({
        type: IMPORT_WKZ_DATA,
        payload: data,
      })
    } catch (e) {
      if (axios.isCancel(e)) {
        return null
      }
      const is404 = e?.response?.status === 404
      if (is404) {
        return dispatch({
          type: IMPORT_WKZ_DATA,
          payload: {
            data: [],
            total: 0,
          },
        })
      }

      dispatch({
        type: IMPORT_WKZ_DATA_ERROR,
      })
      return errorHandler(e, {})
    }
  }

export const updateFilter = (filter, value) => (dispatch) => {
  dispatch({
    type: UPDATE_FILTERS,
    payload: {
      filter,
      value,
    },
  })
}

export const resetFilters = () => (dispatch) =>
  dispatch({ type: RESET_FILTERS })
