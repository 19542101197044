import React, { useEffect } from 'react'
import {
  Drawer,
  Box,
  Typography,
  SvgIcon,
  IconButton,
  Divider,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { X as CloseIcon } from 'react-feather'
import { useSelector } from 'react-redux'
import { personalInformationSelector } from 'src/selectors/accountSelectors'
import ResetPwd from 'src/views/account/ResetPwd'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles()((theme) => ({
  drawer: {
    width: '100%',
    top: 64,
    height: 'calc(100% - 64px)',
    borderRight: '15px solid #f4f6f8',
    boxShadow: '1px 10px 8px 3px rgba(0, 0, 0, 0.12)',
    zIndex: 1201,
    [theme.breakpoints.up('sm')]: {
      width: `calc(50% - ${theme.spacing(1)})`,
    },
  },
  sidebarHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '55px',
  },
  sidebarTitle: {
    fontFamily: 'PT-Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '31px',
    fontSize: '24px',
  },
  personalInformation: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '66px',
  },
  profileDescription: {
    fontFamily: 'PT-Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '12px',
    fontSize: '12px',
    marginBottom: '14px',
    letterSpacing: '0.4px',
  },
  profileValue: {
    fontFamily: 'PT-Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '20px',
    fontSize: '14px',
  },
  changePassword: {
    marginTop: '33px',
  },
  profileBlock: {
    width: '50%',
  },
}))

export default function ChangePasswordSidebar({ open, handleClose }) {
  const { classes } = useStyles()
  const location = useLocation()

  const { firstName, lastName, email } = useSelector(
    personalInformationSelector,
  )

  const handleSidebarClose = () => {
    handleClose()
  }

  useEffect(() => {
    handleClose()
  }, [location, handleClose])

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      open={open}
      variant="persistent"
      data-testid="change-password-sidebar"
    >
      <Box>
        <Box p={2}>
          <Box className={classes.sidebarHeader}>
            <Box>
              <Typography className={classes.sidebarTitle}>
                User Profile
              </Typography>
            </Box>
            <Box>
              <Box>
                <IconButton
                  data-testid="change-password-sidebar-close"
                  onClick={handleSidebarClose}
                  size="large"
                >
                  <SvgIcon>
                    <CloseIcon />
                  </SvgIcon>
                </IconButton>
              </Box>
            </Box>
          </Box>
          <Box className={classes.personalInformation}>
            <Box className={classes.profileBlock}>
              <Typography className={classes.profileDescription}>
                First Name
              </Typography>
              <Typography
                data-testid="change-password-sidebar-first-name"
                className={classes.profileValue}
              >
                {firstName || ''}
              </Typography>
            </Box>
            <Box className={classes.profileBlock}>
              <Typography className={classes.profileDescription}>
                Last Name
              </Typography>
              <Typography
                data-testid="change-password-sidebar-last-name"
                className={classes.profileValue}
              >
                {lastName}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography className={classes.profileDescription}>
              E-Mail Address
            </Typography>
            <Typography
              data-testid="change-password-sidebar-email"
              className={classes.profileValue}
            >
              {email}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          <Box className={classes.changePassword}>
            <Box>
              <Typography className={classes.sidebarTitle}>
                Change Password
              </Typography>
            </Box>
            <ResetPwd />
          </Box>
        </Box>
      </Box>
    </Drawer>
  )
}
