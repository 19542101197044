import produce from 'immer'
import {
  FETCHING_DATA,
  FETCHING_DATA_404,
  FETCHING_DATA_ERROR,
  ADD_NEW_INPUT,
  ACTIVATE_ADDING_MODE,
  SELECT_ROW,
  SET_DATA,
  UPDATE_FILTERS,
  IMPORT_SHOPS,
  PAGE_CHANGE,
  CLOSE_SIDEBAR,
  UPDATE_SIDEBAR_VALUE,
  OPEN_DRAWER,
  UPDATE_INPUT,
} from 'src/actionTypes/wkzDataInputConfigAT'
const initialState = {
  data: [],
  fetching: false,
  fetchingError: false,
  activeRow: null,
  pagination: {
    page: 1,
    count: 0,
  },
  filters: {
    shop: null,
  },
  openDrawer: false,
  addingMode: false,
  shops: [],
  placements: [],
}
export default function WkzDataInputConfigReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case FETCHING_DATA:
      return produce(state, (draft) => {
        draft.fetchingError = false
        draft.fetching = true
      })
    case FETCHING_DATA_ERROR:
      return produce(state, (draft) => {
        draft.fetchingError = true
        draft.fetching = false
      })
    case FETCHING_DATA_404:
      return produce(state, (draft) => {
        draft.fetching = false
        draft.fetchingError = false
        draft.data = []
      })
    case SET_DATA:
      return produce(state, (draft) => {
        const { data, count } = payload
        draft.data = data
        draft.pagination.count = count
        draft.fetching = false
        draft.fetchingError = false
      })
    case PAGE_CHANGE:
      return produce(state, (draft) => {
        draft.pagination.page = payload
      })
    case IMPORT_SHOPS:
      return produce(state, (draft) => {
        draft.shops = payload.domains
        draft.placements = payload.placements
      })
    case UPDATE_FILTERS:
      return produce(state, (draft) => {
        draft.filters[payload.type] = payload.value
        draft.pagination.page = 1
      })
    case SELECT_ROW:
      return produce(state, (draft) => {
        draft.activeRow = payload
        draft.openDrawer = true
        if (payload.shop) {
          draft.activeRow = {
            ...draft.activeRow,
            shop: { name: payload.shop, id: payload.domainId },
          }
        }
        // if drawer is open and user clicks on some row then turn off adding mode
        if (draft.addingMode) {
          draft.addingMode = false
        }
      })
    case CLOSE_SIDEBAR:
      return produce(state, (draft) => {
        draft.activeRow = null
        draft.openDrawer = false
        draft.addingMode = false
      })
    case UPDATE_SIDEBAR_VALUE:
      return produce(state, (draft) => {
        if (!draft.activeRow) {
          draft.activeRow = {}
        }
        draft.activeRow[payload.type] = payload.value
      })
    case UPDATE_INPUT:
      return produce(state, (draft) => {
        const indexOfTarget = draft.data.findIndex((d) => d.id === payload.id)
        if (indexOfTarget > -1) {
          draft.data[indexOfTarget] = payload
        }
      })
    case OPEN_DRAWER:
      return produce(state, (draft) => {
        draft.openDrawer = true
      })
    case ACTIVATE_ADDING_MODE:
      return produce(state, (draft) => {
        draft.addingMode = true
      })
    case ADD_NEW_INPUT:
      return produce(state, (draft) => {
        if (!draft.data) {
          draft.data = []
        }
        draft.data = [...draft.data, payload]
      })

    default:
      return state
  }
}
