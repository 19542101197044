// @ts-nocheck
import { io } from 'socket.io-client'
import authService from './authService'

const options = {
  reconnection: true,
  reconnectionAttempts: 15,
  // reconnectionDelay: 2000,
  upgrade: true,
  transports: ['websocket'],
  timeout: 20000,
}

class Sockets {
  socket: any

  init(namespace?: string) {
    // const space = namespace ? `/${namespace}` : ''
    // this.socket = io.connect(`${this.getBaseUrl()}${space}`)
    const token = authService.getAccessToken()
    const baseUrl = this.getBaseUrl()
    // console.log('zov prirode')
    this.socket = io.connect(`${baseUrl}`, {
      auth: {
        token,
      },
      ...options,
    })

    // this.refreshListeners()
  }

  getBaseUrl() {
    let base
    if (
      process.env.NODE_ENV === 'production' &&
      process.env.REACT_APP_API_BASE_URL
    ) {
      // eslint-disable-next-line
      base = process.env.REACT_APP_API_BASE_URL
    } else if (process.env.REACT_APP_DEV_API_BASE_URL) {
      // eslint-disable-next-line
      base = process.env.REACT_APP_DEV_API_BASE_URL
    }
    return base
  }

  getCustomSocket() {
    return this.socket
  }

  getNamespace(namespace: string) {
    return io.of(namespace)
  }

  //   refreshListeners() {
  //     this.registeredClasses.forEach((listener) =>
  //       listener.setSocket(this.socket),
  //     )
  //   }

  //   registerListener(listener) {
  //     this.registeredClasses.push(listener)

  //     this.refreshListeners()
  //   }
}
const socketInstance = new Sockets()

socketInstance.init()

export default socketInstance
