import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  SvgIcon,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { Menu as MenuIcon } from 'react-feather'
import Logo, { SmileLogoIcon } from 'src/components/Logo'
import { THEMES } from 'src/constants'
import Account from './Account'
import Help from './Help'
import TopBarCTA from './CTA'
import ChangePasswordSidebar from './ChangePasswordSidebar'
import CrawlerQueueIcon from './CrawlerQueueIcon'
import LogIcon from './LogsIcon'
import KatsolinaDetailsIcon from './KatsolinaDetailsIcon'

const useStyles = makeStyles()((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.main,
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default,
        }
      : {}),
  },
  toolbar: {
    minHeight: 64,
  },
}))

function TopBar({ className, onMobileNavOpen, ...rest }) {
  const { classes, cx } = useStyles()
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)

  return (
    <>
      <AppBar
        className={cx(classes.root, className)}
        {...rest}
        data-testid="layout-top-nav"
      >
        <Toolbar className={classes.toolbar}>
          {/** small screens */}
          <Hidden mdUp>
            <IconButton
              className={classes.menuButton}
              color="inherit"
              onClick={onMobileNavOpen}
              size="large"
            >
              <SvgIcon fontSize="small">
                <MenuIcon />
              </SvgIcon>
            </IconButton>
          </Hidden>
          {/** big screens */}
          <Hidden mdDown>
            <RouterLink to="/app/home">
              <Logo />
            </RouterLink>
            <TopBarCTA />
          </Hidden>
          <Box ml={2} flexGrow={1} />
          {/** small screens */}
          <Hidden mdUp>
            <Box flexGrow={1}>
              <SmileLogoIcon />
            </Box>
          </Hidden>
          {/** visible to all */}
          <KatsolinaDetailsIcon />
          <LogIcon />
          <CrawlerQueueIcon />
          <Help />
          <Box ml={2}>
            <Account openSidebar={handleOpen} />
          </Box>
        </Toolbar>
      </AppBar>
      <ChangePasswordSidebar open={open} handleClose={handleClose} />
    </>
  )
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
}

export default TopBar
