import { useState, useEffect } from 'react'

export default function useRetry() {
  const [retry, setRetry] = useState(false)
  const [retryCount, setRetryCount] = useState(0)
  const [retryLimitReached, setRetryLimitReached] = useState(false)

  useEffect(() => {
    let resetRetryTimeout
    // allow to retry after 5 minutes
    if (retryCount === 3) {
      resetRetryTimeout = setTimeout(() => {
        setRetryCount(0)
        setRetryLimitReached(false)
      }, 1000 * 180)
    }

    return () => {
      clearTimeout(resetRetryTimeout)
    }
  }, [retryCount])

  const retryIt = () => {
    if (retryCount < 3) {
      setRetry(!retry)
      const newCount = retryCount + 1
      setRetryCount(newCount)
    } else {
      setRetryLimitReached(true)
    }
  }
  // console.log(retryCount)
  return [retry, retryIt, retryLimitReached]
}
