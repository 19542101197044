import React from 'react'
import Header from 'src/components/TableList/HeaderV2'
import { Typography } from '@mui/material'

const Title = () => (
  <Typography
    data-testid="customer-panel-header"
    variant="h3"
    color="textPrimary"
  >
    Keywords
  </Typography>
)

const NewsletterHeader = () => <Header Title={Title} />

export default NewsletterHeader
