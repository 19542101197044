import axios from 'src/utils/axios'
import { errorHandler } from 'src/utils/errorHandler'
import {
  DELETE_ALL_PACKSHOTS,
  FETCHING_IN_PROGRESS,
  FETCHING_SUCCESS,
  FETCHING_ERROR,
  CLOSE_SIDEBAR,
  PAGE_CHANGE,
  POPULATE_DROPDOWN_VALUES,
  FETCHING_PACKSHOTS,
  FETCHING_PACKSHOTS_ERROR,
  FETCHING_PACKSHOTS_SUCCESS,
  FETCHING_TEXTS,
  FETCHING_TEXTS_ERROR,
  FETCHING_TEXTS_SUCCESS,
  SET_PRIMARY,
  SET_UPLOADING_PACKSHOTS,
  UPDATE_ACTIVE_TAB,
  UPDATE_FILTERS,
  UPDATE_PACKSHOT_NAME,
  UPDATE_TEXTFILE_NAME,
  UPLOADING_PACKSHOTS_ERROR,
  UPLOADING_TEXTFILES,
  UPLOADING_TEXTFILES_ERROR,
  UPLOADING_TEXTFILES_SUCCESS,
  UPLOAD_PACKSHOTS_SUCCESS,
  DELETE_PACKSHOT,
  DELETE_TEXTFILES,
  OPEN_ROW,
  RESET_FILTERS,
  FETCH_FOUND_PACKSHOTS_REQUEST,
  FETCH_FOUND_PACKSHOTS_SUCCESS,
  FETCH_FOUND_PACKSHOTS_ERROR,
  VALIDATE_PACKSHOT_CONFIRM,
  VALIDATE_PACKSHOT_INVALID,
} from 'src/actionTypes/damPznActionTypes'
export const REDUCER_NAME = 'damPzn'

export const fetchDamPzns =
  ({ page = 1, filters, source }) =>
  async (dispatch) => {
    const { pznOrTitle } = filters
    dispatch({
      type: FETCHING_IN_PROGRESS,
    })

    const manufacturerIds = filters?.manufacturer
      ? filters.manufacturer.map((manufacturer) => manufacturer.id).join(',')
      : null
    const brandIds = filters?.brand
      ? filters.brand.map((brand) => brand.id).join(',')
      : null
    const companyIds = filters?.companies
      ? filters.companies.map((c) => c.id).join(',')
      : null
    const params = {
      page,
      pznOrTitle: pznOrTitle || null,
      manufacturerIds: manufacturerIds ? manufacturerIds : null,
      brandIds: brandIds ? brandIds : null,
      companyIds: companyIds ? companyIds : null,
      packshotsUploaded: filters?.packshots?.toString()
        ? filters.packshots.toString()
        : null,
      textUploaded: filters?.text?.toString() ? filters.text.toString() : null,
    }

    try {
      const { data } = await axios.get('/dam/pzn', {
        params,
        cancelToken: source.token,
      })
      return dispatch({
        type: FETCHING_SUCCESS,
        payload: data,
      })
    } catch (e) {
      if (axios.isCancel(e)) {
        return null
      }
      const is404 = e?.response?.status === 404
      if (is404) {
        return dispatch({
          type: FETCHING_SUCCESS,
          payload: {
            slots: [],
            total: 0,
          },
        })
      }
      dispatch({
        type: FETCHING_ERROR,
      })
      return errorHandler(e, {})
    }
  }

export const getDropdownValues = (filters) => async (dispatch) => {
  const manufacturerIds = filters?.manufacturer
    ? filters.manufacturer.map((m) => m.id).join(',')
    : null

  const brandIds = filters?.brand
    ? filters.brand.map((b) => b.id).join(',')
    : null

  const companyIds = filters?.companies
    ? filters.companies.map((c) => c.id).join(',')
    : null

  const params = {
    brandIds,
    manufacturerIds,
    companyIds,
  }
  try {
    const { data } = await axios.get(`/dam/filters/pzn`, { params })

    const { brands, manufacturers, companies } = data

    const cleanedBrands = brands.filter((brand) => brand.name)

    return dispatch({
      type: POPULATE_DROPDOWN_VALUES,
      payload: {
        brands: cleanedBrands,
        manufacturers,
        companies,
      },
    })
  } catch (e) {
    return errorHandler(e, {})
  }
}

export const getPackshotsAction = (id, source) => async (dispatch) => {
  dispatch({
    type: FETCHING_PACKSHOTS,
  })
  try {
    const { data } = await axios.get(`/dam/pzn/packshots/${id}`, {
      cancelToken: source.token,
    })

    return dispatch({
      type: FETCHING_PACKSHOTS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (axios.isCancel(error)) {
      return 'canceled request'
    }

    if (error?.response?.status === 404) {
      return dispatch({
        type: FETCHING_PACKSHOTS_SUCCESS,
        payload: [],
      })
    }
    dispatch({
      type: FETCHING_PACKSHOTS_ERROR,
    })
    return errorHandler(error, {})
  }
}

export const uploadImages =
  ({ id, pzn, files }) =>
  async (dispatch) => {
    const packshotsArray = Array.from(files)
    dispatch({
      type: SET_UPLOADING_PACKSHOTS,
      payload: { uploadingPackshots: packshotsArray },
    })

    const formData = new FormData()

    formData.append('id', id)
    formData.append('pzn', pzn)

    packshotsArray.forEach((image) => {
      formData.append('images', image)
    })

    try {
      const { data } = await axios({
        method: 'POST',
        url: '/dam/upload-images',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      dispatch({
        type: UPLOAD_PACKSHOTS_SUCCESS,
        payload: {
          files: data,
          id,
        },
      })

      return data
    } catch (e) {
      dispatch({
        type: UPLOADING_PACKSHOTS_ERROR,
      })
      return errorHandler(e, {})
    }
  }

export const deletePackshotAction = (id) => async (dispatch) => {
  try {
    await axios.delete(`/dam/pzn/packshot/${id}`)
    return dispatch({
      type: DELETE_PACKSHOT,
      payload: id,
    })
  } catch (error) {
    return errorHandler(error, {})
  }
}

export const updatePackshotName =
  ({ id, name } = {}) =>
  async (dispatch) => {
    try {
      await axios.patch(`/dam/pzn/packshot/${id}/name/${name}`)
      return dispatch({
        type: UPDATE_PACKSHOT_NAME,
        payload: {
          id,
          name,
        },
      })
    } catch (e) {
      return errorHandler(e, {})
    }
  }

export const uploadTextFilesAction =
  ({ id, pzn, files }) =>
  async (dispatch) => {
    const filesArray = Array.from(files)
    dispatch({
      type: UPLOADING_TEXTFILES,
      payload: filesArray,
    })

    const formData = new FormData()

    formData.append('id', id)
    formData.append('pzn', pzn)

    filesArray.forEach((file) => {
      formData.append('textFiles', file)
    })

    try {
      const { data } = await axios({
        method: 'POST',
        url: '/dam/upload-text',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      return dispatch({
        type: UPLOADING_TEXTFILES_SUCCESS,
        payload: { files: data, id },
      })
    } catch (e) {
      dispatch({
        type: UPLOADING_TEXTFILES_ERROR,
      })
      return errorHandler(e, {})
    }
  }

export const updateTextFileName =
  ({ id, name } = {}) =>
  async (dispatch) => {
    if (!id || !name) {
      return errorHandler(
        new Error('not provided required arguments `id` or `name`'),
      )
    }
    try {
      await axios.patch(`/dam/pzn/text/${id}/name/${name}`)
      return dispatch({
        type: UPDATE_TEXTFILE_NAME,
        payload: {
          id,
          name,
        },
      })
    } catch (e) {
      return errorHandler(e, {})
    }
  }

export const updateFilterAction = (target, value) => (dispatch) =>
  dispatch({
    type: UPDATE_FILTERS,
    payload: {
      target,
      value,
    },
  })

export const pageChangeAction = (page) => (dispatch) =>
  dispatch({
    type: PAGE_CHANGE,
    payload: page,
  })

export const openRowAction = (index) => (dispatch) =>
  dispatch({
    type: OPEN_ROW,
    payload: { index },
  })

export const closeSidebar = () => (dispatch) =>
  dispatch({
    type: CLOSE_SIDEBAR,
  })

export const resetFilters = () => (dispatch) =>
  dispatch({ type: RESET_FILTERS })

export const onChangedTabAction = (newTab) => (dispatch) =>
  dispatch({ type: UPDATE_ACTIVE_TAB, payload: newTab })

export const downloadPdf = async (url) => {
  const { data } = await axios.get(url, {
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/pdf',
    },
  })
  return data
}

export const deleteTextFileAction = (files) => async (dispatch) => {
  try {
    const dataForDeleting = files.map((file) => file.textId)
    // TODO
    // backend returns error if deleting falied so check data obj axios returns
    await axios.delete('/dam/pzn/text', {
      data: { textIds: dataForDeleting },
    })
    return dispatch({
      type: DELETE_TEXTFILES,
      payload: dataForDeleting,
    })
  } catch (error) {
    return errorHandler(error, {})
  }
}

export const getTextFilesAction = (id, source) => async (dispatch) => {
  dispatch({
    type: FETCHING_TEXTS,
  })
  try {
    const { data } = await axios.get(`/dam/pzn/text/${id}`, {
      cancelToken: source.token,
    })
    return dispatch({
      type: FETCHING_TEXTS_SUCCESS,
      payload: {
        data,
        id,
      },
    })
  } catch (error) {
    if (axios.isCancel(error)) {
      return 'canceled request'
    }
    dispatch({
      type: FETCHING_TEXTS_ERROR,
    })
    return errorHandler(error, {})
  }
}

export const deleteAllPackshots = (packshotIds, rowId) => async (dispatch) => {
  try {
    await axios.delete('dam/pzn/packshots', {
      data: { packshotIds },
    })
    return dispatch({
      type: DELETE_ALL_PACKSHOTS,
      payload: { rowId },
    })
  } catch (error) {
    return errorHandler(error, {})
  }
}

export const fetchFoundPackshots = (shopId, pzn) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_FOUND_PACKSHOTS_REQUEST })
    const { data } = await axios.get(
      `/dam/pzn/media/confirmation-assets/${shopId}/${pzn}`,
    )

    dispatch({
      type: FETCH_FOUND_PACKSHOTS_SUCCESS,
      payload: { foundPackshots: data },
    })

    return null
  } catch (error) {
    dispatch({ type: FETCH_FOUND_PACKSHOTS_ERROR })
    return errorHandler(error, {})
  }
}

export const validatePackshotConfirm =
  (pzn, shopId, packshotId, scraperPackshotId) => async (dispatch) => {
    try {
      await axios.post('/dam/pzn/media/confirmation-assets', {
        pzn,
        shopId,
        status: 1,
        packshotId,
        scraperPackshotId,
      })

      dispatch({
        type: VALIDATE_PACKSHOT_CONFIRM,
        payload: { scraperPackshotId, packshotId },
      })

      return null
    } catch (error) {
      return errorHandler(error, {})
    }
  }

export const validatePackshotInvalid =
  (pzn, shopId, scraperPackshotId) => async (dispatch) => {
    try {
      await axios.post('/dam/pzn/media/confirmation-assets', {
        pzn,
        shopId,
        status: 0,
        scraperPackshotId,
      })
      dispatch({
        type: VALIDATE_PACKSHOT_INVALID,
        payload: { scraperPackshotId },
      })

      return null
    } catch (error) {
      return errorHandler(error, {})
    }
  }
