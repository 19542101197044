import produce from 'immer'

import {
  POPULATE_DROPDOWN_VALUES,
  FETCHING_IN_PROGRESS,
  FETCHING_ERROR,
  FETCHING_SUCCESS,
  PAGE_CHANGE,
  UPDATE_FILTERS,
  OPEN_ROW,
  CLOSE_SIDEBAR,
  RESET_FILTERS,
  FETCHING_DAILY_CHART_IN_PROGRESS,
  FETCHING_DAILY_CHART_SUCCESS,
  FETCHING_DAILY_CHART_ERROR,
  FETCHING_PRICE_CHART_DATA_SUCCESS,
  FETCHING_PRICE_CHART_DATA_ERROR,
  FETCHING_PRICE_CHART_DATA_IN_PROGRESS,
  FETCHING_COMPETITOR_CHART_DATA_SUCCESS,
  FETCHING_COMPETITOR_CHART_DATA_ERROR,
  FETCHING_COMPETITOR_CHART_DATA_IN_PROGRESS,
} from 'src/actions/wkzMediaListActions'

const initialState = {
  fetchingMedia: false,
  fetchingMediaError: false,
  mediaResults: [],
  shopsDropdown: [],
  manufacturersDropdown: [],
  placementsDropdown: [],
  brandsDropdown: [],
  activeRow: null,
  userData: null,
  pagination: {
    page: 1,
    count: 0,
  },
  filters: {
    shop: null,
    manufacturer: null,
    startDate: null,
    endDate: null,
    placement: null,
    brand: null,
  },
  sidebarData: null,
  dailyCharts: {},
  pricesChartData: {},
  competitorChartData: {},
  fetchingPricesChartData: false,
  pricesChartFetchingDataError: false,
  competitorChartFetchingError: false,
  fetchingCompetitorsChartData: false,
  dailyChartsFetching: false,
  dailyChartsError: false,
}

export default function WkzMediaListReducer(state = initialState, action) {
  switch (action.type) {
    case FETCHING_IN_PROGRESS:
      return produce(state, (draft) => {
        draft.fetchingMedia = true
        draft.fetchingMediaError = false
      })
    case FETCHING_ERROR:
      return produce(state, (draft) => {
        draft.fetchingMedia = false
        draft.fetchingMediaError = true
      })
    case FETCHING_SUCCESS:
      return produce(state, (draft) => {
        const { slots, total } = action.payload
        draft.mediaResults = slots || []
        draft.pagination = {
          ...draft.pagination,
          count: total,
        }
        draft.fetchingMedia = false
        draft.fetchingMediaError = false
        draft.activeRow = null
      })
    case POPULATE_DROPDOWN_VALUES:
      return produce(state, (draft) => {
        draft.shopsDropdown = action.payload?.shops || []
        draft.manufacturersDropdown = action.payload?.manuCustomer || []
        draft.placementsDropdown = action.payload?.placements || []
        draft.brandsDropdown = action.payload?.brands || []
      })
    case UPDATE_FILTERS:
      return produce(state, (draft) => {
        const { filter, value } = action.payload
        draft.filters[filter] = value
        draft.pagination.page = 1
      })
    case RESET_FILTERS:
      return produce(state, (draft) => {
        draft.filters = initialState.filters
      })
    case PAGE_CHANGE:
      return produce(state, (draft) => {
        draft.pagination = {
          ...draft.pagination,
          page: action.payload,
        }
      })
    case OPEN_ROW:
      return produce(state, (draft) => {
        draft.activeRow = action.payload
        draft.sidebarData = { ...draft.mediaResults[action.payload] }
      })
    case CLOSE_SIDEBAR:
      return produce(state, (draft) => {
        draft.activeRow = null
        draft.userData = null
        draft.sidebarData = null
      })
    case FETCHING_DAILY_CHART_SUCCESS:
      return produce(state, (draft) => {
        draft.dailyCharts[action.payload.banner_id] = action.payload
        draft.dailyChartsFetching = false
        draft.dailyChartsError = false
      })
    case FETCHING_DAILY_CHART_IN_PROGRESS:
      return produce(state, (draft) => {
        draft.dailyChartsFetching = true
        draft.dailyChartsError = false
      })
    case FETCHING_DAILY_CHART_ERROR:
      return produce(state, (draft) => {
        draft.dailyChartsFetching = false
        draft.dailyChartsError = true
      })
    case FETCHING_PRICE_CHART_DATA_IN_PROGRESS:
      return produce(state, (draft) => {
        draft.fetchingPricesChartData = true
        draft.pricesChartFetchingDataError = false
      })
    case FETCHING_PRICE_CHART_DATA_ERROR:
      return produce(state, (draft) => {
        draft.fetchingPricesChartData = false
        draft.pricesChartFetchingDataError = true
      })
    case FETCHING_PRICE_CHART_DATA_SUCCESS:
      return produce(state, (draft) => {
        draft.fetchingPricesChartData = false
        draft.pricesChartFetchingDataError = false
        draft.pricesChartData[action.payload.banner_id] = action.payload
      })
    case FETCHING_COMPETITOR_CHART_DATA_ERROR:
      return produce(state, (draft) => {
        draft.fetchingCompetitorsChartData = false
        draft.competitorChartFetchingError = true
      })
    case FETCHING_COMPETITOR_CHART_DATA_IN_PROGRESS:
      return produce(state, (draft) => {
        draft.fetchingCompetitorsChartData = true
        draft.competitorChartFetchingError = false
      })
    case FETCHING_COMPETITOR_CHART_DATA_SUCCESS:
      return produce(state, (draft) => {
        draft.fetchingCompetitorsChartData = false
        draft.competitorChartFetchingError = false
        draft.competitorChartData[action.payload.cacheKey] = action.payload.data
      })
    default:
      return state
  }
}
