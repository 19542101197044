import React from 'react'

// for some reason icon wont load if copied from figma, and works if we put it in react component
function FilterIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6V8H21V6H3ZM10 18H14V16H10V18ZM18 13H6V11H18V13Z"
        fill="#263238"
      />
    </svg>
  )
}

export default FilterIcon
