import React, { memo, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import {
  NO_INTERNET,
  SERVER_ERROR,
  NO_RESULTS,
  WKZ_NO_RESULTS,
  INVALID_SEARCH,
} from 'src/constants/errorPageConstants'
import NoInternetConnection from './NoInternetConnection'
import SomethingWentWrong from './SomethingWentWrong'
import NoResults from './NoResults'
import WkzEmpty from './WkzEmpty'
import InvalidSearch from './InvalidSearch'

function ErrorPageByType({
  type,
  showNotification = false,
  retry = false,
  ...rest
}) {
  const { enqueueSnackbar } = useSnackbar()
  useEffect(() => {
    if (showNotification) {
      enqueueSnackbar('Error. Try again', {
        variant: 'error',
      })
    }
  }, [enqueueSnackbar, showNotification, retry])

  if (window.navigator.onLine === false) {
    return <NoInternetConnection {...rest} />
  }

  if (type === NO_INTERNET) {
    return <NoInternetConnection {...rest} />
  }

  if (type === SERVER_ERROR) {
    return <SomethingWentWrong {...rest} />
  }

  if (type === WKZ_NO_RESULTS) {
    return <WkzEmpty {...rest} />
  }

  if (type === NO_RESULTS) {
    return <NoResults {...rest} />
  }

  if (type === INVALID_SEARCH) {
    return <InvalidSearch {...rest} />
  }

  return <NoResults />
}

export default memo(ErrorPageByType)
