import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import clsx from 'clsx'

/**
 * Input field that is autocomplete and uses asynchronous options
 */

export default function SearchAsYouType({
  options = [],
  loading = false,
  onSearch,
  onSelect,
  isOptionEqualToValue, // (option, value) => option.title === value.title
  getOptionLabel, // (option) => option.title
  classes = {},
  label = '',
  renderOption = null,
  value,
}) {
  const [searchValue, setSearchValue] = useState(null)
  /**
   * need to have double value for the same thing because if we use @searchValue both on @Autocomplete and @TextInput components
   * then it won't work properly and it will set `undefined` as field value
   * don't know why
   */
  const [searchDoubled, setSearchDoubled] = useState(null)
  const onChange = async (_, val) => {
    setSearchValue(val)
    onSearch(val)
  }

  React.useEffect(() => {
    setSearchValue(value)
    setSearchDoubled(value)
  }, [value])

  return (
    <Autocomplete
      id="searchAsType"
      className={clsx(classes.autocomplete && classes.autocomplete)}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      options={options}
      loading={loading}
      onChange={onSelect}
      value={searchDoubled}
      onInputChange={onChange}
      filterOptions={(x) => x}
      renderOption={renderOption}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          value={searchValue || null}
          className={clsx(classes.textField && classes.textField)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

SearchAsYouType.propTypes = {
  options: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  isOptionEqualToValue: PropTypes.func.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
}
