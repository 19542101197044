import React from 'react'
import { Box, Card, Typography, Button } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import SomethingWentWrongImage from 'src/assets/images/errors/somethingWentWrong.svg'

const useStyles = makeStyles()((theme) => ({
  card: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 500,
    whiteSpace: 'normal',
  },
  bigTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '28px',
    textAlign: 'center',
    marginTop: 16,
    color: '#263238',
  },
  text: {
    fontFamily: 'PT-Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '21px',
    alignItems: 'center',
    textAlign: 'center',
  },
  textWrapper: {
    width: 380,
    [theme.breakpoints.down('md')]: {
      width: 300,
    },
    [theme.breakpoints.down('sm')]: {
      width: 250,
      margin: 'auto',
    },
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  link: {
    color: '#4472C4',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 16,
  },
  button: {
    background: '#F5F5F5',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.14)',
    borderRadius: '4px',
  },
}))

function SomethingWentWrong({ onRetry }) {
  const { classes } = useStyles()
  return (
    <Card className={classes.card} data-testid="error-screen">
      <Box p={1}>
        <Box className={classes.imageWrapper}>
          <img src={SomethingWentWrongImage} alt="something went wrong" />
        </Box>

        <Typography className={classes.bigTitle}>
          Something went wrong.
        </Typography>
        <Box className={classes.textWrapper} mt={1}>
          <p className={classes.text}>
            Please try again. If you still have problems, please contact{' '}
            <span>
              <a className={classes.link} href="mailto:support@smile.bi">
                support@smile.bi
              </a>
            </span>{' '}
          </p>
        </Box>
        <Box className={classes.buttonWrapper}>
          <Button
            data-testid="retry-button"
            className={classes.button}
            onClick={onRetry}
          >
            RETRY
          </Button>
        </Box>
      </Box>
    </Card>
  )
}

export default SomethingWentWrong
