import React, { useEffect, useState, useCallback } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useSelector, useDispatch } from 'react-redux'
import SearchAsYouType from 'src/components/SearchAsYouType'
import { getCategoriesDebounced, updateSidebarValues } from '../lib/actions'
import { getThreadValue, getCategoriesSelector } from '../lib/selectors'

const useStyles = makeStyles()(() => ({
  input: {
    width: '100%',
  },
}))

export default function CategoryInput({ kat }) {
  const { classes } = useStyles()
  const activeRow = useSelector(getThreadValue(kat.subBrandId)) || {}
  const selectedCategories = useSelector(getCategoriesSelector)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [searchVal, setSearchVal] = useState('')

  const shopId = activeRow?.shop?.id || null
  const category = activeRow?.category || null
  const dispatch = useDispatch()

  const query = useCallback(
    async (domainId, searchFor) => {
      if (!domainId) {
        return null
      }
      setLoading(true)
      try {
        const res =
          (await getCategoriesDebounced(domainId, searchFor, dispatch)) || []
        setOptions(res)
      } catch (error) {
        setLoading(false)
      } finally {
        setLoading(false)
      }
      return null
    },
    [dispatch],
  )

  useEffect(() => {
    query(shopId, searchVal).catch((e) => console.log(e))
  }, [searchVal, shopId, query])

  useEffect(() => {
    setSearchVal('')
  }, [shopId])

  // useEffect(() => {
  //   if (selectedCategories) {
  //     setOptions(selectedCategories)
  //   }
  // }, [selectedCategories])

  const onSearch = async (title = '') => {
    setSearchVal(title)
  }

  const onSelect = (_, value) => {
    dispatch(updateSidebarValues(kat.subBrandId, 'category', value))
  }
  const isOptionEqualToValue = (option, value) =>
    option.category === value.category
  const getOptionLabel = (option) =>
    option.category || option.categoryLink || ''
  const renderOption = (props, option) => (
    <li {...props} key={option.id}>
      {option.category || option.categoryLink || ''}
    </li>
  )
  return (
    <SearchAsYouType
      onSelect={onSelect}
      onSearch={onSearch}
      loading={loading}
      options={options}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      label="Category"
      renderOption={renderOption}
      classes={{ autocomplete: classes.input }}
      value={category}
    />
  )
}
