import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router'
import {
  Drawer,
  Hidden,
  Box,
  Grid,
  Typography,
  IconButton,
  SvgIcon,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useDispatch, useSelector } from 'react-redux'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { toggleHelpDrawerAction } from 'src/actions/helpActions'
import { X as CloseIcon, HelpCircle as HelpIcon } from 'react-feather'
import HelpItem from './HelpItem'
import helpContent from './helpContent.json'

const useStyles = makeStyles()(() => ({
  mobileDrawer: {
    width: 256,
    top: 64,
    boxShadow:
      '0px 1px 2px rgba(63, 63, 68, 0.15), 0px 0px 0px rgba(63, 63, 68, 0.05)',
    zIndex: 1202,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
    zIndex: 1201,
  },
  container: {
    marginBottom: 130,
  },
  headerContainer: {
    marginTop: 26,
    marginBottom: 30,
  },
  helpHeader: {
    display: 'flex',
    padding: '12px',
  },
  helpText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '28px',
  },
  helpIcon: {
    paddingTop: 2,
  },
  closeIcon: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 2,
  },
}))

function HelpSidebar() {
  const openDrawer = useSelector((state) => state?.help?.openDrawer) || false
  const activeHelper = useSelector((state) => state.help.activeHelper)
  const dispatch = useDispatch()
  const { classes } = useStyles()
  const onMobileClose = () => {
    dispatch(toggleHelpDrawerAction())
  }
  const [scrollRef, setScrollRef] = useState(null)
  const location = useLocation()
  const { user } = useSelector((state) => state.account)
  const { isAdmin } = user
  const scrollTo = (ref) => {
    setScrollRef(ref)
  }

  useEffect(() => {
    if (openDrawer && onMobileClose) {
      onMobileClose()
    }
    // eslint-disable-next-line
  }, [location.pathname])

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Grid container className={classes.headerContainer}>
        <Grid item xs={8}>
          <Box className={classes.helpHeader}>
            <Box className={classes.helpIcon}>
              <SvgIcon>
                <HelpIcon />
              </SvgIcon>
            </Box>
            <Box ml={1}>
              <Typography className={classes.helpText}>Help</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box className={classes.closeIcon}>
            <IconButton onClick={onMobileClose} size="large">
              <SvgIcon htmlColor="#263238">
                <CloseIcon />
              </SvgIcon>
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      <PerfectScrollbar
        containerRef={scrollTo}
        options={{ suppressScrollX: true }}
      >
        <Grid container className={classes.container}>
          {helpContent.map(
            ({ title = '', content: textContent = '', id, adminOnly }) => {
              const active = activeHelper === id
              // do not display help note if user is admin and its only for admins
              if (!isAdmin && adminOnly) {
                return null
              }

              return (
                <HelpItem
                  key={`${id}_${title}`}
                  active={active}
                  title={title}
                  text={textContent}
                  scrollRef={scrollRef}
                />
              )
            },
          )}
        </Grid>
      </PerfectScrollbar>
    </Box>
  )

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="right"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openDrawer}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="right"
          classes={{ paper: classes.desktopDrawer }}
          open={openDrawer}
          onClose={onMobileClose}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  )
}

export default HelpSidebar
