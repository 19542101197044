import { useState, useEffect, useCallback } from 'react'

const initialVersion = '1'

const useVersionSwitcher = () => {
  const [currentVersion, setCurrentVersion] = useState(() => {
    return localStorage.getItem('suggestionEngineVersion') || initialVersion
  })

  useEffect(() => {
    localStorage.setItem('suggestionEngineVersion', currentVersion)
  }, [currentVersion])

  const switchVersion = useCallback(() => {
    if (currentVersion === '1') {
      setCurrentVersion('2')
    } else {
      setCurrentVersion('1')
    }
  }, [currentVersion])

  return {
    currentVersion,
    switchVersion,
  }
}

export default useVersionSwitcher
