import { useCallback } from 'react'
import { Box, IconButton, SvgIcon } from '@mui/material'
import { useSelector } from 'react-redux'
import { getScope } from 'src/selectors/accountSelectors'
import { AutoFixHigh } from '@mui/icons-material'
import useBackgroundKatsoll from 'src/views/management/KatSoll/hooks/useBackgroundRecomendation'
import { styled } from '@mui/material/styles'

const RainbowIcon = styled(AutoFixHigh)`
  cursor: pointer;
  animation: neonGlow 2s infinite;

  @keyframes neonGlow {
    0% {
      color: cyan;
    }
    25% {
      color: magenta;
    }
    50% {
      color: limegreen;
    }
    75% {
      color: hotpink;
    }
    100% {
      color: electricblue;
    }
  }
`

export default function KatsolinaDetailsIcon() {
  //   const { classes } = useStyles()
  const { setOpenDetails, openDetails, jobs } = useBackgroundKatsoll()
  const hasScope = useSelector(getScope('category'))
  const processRunning = jobs.some((job) => job.status === 'running')
  const toggleQueueSidebar = useCallback(() => {
    if (openDetails) {
      setOpenDetails(false)
    } else {
      setOpenDetails(true)
    }
  }, [openDetails, setOpenDetails])

  if (!hasScope) {
    return null
  }

  return (
    <Box ml={1}>
      <IconButton onClick={toggleQueueSidebar} size="large">
        {processRunning ? (
          <SvgIcon htmlColor="yellow">
            <RainbowIcon />
          </SvgIcon>
        ) : (
          <SvgIcon htmlColor="#fff">
            <AutoFixHigh />
          </SvgIcon>
        )}
      </IconButton>
    </Box>
  )
}
