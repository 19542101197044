export const TOGGLE_HELP_DRAWER = '@help/TOGGLE_HELP_DRAWER'
export const SET_ACTIVE_HELPER = '@help/SET_ACTIVE_HELPER'

export const toggleHelpDrawerAction = () => (dispatch) =>
  dispatch({
    type: TOGGLE_HELP_DRAWER,
  })

export const setActiveHelperAction = (helper) => (dispatch) =>
  dispatch({
    type: SET_ACTIVE_HELPER,
    payload: helper,
  })
