import produce from 'immer'
import {
  OPEN_DRAWER,
  CLOSE_SIDEBAR,
  SELECT_ROW,
  SET_RESULTS,
  FETCHING_RESULTS_404,
  FETCHING_RESULTS,
  FETCHING_ERROR,
  UPDATE_ROW,
  UPDATE_ACTIVATION_STATUS,
  UPDATE_CONFIRMATION_STATUS,
  PAGE_CHANGE,
  SET_FILTERS,
  UPDATE_FILTERS,
} from 'src/actionTypes/googleShoppingActionTypes'
const initialState = {
  pagination: {
    page: 1,
    count: 0,
  },
  filters: {
    search: null,
    manufacturer: null,
    brand: null,
    status: null,
  },
  fetching: false,
  fetchingError: false,
  openDrawer: false,
  activeRow: null,
  results: [],
  brands: [],
  manufacturers: [],
  statuses: [],
}
export default function GoogleShoppingReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case SET_FILTERS:
      return produce(state, (draft) => {
        draft.brands = payload.brands ?? []
        draft.manufacturers = payload.manufacturers ?? []
        draft.statuses = payload.confirmationStatus ?? []
      })
    case OPEN_DRAWER:
      return produce(state, (draft) => {
        draft.openDrawer = true
      })
    case PAGE_CHANGE:
      return produce(state, (draft) => {
        draft.pagination.page = payload
      })
    case CLOSE_SIDEBAR:
      return produce(state, (draft) => {
        draft.activeRow = null
        draft.openDrawer = false
      })
    case SELECT_ROW:
      return produce(state, (draft) => {
        draft.activeRow = payload
        draft.openDrawer = true
      })
    case FETCHING_RESULTS:
      return produce(state, (draft) => {
        draft.fetching = true
        draft.fetchingError = true
      })
    case FETCHING_RESULTS_404:
      return produce(state, (draft) => {
        draft.fetching = false
        draft.fetchingError = false
        draft.results = []
      })
    case FETCHING_ERROR:
      return produce(state, (draft) => {
        draft.fetching = false
        draft.fetchingError = true
      })
    case SET_RESULTS:
      return produce(state, (draft) => {
        draft.results = payload.data
        draft.pagination.count = payload.count
        draft.fetching = false
        draft.fetchingError = false
      })
    case UPDATE_FILTERS:
      return produce(state, (draft) => {
        const { target, value } = payload
        draft.filters[target] = value
        draft.pagination.page = 1
      })
    case UPDATE_ROW:
    case UPDATE_ACTIVATION_STATUS:
    case UPDATE_CONFIRMATION_STATUS:
      return produce(state, (draft) => {
        const idxOfTarget = draft.results.findIndex(
          (row) => row.pzn === payload.pzn,
        )
        if (idxOfTarget > -1) {
          draft.results[idxOfTarget] = { ...payload }
          draft.activeRow = { ...payload }
        }
      })
    default:
      return state
  }
}
