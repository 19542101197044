import axios from 'src/utils/axios'
import { errorHandler } from 'src/utils/errorHandler'
import {
  UPDATE_FILTERS,
  CLOSE_SIDEBAR,
  OPEN_DRAWER,
  FETCHING,
  SET_RESULTS,
  FETCHING_404,
  FETCHING_ERROR,
  PAGE_CHANGE,
  SET_SIDEBAR_ABDA,
  ADD_KEYWORD,
  REMOVE_KEYWORD,
  SET_ALL_KEYWORDS,
} from './actionTypes'

export const getAbdaKeywords =
  ({ page, filters, source }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: FETCHING,
      })

      const params = {
        page,
        abda: filters?.search || null,
      }

      const { data } = await axios.get('/keywords/abda-groups', {
        params,
        cancelToken: source.token,
      })
      const { count, res } = data
      return dispatch({
        type: SET_RESULTS,
        payload: {
          data: res,
          count,
        },
      })
    } catch (error) {
      if (axios.isCancel(error)) {
        return null
      }
      const is404 = error?.response?.status === 404
      if (is404) {
        return dispatch({
          type: FETCHING_404,
        })
      }
      dispatch({
        type: FETCHING_ERROR,
      })
      return errorHandler(error, {})
    }
  }

export const addNewService = (kw) => async () => {
  try {
    return await axios.post('/keywords', { name: kw })
  } catch (error) {
    return errorHandler(error, {})
  }
}

export const getKeywordsForAbdaByAbda =
  ({ abdaPodGrp: abda } = {}) =>
  async (dispatch) => {
    try {
      const { data } = await axios.get(
        `/keywords/abda-groups/abda-keywords/${abda}`,
      )
      return dispatch({
        type: SET_SIDEBAR_ABDA,
        payload: data.res,
      })
    } catch (error) {
      return errorHandler(error, {})
    }
  }

export const addKeywordToAbda =
  ({ id, keyword, abda }) =>
  async (dispatch) => {
    try {
      const body = {
        abda,
        keywordId: id,
        keyword,
      }
      const { data } = await axios.post(
        '/keywords/abda-groups/abda-keywords',
        body,
      )
      return dispatch({
        type: ADD_KEYWORD,
        payload: data,
      })
    } catch (error) {
      return errorHandler(error, {})
    }
  }
export const removeKeywordFromAbda = (keywordId) => async (dispatch) => {
  try {
    await axios.delete(`/keywords/abda-groups/abda-keywords/${keywordId}`)
    return dispatch({
      type: REMOVE_KEYWORD,
      payload: keywordId,
    })
  } catch (error) {
    return errorHandler(error, {})
  }
}

export const importAllKeywords = () => async (dispatch) => {
  try {
    const { data } = await axios.get('/sku-config/sku/keywords')
    return dispatch({
      type: SET_ALL_KEYWORDS,
      payload: data,
    })
  } catch (error) {
    return errorHandler(error, {})
  }
}

export const pageChangeAction = (page) => (dispatch) =>
  dispatch({
    type: PAGE_CHANGE,
    payload: page,
  })

export const updateFiltersAction = (target, value) => (dispatch) =>
  dispatch({
    type: UPDATE_FILTERS,
    payload: {
      target,
      value,
    },
  })

export const closeDrawerAction = () => (dispatch) =>
  dispatch({
    type: CLOSE_SIDEBAR,
  })
export const openDrawer = (data) => (dispatch) =>
  dispatch({ type: OPEN_DRAWER, payload: data })
