import React from 'react'
import { TextField, Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
const useStyles = makeStyles()(() => ({
  input: { width: 300 },
}))
export default function AddInput({ onChange, value }) {
  const { classes } = useStyles()
  return (
    <Box>
      <TextField
        className={classes.input}
        onChange={onChange}
        value={value}
        placeholder="Service Name"
        variant="outlined"
      />
    </Box>
  )
}
