import produce from 'immer'
import {
  PAGE_CHANGE,
  FETCHING_IN_PROGRESS,
  FETCHING_SUCCESS,
  FETCHING_ERROR,
  UPDATE_FILTERS,
  IMPORT_FILTERS,
  OPEN_SIDEBAR,
  CLOSE_SIDEBAR,
  IMPORT_SHOPS,
  SELECT_SHOP,
  SELECT_STATUS,
  SET_PACKSHOTS,
  SET_STATUS,
  FETCHING_PACKSHOTS_FOR_RATING,
  FETCHING_PACKSHOTS_FOR_RATING_ERROR,
  CLEAR_FILTERS_FOR_RATING,
  UPDATE_SINGLE_FILTER,
  CLEAR_ALL_SELECTED_MANUFACTURERS,
  CLEAR_ALL_SELECTED_BRANDS,
  CLEAR_ALL_SELECTED_ARTICLE_NAMES,
} from 'src/actionTypes/packshotsRatingTypes'
const initialState = {
  pagination: {
    page: 1,
    count: 0,
  },
  shops: [],
  activeRow: null,
  selectedShop: null,
  selectedStatus: null,
  pzns: [],
  fetchingInProgress: false,
  fetchingError: false,
  filters: {
    pzn: null,
    manufacturers: [],
    brands: [],
    articleNames: [],
  },
  brands: [],
  manufacturers: [],
  packshots: null,
  settingPackshotsInProgress: false,
  statuses: [
    { id: 1, name: 'OK' },
    { id: 0, name: 'NOT OK' },
    { id: 50, name: 'Unconfirmed' },
  ],
}

export default function PackshotsRatingReducer(state = initialState, action) {
  switch (action.type) {
    case FETCHING_IN_PROGRESS:
      return produce(state, (draft) => {
        draft.fetchingInProgress = true
        draft.fetchingError = false
        draft.pzns = null
      })
    case FETCHING_SUCCESS:
      return produce(state, (draft) => {
        const { result, count } = action.payload
        draft.fetchingError = false
        draft.pzns = result
        draft.fetchingInProgress = false
        draft.pagination = {
          ...draft.pagination,
          count,
        }
      })
    case FETCHING_ERROR:
      return produce(state, (draft) => {
        draft.fetchingError = true
        draft.fetchingInProgress = false
        draft.pzns = null
      })
    case PAGE_CHANGE:
      return produce(state, (draft) => {
        draft.pagination.page = action.payload.page
      })
    case UPDATE_FILTERS:
      return produce(state, (draft) => {
        const { target, value } = action.payload
        const hasSomethingInside = draft.filters[target]?.length > 0
        if (hasSomethingInside) {
          const alreadyInArray = draft.filters[target].find(
            (e) => e.id === value.id,
          )
          if (alreadyInArray) {
            const filtered = draft.filters[target].filter(
              (e) => e.id !== value.id,
            )
            draft.filters[target] = filtered
          } else {
            draft.filters[target] = [...draft.filters[target], value]
          }
        } else {
          draft.filters[target] = [value]
        }
        draft.pagination.page = 1
      })
    case UPDATE_SINGLE_FILTER:
      return produce(state, (draft) => {
        const { target, value } = action.payload
        draft.filters[target] = value
        draft.pagination.page = 1
      })
    case IMPORT_FILTERS:
      return produce(state, (draft) => {
        const { brands, manufacturers } = action.payload
        draft.brands = brands
        draft.manufacturers = manufacturers
      })
    case OPEN_SIDEBAR:
      return produce(state, (draft) => {
        draft.activeRow = action.payload
      })
    case CLOSE_SIDEBAR:
      return produce(state, (draft) => {
        draft.activeRow = null
      })
    case IMPORT_SHOPS:
      return produce(state, (draft) => {
        draft.shops = action.payload
      })
    case SELECT_SHOP:
      return produce(state, (draft) => {
        draft.selectedShop = action.payload
      })
    case SELECT_STATUS:
      return produce(state, (draft) => {
        draft.selectedStatus = action.payload
      })
    case CLEAR_FILTERS_FOR_RATING:
      return produce(state, (draft) => {
        // draft.selectedStatus = null
        // draft.selectedShop = null
        draft.packshots = null
      })
    case SET_PACKSHOTS:
      return produce(state, (draft) => {
        draft.packshots = action.payload
        draft.settingPackshotsInProgress = false
      })
    case FETCHING_PACKSHOTS_FOR_RATING:
      return produce(state, (draft) => {
        draft.settingPackshotsInProgress = true
      })
    case FETCHING_PACKSHOTS_FOR_RATING_ERROR:
      return produce(state, (draft) => {
        draft.settingPackshotsInProgress = false
      })
    case SET_STATUS:
      return produce(state, (draft) => {
        const index = draft?.packshots?.findIndex(
          (e) => e.id === action.payload.id,
        )
        if (index > -1) {
          draft.packshots[index] = action.payload
        }
      })
    case CLEAR_ALL_SELECTED_MANUFACTURERS:
      return produce(state, (draft) => {
        draft.filters.manufacturers = []
      })
    case CLEAR_ALL_SELECTED_BRANDS:
      return produce(state, (draft) => {
        draft.filters.brands = []
      })
    case CLEAR_ALL_SELECTED_ARTICLE_NAMES:
      return produce(state, (draft) => {
        draft.filters.articleNames = []
      })
    default:
      return state
  }
}
