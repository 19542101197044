import axios from 'src/utils/axios'
import { errorHandler } from 'src/utils/errorHandler'
export const REDUCER_NAME = 'usersPanel'

export const FETCHING_ERROR = '@user_panel/FETCHING_ERROR'
export const FETCHING_IN_PROGRESS = '@user_panel/FETHING_IN_PROGRESS'
export const FETCHING_SUCCESS = '@user_panel/FETCHING_SUCCESS'
export const PAGE_CHANGE = '@user_panel/PAGE_CHANGE'
export const POPULATE_DROPDOWN_VALUES = '@user_panel/POPULATE_DROPDOWN_VALUES'
export const UPDATE_FILTERS = '@user_panel/UPDATE_FILTERS'
export const RESET_FILTERS = '@user_panel/RESET_FILTERS'
export const UPDATE_USER_VALUES = '@user_panel/UPDATE_USER_VALUES'
export const FIELDS_MISSING = '@user_panel/FIELDS_MISSING'

export const OPEN_ROW = '@user_panel/OPEN_ROW'
export const OPEN_USERS_SIDEBAR = '@user_panel/OPEN_USERS_SIDEBAR'
export const CLOSE_USERS_SIDEBAR = '@user_panel/CLOSE_USERS_SIDEBAR'

export const CREATE_USER = '@user_panel/CREATE_USER'
export const UPDATE_USER = '@user_panel/UPDATE_USER'
export const DEACTIVATE_USER_BY_ID = '@user_panel/DEACTIVATE_USER_BY_ID'
export const ACTIVATE_USER_BY_ID = '@user_panel/ACTIVATE_USER_BY_ID'

export const fetchUsers =
  ({ page = 1, filters = {}, source }) =>
  async (dispatch) => {
    dispatch({
      type: FETCHING_IN_PROGRESS,
    })
    const { user_account: userAccount, company, role } = filters
    const params = {
      page,
      user_account: userAccount || null,
      company_id: company?.companyId,
      role_id: role?.roleId,
    }

    try {
      const { data } = await axios.get('/auth/get-all-users', {
        params,
        cancelToken: source?.token,
      })

      return dispatch({
        type: FETCHING_SUCCESS,
        payload: data,
      })
    } catch (e) {
      if (axios.isCancel(e)) {
        return null
      }
      const is404 = e?.response?.status === 404
      if (is404) {
        return dispatch({
          type: FETCHING_SUCCESS,
          payload: {
            slots: [],
            total: 0,
          },
        })
      }

      dispatch({
        type: FETCHING_ERROR,
      })
      return errorHandler(e, {})
    }
  }

export const fetchDropdownValuesForFilters = () => async (dispatch) => {
  try {
    const { data } = await axios.get('/auth/user/filters')

    return dispatch({
      type: POPULATE_DROPDOWN_VALUES,
      payload: {
        companies: data.companies || [],
        genders: data.genders || [],
        roles: data.roles || [],
        positions: data.positions || [],
        scopes: data.scopes || [],
        plans: data.plans || [],
        manufacturers: data.manufacturers || [],
      },
    })
  } catch (e) {
    return errorHandler(e, {})
  }
}

const getUserData = (values) => {
  const {
    email,
    company,
    scopes,
    birthday: birthdayRaw,
    first_name: firstName,
    last_name: lastName,
    gender,
    id,
    role,
    job_position: jobPosition,
    plan,
    manufacturers,
    password,
  } = values
  const scopesId = `[${scopes.map((item) => item.scopeId)}]`
  const manufacturerId = `[${manufacturers.map(
    (manufacturer) => manufacturer.manuId,
  )}]`
  const birthday = birthdayRaw ? new Date(birthdayRaw).toISOString() : null
  const params = {
    birthday,
    orgId: company.companyId,
    email,
    firstName,
    genderId: gender.genderId,
    id,
    lastName,
    role: role.role,
    positionId: jobPosition.positionId,
    scopesId,
    planId: plan.planId,
    manufacturerId,
    password,
  }

  return params
}

export const createUserAction =
  ({ values, companies }) =>
  async (dispatch) => {
    const userData = getUserData(values, companies)

    try {
      const { data } = await axios.post('/auth/signup', userData)
      const returnValue = dispatch({
        type: CREATE_USER,
        payload: data,
      })

      dispatch(fetchUsers({ page: 1 }))

      return returnValue
    } catch (e) {
      return errorHandler(e, {})
    }
  }

export const updateUserAction = (values, companies) => async (dispatch) => {
  const userData = getUserData(values, companies)

  try {
    const { data } = await axios.post('/auth/user/account', userData)
    const returnValue = dispatch({
      type: UPDATE_USER,
      payload: data,
    })

    dispatch(fetchUsers({ page: 1 }))

    return returnValue
  } catch (e) {
    return errorHandler(e, {})
  }
}

export const fieldsMissingAction = (errors) => (dispatch) =>
  dispatch({
    type: FIELDS_MISSING,
    payload: errors,
  })

export const pageChangeAction = (page) => (dispatch) =>
  dispatch({
    type: PAGE_CHANGE,
    payload: page,
  })

export const updateFiltersAction = (target, value) => (dispatch) =>
  dispatch({
    type: UPDATE_FILTERS,
    payload: {
      target,
      value,
    },
  })

export const updateUserValues = (actionType, key, value) => (dispatch) =>
  dispatch({
    type: actionType,
    payload: {
      type: key,
      value,
    },
  })

export const openRowAction = (index) => (dispatch) =>
  dispatch({
    type: OPEN_ROW,
    payload: index,
  })

export const openUsersSidebar = () => (dispatch) =>
  dispatch({
    type: OPEN_USERS_SIDEBAR,
  })

export const closeUsersSidebar = () => (dispatch) =>
  dispatch({
    type: CLOSE_USERS_SIDEBAR,
  })

export const resetFilters = () => (dispatch) =>
  dispatch({ type: RESET_FILTERS })

export function disableUserAccount(id) {
  return async (dispatch) => {
    try {
      await axios.patch(`/auth/user/deactivate/${id}`)
      return dispatch({
        type: DEACTIVATE_USER_BY_ID,
        payload: id,
      })
    } catch (error) {
      return errorHandler(error, {})
    }
  }
}

export function enableUserAccount(id) {
  return async (dispatch) => {
    try {
      await axios.patch(`/auth/user/activate/${id}`)
      return dispatch({
        type: ACTIVATE_USER_BY_ID,
        payload: id,
      })
    } catch (error) {
      return errorHandler(error, {})
    }
  }
}

export async function editPassword(id, pwd) {
  try {
    return await axios.patch(
      `/auth/user/update-password/${id}/${encodeURIComponent(pwd)}`,
    )
  } catch (error) {
    return errorHandler(error, {})
  }
}
