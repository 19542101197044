import { useSnackbar } from 'notistack'
import { useCallback } from 'react'

export default function useSimpleNotification() {
  const { enqueueSnackbar } = useSnackbar()
  const noticifation = useCallback(
    (type, msg) => {
      const successMsg = msg || 'Success'
      if (type === 'success') {
        return enqueueSnackbar(successMsg, {
          variant: 'success',
        })
      }
      const errMsg = msg || 'Error. Try again'
      return enqueueSnackbar(errMsg, {
        variant: 'error',
      })
    },
    [enqueueSnackbar],
  )

  return noticifation
}
