import produce from 'immer'
import uniqBy from 'lodash/uniqBy'
import {
  SET_FILTERS,
  UPDATE_FILTERS,
  REMOVE_CHECKED_PZN,
  ADD_CHECKED_PZN,
  CLEAR_ALL_CHECKED,
  CLOSE_SIDEBAR,
  OPEN_DRAWER,
  SET_KEYWORDS,
  FETCHING_KEYWORDS,
  FETCHING_KEYWORDS_404,
  FETCHING_KEYWORDS_ERROR,
  SET_IMPORTED_KEYWORDS,
  REMOVE_KEYWORD,
  SET_ALL_KEYWORDS,
  ASSIGN_KEYWORDS,
  PAGE_CHANGE,
  SET_CRAWLEDKW_VALUE,
  SET_SEARCHKW_VALUE,
  CONFIRM_ABDA,
  REMOVE_KEYWORD_FROM_MANY,
  REMOVE_ALL_KEYWORDS_FOR_PZN_BY_PZN_ID,
  REMOVE_ALL_KEYWORDS_FOR_SINGLE_PZN_BY_PZN_ID,
} from 'src/actionTypes/KeywordsActionTypes'
const initialState = {
  pzns: [],
  fetchingPzns: false,
  fetchingPznsError: false,
  brands: [],
  manufacturers: [],
  filters: {
    search: null,
    manufacturer: null,
    brand: null,
    bundlePzn: null,
  },
  shops: [],
  pagination: {
    page: 1,
    count: 0,
  },
  activeRow: null,
  openDrawer: false,
  addingMode: false,
  checkedMap: new Map(),
  checked: [],
  keywords: [],
  currentKeywords: {},
}
export default function KeywordsReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case SET_FILTERS:
      return produce(state, (draft) => {
        draft.manufacturers = payload.manufacturers
        draft.brands = payload.brands
      })
    case UPDATE_FILTERS:
      return produce(state, (draft) => {
        draft.filters[payload.target] = payload.value
        draft.pagination.page = 1
      })
    case REMOVE_CHECKED_PZN:
      return produce(state, (draft) => {
        if (Array.isArray(payload)) {
          payload.forEach((p) => draft.checkedMap.delete(p.id))
          draft.checked = state.checked.filter((pzn) =>
            draft.checkedMap.has(pzn.id),
          )
        } else {
          draft.checked = draft.checked.filter((pzn) => pzn.id !== payload.id)
          draft.checkedMap.delete(payload.id)
        }
      })
    case ADD_CHECKED_PZN:
      return produce(state, (draft) => {
        if (Array.isArray(payload)) {
          const deduplicated = uniqBy(
            [...state.checked, ...payload],
            (element) => element.id,
          )
          draft.checked = deduplicated
          payload.forEach((p) => draft.checkedMap.set(p.id, true))
        } else {
          draft.checked.push(payload)
          draft.checkedMap.set(payload.id, true)
        }
      })
    case CLEAR_ALL_CHECKED:
      return produce(state, (draft) => {
        draft.checked = []
        draft.checkedMap = new Map()
      })

    case CLOSE_SIDEBAR:
      return produce(state, (draft) => {
        draft.activeRow = null
        draft.openDrawer = false
      })
    case PAGE_CHANGE:
      return produce(state, (draft) => {
        draft.pagination.page = payload
      })
    case OPEN_DRAWER:
      return produce(state, (draft) => {
        draft.openDrawer = true
      })
    case SET_KEYWORDS:
      return produce(state, (draft) => {
        draft.pzns = payload.data
        draft.pagination.count = payload.count
        draft.fetchingPzns = false
        draft.fetchingPznsError = false
      })
    case FETCHING_KEYWORDS:
      return produce(state, (draft) => {
        draft.fetchingPzns = true
        draft.fetchingPznsError = true
      })
    case FETCHING_KEYWORDS_404:
      return produce(state, (draft) => {
        draft.fetchingPzns = false
        draft.fetchingPznsError = false
        draft.pzns = []
        draft.pagination.count = 0
      })
    case FETCHING_KEYWORDS_ERROR:
      return produce(state, (draft) => {
        draft.fetchingPzns = false
        draft.fetchingPznsError = true
      })
    case SET_IMPORTED_KEYWORDS:
      return produce(state, (draft) => {
        const newKwds = payload.reduce((acc, next) => {
          const cp = { ...acc }
          cp[next.pznCId] = next
          return cp
        }, {})
        draft.currentKeywords = newKwds
      })
    case REMOVE_KEYWORD:
      return produce(state, (draft) => {
        const filteredKwds = draft.currentKeywords[payload.id].keywords.filter(
          (kwd) => kwd.id !== payload.kwdId,
        )
        draft.currentKeywords[payload.id].keywords = filteredKwds
      })
    case REMOVE_KEYWORD_FROM_MANY:
      return produce(state, (draft) => {
        const ids = payload.map((val) => val.id)
        draft.currentKeywords = Object.keys(state.currentKeywords).reduce(
          (acc, next) => {
            const element = { ...state.currentKeywords[next] }
            const filtered =
              element.keywords?.filter((e) => !ids.includes(e.id)) || []
            element.keywords = filtered
            return {
              ...acc,
              [next]: element,
            }
          },
          {},
        )
      })
    case REMOVE_ALL_KEYWORDS_FOR_PZN_BY_PZN_ID:
      return produce(state, (draft) => {
        const { ids } = payload
        draft.currentKeywords = Object.keys(state.currentKeywords).reduce(
          (acc, next) => {
            const cp = { ...acc }
            if (ids.includes(next)) {
              cp.currentKeywords[next].keywords = []
            }
            return cp
          },
          {},
        )
      })
    case REMOVE_ALL_KEYWORDS_FOR_SINGLE_PZN_BY_PZN_ID:
      return produce(state, (draft) => {
        const { id } = payload
        draft.currentKeywords[id].keywords = []
      })

    case ASSIGN_KEYWORDS:
      return produce(state, (draft) => {
        payload.forEach((kwd) => {
          if (!draft.currentKeywords[kwd.pznCId]) {
            draft.currentKeywords[kwd.pznCId] = {}
          }
          if (!draft.currentKeywords[kwd.pznCId].keywords) {
            draft.currentKeywords[kwd.pznCId].keywords = []
          }
          draft.currentKeywords[kwd.pznCId].keywords.push({
            ...kwd,
            keyword: kwd.name,
            id: kwd.keywordId,
            keywordPznId: kwd.id,
          })
        })
      })
    case SET_ALL_KEYWORDS:
      return produce(state, (draft) => {
        draft.keywords = payload
      })
    case SET_CRAWLEDKW_VALUE:
      return produce(state, (draft) => {
        const { pznCId, value, keywordPznId, updatedAt, userUpdated } = payload
        const indexOfTarget = draft.currentKeywords[pznCId].keywords.findIndex(
          (k) => k.keywordPznId === keywordPznId,
        )
        draft.currentKeywords[pznCId].keywords[indexOfTarget].crawlerKw = value
        draft.currentKeywords[pznCId].keywords[indexOfTarget].updatedAt =
          updatedAt
        draft.currentKeywords[pznCId].keywords[indexOfTarget].userUpdated =
          userUpdated
      })
    case SET_SEARCHKW_VALUE:
      return produce(state, (draft) => {
        const { pznCId, value, keywordPznId, updatedAt, userUpdated } = payload
        const indexOfTarget = draft.currentKeywords[pznCId].keywords.findIndex(
          (k) => k.keywordPznId === keywordPznId,
        )
        draft.currentKeywords[pznCId].keywords[indexOfTarget].searchKw = value
        draft.currentKeywords[pznCId].keywords[indexOfTarget].updatedAt =
          updatedAt
        draft.currentKeywords[pznCId].keywords[indexOfTarget].userUpdated =
          userUpdated
      })
    case CONFIRM_ABDA:
      return produce(state, (draft) => {
        const { pznCId, keywordPznId, userUpdated, updatedAt } = payload
        const indexOfTarget = draft.currentKeywords[pznCId].keywords.findIndex(
          (k) => k.keywordPznId === keywordPznId,
        )
        draft.currentKeywords[pznCId].keywords[indexOfTarget].abda = 0
        draft.currentKeywords[pznCId].keywords[indexOfTarget].userUpdated =
          userUpdated
        draft.currentKeywords[pznCId].keywords[indexOfTarget].updatedAt =
          updatedAt
      })

    default:
      return state
  }
}
