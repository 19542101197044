import React, { lazy, Suspense, Fragment } from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'
import DashboardLayout from 'src/layouts/DashboardLayout'
import LegalLayout from 'src/layouts/LegalLayout'
import MainLayout from 'src/layouts/MainLayout'
import LoadingScreen from 'src/components/LoadingScreen'
import AuthGuard from 'src/components/AuthGuard'
import GuestGuard from 'src/components/GuestGuard'
import AdminAndInternalGuard from 'src/components/AdminAndInternalGuard'
import AdminGuard from 'src/components/AdminGuard'
import ScopeGuard from 'src/components/ScopeGuard'
import { useSelector } from 'react-redux'
import Redirecter from 'src/components/Redirecter'
import componentLoader from 'src/utils/componentLoader'
import Home from './views/pages/Home'

const routesConfig = (user) => {
  const adminRoutesConfig = [
    {
      exact: true,
      path: '/',
      component: () => <Redirecter />,
    },
    {
      exact: true,
      path: '/404',
      layout: user ? DashboardLayout : LegalLayout,
      component: lazy(() => import('src/views/pages/Error404View')),
    },
    {
      exact: true,
      guard: GuestGuard,
      path: '/login',
      component: lazy(() => import('src/views/auth/LoginView')),
    },
    {
      exact: true,
      guard: GuestGuard,
      path: '/auth/user/account/:hash',
      component: lazy(() => import('src/views/auth/ActivateAccountView')),
    },
    {
      path: '/sites',
      layout: LegalLayout,
      routes: [
        {
          exact: true,
          path: '/sites/impressum',
          component: lazy(() => import('src/views/legal/Impressum')),
        },
        {
          exact: true,
          path: '/sites/implementation-details',
          component: lazy(() =>
            import('src/views/management/ImplementationDetails'),
          ),
        },
        {
          exact: true,
          path: '/sites/privacy',
          component: lazy(() => import('src/views/legal/Privacy')),
        },
        {
          exact: true,
          path: '/sites/release-notes',
          component: lazy(() => import('src/views/pages/ReleaseNotes')),
        },
      ],
    },
    {
      path: '/download',
      routes: [
        {
          exact: true,
          path: '/download/zip/:zipKey',
          component: lazy(() => import('src/views/pages/DownloadPackshots')),
        },
      ],
    },
    {
      path: '/forgot-password',
      routes: [
        {
          exact: true,
          path: '/forgot-password',
          component: lazy(() => import('src/views/pages/ForgotPassword')),
        },
      ],
    },
    {
      path: '/reset-password',
      routes: [
        {
          exact: true,
          path: '/reset-password',
          component: lazy(() => import('src/views/pages/ResetPassword')),
        },
      ],
    },
    {
      path: '/recovery-code',
      routes: [
        {
          exact: true,
          path: '/recovery-code',
          component: lazy(() => import('src/views/pages/RecoveryCode')),
        },
      ],
    },
    {
      path: '/app',
      guard: AuthGuard,
      layout: DashboardLayout,
      routes: [
        {
          exact: true,
          path: '/app/home',
          component: () => <Home />,
        },
        {
          exact: true,
          path: '/app',
          component: () => <Redirect to="/app/management/amazon" />,
        },

        {
          exact: true,
          guard: AdminGuard,
          path: '/app/management/users',
          component: lazy(() =>
            componentLoader(() => import('src/views/management/UsersListView')),
          ),
        },
        {
          exact: true,
          guard: AdminGuard,
          path: '/app/management/shop-contacts',
          component: lazy(() =>
            componentLoader(() =>
              import('src/views/management/ShopContactsView'),
            ),
          ),
        },
        {
          exact: true,
          guard: AdminGuard,
          scope: 'ondemandusage',
          path: '/app/users/on-demand-usage',
          component: lazy(() =>
            componentLoader(() =>
              import('src/views/management/OnDemandTracking'),
            ),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'ondemandschedule',
          path: '/app/users/on-demand-scheduler',
          component: lazy(() =>
            componentLoader(() => import('src/views/management/Scheduler')),
          ),
        },
        {
          exact: true,
          guard: AdminAndInternalGuard,
          path: '/app/admin/management/bookmarks',
          component: lazy(() =>
            componentLoader(() => import('src/views/management/Bookmarks')),
          ),
        },
        {
          exact: true,
          guard: AdminGuard,
          path: '/app/admin/management/templates',
          component: lazy(() =>
            componentLoader(() => import('src/views/management/Templates')),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'smilecustomers',
          path: '/app/management/customers',
          component: lazy(() =>
            componentLoader(() =>
              import('src/views/management/CustomersPanel'),
            ),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'bookedservices',
          path: '/app/management/booked-services',
          component: lazy(() =>
            componentLoader(() =>
              import('src/views/management/BookedServices'),
            ),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'pznoverview',
          path: '/app/management/pzn-overview',
          component: lazy(() =>
            componentLoader(() => import('src/views/management/PznOverview')),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'amazon',
          path: '/app/management/amazon',
          component: lazy(() =>
            componentLoader(() =>
              import('src/views/management/AmazonListView'),
            ),
          ),
        },

        {
          exact: true,
          guard: ScopeGuard,
          scope: 'bundles',
          path: '/app/management/bundles',
          component: lazy(() =>
            componentLoader(() => import('src/views/management/Bundles')),
          ),
        },
        {
          exact: true,
          guard: AdminGuard,
          path: '/app/management/wkz-slot-config',
          component: lazy(() =>
            componentLoader(() =>
              import('src/views/management/wkzSlotsConfig'),
            ),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'wkz',
          path: '/app/management/wkz-media',
          component: lazy(() =>
            componentLoader(() => import('src/views/management/wkzMediaList')),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'wkz',
          path: '/app/management/wkz-data-entry-config',
          component: lazy(() =>
            componentLoader(() =>
              import('src/views/management/WkzDataInputConfig'),
            ),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'wkz',
          path: '/app/management/wkz-data-entry',
          component: lazy(() =>
            componentLoader(() => import('src/views/management/WkzDataEntry')),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'newsletter',
          path: '/app/management/newsletter-config',
          component: lazy(() =>
            componentLoader(() => import('src/views/management/Newsletter')),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'dam',
          path: '/app/management/dam/pzn',
          component: lazy(() =>
            componentLoader(() => import('src/views/management/DAM/PZN')),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'keyword',
          path: '/app/management/keywords',
          component: lazy(() =>
            componentLoader(() => import('src/views/management/Keywords')),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'smilegpt',
          path: '/app/management/ratings-gpt',
          component: lazy(() =>
            componentLoader(() => import('src/views/management/RatingGPT')),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'mankeywords',
          path: '/app/management/keywords-management',
          component: lazy(() =>
            componentLoader(() => import('src/views/management/KeywordMgmt')),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'brand',
          path: '/app/management/brands-management',
          component: lazy(() =>
            componentLoader(() => import('src/views/management/Brands')),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'articlenames',
          path: '/app/management/article-names',
          component: lazy(() =>
            componentLoader(() => import('src/views/management/ArticleNames')),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'abdakeywords',
          path: '/app/management/abda-keywords',
          component: lazy(() =>
            componentLoader(() => import('src/views/management/AbdaKeywords')),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'contacts',
          path: '/app/management/contacts',
          component: lazy(() =>
            componentLoader(() => import('src/views/management/Contacts')),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'emailtemplates',
          path: '/app/management/email-templates',
          component: lazy(() =>
            componentLoader(() =>
              import('src/views/management/ContactsTemplates'),
            ),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'subbrandsettings',
          path: '/app/management/communication-subbrands',
          component: lazy(() =>
            componentLoader(() =>
              import('src/views/management/CommunicationSubbrands'),
            ),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'promptfactory',
          path: '/app/management/prompt-factory',
          component: lazy(() =>
            componentLoader(() => import('src/views/management/PromptFactory')),
          ),
        },
        // {
        //   exact: true,
        //   guard: ScopeGuard,
        //   scope: 'category',
        //   path: '/app/management/abda-katsoll',
        //   component: lazy(() =>
        //     componentLoader(() => import('src/views/management/AbdaKatSoll')),
        //   ),
        // },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'category',
          path: '/app/management/katsoll',
          component: lazy(() =>
            componentLoader(() => import('src/views/management/KatSoll')),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'mymarkets',
          path: '/app/management/my-markets',
          component: lazy(() =>
            componentLoader(() => import('src/views/management/MyMarkets')),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'mymarkets',
          path: '/app/management/upload-excel',
          component: lazy(() =>
            componentLoader(() => import('src/views/management/UploadExcel')),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'qlikfilterpresets',
          path: '/app/management/preset-filters',
          component: lazy(() =>
            componentLoader(() => import('src/views/management/PresetFilters')),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'googleshopping',
          path: '/app/management/gshopping',
          component: lazy(() =>
            componentLoader(() =>
              import('src/views/management/GoogleShopping'),
            ),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'dam',
          path: '/app/management/dam/sending-history',
          component: lazy(() =>
            componentLoader(() =>
              import('src/views/management/SendingHistory'),
            ),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'pacrating',
          path: '/app/management/packshots-rating',
          component: lazy(() =>
            componentLoader(() =>
              import('src/views/management/PackshotsRating'),
            ),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'descrating',
          path: '/app/management/description-rating',
          component: lazy(() =>
            componentLoader(() =>
              import('src/views/management/DescriptionRating'),
            ),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'pzn',
          path: '/app/management/pzn',
          component: lazy(() =>
            componentLoader(() =>
              import('src/views/management/PznDefinitionManager'),
            ),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'pzn',
          path: '/app/management/pad',
          component: lazy(() =>
            componentLoader(() => import('src/views/management/DAM/PZN')),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'ondemandcrawling',
          path: '/app/management/on-demand-crawling',
          component: lazy(() =>
            componentLoader(() => import('src/views/management/OnDemandCrawl')),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'targetgroups',
          path: '/app/management/target-groups',
          component: lazy(() =>
            componentLoader(() => import('src/views/management/TargetGroups')),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'tilesfactory',
          path: '/app/management/tiles-factory',
          component: lazy(() =>
            componentLoader(() => import('src/views/management/TilesFactory')),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'tonality',
          path: '/app/management/tonalities',
          component: lazy(() =>
            componentLoader(() => import('src/views/management/Tonalities')),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'moods',
          path: '/app/management/moods',
          component: lazy(() =>
            componentLoader(() => import('src/views/management/Moods')),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'rxproducttopics',
          path: '/app/management/product-topics',
          component: lazy(() =>
            componentLoader(() => import('src/views/management/ProductTopics')),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'internationalization',
          path: '/app/management/internationalization',
          component: lazy(() =>
            componentLoader(() =>
              import('src/views/management/Internationalization'),
            ),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'styles',
          path: '/app/management/styles',
          component: lazy(() =>
            componentLoader(() => import('src/views/management/Styles')),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'objectives',
          path: '/app/management/objectives',
          component: lazy(() =>
            componentLoader(() => import('src/views/management/Objectives')),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'conny',
          path: '/app/implementation-details',
          component: lazy(() =>
            componentLoader(() =>
              import('src/views/management/ImplementationDetails'),
            ),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scope: 'avbanalytics',
          path: '/app/management/availability-analytics',
          component: lazy(() =>
            componentLoader(() =>
              import('src/views/management/AvailabilityAnalytics'),
            ),
          ),
        },
        {
          exact: true,
          guard: ScopeGuard,
          scppe: 'dam',
          path: '/app/management/dam/rating',
          component: () => <Redirect to="/app/management/packshotsRating" />,
        },
        {
          component: () => <Redirect to="/404" />,
        },
      ],
    },
    {
      path: '*',
      layout: MainLayout,
      routes: [
        {
          component: () => <Redirect to="/404" />,
        },
      ],
    },
  ]

  return adminRoutesConfig
}

const renderRoutes = (routes) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment
          const Layout = route.layout || Fragment
          const Component = route.component
          const key = `${route.path}${i}`
          return (
            <Route
              key={key}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <>
                  {route.guard ? (
                    <Guard scope={route.scope}>
                      <Layout>
                        {route.routes ? (
                          renderRoutes(route.routes)
                        ) : (
                          <Component {...props} />
                        )}
                      </Layout>
                    </Guard>
                  ) : (
                    <Guard>
                      <Layout>
                        {route.routes ? (
                          renderRoutes(route.routes)
                        ) : (
                          <Component {...props} />
                        )}
                      </Layout>
                    </Guard>
                  )}
                </>
              )}
            />
          )
        })}
      </Switch>
    </Suspense>
  ) : null

function Routes() {
  const { user } = useSelector((state) => state.account)
  return renderRoutes(routesConfig(user))
}

export default Routes
