import axios from 'src/utils/axios'
import { errorHandler } from 'src/utils/errorHandler'

export const REDUCER_NAME = '@bundles'

export const FETCHING_IN_PROGRESS = '@bundles/FETHING_IN_PROGRESS'
export const FETCHING_ERROR = '@bundles/FETCHING_ERROR'
export const FETCHING_SUCCESS = '@bundles/FETCHING_SUCCESS'
export const UPDATE_FILTERS = '@bundles/UPDATE_FILTERS'
export const RESET_FILTERS = '@bundles/RESET_FILTERS'
export const PAGE_CHANGE = '@bundles/PAGE_CHANGE'
export const POPULATE_DROPDOWN_VALUES = '@bundles/POPULATE_DROPDOWN_VALUES'

export const OPEN_DRAWER = '@bundles/OPEN_DRAWER'
export const ENTER_EDIT_MODE = '@bundles/ENTER_EDIT_MODE'
export const EXIT_EDIT_MODE = '@bundles/EXIT_EDIT_MODE'

export const CONFIRM_BUNDLE = '@bundles/CONFIRM_BUNDLE'
export const UNCONFIRM_BUNDLE = '@bundles/UNCONFIRM_BUNDLE'

export const UPDATE_UNDERLYING_PZN = '@bundles/UPDATE_UNDERLYING_PZN'
export const UPDATE_UNDERLYING_AMOUNT = '@bundles/UPDATE_UNDERLYING_AMOUNT'
export const CONFIRM_UNDERLYING_PZN = '@bundles/CONFIRM_UNDERLYING_PZN'
export const ADD_UNDERLYING_PZN = '@bundles/ADD_UNDERLYING_PZN'
export const DELETE_UNDERLYING_PZN = '@bundles/DELETE_UNDERLYING_PZN'
export const EDIT_BUNDLE_TYPE = '@bundles/EDIT_BUNDLE_TYPE'
export const MAKE_BUNDLE_IRRELEVANT = '@bundles/MAKE_BUNDLE_IRRELEVANT'
export const MAKE_BUNDLE_RELEVANT = '@bundles/MAKE_BUNDLE_RELEVANT'
export const TOGGLE_BUNDLE_SELECTION = '@bundles/TOGGLE_BUNDLE_SELECTION'
export const ACTIVATE_PZN_ADDING_SIDEBAR =
  '@bundles/ACTIVATE_PZN_ADDING_SIDEBAR'

/**
 * Pick all loaded results and change flag them as "seen"
 * @param {Object} products - loaded results on the page
 * @param {Array} ids - ids of the loaded results on the page
 * @returns {Promise<Void>}
 */
export const registerSeenBundles = async (products, ids) => {
  try {
    if (!ids?.length) {
      return
    }

    // iterate over bundles and create array of objects for request
    const seenArray = ids.reduce((acc, nextId) => {
      const { id, seen } = products[nextId]
      // if bundle is already seen do not add it to request array
      if (seen !== 'seen') {
        acc.push({
          bundle_id: id,
          seen,
        })
      }
      return acc
    }, [])

    // if all bundles are already seen by user there is no need to send request
    if (seenArray.length) {
      await axios.post('/bundles/add-bundle-seen-info', {
        seenData: [...seenArray],
      })
    }
  } catch (e) {
    errorHandler(e, {})
  }
}

export const getBundles =
  ({ page = 1, filters = {}, source } = {}) =>
  async (dispatch) => {
    dispatch({
      type: FETCHING_IN_PROGRESS,
    })

    try {
      const params = {
        page,
        manufacturer: filters?.manufacturer?.manId || null,
        shop: filters?.shop || null,
        type: filters?.type?.type || null,
        status: filters?.status?.status || null,
        title: filters?.title || null,
        smilePzn: filters?.isSmilePzn?.value.toString() || null,
      }

      const { data } = await axios.get(`/bundles`, {
        params,
        cancelToken: source?.token,
      })
      const products = !Array.isArray(data.data) ? data.data : {}
      const count = data.total
      const { ids, percentage } = data
      const percentageNumber = Number(percentage)
      registerSeenBundles(products, ids)

      return dispatch({
        type: FETCHING_SUCCESS,
        payload: {
          products,
          count,
          ids,
          percentage: percentageNumber,
        },
      })
    } catch (e) {
      if (axios.isCancel(e)) {
        return null
      }
      dispatch({
        type: FETCHING_ERROR,
      })
      return errorHandler(e, {})
    }
  }

/**
 * @typedef ImportShopsAndManufacturersServiceResponse
 * @property {Array} manufacturers - all manufacturers needed for filtering bundles
 * @property {Array} shops - all shops needed for filtering bundles
 */
/**
 * Get values needed for filters we are using in the dropdown, fetch for shops and manufacturers values
 * @returns {Promise<ImportShopsAndManufacturersServiceResponse>}
 */
export const getDropdownValues = () => async (dispatch) => {
  try {
    const { data } = await axios.get('/bundles/filters')
    return dispatch({
      type: POPULATE_DROPDOWN_VALUES,
      payload: data,
    })
  } catch (e) {
    return errorHandler(e, {})
  }
}

/**
 *
 * @typedef ConfirmBundleParams
 * @property {string} bundleId - pbn of the bundle
 * @property {number} bundleType - type of the bundle
 */

/**
 * @typedef ConfirmBundleResponse
 * @property {string} date - confirmation date
 * @property {boolean} isAvailable - is bundle available
 * @property {string} status - status of the bundle
 * @property {string} username - username of the user confirmed bundle
 */
/**
 * Confirms entire bundle
 * @param {ConfirmBundleParams} params
 * @returns {Promise<ConfirmBundleResponse>}
 */
export const confirmBundleAction =
  ({ bundleId, bundleType }) =>
  async (dispatch) => {
    try {
      const { data } = await axios.patch('/bundles/confirm-bundle', {
        id: bundleId,
        type: bundleType,
      })
      return dispatch({
        type: CONFIRM_BUNDLE,
        payload: data,
      })
    } catch (e) {
      return errorHandler(e, {})
    }
  }

/**
 * @typedef {Object} ConfirmUnderlyingPznResponse
 * @property {string} date - confirmation date
 * @property {string} status - confirmation status
 * @property {string} username - username of person confirmed underlying pzn
 */

/**
 * Confirms underlying pzn(sku) and returns confirmation data
 * @param {number} id - id of the underlying pzn(sku)
 * @param {number} bundleId - id of the current bundle
 * @return {Promise<ConfirmUnderlyingPznResponse>}
 */
export const confirmUnderlyingPznAction =
  ({ id, bundleId }) =>
  async (dispatch) => {
    try {
      const params = {
        id,
        bundleId,
      }
      const { data } = await axios.patch('/bundles/confirm-single-pzn', params)

      return dispatch({
        type: CONFIRM_UNDERLYING_PZN,
        payload: { ...data, id },
      })
    } catch (e) {
      return errorHandler(e, {})
    }
  }

/**
 * Unconfirms bundle (set bundle status to "unconfirmed")
 * @param {number} bundleId - pbn of the bundle
 * @returns {Promise<Void>}
 */
export const unconfirmBundleAction = (bundleId) => async (dispatch) => {
  try {
    await axios.patch('/bundles/unconfirm-bundle', {
      id: bundleId,
    })
    return dispatch({
      type: UNCONFIRM_BUNDLE,
    })
  } catch (e) {
    return errorHandler(e, {})
  }
}

/**
 * @typedef UpdateUnderlyingPznResponse
 * @property {string} amount - amount value of that pzn
 * @property {string} unit - units related to the amount for example 15 st
 * @property {string} title - title of the pzn
 * @property {number} id - id of the pzn
 * @property {number} bundleId - id of the bundle
 * @property {number|null} cliendID - customer id, depends if user is customer
 */

/**
 * Update underlying pzn
 * @param {Object} param0 - Update params
 * @param {number} param0.id - id of the pzn
 * @param {string} param0.pzn - pzn value
 * @param {number} param0.bundleId - id of the bundle
 * @param {number} param0.orgId - users orgId
 * @return {Promise<UpdateUnderlyingPznResponse>}
 */
export const updateUnderlyingPznAction =
  ({ id, pzn, bundleId, orgId }) =>
  async (dispatch) => {
    try {
      const params = {
        id,
        pzn,
        bundleId,
        orgId,
      }
      const { data } = await axios.patch(
        '/bundles/update-underlying-pzn',
        params,
      )
      return dispatch({
        type: UPDATE_UNDERLYING_PZN,
        payload: { ...data, pzn },
      })
    } catch (e) {
      return errorHandler(e, {})
    }
  }

/**
 *
 * @typedef AddPznParams
 * @property {number} bundleId - id of bundle we want to add this pzn to
 * @property {string} pzn - pzn value
 * @property {number} quantity - quantity of pzn's we are adding
 */

/**
 * Response object structure
 * @typedef AddUnderlyingPZNResponse
 * @property {string} amount - amount of pzn
 * @property {number} bundleId - id of the bundle
 * @property {string} date - current date in DD.MM format
 * @property {number} id - id of added pzn
 * @property {number} quantity - quantity(amount) of added pzn
 * @property {string} status - status of the pzn
 * @property {string} title - title of the pzn
 * @property {string} unit - unit of the pzn
 * @property {string} username - username of the user added pzn
 *
 */

/**
 * Add underlying PZN(SKU)
 * @param {AddPznParams} params
 * @return {Promise<AddUnderlyingPZNResponse>}
 */
export const addUnderlyingPznAction =
  ({ bundleId, pzn, quantity }) =>
  async (dispatch) => {
    try {
      const params = {
        bundleId,
        pzn,
        quantity,
      }

      const { data } = await axios.post(
        '/bundles/add-new-underlying-pzn',
        params,
      )
      return dispatch({
        type: ADD_UNDERLYING_PZN,
        payload: { ...data, pzn },
      })
    } catch (e) {
      return errorHandler(e, {})
    }
  }

/**
 * Delete underlying PZN(SKU)
 * @param {number} pznId - id of PZN(SKU) user wants to delete
 * @returns {Promise<Void>} void
 */
export const deletePznAction = (pznId) => async (dispatch) => {
  try {
    // const params = { id: pznId.toString() }
    const id = pznId.toString()
    await axios.delete(`/bundles/delete-underlying-pzn/${id}`)

    return dispatch({
      type: DELETE_UNDERLYING_PZN,
      payload: pznId,
    })
  } catch (e) {
    return errorHandler(e, {})
  }
}

/**
 * Update amount of the underlying pzn
 * @param {Object} param0 - Update params
 * @param {number} param0.id - id of the element we want to update
 * @param {number} param0.amount - amount to update to
 * @return {Promise<Void>}
 */
export const updateAmountAction =
  ({ id, amount }) =>
  async (dispatch) => {
    try {
      const params = {
        id,
        quantity: amount,
      }

      await axios.patch('/bundles/update-pzn-amount', params)
      return dispatch({
        type: UPDATE_UNDERLYING_AMOUNT,
        payload: { id, amount },
      })
    } catch (e) {
      return errorHandler(e, {})
    }
  }

/**
 * Update values
 * @typedef ChangeBundleTypesParams
 * @property {number} id - id of element we want to update
 * @property {number} type - type we want to update
 */
/**
 * Update bundle type
 * @param {ChangeBundleTypesParams} param
 * @returns {Promise<Void>}
 */
export const updateBundleTypeAction =
  ({ id, type }) =>
  async (dispatch) => {
    try {
      const params = {
        id,
        type,
      }

      await axios.patch('/bundles/update-bundle-type', params)
      return dispatch({
        type: EDIT_BUNDLE_TYPE,
        payload: type,
      })
    } catch (e) {
      return errorHandler(e, {})
    }
  }

export const toggleBundleRelevancyAction =
  ({ type, bundleId, relevant }) =>
  async (dispatch) => {
    try {
      const params = {
        id: bundleId,
        relevant,
      }
      await axios.patch('/bundles/set-relevant-status', params)
      return dispatch({
        type,
      })
    } catch (e) {
      return errorHandler(e, {})
    }
  }

/**
 * Reset filters, set them to initialState values
 */
export const resetFilters = () => (dispatch) =>
  dispatch({ type: RESET_FILTERS })

/**
 * Updates filters
 * @param @oneof {string} target - key of the filter  (`title` | `status` | `manufacturer` | `shop`)
 * @param {string | object} value - new value
 */
export const updateFilterAction = (target, value) => (dispatch) =>
  dispatch({
    type: UPDATE_FILTERS,
    payload: {
      target,
      value,
    },
  })

/**
 * Action that opens sidebar drawer with details
 * @param {string} idx - index of target row
 */
export const toggleDrawer = (idx) => (dispatch) =>
  dispatch({
    type: OPEN_DRAWER,
    payload: idx,
  })

export const pageChangeAction = (page) => (dispatch) =>
  dispatch({
    type: PAGE_CHANGE,
    payload: page,
  })

export const enterEditModeAction = () => (dispatch) =>
  dispatch({
    type: ENTER_EDIT_MODE,
  })

export const exitEditModeAction = () => (dispatch) =>
  dispatch({
    type: EXIT_EDIT_MODE,
  })

export const toggleBundleSelection = (bundle) => (dispatch) =>
  dispatch({ type: TOGGLE_BUNDLE_SELECTION, payload: bundle })

export const togglePznSidebar = () => (dispatch) =>
  dispatch({ type: ACTIVATE_PZN_ADDING_SIDEBAR })
