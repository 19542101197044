import React from 'react'
import { Divider } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(() => ({
  divider: {
    border: '1px solid #B0BEC5',
    position: 'absolute',
    width: '100%',
    left: 0,
  },
}))

export default function AbsoluteDivider({ classes = {} }) {
  const { classes: defaultClasses, cx } = useStyles()
  return (
    <Divider
      classes={{ root: cx(classes.root, defaultClasses.divider) }}
      variant="fullWidth"
    />
  )
}
