import Label from 'src/components/LabelTS'
import { Statuses } from 'src/views/management/KatSoll/hooks/useBackgroundRecomendation'

export default function getLabel(status: Statuses) {
  if (status === 'failed') {
    return <Label color="error">Failed</Label>
  }

  if (status === 'running') {
    return <Label color="warning">Running</Label>
  }

  if (status === 'skipped') {
    return <Label color="gray">Skipped</Label>
  }

  if (status === 'done') {
    return <Label color="success">Done</Label>
  }

  return null
}
