function addDataLayer(key, value) {
  if (window.dataLayer) {
    // followed this guide https://brianclifton.com/blog/2019/11/07/filter-internal-staff-from-google-analytics/
    window.dataLayer.push({ [key]: value })
  }
}

export function addInternalUserDataLayer(user) {
  const isCustomer =
    user?.roles &&
    user.roles.filter((role) => role.name === 'customer').length > 0
  if (!isCustomer) {
    addDataLayer('internalUser', true)
  }
}
