import React from 'react'
import { TextField } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import debounce from 'lodash/debounce'
import Autocomplete from '@mui/material/Autocomplete'
import { useSelector, useDispatch } from 'react-redux'
import { updateFiltersAction, fetchManufacturers } from '../lib/actions'
import { getBrands, getSelectedBrand } from '../lib/selectors'

const useStyles = makeStyles()((theme) => ({
  input: {
    [theme.breakpoints.up('lg')]: {
      width: 210,
    },
    [theme.breakpoints.down('lg')]: {
      flexGrow: 1,
      marginBottom: 5,
      marginTop: 5,
    },
  },
  openDrawer: {
    width: '100%',
  },
}))

function BrandsInput({ handleChange, openDrawer }) {
  const dispatch = useDispatch()
  const { classes, cx } = useStyles()
  const brands = useSelector(getBrands)
  const selectedBrand = useSelector(getSelectedBrand)
  const handleShopChange = (e, newValue) => {
    handleChange(newValue)
    dispatch(fetchManufacturers(newValue))
  }

  const optionsSelected = (option, value) => option.id === value.id

  return (
    <Autocomplete
      className={cx(classes.input, openDrawer && classes.openDrawer)}
      id="rating-brand"
      options={brands}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={optionsSelected}
      value={selectedBrand || null}
      onChange={handleShopChange}
      renderInput={(params) => (
        <TextField {...params} label="Brands" variant="outlined" />
      )}
    />
  )
}

export default function BrandsInputContainer(props) {
  const dispatch = useDispatch()

  const handleChange = (value) => {
    dispatch(updateFiltersAction('brand', value))
  }
  const handleChangeDebounced = debounce(handleChange, 200)
  return <BrandsInput {...props} handleChange={handleChangeDebounced} />
}
