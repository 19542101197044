import useSimpleNotification from 'src/hooks/useSimpleNotification'
import { useSelector, useDispatch } from 'react-redux'
import BasicBlueButton from 'src/components/BasicBlueButton'
import { addNewKatsoll } from '../lib/actions'
import { getThreadValue } from '../lib/selectors'
// import { makeStyles } from '@mui/styles'

export default function AddButton({ kat }) {
  // const classes = useStyles()
  const dispatch = useDispatch()
  const thread = useSelector(getThreadValue(kat.subBrandId)) || {}
  // const checkedElements = useSelector(getCheckedElements)
  const noti = useSimpleNotification()

  const onClick = () => {
    const { shop, category } = thread
    if (!shop || !category) {
      return noti('error', 'You need to select shop and category')
    }
    const body = {
      subBrandId: kat.subBrandId,
      domainId: shop.id,
      category: category.category,
      categoryLink: category.categoryLink,
      categoryRd: category.categoryRd,
      valid: true,
    }
    dispatch(addNewKatsoll(body))
  }

  return (
    <BasicBlueButton
      // classes={{ btn: clsx(hasCheckedItems && classes.btn) }}
      onClick={onClick}
      text="add"
    />
  )
}
