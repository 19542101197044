function generateActionType(type) {
  return `@nwletter/${type}`
}

export const SELECT_ROW = generateActionType('SELECT_ROW')
export const SET_LETTERS = generateActionType('SET_LETTERS')
export const FETCHING_LETTER = generateActionType('FETCHING_LETTER')
export const FETCHING_LETTER_ERROR = generateActionType('FETCHING_LETTER_ERROR')
export const FETCHING_LETTER_404 = generateActionType('FETCHING_LETTER_404')
export const UPDATE_FILTERS = generateActionType('UPDATE_FILTERS')
export const IMPORT_SHOPS = generateActionType('IMPORT_SHOPS')
export const PAGE_CHANGE = generateActionType('PAGE_CHANGE')
export const CLOSE_SIDEBAR = generateActionType('CLOSE_SIDEBAR')
export const UPDATE_SIDEBAR_VALUE = generateActionType('UPDATE_SIDEBAR_VALUE')
export const OPEN_DRAWER = generateActionType('OPEN_DRAWER')
export const ACTIVATE_ADDING_MODE = generateActionType('ACTIVATE_ADDING_MODE')
export const ADD_NEW_NEWSLETTER = generateActionType('ADD_NEW_NEWSLETTER')
export const UPDATE_NEWSLETTER = generateActionType('UPDATE_NEWSLETTER')
export const DELETE_LETTER = generateActionType('DELETE_LETTER')
