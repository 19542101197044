import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, IconButton, SvgIcon } from '@mui/material'
import { X as CloseIcon } from 'react-feather'
import { useDefaultDrawerStyles } from 'src/theme/drawer'

export default function SidebarHeader({
  onClose,
  drawerDesktopWidth = '50%',
  title = 'title',
}) {
  const { classes: defaultClasses } = useDefaultDrawerStyles({
    destopWidth: drawerDesktopWidth,
  })()

  return (
    <Box p={2}>
      <Box className={defaultClasses.header} data-testid="sidebar-title">
        <Box className={defaultClasses.titleWrapper}>
          <Typography className={defaultClasses.title}>{title}</Typography>
        </Box>
        <Box>
          <IconButton
            onClick={() => onClose()}
            size="large"
            data-testid="sidebar-header-close-icon"
          >
            <SvgIcon>
              <CloseIcon />
            </SvgIcon>
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}

SidebarHeader.propTypes = {
  onClose: PropTypes.func,
  drawerDesktopWidth: PropTypes.string,
  title: PropTypes.string,
}
