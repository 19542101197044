import axios from 'src/utils/axios'
import { errorHandler } from 'src/utils/errorHandler'

export const FETCHING_IN_PROGRESS = '@wkz_media/FETHING_IN_PROGRESS'
export const FETCHING_ERROR = '@wkz_media/FETCHING_ERROR'
export const FETCHING_SUCCESS = '@wkz_media/FETCHING_SUCCESS'
export const UPDATE_FILTERS = '@wkz_media/UPDATE_FILTERS'
export const RESET_FILTERS = '@wkz_media/RESET_FILTERS'
export const PAGE_CHANGE = '@wkz_media/PAGE_CHANGE'
export const POPULATE_DROPDOWN_VALUES = '@wkz_media/POPULATE_DROPDOWN_VALUES'
export const OPEN_ROW = '@wkz_media/OPEN_ROW'
export const CLOSE_SIDEBAR = '@wkz_media/CLOSE_SIDEBAR'

export const FETCHING_DAILY_CHART_IN_PROGRESS =
  '@wkz_media/FETCHING_DAILY_CHART_IN_PROGRESS'
export const FETCHING_DAILY_CHART_ERROR =
  '@wkz_media/FETCHING_DAILY_CHART_ERROR'
export const FETCHING_DAILY_CHART_SUCCESS =
  '@wkz_media/FETCHING_DAILY_CHART_SUCCESS'

export const FETCHING_PRICE_CHART_DATA_IN_PROGRESS =
  '@wkz_media/FETCHING_PRICE_CHART_DATA_IN_PROGRESS'
export const FETCHING_PRICE_CHART_DATA_ERROR =
  '@wkz_media/FETCHING_PRICE_CHART_DATA_ERROR'
export const FETCHING_PRICE_CHART_DATA_SUCCESS =
  '@wkz_media/FETCHING_PRICE_CHART_DATA_SUCCESS'

export const FETCHING_COMPETITOR_CHART_DATA_IN_PROGRESS =
  '@wkz_media/FETCHING_COMPETITOR_CHART_DATA_IN_PROGRESS'
export const FETCHING_COMPETITOR_CHART_DATA_ERROR =
  '@wkz_media/FETCHING_COMPETITOR_CHART_DATA_ERROR'
export const FETCHING_COMPETITOR_CHART_DATA_SUCCESS =
  '@wkz_media/FETCHING_COMPETITOR_CHART_DATA_SUCCESS'

export const fetchWkzMediaSlots =
  ({ page, filters = {}, source }) =>
  async (dispatch) => {
    dispatch({
      type: FETCHING_IN_PROGRESS,
    })

    const manufacturerIds = filters.manufacturer
      ? filters.manufacturer.map((value) => value.id)
      : null

    const brandIds = filters.brand
      ? filters.brand.map((value) => value.id)
      : null
    const shopId = filters.shop ? filters.shop.map((val) => val.id) : null
    const params = {
      page,
      ...filters,
      placement: filters?.placement?.id || null,
      manufacturer: filters?.manufacturer
        ? JSON.stringify(manufacturerIds)
        : null,
      brand: filters?.brand ? JSON.stringify(brandIds) : null,
      shop: filters?.shop ? JSON.stringify(shopId) : null,
    }

    try {
      const res = await axios.get('/wkz/media', {
        params,
        cancelToken: source.token,
      })

      return dispatch({
        type: FETCHING_SUCCESS,
        payload: res.data,
      })
    } catch (e) {
      if (axios.isCancel(e)) {
        return null
      }
      const is404 = e?.response?.status === 404
      if (is404) {
        return dispatch({
          type: FETCHING_SUCCESS,
          payload: {
            slots: [],
            total: 0,
          },
        })
      }

      dispatch({
        type: FETCHING_ERROR,
      })
      return errorHandler(e, {})
    }
  }

export const getDropdownValues = (filters) => async (dispatch) => {
  const manufacturerIds = filters?.manufacturerIds
    ? filters.manufacturerIds.map((val) => val.id)
    : null
  const brandIds = filters?.brandIds
    ? filters.brandIds.map((val) => val.id)
    : null
  const shopIds = filters?.shopId ? filters.shopId.map((val) => val.id) : null

  const params = {
    shopId: shopIds ? JSON.stringify(shopIds) : null,
    manufacturerIds: manufacturerIds ? JSON.stringify(manufacturerIds) : null,
    brandIds: brandIds ? JSON.stringify(brandIds) : null,
    placementId: filters?.placementId?.id ? filters?.placementId?.id : null,
  }

  try {
    const { data } = await axios.get(`/wkz/media/filters`, { params })
    const { filteredShops, manuCustomer, placements, brands } = data

    dispatch({
      type: POPULATE_DROPDOWN_VALUES,
      payload: {
        shops: filteredShops,
        manuCustomer,
        placements,
        brands,
      },
    })

    return manuCustomer
  } catch (e) {
    return errorHandler(e, {})
  }
}

export const getDailyHitsChartValues = (id, cached) => async (dispatch) => {
  try {
    dispatch({
      type: FETCHING_DAILY_CHART_IN_PROGRESS,
    })

    if (!id) {
      return dispatch({
        type: FETCHING_DAILY_CHART_ERROR,
      })
    }

    if (cached) {
      return dispatch({
        type: FETCHING_DAILY_CHART_SUCCESS,
        payload: cached,
      })
    }

    const { data } = await axios.get(`/wkz/media/hits/${id}`)
    const payload = {
      banner_id: data[0].banner_id,
      stats: JSON.parse(data[0].stats),
    }

    return dispatch({
      type: FETCHING_DAILY_CHART_SUCCESS,
      payload,
    })
  } catch (e) {
    dispatch({
      type: FETCHING_DAILY_CHART_ERROR,
    })
    return errorHandler(e, {})
  }
}

export const getPricesChartData = (id, cached) => async (dispatch) => {
  dispatch({
    type: FETCHING_PRICE_CHART_DATA_IN_PROGRESS,
  })

  if (!id) {
    return dispatch({
      type: FETCHING_PRICE_CHART_DATA_ERROR,
    })
  }

  if (cached) {
    return dispatch({
      type: FETCHING_PRICE_CHART_DATA_SUCCESS,
      payload: cached,
    })
  }

  try {
    const { data } = await axios.get(`/wkz/media/price/${id}`)
    return dispatch({
      type: FETCHING_PRICE_CHART_DATA_SUCCESS,
      payload: data,
    })
  } catch (e) {
    dispatch({
      type: FETCHING_PRICE_CHART_DATA_ERROR,
    })
    return errorHandler(e, {})
  }
}

export const getCompetitorChartData =
  ({ id, cached, filters = {}, cacheKey }) =>
  async (dispatch) => {
    dispatch({
      type: FETCHING_COMPETITOR_CHART_DATA_IN_PROGRESS,
    })

    if (!id) {
      return dispatch({
        type: FETCHING_COMPETITOR_CHART_DATA_ERROR,
      })
    }

    if (cached) {
      return dispatch({
        type: FETCHING_COMPETITOR_CHART_DATA_SUCCESS,
        payload: {
          bannerId: id,
          data: cached,
          cacheKey,
        },
      })
    }
    const brands = filters.brand?.length ? filters.brand.map((b) => b.id) : null
    try {
      const params = {
        slotId: id,
        brand: JSON.stringify(brands),
      }

      const { data } = await axios.get(`wkz/media/competitors`, { params })
      return dispatch({
        type: FETCHING_COMPETITOR_CHART_DATA_SUCCESS,
        payload: {
          bannerId: id,
          data,
          cacheKey,
        },
      })
    } catch (e) {
      dispatch({
        type: FETCHING_COMPETITOR_CHART_DATA_ERROR,
      })
      return errorHandler(e, {})
    }
  }

export const updateFilter = (filter, value) => (dispatch) => {
  dispatch({
    type: UPDATE_FILTERS,
    payload: {
      filter,
      value,
    },
  })
}

export const pageChangeAction = (page) => (dispatch) =>
  dispatch({
    type: PAGE_CHANGE,
    payload: page,
  })

export const openRowAction = (index) => (dispatch) =>
  dispatch({
    type: OPEN_ROW,
    payload: index,
  })

export const closeSidebar = () => (dispatch) =>
  dispatch({
    type: CLOSE_SIDEBAR,
  })

export const resetFilters = () => (dispatch) =>
  dispatch({ type: RESET_FILTERS })
