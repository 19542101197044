function generateActionType(type) {
  return `@gshp/${type}`
}
export const SELECT_ROW = generateActionType('SELECT_ROW')
export const SET_FILTERS = generateActionType('SET_FILTERS')
export const UPDATE_FILTERS = generateActionType('UPDATE_FILTERS')
export const CLOSE_SIDEBAR = generateActionType('CLOSE_SIDEBAR')
export const OPEN_DRAWER = generateActionType('OPEN_DRAWER')
export const PAGE_CHANGE = generateActionType('PAGE_CHANGE')
export const SET_RESULTS = generateActionType('SET_RESULTS')
export const FETCHING_RESULTS = generateActionType('FETCHING_RESULTS')
export const FETCHING_ERROR = generateActionType('FETCHING_ERROR')
export const FETCHING_RESULTS_404 = generateActionType('FETCHING_RESULTS_404')
export const UPDATE_ROW = generateActionType('UPDATE_ROW')
export const UPDATE_ACTIVATION_STATUS = generateActionType(
  'UPDATE_ACTIVATION_STATUS',
)
export const UPDATE_CONFIRMATION_STATUS = generateActionType(
  'UPDATE_CONFIRMATION_STATUS',
)
