import React, { useEffect, useState } from 'react'
import { Box, Fade } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useDispatch, useSelector } from 'react-redux'
import { getThreadValue, getCurrentKatSoll } from '../lib/selectors'
import { getKatsollDetails, deleteManyKatsoll } from '../lib/actions'
import KatSollDetails from './KatSollDetails'
import BasicGreyButton from 'src/components/BasicGreyButton'
import CategoryRecomendationModal from './CategoryRecomendationModal'
import axios from 'src/utils/axios'
import { addNewKatsoll } from '../lib/actions'
import useSimpleNotification from 'src/hooks/useSimpleNotification'
import { isAdminSelector } from 'src/selectors/accountSelectors'

const useStyles = makeStyles()((theme) => ({
  btn: {
    width: 170,
  },
}))

export default function KatsollDetailsContainer({ kat }) {
  const { classes } = useStyles()
  const dispatch = useDispatch()
  const thread = useSelector(getThreadValue(kat.subBrandId)) || {}
  const noti = useSimpleNotification()
  const katSollDetails = useSelector(getCurrentKatSoll(kat.subBrandId))
  const [checked, setChecked] = useState(new Map())
  const domainId = thread?.shop?.id || null
  const [selectedLinkForMagic, setSelectedLinkForMagic] = useState(null)
  const isAdmin = useSelector(isAdminSelector)

  useEffect(() => {
    const id = kat?.subBrandId
    if (id && domainId) {
      dispatch(getKatsollDetails({ id, domainId }))
    }
  }, [kat, domainId, dispatch])

  useEffect(() => {
    setChecked(new Map())
  }, [domainId])

  const onChange = (categoryId) => {
    if (checked.has(categoryId)) {
      setChecked((prev) => {
        const copy = new Map(prev)
        copy.delete(categoryId)
        return copy
      })
    } else {
      setChecked((prev) => new Map(prev.set(categoryId, true)))
    }
  }

  const onDeleteMany = async () => {
    await dispatch(
      deleteManyKatsoll(kat.subBrandId, Array.from(checked.keys())),
    )
    setChecked(new Map())
  }

  const handleModalClose = () => {
    setSelectedLinkForMagic(null)
  }

  const onAdd = async (item) => {
    try {
      const { data: category } = await axios.get(
        `/categories/subbrands/category/url`,
        {
          params: {
            url: item.url,
          },
        },
      )

      const body = {
        subBrandId: kat.subBrandId,
        domainId: domainId,
        category: category.category,
        categoryLink: category.category_link,
        categoryRd: category.category_rd,
        valid: true,
      }
      await dispatch(addNewKatsoll(body))
      noti('success')
    } catch (error) {
      console.log(error)
      noti('error')
    }
  }

  return (
    <Box>
      <Box>
        <Fade in={checked.size > 1}>
          <Box mt={1}>
            <BasicGreyButton
              classes={{ root: classes.btn }}
              buttonText="Delete Selected"
              onClick={onDeleteMany}
            />
          </Box>
        </Fade>
      </Box>
      {katSollDetails?.length > 0 &&
        katSollDetails.map((katSoll) => (
          <KatSollDetails
            key={katSoll.categoryId}
            {...katSoll}
            onChange={onChange}
            checked={checked.has(katSoll.categoryId)}
            onMagicClick={() => setSelectedLinkForMagic(katSoll.categoryLink)}
            isValid={katSoll.valid}
          />
        ))}
      <CategoryRecomendationModal
        open={selectedLinkForMagic}
        onClose={handleModalClose}
        domainId={domainId}
        link={selectedLinkForMagic}
        onAdd={onAdd}
      />
    </Box>
  )
}
