import produce from 'immer'
import union from 'lodash/union'
import {
  LOAD_STRATEGY,
  SET_PACKSHOTS,
  SELECT_PACKSHOT,
  SELECT_MANY_PACKSHOTS,
  UNSELECT_PACKSHOT,
  CLEAR_ALL_PACKSHOTS,
  CLEAR_ALL_PACKSHOTS_BY_PZN,
} from 'src/actionTypes/packshotsActionTypes'

const initialState = {
  strategy: null,
  packshots: {},
  selectedPackshots: {},
}
export default function DamPackshotsReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_STRATEGY:
      return produce(state, (draft) => {
        draft.strategy = action.payload
      })
    case SET_PACKSHOTS:
      return produce(state, (draft) => {
        const { pzn, packshots } = action.payload
        draft.packshots[pzn] = packshots
      })
    case SELECT_PACKSHOT:
      return produce(state, (draft) => {
        const { id, pzn } = action.payload
        if (!state.selectedPackshots[pzn]) {
          draft.selectedPackshots[pzn] = []
        }
        draft.selectedPackshots[pzn] = [...draft.selectedPackshots[pzn], id]
      })
    case UNSELECT_PACKSHOT:
      return produce(state, (draft) => {
        const { id, pzn } = action.payload

        draft.selectedPackshots[pzn] = draft.selectedPackshots[pzn].filter(
          (selectedId) => selectedId !== id,
        )
      })
    case SELECT_MANY_PACKSHOTS:
      return produce(state, (draft) => {
        const { packshots, pzn } = action.payload
        draft.selectedPackshots[pzn] = union(
          state.selectedPackshots[pzn],
          packshots,
        )
      })
    case CLEAR_ALL_PACKSHOTS:
      return produce(state, (draft) => {
        draft.packshots = initialState.packshots
        draft.selectedPackshots = initialState.selectedPackshots
      })
    case CLEAR_ALL_PACKSHOTS_BY_PZN:
      return produce(state, (draft) => {
        const { [action.payload]: omitPackshot, ...restPackshots } =
          state.packshots
        const { [action.payload]: omitSelected, ...restSelected } =
          state.selectedPackshots
        draft.packshots = restPackshots
        draft.selectedPackshots = restSelected
      })
    default:
      return state
  }
}
