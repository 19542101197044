import React from 'react'
import { Box, Card } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  root: {},
  wrapperStyle: {
    // [theme.breakpoints.up("lg")]: {
    //   overflowX: "hidden",
    // },
    [theme.breakpoints.down('lg')]: {
      overflowX: 'scroll',
    },
    overflowY: 'hidden',
  },
}))

export default function TableWrapper({
  children,
  classes: customClasses = {},
  Pagination,
}) {
  const { classes, cx } = useStyles()
  return (
    <Card className={classes.root}>
      <Box className={cx(classes.wrapperStyle, customClasses)}>
        <Box>
          {Pagination}
          {children}
        </Box>
      </Box>
    </Card>
  )
}
