import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Header from 'src/components/TableList/HeaderV2'
import { Typography, Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import BasicGreyButton from 'src/components/BasicGreyButton'
import { openDrawer } from '../actions'
import { openDrawerSelector } from '../selectors'

const useStyles = makeStyles()((theme) => ({
  button: {
    width: '155px',
  },
  addBtn: {
    ...theme.buttons.green,
    width: '155px',
  },
  deleteBtn: {
    ...theme.buttons.red,
    width: '215px',
  },
  btnWrapper: {
    '& > *': {
      marginLeft: 10,
    },
  },
}))

const Title = () => (
  <Typography
    data-testid="customer-panel-header"
    variant="h3"
    color="textPrimary"
  >
    Booked Services
  </Typography>
)

const EndComponent = () => {
  const { classes } = useStyles()
  const openDrawerValue = useSelector(openDrawerSelector)
  const dispatch = useDispatch()

  const onAdd = () => {
    dispatch(openDrawer())
  }

  if (openDrawerValue) {
    return null
  }
  return (
    <Box className={classes.btnWrapper}>
      <BasicGreyButton
        classes={{ root: classes.button }}
        onClick={onAdd}
        buttonText="Add Service"
      />
    </Box>
  )
}

const KeywordsManagementHeader = () => (
  <Header Title={Title} EndComponent={EndComponent} />
)

export default KeywordsManagementHeader
