import Autocomplete from '@mui/material/Autocomplete'
import { TextField } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { getShops, getThreadValue } from '../lib/selectors'
import { updateSidebarValues } from '../lib/actions'

const ShopsInput = ({ className, kat }) => {
  const shops = useSelector(getShops)
  const activeRow = useSelector(getThreadValue(kat.subBrandId)) || {}
  const dispatch = useDispatch()

  const onChange = (val) => {
    dispatch(updateSidebarValues(kat.subBrandId, 'shop', val))
    dispatch(updateSidebarValues(kat.subBrandId, 'category', null))
  }

  return (
    <Autocomplete
      options={shops}
      className={className}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, val) => option.name === val.name}
      renderInput={(params) => (
        <TextField {...params} label="Shops" variant="outlined" />
      )}
      value={activeRow.shop || null}
      onChange={(e, val) => onChange(val)}
    />
  )
}

export default ShopsInput
